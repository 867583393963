import {PickGuid} from "../../../models/types";
import {Company} from "../../../models/company/companyModel";
import {GenericReason} from "./types";
import {JSONList} from "../../../models/utils/jsonList";
import {assertOk} from "../../../utils/requests";

export async function createOrUpdateCompanyReasonsFor(
    type: "lock" | "unlock",
    company: PickGuid<Company>,
    reasons: readonly GenericReason[],
): Promise<JSONList<GenericReason>> {
    const response = await fetch(
        `/companies/${company.guid}/${type}Reasons`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({list: reasons}),
        },
    );
    assertOk(response);
    return response.json();
}