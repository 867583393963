import * as React from "react";

import {Button, H5} from "@danfoss/etui-core";
import {Col, Form, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {
    SettingTemplate,
    SettingTemplateCreation,
    TemplateConfigurationSlave,
} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import DefaultInput from "../common/DefaultInput";
import NameInput from "../common/NameInput";
import RawDataTableCustom from "../common/RawDataTable";
import ConfigurabilityService from "../ConfigurabilityService";
import {Sections, SectionsModal} from "../CreateSettingTemplate";

interface SettingTemplateSlaveProps {
    isModal: boolean;
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    onSubmit: () => void;
    settingTemplateCreation: SettingTemplateCreation;
    setSettingTemplateCreation: (settingTemplateCreation: SettingTemplateCreation) => void;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    hasDefault: boolean;
    setHasDefault: (b: boolean) => void;
}

// tslint:disable:max-line-length
const SettingTemplateSlaveSection: React.FC<SettingTemplateSlaveProps> = (props) => {
    const services = new Services();
    const configurabilityService = new ConfigurabilityService();

    function mandatoryFieldsCheck() {
        const slave = props.currentSettingTemplate.templateContent as TemplateConfigurationSlave;
        const alarms = slave.dataTemplate.alarms.filter((e) => e.checked).length;
        const diagnostics = slave.dataTemplate.diagnostics.filter((e) => e.checked).length;
        return props.selectedDeviceModel == null || services.isEmpty(props.currentSettingTemplate.name) || (alarms + diagnostics === 0);
    }

    function onPrevious() {
        return () => props.setCurrentStep(Sections.deviceModelSection);
    }

    function onSubmit() {
        props.onSubmit();
    }

    return (
        <>
            <Form>
                <Row className={"justify-content-center"}>
                    <Col style={{textAlign: "center"}}>
                        <H5> {props.currentSettingTemplate.guid == null ? "Define " : "Update "}
                            the setting template slave for: <b>{props.selectedDeviceModel.item.name}</b>
                        </H5>
                    </Col>
                </Row>
                <NameInput
                    settingTemplateCreation={props.settingTemplateCreation}
                    setSettingTemplateCreation={props.setSettingTemplateCreation}
                    currentSettingTemplate={props.currentSettingTemplate}
                    setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                />
                <Row className={"mx-2 pb-3"}>
                    <DefaultInput
                        hasDefault={props.hasDefault}
                        settingTemplateCreation={props.settingTemplateCreation}
                        setSettingTemplateCreation={props.setSettingTemplateCreation}
                        currentSettingTemplate={props.currentSettingTemplate}
                        setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                        selectedDeviceModel={props.selectedDeviceModel}
                        setHasDefault={props.setHasDefault}
                    />
                </Row>

                <Row style={{justifyContent: "center"}}>
                    <Col>
                        <RawDataTableCustom
                            section={SectionsModal.rawDataAlarmType}
                            currentSettingTemplate={props.currentSettingTemplate}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                        />
                        <RawDataTableCustom
                            section={SectionsModal.rawDataDiagnosticType}
                            currentSettingTemplate={props.currentSettingTemplate}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                        />
                    </Col>
                </Row>
                {/* Bottom nav buttons */}
                {!props.isModal && (
                    <Row className="w-100 d-flex flex-column">
                        <div className="text-right pt-4">
                            <Button onClick={onPrevious()}>Previous</Button>
                            {" "}
                            <Button disabled={mandatoryFieldsCheck()} variant="strong" onClick={onSubmit}>
                                {props.currentSettingTemplate.guid == null ? "Next" : "Update"}
                            </Button>
                        </div>
                    </Row>
                )}
            </Form>
        </>
    );
};

export default SettingTemplateSlaveSection;
