import {useSearchParams} from "react-router-dom";
import {numberOrElse} from "../../../utils/numbers";
import {Pagination} from "../../common/Pagination";
import * as React from "react";

type SearchParamsPaginationProps = {
    totalNumberOfRows: number;
    width?: number;
};

export function SearchParamsPagination({totalNumberOfRows, width}: SearchParamsPaginationProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const pageSize = numberOrElse(searchParams.get("size"), 10);
    const currentPage = numberOrElse(searchParams.get("page"), 0) + 1;
    const numberOfPages = Math.ceil(totalNumberOfRows / pageSize);

    function changePage(page: number) {
        setSearchParams((prev) => {
            prev.set("page", `${page - 1}`);
            return prev;
        });
    }

    return (
        <Pagination
            first={1}
            last={numberOfPages}
            value={currentPage}
            width={width}
            onChange={changePage}
        />
    );
}