import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {usePromise} from "../../../../../hooks/usePromise";
import {findCompanies} from "../../../api";
import {useDebounceCallback} from "../../../../../hooks/useDebounceCallback";
import {Button} from "../../../../common/Button";
import {constant, doNothing} from "../../../../../utils/functions";
import {Icon} from "../../../../common/Icon";
import {Overlay} from "../../../../common/Overlay";
import {ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX} from "../../../../../hooks/useOverlay";
import {SortButton} from "./SortButton";
import {useForm} from "../../../../../hooks/useForm";
import {Company} from "../../../../../models/company/companyModel";
import {range} from "../../../../../utils/numbers";
import {Skeleton} from "../../../../common/Skeleton";

type CompanyNameColumnFilterProps = {};



type Action =
    {type: "concat", list: string[]}
    | {type: "replace", list: string[], total: number, page: number};


type State = {
    list: string[],
    total: number;
    loading: boolean;
    page: number;
};

function updateCompanyList(state: State, action: Action): State {
    switch (action.type) {
        case "concat": {
            return {
                ...state,
                loading: false,
                list: state.list.concat(action.list)
            };
        }
        case "replace": {
            return {
                loading: false,
                list: action.list,
                total: action.total,
                page: action.page,
            };
        }
    }
}



export function CompanyNameColumnFilterProps({}: CompanyNameColumnFilterProps) {

    const [open, setOpen] = React.useState(false);

    // todo - maybe move within children or maybe create a context
    const [searchParams, setSearchParams] = useSearchParams();

    const {data, state, refresh} = usePromise(findCompanies, [{
        page: 0,
        size: 20,
        sort: ["name,asc"],
        searchTerm: ""
    } as any]);


    // accumulation of companies

    const [x, d] = React.useReducer(updateCompanyList, { list: [], page: 0, total: 0, loading: true});


    const [companies, setCompanies] = React.useState<Company[]>([]);
    React.useEffect(() => {
        if (data?.list != null) {
            setCompanies(companies.concat(data.list));
        }
    }, [data?.list]);

    const searchCompanies = useDebounceCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 3) {
            setCompanies([]);
            refresh([{
                page: 0,
                size: 20,
                sort: ["name,asc"],
                searchTerm: e.target.value
            } as any]);
        }
    }, 300);

    const {register, handleSubmit} = useForm({defaultValues: { companies: [] }});

    function updatePageSearchURLParams(data: {companies: string[]}) {
        setSearchParams((prev) => {
            prev.delete("company");
            data.companies.forEach((companyGuid) => prev.append("company", companyGuid));
            return prev;
        })
    }

    const canSortBeDisabled = searchParams
        .getAll("sort")
        .findIndex((str) => str.startsWith("name")) > -1;


    const formId = "companies-filter-by-name";

    return (
        <>
            <Button onClick={constant(setOpen, !open)}><Icon name={"filter_list"}/></Button>
            <form id={formId} onSubmit={handleSubmit(updatePageSearchURLParams)}/>
            {open &&
                <Overlay
                    className={"column-filter-panel"}
                    asTag={"menu"}
                    positioned={ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX}
                >
                    <li>
                        <SortButton propertyName={"name"} order={"desc"} onChange={setSearchParams}>
                            From A to Z
                        </SortButton>
                    </li>
                    <li>
                        <SortButton propertyName={"name"} order={"asc"} onChange={setSearchParams}>
                            From Z to A
                        </SortButton>
                    </li>
                    {canSortBeDisabled && (
                        <li>
                            <SortButton propertyName={"name"} order={"none"} onChange={setSearchParams}>
                                Disable sort
                            </SortButton>
                        </li>
                    )}
                    <hr/>
                    <li>
                        <label>
                            <input
                                type={"text"}
                                onChange={searchCompanies}
                                placeholder={"Type partial value to search"}
                            />
                        </label>
                        <ul className={"value-multiselector"}>
                            {companies.map((c) => {
                                return (
                                    <li key={c.guid}>
                                        <input
                                            type={"checkbox"}
                                            {...register("companies")}
                                            value={c.guid}
                                            form={formId}
                                        />
                                        {c.name}
                                    </li>
                                );
                            })}
                            <ListEnd onIntersecting={doNothing}/>
                        </ul>
                    </li>
                    <footer>
                        <Button form={formId}>Apply</Button>
                    </footer>
                </Overlay>
            }
        </>
    );
}


type ListEndProps = {
    onIntersecting?(): void;
};


export function ListEnd({onIntersecting}: ListEndProps) {
    const ref = useIntersectionObserver<HTMLLIElement>((entry) => {
        if (entry.isIntersecting) {
            onIntersecting?.();
        }
    });
    return (
        <li ref={ref}/>
    );
}


type UseIntersectionObserverCallback = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => void;

export function useIntersectionObserver<T extends Element>(callback: UseIntersectionObserverCallback) {

    const ref = React.useRef<T>();

    React.useEffect(() => {

        const observer = new IntersectionObserver(([entry], self) => {
            callback(entry, self);
        });

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        }

    },[]);

    return ref;
}

