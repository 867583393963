import * as React from "react";
import {Modal} from "react-bootstrap";
import {DeviceCore} from "../../../../../models/device/device";
import {LocationCore} from "../../../../../models/location/location";
import {ItemDetailView} from "../../../../common/ItemDetailView";
import {DeviceCoreInfoView} from "../DeviceCoreInfoView";

interface ValidDeviceInfoModalProps {
    show: boolean;
    device: DeviceCore;
    location?: LocationCore;
    enterDateStr?: string;
    onClose(): void;
}

export function ValidDeviceInfoModal({show, device, location, enterDateStr, onClose}: ValidDeviceInfoModalProps) {
    const enterDateISOStr = enterDateStr?.substring(0, 29);
    const enterDate = new Date(enterDateISOStr);
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton={true}>
                <h4>Device</h4>
            </Modal.Header>
            <Modal.Body className={"device-info"}>
                <DeviceCoreInfoView device={device}/>
                {(location || !isNaN(Number(enterDate))) ?
                    (
                        <section className={"device-storage-info"}>
                            <h5>Storage</h5>
                            <div className={"device-info-grid"}>
                                <span>Location</span>
                                <ItemDetailView
                                    heading={location?.description ?? location?.code ?? "-"}
                                    subheading={location?.address?.fullAddress}
                                />
                                <span>Enter date</span>
                                <ItemDetailView
                                    heading={!isNaN(Number(enterDate))
                                        ? enterDate.toLocaleDateString()
                                        : "-"}
                                    subheading={`${!isNaN(Number(enterDate)) ? enterDate.toLocaleTimeString() : ""} ${location?.timezoneName ?? ""}`}
                                />
                            </div>
                        </section>
                    ) : null
                }
            </Modal.Body>
        </Modal>
    );
}
