import * as React from "react";
import {getAllComponentsModelMedia} from "../../api";
import {StatefulMap, useMap} from "../../../../hooks/useMap";
import {Media} from "../../../../models/media/mediaModel";
import {identity} from "../../../../utils/functions";
import {consumeJSONList} from "../../../../utils/json";
import {toMap} from "../../../../utils/maps";

interface CompaniesComponentModelsMediasContextType {
    medias: StatefulMap<string, Media>;
    refresh(): void;
}

const CompaniesComponentModelsMediasContext = React.createContext<CompaniesComponentModelsMediasContextType>(null);

export function useCompaniesComponentModelsMedias() {
    return React.useContext(CompaniesComponentModelsMediasContext);
}


// ToDo deprecate. Implement usePromise(, {cacheKey: "" })
export function CompaniesComponentModelsMediasProvider({children}: React.PropsWithChildren<unknown>) {

    const medias = useMap<string, Media>();

    function refresh() {
        getAllComponentsModelMedia()
            .then(consumeJSONList(toMap<Media, string>((media) => media.guid, identity)))
            .then(medias.from)
            .catch(console.error);
    }

    React.useEffect(() => {
        refresh();
    }, []);

    return (
        <CompaniesComponentModelsMediasContext.Provider value={{medias, refresh}}>
            {children}
        </CompaniesComponentModelsMediasContext.Provider>
    );
}
