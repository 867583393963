import * as React from "react";

type Callback = (...x: unknown[]) => unknown;

interface Options<C extends Callback> {
    onLeading?(...x: Parameters<C>): void;
}

export function useDebounceCallback<C extends Callback>(callback: C, ms: number, options?: Options<C>) {
    const timeoutId = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        return () => {
            clearTimeout(timeoutId.current);
        };
    }, []);

    return (...x: Parameters<C>) => {
        clearTimeout(timeoutId.current);
        options?.onLeading?.(...x);
        timeoutId.current = setTimeout(() => {
            callback(...x);
        }, ms);
    };
}
