import * as React from "react";
import {Td} from "../../common/DataTable";
import {LocationCore} from "../../../models/location/location";

import "./style.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {Company} from "../../../models/company/companyModel";

type LocationCellViewProps = React.HTMLAttributes<HTMLTableDataCellElement> & {
    company: Company;
    location: LocationCore;
    fallback: React.ReactNode;
};

export function LocationCellView({ company, location, fallback, ...tdProps}: LocationCellViewProps) {

    const navigate = useNavigate();
    const routeLocation = useLocation();

    function openEdit() {
        navigate(
            `${company.guid}/defaultWarehouse/edit`,
            {
                state: {
                    company,
                    origin: `${routeLocation.pathname}${routeLocation.search}`,
                    currentDefaultWarehouse: location,
                },
                replace: false
            },
        );
    }

    if (location == null) {
        return (
            <Td {...tdProps} onClick={openEdit}>{fallback}</Td>
        );
    }

    if (location.description == null) {
        return (
            <Td {...tdProps} onClick={openEdit}>{location.address.fullAddress}</Td>
        );
    }

    return (
        <Td
            {...tdProps}
            className={`location-cell-view-with-description ${tdProps.className ?? ""}`}
            onClick={openEdit}
        >
            <p>{location?.description}</p>
            <small>{location.address.fullAddress}</small>
        </Td>
    );
}