import {JSONEntitiesCollection, JSONList} from "../../models/utils/jsonList";
import {Company, CompanyComponentModel, CompanyExtraInformation} from "../../models/company/companyModel";
import {LocationCore} from "../../models/location/location";
import {assertOk, toRequestParamsString} from "../../utils/requests";
import {PickGuid} from "../../models/types";
import {CompanyAlarmType} from "../../models/alarm/alarmType";
import {CompanyComponentType} from "../../models/component/componentType";

type FindCompaniesParams =
    { componentTypeGuid: string } |
    { companyRelationType?: "ALL" | "PARENT_COMPANIES" | "CHILD_COMPANIES", partialName?: string};

type FindPaginatedCompanies = {
    page: number,
    size: number,
    sort?: string[],
    searchTerm?: string,
};
export async function findCompanies(params?: FindPaginatedCompanies): Promise<JSONEntitiesCollection<Company>>;
export async function findCompanies(params?: FindCompaniesParams): Promise<JSONList<Company>>;
export async function findCompanies(params?: unknown) {
    const paramString: string = toRequestParamsString(params);
    const response = await fetch(`/companies?${paramString}`);
    assertOk(response);
    return response.json();
}

interface GetCompanyLocationsParams {
    partialLocationName?: string;
    alwaysIncludeDefaultWarehouse?: boolean;
    includeDummies?: boolean;
    limit?: number;
    offset?: number;
}

export async function getCompanyLocations(
    companyGuid: string,
    params: GetCompanyLocationsParams = {},
    signal: AbortSignal = null,
): Promise<JSONList<LocationCore>> {
    const paramString: string = toRequestParamsString(params);
    const response = await fetch(`/companies/${companyGuid}/locations?${paramString}`, {signal});
    assertOk(response);
    return response.json();
}

export async function getCompaniesComponentTypes(companies: PickGuid<Company>[]): Promise<JSONList<CompanyComponentType>> {
    const paramString: string = toRequestParamsString({guid: companies.map(({guid}) => guid)});
    const response = await fetch(`/companies/componentTypes?${paramString}`);
    assertOk(response);
    return response.json();
}

export async function getCompaniesComponentModels(companies: PickGuid<Company>[]): Promise<JSONList<CompanyComponentModel>> {
    const paramString: string = toRequestParamsString({guid: companies.map(({guid}) => guid)});
    const response = await fetch(`/companies/componentModels?${paramString}`);
    assertOk(response);
    return response.json();
}

export async function getCompaniesAlarmTypes(companies: PickGuid<Company>[]): Promise<JSONList<CompanyAlarmType>> {
    return {list: []};
    // const paramString: string = toRequestParamsString({guid: companies.map(({guid}) => guid)});
    // const response = await fetch(`/companies/alarmTypes?${paramString}`);
    // assertOk(response);
    // return response.json();
}


type DefaultWarehouse = {
    companyGuid: string;
    location: LocationCore;
}

export async function getCompaniesDefaultWarehouse(companies: PickGuid<Company>[]): Promise<JSONList<DefaultWarehouse>>{
    const paramString: string = toRequestParamsString({guid: companies.map(({guid}) => guid)});
    const response = await fetch(`/companies/defaultWarehouses?${paramString}`);
    assertOk(response);
    return response.json();
}
