class GeolocationController {
    public async getGeocodeAddress(address, city, country, province, streetNumber): Promise<any> {
        const response = await fetch(
            "/geolocation/geocode_address" +
            "?address=" + address +
            "&city=" + city +
            "&country=" + country +
            "&province=" + province +
            "&streetNumber=" + streetNumber, {
                method: "POST",
            },
        );
        return response.status !== 422 ? response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        ) : {status: response.status, body: {}};
    }
}

export default GeolocationController;
