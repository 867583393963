import * as React from "react";

import {Icon, icons, Tooltip} from "@danfoss/etui-core";
// tslint:disable-next-line:no-submodule-imports
import {TooltipPlacement} from "@danfoss/etui-core/ts/Tooltip/Tooltip.types";
import {Col, Form, Row} from "react-bootstrap";

interface SelectInputTitleProps {
    label: string;
    tooltipText?: string;
    placement?: TooltipPlacement;
}

const SelectInputTitleCustom: React.FC<SelectInputTitleProps> = (props) => {

    return (
        <Row>
            <Col style={{alignItems: "center", display: "flex"}}>
                <Form.Label style={{marginRight: "10px"}}>{props.label}</Form.Label>
                {props.tooltipText != null && (
                    <Tooltip
                        message={props.tooltipText}
                        placement={props.placement != null ? props.placement : "right"}
                    >
                        <Icon glyph={icons.INFO_CIRCLE}/>
                    </Tooltip>
                )}
            </Col>
        </Row>
    );
};

export default SelectInputTitleCustom;
