import * as React from "react";
import {useMap} from "../../../../../../hooks/useMap";
import {useComponentModelChangeManager} from "../../../contexts/ComonentModelChangesContext";
import {useCompaniesComponentModelsMedias} from "../../../contexts/CompaniesComponentModelsMediasProvider";
import {AbstractMediaContext} from "./context";
import {AbstractMedia, VirtualMedia} from "./types";

export function AbstractMediaProvider({children}: React.PropsWithChildren<unknown>) {

    const {medias, refresh} = useCompaniesComponentModelsMedias();
    const abstractMedias = useMap<string | number, AbstractMedia>();

    const changeManager = useComponentModelChangeManager();

    React.useEffect(() => {
        return changeManager.observeChanges((event) => {
            if (event.type !== "result") {
                return;
            }
            const attemptedToUploadMedia = event.results.findIndex((r) => r.change.type === "upload-media") > -1;
            if (attemptedToUploadMedia) {
                refresh();
            }
        });
    }, []);

    React.useEffect(() => {
        abstractMedias.from(medias.toArray());
    }, [medias.mapRef()]);

    return (
        <AbstractMediaContext.Provider
            value={{
                medias: abstractMedias,
                addMedia(file: File): VirtualMedia {
                    const timestamp = Number(new Date());
                    const newMedia: VirtualMedia = {
                        timestamp,
                        file,
                        __virtual: "add",
                    };
                    abstractMedias.set(timestamp, newMedia);
                    changeManager.addMedia(newMedia);
                    return newMedia;
                },
            }}
        >
            {children}
        </AbstractMediaContext.Provider>
    );
}
