import * as React from "react";
import {
    getComponentModelProperties,
    updateComponentModelPropertyValue
} from "../../api";
import {useMap} from "../../../../hooks/useMap";
import {PropertyConfiguration} from "../../../../models/property/property";
import {consumeTargetValue} from "../../../../utils/eventsHandling";
import {constant} from "../../../../utils/functions";
import {toMap} from "../../../../utils/maps";
import {Button} from "../../../common/Button";
import {Table, Td, Th, Tr} from "../../../common/DataTable";
import {Dialog} from "../../../common/Dialog";
import {Icon} from "../../../common/Icon";
import {Skeleton} from "../../../common/Skeleton";
import {useComponentModel} from "../contexts/ComponentModelProvider";
import {similar} from "../../../../utils/strings";
import {useData} from "../../../../DataLoader";
import {useForm} from "../../../../hooks/useForm";

import "./styles.scss";

interface ComponentModelPropertiesModalViewProps {
    properties: PropertyConfiguration[];
    onClose(update: boolean): void;
}

export function ComponentModelPropertiesModalView({properties, onClose}: ComponentModelPropertiesModalViewProps) {
    const componentModel = useComponentModel();
    const currentPropertiesValues = useMap(toMap(properties, (pc) => pc.propertyGuid, (pc) => pc.currentValue));

    const [propertiesNameFilter, setPropertiesNameFilter] = React.useState("");

    const propertiesLoader = useData({
        loader: getComponentModelProperties,
        keys: ["component-models-properties"],
        map(x) {
            return x.list;
        },
    });

    const initialValues = propertiesLoader.state === "successful" ?
        Object.fromEntries(propertiesLoader.data.map((p) => {
            return [p.guid, currentPropertiesValues.get(p.guid) ?? ""];
        })) : {};

    const { register, watch, handleSubmit } = useForm({defaultValues: initialValues});

    async function saveData(data) {
        const propertiesUpdateRequests = Object.entries(data)
            .filter(([propertyGuid, propertyValue]) => propertyValue !== initialValues[propertyGuid])
            .map(([propertyGuid, propertyValue]) => {
                return updateComponentModelPropertyValue(componentModel, {guid: propertyGuid}, propertyValue);
            });

        try {
            await Promise.all(propertiesUpdateRequests);
            onClose(true);
        } catch (e) {
            onClose(false);
        }
    }

    const form = watch();

    const formId = `${componentModel.guid}-properties-form`;

    const tableRows = propertiesLoader.state === "loading" ? Array
            .from({length: 20}, (_, i) => <Skeleton key={i} type={"table-row"} size={2}/>)
        : propertiesLoader.state === "successful" ? propertiesLoader.data
            .filter((p) => similar(p.name, propertiesNameFilter))
            .map((p) => {
                const cellState =
                    (p.propertyValueType === "number" && Number(form[p.guid]) !== Number(initialValues[p.guid]))
                    || (  p.propertyValueType !== "number" && form[p.guid] !== initialValues[p.guid])
                        ? "edited" : "default";
                const inputType = p.propertyValueType === "number" ? "number" : "text";
                return (
                    <Tr key={p.guid}>
                        <Td state={cellState}>{p.name}</Td>
                        <Td state={cellState}>
                            <input
                                form={formId}
                                type={inputType}
                                className={"input-field"}
                                placeholder={"No value"}
                                {...register(p.guid)}
                            />
                        </Td>
                    </Tr>
                );
            }) : [] ;

    const isEdited = propertiesLoader.state === "successful"
        ? propertiesLoader.data.reduce((partialIsEdited, p) => {
                return partialIsEdited || form[p.guid] !== initialValues[p.guid];
            }, false)
        : false;

    const footerButtons = isEdited ? (
        <>
            <Button variant={"secondary"} onClick={constant(onClose, false)}> Cancel </Button>
            <Button variant={"primary"} form={formId}> Save </Button>
        </>
    ) : (
        <Button variant={"primary"} onClick={constant(onClose, false)}> Close </Button>
    );

    return (
        <Dialog className={"component-model-connected-entities-dialog component-model-properties-dialog"}>
            <form id={formId} onSubmit={handleSubmit(saveData)}/>
            <header>
                <hgroup>
                    <small>{componentModel.name}</small>
                    <h3>Properties</h3>
                </hgroup>
                <label className={"input-field"}>
                    <input
                        type={"text"}
                        value={propertiesNameFilter}
                        placeholder={"Filter properties"}
                        onChange={consumeTargetValue(setPropertiesNameFilter)}
                    />
                    <Button variant={"tertiary"} onClick={constant(setPropertiesNameFilter, "")}>
                        <Icon name={"backspace"}/>
                    </Button>
                </label>
            </header>
            <section>
                <Table>
                    <thead>
                        <tr>
                            <Th>Property</Th>
                            <Th>Value</Th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </Table>
            </section>
            <footer>{footerButtons}</footer>
        </Dialog>
    );
}
