import {Icon, icons} from "@danfoss/etui-core";
import * as React from "react";
import {Device} from "../../../../../models/device/device";
import {getCurrentInstallationOfDevice} from "../../../utils";
import {SearchResultView} from "../SearchResultView";
import {InstalledDeviceInfoModal} from "./InstalledDeviceInfoModal";

interface NotMovableSearchResultProps {
    device: Device;
}

export function NotMovableSearchResult(
    {
        device,
        ...rest
    }: NotMovableSearchResultProps & React.LiHTMLAttributes<HTMLLIElement>,
) {
    const [showInfo, setShowInfo] = React.useState(false);

    const equipment = getCurrentInstallationOfDevice(device)?.equipment;

    const equipmentCode = equipment?.customerCode?.code;
    const equipmentManufacturer = equipment?.manufacturerCode?.code;

    const subTitle = equipmentCode ? `Device Installed. Equipment code ${equipmentCode}`
        : equipmentManufacturer ? `Device Installed. Equipment manufacturer ${equipmentManufacturer}`
        : "Device Installed";

    function openInfoModal() {
        setShowInfo(true);
    }

    function closeInfoModal() {
        setShowInfo(false);
    }

    return (
        <>
            <SearchResultView
                title={device.core.code}
                subTitle={subTitle}
                onClick={openInfoModal}
                {...rest}
                className={`not-movable-search-result ${rest.className ?? ""}`}
            >
                <span className={"device-info-expand-icon"}>
                    <Icon glyph={icons.EXPAND}/>
                </span>
            </SearchResultView>
            <InstalledDeviceInfoModal
                show={showInfo}
                device={device}
                onClose={closeInfoModal}
            />
        </>
    );
}
