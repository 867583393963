import * as React from "react";
import {getComponentTypeDiagnosticsTypes, updateComponentModelDiagnosticTypes} from "../../api";
import {useMap} from "../../../../hooks/useMap";
import {ComponentTypeDiagnosticType} from "../../../../models/component/componentType";
import {ComponentModelDiagnosticType} from "../../../../models/diagnostic/componentModelDiagnosticType";
import {Table, Td, Th, Tr} from "../../../common/DataTable";
import {Dialog} from "../../../common/Dialog";
import {useComponentModel} from "../contexts/ComponentModelProvider";
import {constant} from "../../../../utils/functions";
import {consumeTargetValue} from "../../../../utils/eventsHandling";
import {Button} from "../../../common/Button";
import {Skeleton} from "../../../common/Skeleton";
import {Icon} from "../../../common/Icon";
import {usePromise} from "../../../../hooks/usePromise";
import {useForm} from "../../../../hooks/useForm";

import "./styles.scss";
import {range} from "../../../../utils/numbers";

interface ComponentModelDiagnosticsTypesModalViewProps {
    diagnosticsTypes: ComponentModelDiagnosticType[];
    onClose(update: boolean): void;
}

type Fields = "deviceRequired" | "availableOnCloud" | "energyConsumptionAlwaysOn" | "energyConsumption";

export function ComponentModelDiagnosticsTypesModalView(
    {
        diagnosticsTypes,
        onClose,
    }: ComponentModelDiagnosticsTypesModalViewProps,
) {
    const componentModel = useComponentModel();

    const componentModelDiagnosticTypes = useMap(diagnosticsTypes.map((cmdt) => [cmdt.diagnosticTypeGuid, cmdt]));

    const [diagnosticTypesNameFilter, setDiagnosticTypesNameFilter] = React.useState("");

    const { data, state } = usePromise(getComponentTypeDiagnosticsTypes, [{guid: componentModel.typeGuid}]);

    const initialValues = state === "fulfilled" ? Object.fromEntries(data.list.map((ctdt) => {
        const cmdt = componentModelDiagnosticTypes.get(ctdt.diagnosticType.guid);
        return [ctdt.diagnosticType.guid, {
            deviceRequired: cmdt?.deviceRequired ?? ctdt.deviceRequired ?? false,
            availableOnCloud: cmdt?.availableOnCloud ?? ctdt.availableOnCloud ?? false,
            energyConsumptionAlwaysOn: cmdt?.energyConsumptionAlwaysOn ?? ctdt.energyConsumptionAlwaysOn ?? false,
            energyConsumption: cmdt?.energyConsumption ?? ctdt.energyConsumption ?? ""
        }];
    })) : {};

    const { register, watch, handleSubmit } = useForm({defaultValues: initialValues});

    const form = watch();

    const formId = `${componentModel.guid}-diagnostics-types-form`;

    function cellState(ctdt: ComponentTypeDiagnosticType, field: Fields, defaultValue: unknown = false) {
        const row = form[ctdt.diagnosticType.guid];
        const cmdt = componentModelDiagnosticTypes.get(ctdt.diagnosticType.guid);
        return row[field] !== (cmdt?.[field] ?? ctdt[field] ?? defaultValue) ? "edited" : "default";
    }

    const tableRows = state === "pending"
        ? range(20).map(( i) => <Skeleton key={i} type={"table-row"} size={5}/>)
        : state === "fulfilled" ? data.list.map((ctdt) => {
                const dtGuid = ctdt.diagnosticType.guid;
                return (
                    <Tr key={ctdt.diagnosticType.guid}>
                        <Td>{ctdt.diagnosticType.name}</Td>
                        <Td state={cellState(ctdt, "deviceRequired")}>
                            <input type={"checkbox"} {...register(`${dtGuid}/deviceRequired`)} form={formId}/>
                        </Td>
                        <Td state={cellState(ctdt, "availableOnCloud")}>
                            <input type={"checkbox"} {...register(`${dtGuid}/availableOnCloud`)} form={formId}/>
                        </Td>
                        <Td state={cellState(ctdt, "energyConsumptionAlwaysOn")}>
                            <input
                                type={"checkbox"}
                                {...register(`${dtGuid}/energyConsumptionAlwaysOn`)}
                                form={formId}
                            />
                        </Td>
                        <Td state={cellState(ctdt, "energyConsumption", "")}>
                            <input
                                type={"number"}
                                className={"input-field"}
                                {...register(`${dtGuid}/energyConsumption`)}
                                step={"0.001"}
                                form={formId}
                            />
                        </Td>
                    </Tr>
                );
            })
        : [];


    const edited = state === "fulfilled"
        ? data.list.reduce((partialIsEdited, ctdt) => {
            const currentRow = form[ctdt.diagnosticType.guid];
            const initialRow = initialValues[ctdt.diagnosticType.guid];
            return partialIsEdited
                || currentRow.deviceRequired !== initialRow.deviceRequired
                || currentRow.availableOnCloud !== initialRow.availableOnCloud
                || currentRow.energyConsumptionAlwaysOn !== initialRow.energyConsumptionAlwaysOn
                || currentRow.energyConsumption !== initialRow.energyConsumption;
        }, false)
        : false;

    const footerButtons = edited ? (
        <>
            <Button variant={"secondary"} onClick={constant(onClose, false)}> Cancel </Button>
            <Button variant={"primary"} form={formId}> Save </Button>
        </>
    ) : (
        <Button variant={"primary"} onClick={constant(onClose, false)}> Close </Button>
    );

    async function saveData(data: Record<string, Pick<ComponentModelDiagnosticType, Fields>>) {
        const updateRequests = Object.entries(data).filter(([guid]) => {
            const currentRow = form[guid];
            const initialRow = initialValues[guid];
            return currentRow.deviceRequired !== initialRow.deviceRequired
                || currentRow.availableOnCloud !== initialRow.availableOnCloud
                || currentRow.energyConsumptionAlwaysOn !== initialRow.energyConsumptionAlwaysOn
                || currentRow.energyConsumption !== initialRow.energyConsumption;
        }).map(([guid, requesting]) => {
            return updateComponentModelDiagnosticTypes(
                componentModel,
                {guid},
                {
                    deviceRequired: requesting.deviceRequired,
                    availableOnCloud: requesting.availableOnCloud,
                    energyConsumptionAlwaysOn: requesting.energyConsumptionAlwaysOn,
                    energyConsumption: requesting.energyConsumption,
                }
            )
        });

        try {
            await Promise.all(updateRequests);
            onClose(true);
        } catch (e) {
            onClose(false);
        }
    }

    return (
        <Dialog className={"component-model-connected-entities-dialog component-model-diagnostics-dialog"}>
            <form id={formId} onSubmit={handleSubmit(saveData)}/>
            <header>
                <hgroup>
                    <small>{componentModel.name}</small>
                    <h3>Diagnostics types</h3>
                </hgroup>
                <label className={"input-field"}>
                    <input
                        type={"text"}
                        value={diagnosticTypesNameFilter}
                        placeholder={"Filter alarms type"}
                        onChange={consumeTargetValue(setDiagnosticTypesNameFilter)}
                    />
                    <Button variant={"tertiary"} onClick={constant(setDiagnosticTypesNameFilter, "")}>
                        <Icon name={"backspace"}/>
                    </Button>
                </label>
            </header>
            <section>
                <Table>
                    <thead>
                        <tr>
                            <Th>Name</Th>
                            <Th>Device required</Th>
                            <Th>available on cloud</Th>
                            <Th>Energy consumption always on</Th>
                            <Th>Energy consumption</Th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </Table>
            </section>
            <footer>{footerButtons}</footer>
        </Dialog>
    );
}
