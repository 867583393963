import * as React from "react";
import {useDebounceCallback} from "../../../../../../hooks/useDebounceCallback";
import {StatefulMap, useMap} from "../../../../../../hooks/useMap";
import {Company} from "../../../../../../models/company/companyModel";
import {ComponentType} from "../../../../../../models/component/componentType";
import {PickGuid} from "../../../../../../models/types";
import {setInputValue} from "../../../../../../utils/DOM";
import {
    consumeCurrenTargetValue,
    consumeTargetValue,
    onKey,
    toOutsideElements,
} from "../../../../../../utils/eventsHandling";
import {constant, identity, over} from "../../../../../../utils/functions";
import {consumeJSONList} from "../../../../../../utils/json";
import {toMap} from "../../../../../../utils/maps";
import {omit} from "../../../../../../utils/objects";
import {CompanyItemView} from "../../../../../common/CompanyItemView";
import {DatalistRef} from "../../../../../common/form/Datalist";
import {Input} from "../../../../../common/form/Input";
import {useComponentModelChangeManager} from "../../../contexts/ComonentModelChangesContext";
import {useComponentBrands} from "../../../contexts/ComponentBrandsProvider";
import {AbstractCompanyComponentModel} from "../../types";
import {Button} from "../../../../../common/Button";
import {Icon} from "../../../../../common/Icon";
import {Overlay} from "../../../../../common/Overlay";
import {ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX} from "../../../../../../hooks/useOverlay";
import {findCompanies} from "../../../../../Companies";

import "./styles.scss";


const FETCH_COMPANY_LIMIT = 20;

type ComponentProps = React.HTMLAttributes<HTMLElement> & {
    componentType: PickGuid<ComponentType>,
    companiesComponentModels: StatefulMap<string, AbstractCompanyComponentModel[]>;
};

type CompanySearchAction =
    {type: "clear"}
    | {type: "updating-search-term"}
    | {type: "load", searchTerm: string}
    | {type: "replace-companies", companies: Company[], hasMore: boolean}
    | {type: "load-more-companies"}
    | {type: "append-companies", companies: Company[], hasMore: boolean};

interface CompanySearchState {
    status: "loading" | "loading-more" | "idle" | "initial";
    companies: Company[];
    canLoadMore: boolean;
    offset: number;
    searchTerm: string;
}

const initialCompanySearchState: CompanySearchState = {
    status: "initial",
    canLoadMore: false,
    companies: [],
    searchTerm: "",
    offset: 0,
};

function updateCompanySearchState(state: CompanySearchState, action: CompanySearchAction): CompanySearchState {
    switch (action.type) {
        case "clear": {
            return initialCompanySearchState;
        }
        case "updating-search-term": {
            return state.status === "loading" ? state : {
                ...state,
                companies: [],
                status: "loading",
            };
        }
        case "load": {
            return {
                ...state,
                status: "loading",
                companies: [],
                offset: 0,
                searchTerm: action.searchTerm,
            };
        }
        case "load-more-companies": {
            return {
                ...state,
                status: "loading-more",
                offset: state.offset + FETCH_COMPANY_LIMIT,
            };
        }
        case "replace-companies": {
            return {
                ...state,
                status: "idle",
                companies: action.companies,
                canLoadMore: action.hasMore,
            };
        }
        case "append-companies": {
            return {
                ...state,
                status: "idle",
                companies: state.companies.concat(action.companies),
                canLoadMore: action.hasMore,
            };
        }
    }
}

export function CompanySearch({componentType, companiesComponentModels, ...props}: ComponentProps) {
    const datalistRef = React.useRef<DatalistRef>();
    const inputRef = React.useRef<Input>();

    const componentTypeCompanies = useMap<string, Company>();
    const changesManager = useComponentModelChangeManager();
    const {firstAvailableBrand} = useComponentBrands();

    const [{ status, companies, canLoadMore, searchTerm, offset}, dispatch] = React.useReducer(
        updateCompanySearchState,
        initialCompanySearchState,
    );

    React.useEffect(() => {
        switch (status) {
            case "loading-more":
            case "loading" : {
                const page = offset / FETCH_COMPANY_LIMIT;
                findCompanies(
                    omit({
                        searchTerm, page, size: FETCH_COMPANY_LIMIT },
                        (key, value) => key === "searchTerm" && !value
                    ),
                ).then((result) => {
                    dispatch({
                        type:  status === "loading" ? "replace-companies" : "append-companies",
                        companies: result.list,
                        hasMore: offset + FETCH_COMPANY_LIMIT < result.total,
                    });
                });
                break;
            }
        }
    }, [status]);

    React.useEffect(() => {
        findCompanies({ componentTypeGuid: componentType.guid })
            .then(consumeJSONList(toMap<Company, string, Company>((c) => c.guid, identity)))
            .then(componentTypeCompanies.from)
            .catch(console.error);
    }, []);

    function addCompany(company: Company) {
        if (companiesComponentModels.has(company.guid)) {
            const brandedModels = companiesComponentModels.get(company.guid);
            const alreadyUsedComponentBrandsGuids = brandedModels.map((ccm) => ccm.componentBrand?.guid ?? null);
            changesManager.addToCompany(company, firstAvailableBrand(...alreadyUsedComponentBrandsGuids));
            return;
        } else if (!componentTypeCompanies.has(company.guid)) {
            changesManager.addComponentTypeToCompany(company);
        }
        changesManager.addToCompany(company, null);
    }

    const updateCompanyList = useDebounceCallback((companyPartialName: string) => {
        if (companyPartialName === "") {
            dispatch({ type: "clear"});
            return;
        }
        if (companyPartialName.length < 3) {
            return;
        }
        dispatch({ type: "load", searchTerm: companyPartialName });
    }, 500, {
        onLeading() {
            dispatch({type: "updating-search-term"});
        },
    });

    function blurInputAndSetValue(value: string) {
        return () => {
            inputRef.current.blur();
            setInputValue(inputRef.current, value);
        };
    }

    function addCompanyFromGuid(companyGuid: string) {
        const companyToAdd = companies.find((c) => c.guid === companyGuid);
        if (companyToAdd) {
            addCompany(companyToAdd);
        }
    }

    const [open, setOpen] = React.useState(false);

    if (!open) {
        return  (
            <Button variant={"secondary"} onClick={constant(setOpen, true)}>
                Add company
            </Button>
        );
    }

    return (
        <label
            className={`input-field search-company-input ${props.className ?? ""}`}
            onBlur={toOutsideElements(constant(setOpen, false))}
        >
            <Input
                ref={inputRef}
                {...props}
                type={"text"}
                autoFocus={true}
                placeholder={"Search company name"}
                className={"input-field"}
                onChange={consumeTargetValue(updateCompanyList)}
                onKeyDown={onKey(
                    "Enter", consumeCurrenTargetValue(addCompanyFromGuid), blurInputAndSetValue(searchTerm),
                )}
            />
            <Button variant={"tertiary"} onClick={over(blurInputAndSetValue(""), constant(dispatch, {type: "clear"}))}>
                <Icon name={"backspace"}/>
            </Button>
            <Overlay
                tabIndex={-1}
                className={"searched-companies-list"}
                positioned={ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX}
            >
                {status === "loading" && <span>Loading...</span>}
                {(status === "idle" && companies.length === 0 ) && <span>Nothing was found</span>}
                {companies.map((company) => {
                    return (
                        <li
                            key={company.guid}
                            value={company.guid}
                            onClick={constant(addCompany, company)}
                        >
                            <CompanyItemView company={company}/>
                        </li>
                    );
                })}
                {canLoadMore ? (
                    <Button variant={"secondary"} onClick={constant(dispatch, {type: "load-more-companies" })}>
                        Load more
                    </Button>
                ) : null}
            </Overlay>
        </label>
    );
}
