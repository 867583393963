import {Button, SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import ReactJson from "react-json-view";
import Services from "../../../controllers/utils/Services";
import {AlarmType} from "../../../models/alarm/alarmType";
import {PatchList} from "../../../models/company/patchModel";
import {AcquisitionSetting} from "../../../models/configurability/rawData/acquisitionSettingModel";
import {ProcessingSetting} from "../../../models/configurability/rawData/processingSetting";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {ProcessingFunction} from "../../../models/processingFunction/procesingFunctionModel";
import {PropertiesGroup} from "../../../models/property/property";
import {Suggestion} from "../../../models/Suggestion";
import {ConfirmModal} from "../../common/ConfirmModal";
import LoadingModal from "../../common/LoadingModal";
import {ProcessingParamType, Sections} from "../CreateRawData";
import RawDataProcessingModal from "../modals/RawDataProcessingModal";

interface RawDataAlarmTypeSectionProps {

    propertiesGroups: Array<Suggestion<PropertiesGroup>>;
    processingFunctions: Array<Suggestion<ProcessingFunction>>;
    onSubmit: (payload: any) => void;
    setCurrentStep: (num: number) => void;
    loading: boolean;
    currentRawDataAlarmType: RawDataAlarmType;
    groups: Array<Suggestion<string>>;
    categories: Array<Suggestion<string>>;
    processingParametersTypes: Array<Suggestion<any>>;
    selectedDeviceModel: DeviceModel;
    setCurrentRawDataAlarmType: (currentRawDataAlarmType: RawDataAlarmType) => void;
    rawDataList: RawData[];
    alarmTypes: Array<Suggestion<AlarmType>>;
}

// tslint:disable:max-line-length
const RawDataAlarmTypeSection: React.FC<RawDataAlarmTypeSectionProps> = (props) => {
    const services = new Services();
    const [confirmProcShow, setConfirmProcShow] = React.useState<boolean>(false);
    const [processingModalShow, setProcessingModalShow] = React.useState(false);
    const [firstRender, setFirstRender] = React.useState<boolean>(false);
    const [param, setParam] = React.useState<AcquisitionSetting | ProcessingSetting>(undefined);
    const initProcessingSettings = props.currentRawDataAlarmType ? props.currentRawDataAlarmType.params.processing : [];
    const [processingSettings, setProcessingSettings] = React.useState<ProcessingSetting[]>(initProcessingSettings);

    const [alarmType, setAlarmType] = React.useState<Suggestion<AlarmType>>(undefined);
    const [customAlarmType, setCustomAlarmType] = React.useState<Suggestion<AlarmType>>(undefined);
    const [propertiesGroup, setPropertiesGroup] = React.useState<Suggestion<PropertiesGroup>>(undefined);
    const [childrenAlarmTypes, setChildrenAlarmTypes] = React.useState<Array<Suggestion<AlarmType>>>([]);
    const [processingFunctions, setProcessingFunctions] = React.useState<Array<Suggestion<ProcessingFunction>>>([]);
    const [propertyNum, setPropertyNum] = React.useState<number>(null);

    React.useEffect(() => {
        const alarm = props.alarmTypes.find((object) => object.value === props.currentRawDataAlarmType.alarmTypeGuid);
        setAlarmType(alarm);
        const propertiesGroupSuggestion = props.propertiesGroups.find((object) => object.value === props.currentRawDataAlarmType.propertyGroupGuid);
        if (propertiesGroupSuggestion) {
            setPropertiesGroup(propertiesGroupSuggestion);
            setPropertyNum(propertiesGroupSuggestion.item.properties.length);
        }
        setProcessingFunctions(props.processingFunctions);
        if (alarm != null) {
            const childrenAlarmTypeSuggestions: Array<Suggestion<AlarmType>> = alarm.item.childrenAlarmTypes.map((at) => ({
                item: at,
                label: at.name,
                value: at.guid,
            }));
            setChildrenAlarmTypes(childrenAlarmTypeSuggestions);
            setCustomAlarmType(childrenAlarmTypeSuggestions.find((object) => object.value === props.currentRawDataAlarmType.childAlarmTypeGuid));
        }
    }, []);

    function rawDataAlarmTypePatchPayload() {
        const rdat = props.currentRawDataAlarmType;
        const payload: PatchList = {
            list: [services.patchOption("replace", "params", rdat.params)],
        };
        services.addReplaceOpIfValueChanged(payload, rdat.childAlarmTypeGuid ? rdat.childAlarmTypeGuid : rdat.alarmTypeGuid,
            customAlarmType ? customAlarmType.item.guid : alarmType.item.guid, "alarmTypeGuid");
        services.replaceOrRemove(payload, rdat.propertyGroupGuid, propertiesGroup ? propertiesGroup.item.guid : "", "devicePropertiesGroupGuid");
        return JSON.parse(JSON.stringify(payload));
    }

    function rawDataAlarmTypePayload() {
        const newRawDataAlarmType: RawDataAlarmType = {
            guid: "",
            alarmTypeGuid: alarmType ? alarmType.item.guid : null,
            childAlarmTypeGuid: customAlarmType ? customAlarmType.item.guid : null,
            params: props.currentRawDataAlarmType.params,
            propertyGroupGuid: propertiesGroup ? propertiesGroup.item.guid : null,
            propertiesGroup: propertiesGroup ? propertiesGroup.item : null,
        };
        return JSON.parse(JSON.stringify(newRawDataAlarmType));
    }

    function onAddOrCreateProcessing(processingSetting: ProcessingSetting) {
        return () => {
            setParam(processingSetting);
            setProcessingModalShow(true);
        };
    }

    function onConfirmRemoveProcessing() {
        return () => {
            const psArr = [];
            props.currentRawDataAlarmType.params.processing = psArr;
            props.setCurrentRawDataAlarmType(props.currentRawDataAlarmType);
            setProcessingSettings(psArr);
            setConfirmProcShow(false);
        };
    }

    function onRemoveProcessing(processingSetting: ProcessingSetting) {
        return () => {
            setParam(processingSetting);
            setConfirmProcShow(true);
        };
    }

    function mandatoryFieldsCheck() {
        return alarmType == null || (processingSettings &&
            (processingSettings.length !== 1 || processingSettings
                    .map((j) => j.parameters
                        .find((e) => e.type === ProcessingParamType.REF) == null).length < 1
            )
        );
    }

    const onChangeAlarmType = (suggestion: Suggestion<AlarmType>) => {
        setAlarmType(suggestion);
        const tempRawDataAlarmType: RawDataAlarmType = {...props.currentRawDataAlarmType};
        tempRawDataAlarmType.alarmTypeGuid = suggestion.value;
        setCustomAlarmType(null);
        const childrenAlarmTypeSuggestions: Array<Suggestion<AlarmType>> = suggestion.item.childrenAlarmTypes.map((at) => ({
            item: at,
            label: at.name,
            value: at.guid,
        }));
        setChildrenAlarmTypes(childrenAlarmTypeSuggestions);
    };

    const onChangeChildAlarmType = (suggestion: Suggestion<AlarmType>) => {
        setCustomAlarmType(suggestion);
        const tempRawDataAlarmType: RawDataAlarmType = {...props.currentRawDataAlarmType};
        tempRawDataAlarmType.childAlarmTypeGuid = suggestion ? suggestion.value : null;
    };

    const onChangePropertiesGroup = (suggestion: Suggestion<PropertiesGroup>) => {
        setPropertiesGroup(suggestion);
        if (suggestion) {
            setProcessingFunctions(props.processingFunctions.filter((e) =>
                e.item.numParams >= suggestion.item.properties.length));
            setPropertyNum(suggestion.item.properties.length);
        } else {
            setProcessingFunctions(props.processingFunctions);
            setPropertyNum(null);
        }
        const psArr = [];
        props.currentRawDataAlarmType.params.processing = psArr;
        props.setCurrentRawDataAlarmType(props.currentRawDataAlarmType);
        setProcessingSettings(psArr);
    };

    const tmpCurrentRawDataAlarmType = {
        ...props.currentRawDataAlarmType,
        alarmTypeGuid: alarmType ? alarmType.item.guid : undefined,
        childAlarmTypeGuid: customAlarmType ? customAlarmType.item.guid : undefined,
        propertiesGroup: propertiesGroup ? propertiesGroup.item : undefined,
        propertyGroupGuid: propertiesGroup ? propertiesGroup.item.guid : undefined,
    };

    function isUpdate() {
        return (props.currentRawDataAlarmType && props.currentRawDataAlarmType.guid);
    }

    function onSubmit() {
        return () => {
            props.onSubmit(isUpdate() ? rawDataAlarmTypePatchPayload() : rawDataAlarmTypePayload());
        };
    }

    return (
        <>
            <Form>
                <Row>
                    <Col className={"mx-2"}>
                        <Row className="mx-2 pb-3">
                            <p>
                                {"Raw data Alarm Type for: " + props.selectedDeviceModel.name}
                            </p>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col>
                                <p>Generic Alarm Type *</p>
                                <>
                                    <SelectInput
                                        onChange={onChangeAlarmType}
                                        name="Alarm Type"
                                        label="Insert Alarm Type"
                                        options={props.alarmTypes}
                                        searchable={true}
                                        value={alarmType}
                                    />
                                </>
                            </Col>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col>
                                <p>Alarm Type</p>
                                <>
                                    <SelectInput
                                        onChange={onChangeChildAlarmType}
                                        name="Alarm Type"
                                        label="Insert a custom alarm type"
                                        options={childrenAlarmTypes}
                                        searchable={true}
                                        clearable={true}
                                        disabled={!alarmType}
                                        value={customAlarmType}
                                    />
                                </>
                            </Col>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col>
                                <p>Properties Group</p>
                                <>
                                    <SelectInput
                                        onChange={onChangePropertiesGroup}
                                        name="Properties Group"
                                        label="Insert properties group"
                                        options={props.propertiesGroups}
                                        searchable={true}
                                        clearable={true}
                                        value={propertiesGroup}
                                    />
                                </>
                            </Col>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col className={"mx-4"}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Processing
                                    </h5>
                                    <Button
                                        className={"mx-0"}
                                        iconProps={services.addCircleIconButton()}
                                        onClick={onAddOrCreateProcessing(undefined)}
                                        disabled={(processingSettings && processingSettings.length > 0)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup style={{maxHeight: "34vh", overflow: "auto"}}>
                                    {processingSettings ? processingSettings.map((value) => (
                                        <ListGroup.Item className={"onListHover d-flex"} key={0}>
                                            <span className={"my-auto"}>
                                                {0},
                                                {value.category},
                                                {value.group},
                                                {props.processingFunctions[value.functionCode].label}
                                            </span>
                                            <Button
                                                className={"ml-auto"}
                                                iconProps={services.dotsIconButton()}
                                                onClick={onAddOrCreateProcessing(value)}
                                            />
                                            <Button
                                                iconProps={services.removeIconButton()}
                                                onClick={onRemoveProcessing(value)}
                                            />
                                        </ListGroup.Item>
                                    )) : <></>
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={"mx-2"} style={{maxWidth: "30%"}}>
                        <Row className="mx-2 pb-3">
                            Preview
                        </Row>
                        <Row style={{maxHeight: "62vh", overflow: "auto"}}>
                            <ReactJson src={processingSettings ? {processing: [...processingSettings]} : undefined}/>
                        </Row>
                    </Col>
                </Row>
                {/* Bottom nav buttons */}
                <Row className="w-100 d-flex flex-column">
                    <div className="text-right pt-4">
                        <Button
                            /* tslint:disable-next-line:jsx-no-lambda */
                            onClick={() => props.setCurrentStep(Sections.deviceModel)}
                        >
                            Previous
                        </Button>
                        {" "}
                        <Button
                            disabled={mandatoryFieldsCheck()}
                            variant="strong"
                            onClick={onSubmit()}
                        >
                            {isUpdate() ? "Update" : "Save"}
                        </Button>
                    </div>
                </Row>
            </Form>
            <RawDataProcessingModal
                modalProps={
                    {
                        show: processingModalShow,
                        onHide: services.onHideModalWithParam(setProcessingModalShow, setFirstRender),
                    }
                }
                rawDataName={"Raw data Alarm Type"}
                param={param}
                groups={props.groups}
                categories={props.categories}
                processingFunctions={processingFunctions}
                firstRender={firstRender}
                setFirstRender={setFirstRender}
                processingParametersTypes={props.processingParametersTypes}
                currentRD={props.currentRawDataAlarmType}
                currentSelections={tmpCurrentRawDataAlarmType}
                section={Sections.rawDataAlarmType}
                setCurrentRD={props.setCurrentRawDataAlarmType}
                rawDataList={props.rawDataList}
                setProcessing={setProcessingSettings}
                setParam={setParam}
                propertyNum={propertyNum}
            />
            <ConfirmModal
                onConfirm={onConfirmRemoveProcessing()}
                show={confirmProcShow}
                onHide={services.onHideModal(setConfirmProcShow)}
            />
            {/* Loading modal */}
            <LoadingModal loading={props.loading}/>
        </>
    );
};

export default RawDataAlarmTypeSection;
