import {SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {InterfaceSettingEnumsJson} from "../../../../models/configurability/configurabilityEnumsModel";
import {
    SerialSetting,
    SettingTemplate,
    TemplateConfigurationTelemetry,
} from "../../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../../models/device/deviceModelModel";
import {Suggestion} from "../../../../models/Suggestion";

interface SerialSettingProps {
    currentInterfaceStep: number;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    deviceModelSlave: Suggestion<DeviceModel>;
    suggestions: InterfaceSettingEnumsJson;
}

// tslint:disable:max-line-length
const SerialSettingInput: React.FC<SerialSettingProps> = (props) => {
    const [baudRate, setBaudRate] = React.useState<Suggestion<number>>(undefined);
    const [baudRateSuggestion, setBaudRateSuggestion] = React.useState<Array<Suggestion<number>>>([]);

    const [parity, setParity] = React.useState<Suggestion<string>>(undefined);
    const [paritySuggestion, setParitySuggestion] = React.useState<Array<Suggestion<string>>>([]);

    const [stopBits, setStopBits] = React.useState<Suggestion<number>>(undefined);
    const [stopBitsSuggestion, setStopBitsSuggestion] = React.useState<Array<Suggestion<number>>>([]);

    const [wordLength, setWordLength] = React.useState<Suggestion<number>>(undefined);
    const [wordLengthSuggestion, setWordLengthSuggestion] = React.useState<Array<Suggestion<number>>>([]);

    const [protocol, setProtocol] = React.useState<Suggestion<string>>(undefined);

    const [defaultProperties, setDefaultProperties] = React.useState<SerialSetting>(undefined);

    function getSerialSetting() {
        let serialSetting = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting as SerialSetting;
        if (serialSetting == null) {
            serialSetting = {
                baudRate: null,
                wordLength: null,
                parity: null,
                stopBits: null,
                protocol: null,
                type: "serial",
            };
            setBaudRate(null);
            setParity(null);
            setStopBits(null);
            setWordLength(null);
            setProtocol(null);
        }
        return serialSetting;
    }

    React.useEffect(() => {
        if (props.deviceModelSlave != null && props.deviceModelSlave.item.defaultProperties != null) {
            const tempProperties = props.deviceModelSlave.item.defaultProperties as SerialSetting;
            setDefaultProperties(tempProperties);
            const serialSetting = getSerialSetting();
            serialSetting.protocol = tempProperties.protocol != null ? tempProperties.protocol : null;
            setProtocol(serialSetting.protocol != null ? {
                item: serialSetting.protocol,
                label: "" + serialSetting.protocol,
                value: "" + serialSetting.protocol,
            } : null);
            setSettingTemplate(serialSetting);
        }
    }, [props.deviceModelSlave, props.currentInterfaceStep]);

    function reset() {
        const serialSetting: SerialSetting = {
            baudRate: null,
            wordLength: null,
            parity: null,
            stopBits: null,
            protocol: null,
            type: "serial",
        };
        setBaudRate(null);
        setParity(null);
        setStopBits(null);
        setWordLength(null);
        setProtocol(null);
        setSettingTemplate(serialSetting);
        setDefaultProperties(null);
    }

    React.useEffect(() => {
        const serialSetting = getSerialSetting();
        setProtocol(serialSetting.protocol != null ? {
            item: serialSetting.protocol,
            label: "" + serialSetting.protocol,
            value: "" + serialSetting.protocol,
        } : null);
        setBaudRate(serialSetting.baudRate != null ? {
            item: serialSetting.baudRate,
            label: "" + serialSetting.baudRate,
            value: "" + serialSetting.baudRate,
        } : null);
        setParity(serialSetting.parity != null ? {
            item: serialSetting.parity,
            label: serialSetting.parity,
            value: serialSetting.parity,
        } : null);
        setStopBits(serialSetting.stopBits != null ? {
            item: serialSetting.stopBits,
            label: "" + serialSetting.stopBits,
            value: "" + serialSetting.stopBits,
        } : null);
        setWordLength(serialSetting.wordLength != null ? {
            item: serialSetting.wordLength,
            label: "" + serialSetting.wordLength,
            value: "" + serialSetting.wordLength,
        } : null);
        const temp = {...props.currentSettingTemplate};
        (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting = serialSetting;
        props.setCurrentSettingTemplate(temp);
    }, [props.currentInterfaceStep]);

    React.useEffect(() => {
        if (props.suggestions != null) {
            setBaudRateSuggestion(props.suggestions.baudRate.map((item) => ({
                item,
                label: "" + item,
                value: "" + item,
            })));
            setWordLengthSuggestion(props.suggestions.wordLength.map((item) => ({
                item,
                label: "" + item,
                value: "" + item,
            })));
            setParitySuggestion(props.suggestions.parity.map((item) => ({item, label: item, value: item})));
            setStopBitsSuggestion(props.suggestions.stopBit.map((item) => ({
                item,
                label: "" + item,
                value: "" + item,
            })));
        }
    }, [props.suggestions]);

    function setSettingTemplate(serialSetting: SerialSetting) {
        const temp = {...props.currentSettingTemplate};
        (temp.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].interfaceSetting = {...serialSetting};
        props.setCurrentSettingTemplate(temp);
    }

    function onChangeBaudRate(value: Suggestion<number>) {
        setBaudRate(value);
        const serialSetting = getSerialSetting();
        serialSetting.baudRate = value != null ? value.item : null;
        setSettingTemplate(serialSetting);
    }

    function onChangeWordLength(value: Suggestion<number>) {
        setWordLength(value);
        const serialSetting = getSerialSetting();
        serialSetting.wordLength = value != null ? value.item : null;
        setSettingTemplate(serialSetting);
    }

    function onChangeParity(value: Suggestion<string>) {
        setParity(value);
        const serialSetting = getSerialSetting();
        serialSetting.parity = value != null ? value.item : null;
        setSettingTemplate(serialSetting);
    }

    function onChangeStopBits(value: Suggestion<number>) {
        setStopBits(value);
        const serialSetting = getSerialSetting();
        serialSetting.stopBits = value != null ? value.item : null;
        setSettingTemplate(serialSetting);
    }

    return (
        <Row>
            <Col>
                <Form.Label>Baud Rate *</Form.Label>
                <SelectInput
                    clearable={true}
                    onChange={onChangeBaudRate}
                    name="Serial prop"
                    options={baudRateSuggestion}
                    searchable={true}
                    value={baudRate}
                    label={(defaultProperties != null ? "default value: " + defaultProperties.baudRate : "")}
                    disabled={props.suggestions == null}
                />
            </Col>
            <Col>
                <Form.Label>Word Length *</Form.Label>
                <SelectInput
                    clearable={true}
                    onChange={onChangeWordLength}
                    name="Serial prop"
                    options={wordLengthSuggestion}
                    searchable={true}
                    value={wordLength}
                    label={(defaultProperties != null ? "default value: " + defaultProperties.wordLength : "")}
                    disabled={props.suggestions == null}
                />
            </Col>

            <Col>
                <Form.Label>Parity *</Form.Label>
                <SelectInput
                    clearable={true}
                    onChange={onChangeParity}
                    name="Serial prop"
                    options={paritySuggestion}
                    searchable={true}
                    value={parity}
                    label={(defaultProperties != null ? "default value: " + defaultProperties.parity : "")}
                    disabled={props.suggestions == null}
                />
            </Col>
            <Col>
                <Form.Label>Stop bits *</Form.Label>
                <SelectInput
                    clearable={true}
                    onChange={onChangeStopBits}
                    name="Serial prop"
                    options={stopBitsSuggestion}
                    searchable={true}
                    value={stopBits}
                    label={(defaultProperties != null ? "default value: " + defaultProperties.stopBits : "")}
                    disabled={props.suggestions == null}
                />
            </Col>
            <Col>
                <Form.Label>Protocol</Form.Label>
                <SelectInput
                    name="Serial prop"
                    value={protocol}
                    disabled={true}
                />
            </Col>
        </Row>

    );
};

export default SerialSettingInput;
