import {PickGuid} from "../../../models/types";
import {Company} from "../../../models/company/companyModel";
import {assertOk} from "../../../utils/requests";

type CompanyIntegrationFlowDefinition = {
    locale: string,
    timezone: string,
    flowName: string,
    enabled?: boolean,
    configurationParams?: string,
    elaborationParams?: string,
};

type CompanyIntegrationFlowResult = {
    companyGuid: string,
    companyName: string,
    userGuid: string,
    userName: string,
    userUserName: string,
    companyIntegrationFlowId: number,
    integrationFlowId: number,
    integrationFlowName: string,
    configurationParams?: string,
    elaborationParams?: string,
    enabled: boolean,
};

export async function createOrUpdateCompanyIntegrationFlow(
    company: PickGuid<Company>,
    definition: CompanyIntegrationFlowDefinition
): Promise<CompanyIntegrationFlowResult> {
    const response = await fetch(
        `/companies/${company.guid}/integrationFlow`,
        {
            method: "PUT",
            body: JSON.stringify(definition),
        }
    );
    assertOk(response);
    return response.json();
}
