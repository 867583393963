export function toRequestParamsString<T extends {}>(params: T): string {
    if (!params) {
        return "";
    }

    const searchParams = new URLSearchParams(
        Object
            .entries(params)
            .reduce((entries, [key, value]) => { // todo Change to flatMap when available. Current Node version to low
                if (value instanceof Array) {
                    return [...entries, ...value.map((v) => [key, v])];
                }
                return [...entries, [key, value]];
            }, []),
    );

    return searchParams.toString();
}

export function assertOk(response: Response): void {
    if (response.ok) {
        return;
    }
    throw response;
}
