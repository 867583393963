import * as React from "react";
import {Td} from "../../../../../../common/DataTable";
import {AbstractCompanyComponentModel} from "../../../types";
import {ComponentBrandDataCell} from "./BrandCell";
import {GenericInputCell} from "./GenericInputCell";
import {useCompanyComponentModelRequestedParams} from "./hooks";
import {ClientMediaDataCell} from "./MediaCell";

import "./styles.scss";
import {Button} from "../../../../../../common/Button";
import {Icon} from "../../../../../../common/Icon";

function getEntityGuid(entity: {guid: string | number} | null) {
    return entity?.guid ?? null;
}
function toEntityWithGuid(guid: string) {
    if (guid === "" || !guid) {
        return null;
    }
    return {guid};
}

interface CompanyComponentModelCellsViewProps {
    companyComponentModel: AbstractCompanyComponentModel;
}

export function CompanyComponentModelCellsView({ companyComponentModel }: CompanyComponentModelCellsViewProps) {
    const {isBeingRemoved, registerCell, remove, keep} = useCompanyComponentModelRequestedParams(companyComponentModel);
    return (
        <>
            <ComponentBrandDataCell {...registerCell("componentBrand", getEntityGuid, toEntityWithGuid)}/>
            <GenericInputCell {...registerCell("clientName")}/>
            <GenericInputCell {...registerCell("clientCode")}/>
            <ClientMediaDataCell {...registerCell("media", getEntityGuid, toEntityWithGuid)}/>
            <GenericInputCell {...registerCell("nickname")}/>
            <Td>{companyComponentModel?.componentsCount}</Td>
            <Td ordinal={"last"}>
                {isBeingRemoved
                    ? (
                        <Button variant={"tertiary"} onClick={keep}>
                            <Icon name={"undo"}/>
                        </Button>
                    ) : (
                        <Button variant={"tertiary"} onClick={remove}>
                            <Icon name={"delete"}/>
                        </Button>
                    )
                }
            </Td>
        </>
    );
}
