import * as React from "react";
import {Dialog} from "../../common/Dialog";
import {useLocation, useNavigate} from "react-router-dom";
import {LocationCore} from "../../../models/location/location";
import {constant, doNothing} from "../../../utils/functions";


export function EditDefaultWarehouse() {

    const location = useLocation();

    const { currentDefaultWarehouse }: {currentDefaultWarehouse: LocationCore} = location.state;


    const navigate = useNavigate();

    const goBack = constant(navigate, origin, { state: {reFetch: false}});

    return (
        <Dialog tabIndex={-1} onClose={doNothing}>
            {JSON.stringify(currentDefaultWarehouse)}
        </Dialog>
    );
}