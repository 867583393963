import {AutoSuggest, Button, Checkbox, icons, SelectInput, Spinner, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import CompanyController from "../../../controllers/api/CompanyController";
import TranslationController from "../../../controllers/api/TranslationController";
import Services from "../../../controllers/utils/Services";
import {
    AlarmType, AlarmTypeSeveritySuggestions, CompanyAlarmType,
    CompanyAlarmTypeSuggestion,
    TableCompanyAlarmType,
} from "../../../models/alarm/alarmType";
import {Company, CompanySuggestion} from "../../../models/company/companyModel";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";
import TranslationModal from "../../common/TranslationsModal";
import CompanyAlarmTypeRow from "../rows/CompanyAlarmTypeRow";

interface CompanyAlarmTypeProps {
    currentCompany: Company;
    tableAlarmTypes: TableCompanyAlarmType[];
    setTableAlarmTypes: (alarmType: TableCompanyAlarmType[]) => void;
    alarmTypes: CompanyAlarmTypeSuggestion[];
    setModalLoading: (loading: boolean) => void;
    selectedAlarmType: AlarmType;
    setSelectedAlarmType: (c: AlarmType) => void;
    alarmTypesName: string;
    setAlarmTypesName: (c: string) => void;
    severitySuggestions: AlarmTypeSeveritySuggestions[];
    loading: boolean;
    setLoading: (loading: boolean) => void;
    changeStep: (step: number) => void;
    step: number;
    submit: () => Promise<boolean>;
}

const CompanyAlarmTypeSection: React.FC<CompanyAlarmTypeProps> = (props) => {
    const services = new Services();
    const companyController: CompanyController = new CompanyController();
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.company_creation.alarm_types.generic_note";

    const defaultSeverity = {
        guid: "fa57ce40-6e70-4ec0-8b62-45a30a48490f",
        name: "Urgent",
        description: "Rischio elevato di danno a cose/persone da parte dell'attrezzatura o perdita del prodotto, intervenire tempestivamente",
    };
    // Table Hooks
    const [allChecked, setAllChecked] = React.useState<boolean>(true);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(true);
    // const [modalLoading, setModalLoading] = React.useState<boolean>(false);
    const [actionsAvailable, setActionsAvailable] = React.useState<boolean>(true);
    const [selectedTrigger, setSelectedTrigger] = React.useState<boolean>(false);
    const [updatedTrigger, setUpdatedTrigger] = React.useState<boolean>(false);
    const [updated, setUpdated] = React.useState<Array<{ item: string, status: boolean }>>(
        props.tableAlarmTypes.map((e) => {
            return {item: e.item.alarmType.guid, status: false};
        }),
    );
    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [operation, setOperation] = React.useState<string>("");
    const [operable, setOperable] = React.useState<boolean>(false);
    const [alarmTypeSuggestions, setAlarmTypeSuggestions] = React.useState<
        Array<{ item: AlarmType, label: string, value: string }>>([]);
    const [showTranslationModal, setShowTranslationModal] = React.useState<boolean>(false);
    // Company Hooks
    const [selectedCompany, setSelectedCompany] = React.useState<Company>(undefined);
    const [companyName, setCompanyName] = React.useState<string>("");
    const [companies, setCompanies] = React.useState<CompanySuggestion[]>([]);

    const [selectedTranslation, setSelectedTranslation] = React.useState<string>("");
    const [companyAlarmTypeToTranslate, setCompanyAlarmTypeToTranslate] = React.useState<CompanyAlarmType>(undefined);
    const columns = [
        {
            key: "checkbox",
            style: {width: "7%"},
            render: (
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <span> Select all</span>
                    <br/>
                    <Checkbox
                        key={"selectAll"}
                        checked={allChecked}
                        indeterminate={indeterminate}
                        onChange={handleCheckbox}
                    />
                </div>

            ),
        },
        {
            key: "name",
            style: {width: "22%"},
            render: "Name",
        },
        {
            key: "description",
            style: {width: "29%"},
            render: "Description Translation",
        },
        {
            key: "color",
            style: {width: "20%"},
            render: "Color",
        },
        {
            key: "severity",
            style: {width: "10%"},
            render: "Severity",
        },
        // {
        //     key: "configuration",
        //     style: {width: "12%"},
        //     render: "Configuration",
        // },
    ];

    const handleCompanyStateChange = (params, downshiftStateAndHelpers) => {
        if (!downshiftStateAndHelpers.isOpen) {
            setCompanyName("");
        } else if (params.hasOwnProperty("inputValue")) {
            setCompanyName(params.inputValue);
        }
    };

    const handleAlarmTypeStateChange = (params, downshiftStateAndHelpers) => {
        if (!downshiftStateAndHelpers.isOpen) {
            props.setAlarmTypesName("");
        } else if (params.hasOwnProperty("inputValue")) {
            props.setAlarmTypesName(params.inputValue);
        }
    };

    function handleCheckbox() {
        setAllChecked(!allChecked);
        props.setTableAlarmTypes(props.tableAlarmTypes.map((t) => {
            t.checked = !allChecked;
            return t;
        }));
    }

    function setAllCheckedStatus(all: boolean, some: boolean) {
        setAllChecked(all);
        setIndeterminate(some);
    }

    React.useEffect(() => {
        if (props.tableAlarmTypes.every((v) => v.checked)) {
            setAllChecked(true);
            setIndeterminate(false);
        } else if (props.tableAlarmTypes.some((v) => v.checked)) {
            setAllChecked(false);
            setIndeterminate(true);
        } else {
            setAllChecked(false);
            setIndeterminate(false);
        }
    }, [props.tableAlarmTypes]);

    React.useEffect(() => {
        if (companyName.length > 0) {
            props.setAlarmTypesName("");
            props.setSelectedAlarmType(undefined);
        }
        if (companyName.length >= 3) {
            companyController.getCompaniesByPatternAndRelationType(companyName, "ALL").then(
                (response) => {
                    const companySuggestion: CompanySuggestion[] = response.list
                        .filter((e) => e.guid !== props.currentCompany.guid)
                        .map((item) => ({
                            item,
                            label: item.name,
                            value: item.guid,
                        }));
                    setCompanies(companySuggestion);
                },
            );
        } else {
            setCompanies([]);
        }
    }, [companyName]);

    function onSearchButton() {
        if (selectedCompany !== undefined && companyName !== "") {
            props.setModalLoading(true);
            companyController.getAlarmTypesByCompanyGuid(selectedCompany.guid).then(
                getOnfulfilled(),
            );
        }
        if (props.selectedAlarmType != null && props.alarmTypesName !== "") {
            const tableLength: number = props.tableAlarmTypes.length;

            props.setModalLoading(true);
            const randomColor: string = services.getDefaultRandomColor();
            const tableItem: TableCompanyAlarmType = {
                checked: true,
                color: randomColor,
                displayColorPicker: false,
                item: {
                    alarmType: props.selectedAlarmType,
                },
                description: props.selectedAlarmType.description,
                name: props.selectedAlarmType.name,
                selectedColor: services.hexToRgb(randomColor),
                severity: {item: defaultSeverity, label: defaultSeverity.name, value: defaultSeverity.guid},
            };
            const l = !props.tableAlarmTypes
                .map((e) =>
                    e.item.alarmType.guid).includes(tableItem.item.alarmType.guid) ?
                [...props.tableAlarmTypes, tableItem] : [...props.tableAlarmTypes];
            props.setTableAlarmTypes(l);
            setUpdated(!updated.map((e) => e.item).includes(tableItem.item.alarmType.guid) ?
                [...updated, {item: tableItem.item.alarmType.guid, status: false}] : [...updated]);
            props.setAlarmTypesName("");
            props.setSelectedAlarmType(undefined);
            props.setModalLoading(false);
            if (l.length === tableLength) {
                services.warningNotification(" ", "Elements already present in the list");
            } else {
                services.successNotification(" ", " Alarm type added");
            }
        }
    }

    function getOnfulfilled() {
        return (response) => {
            if (response.length > 0) {
                const tableLength: number = props.tableAlarmTypes.length;
                const tableCompanyAlarmTypes: TableCompanyAlarmType[] = response.filter((item) =>
                    !props.tableAlarmTypes
                        .map((e) => e.item.guid)
                        .includes(item.guid))
                    .map(
                        (item: CompanyAlarmType) => {
                            const randomColor: string = services.getDefaultRandomColor();
                            const severitySelected: AlarmTypeSeveritySuggestions = {
                                item: item.severity,
                                value: item.severity.guid,
                                label: item.severity.name,
                            };
                            const newItem: TableCompanyAlarmType = {
                                item,
                                checked: true,
                                // description: (item.description !== null &&
                                //     item.description.trim() !== "") ? item.description : undefined,
                                color: item.color !== null ? item.color : randomColor,
                                displayColorPicker: false,
                                selectedColor: services.hexToRgb(item.color !== null ? item.color : randomColor),
                                name: item.alarmType.name,
                                severity: severitySelected,
                            };
                            return newItem;
                        });
                // set updated list
                setUpdated(
                    [...updated, ...tableCompanyAlarmTypes.map((e) => {
                        return {
                            item: e.item.alarmType.guid,
                            status: false,
                        };
                    })],
                );
                const l = [...props.tableAlarmTypes,
                    ...tableCompanyAlarmTypes.filter((e) =>
                        !props.tableAlarmTypes.map((t) =>
                            t.item.alarmType.guid).some(
                            (t) => t === e.item.alarmType.guid))];
                props.setTableAlarmTypes(l);
                if (l.length === tableLength) {
                    services.warningNotification(" ", "Elements already present in the list");
                } else {
                    services.successNotification(" ", " Alarm types from company added");
                }
            } else {
                services.warningNotification("No Alarm type found", "Try with different input");
            }
            setSelectedCompany(undefined);
            setCompanyName("");
            props.setModalLoading(false);

        };
    }

    React.useEffect(() => {
        setUpdated(
            props.tableAlarmTypes.map((e) => {
                return {
                    item: e.item.alarmType.guid,
                    status: false,
                };
            }),
        );
    }, [updatedTrigger]);
    React.useEffect(() => {
        if (updated.every((e) => e.status === true)) {
            if (operable) {
                if (operation === "deleteUnselected") {
                    const newTable = props.tableAlarmTypes.filter((e) => e.checked);
                    console.log(newTable);
                    props.setTableAlarmTypes([]);
                    props.setTableAlarmTypes(newTable.map((e) => {
                        e.checked = true;
                        return e;
                    }));
                    setOperable(false);
                    setUpdatedTrigger(!updatedTrigger);
                } else if (operation === "next") {
                    props.setModalLoading(true);
                    setOperable(false);
                    props.submit().then(
                        (success) => {
                            setActionsAvailable(!success);
                            props.setModalLoading(false);
                            setUpdatedTrigger(!updatedTrigger);
                            setTimeout(() => location.reload(), 3000);
                        },
                    );
                }
            }
        }
    }, [updated]);

    function setOperationWrapper(type: string) {
        setOperable(true);
        setOperation("");
        setOperation(type);
    }

    let table: JSX.Element;
    let deleteSection: JSX.Element;
    if (props.tableAlarmTypes.length > 0) {
        deleteSection = (
            <Col>
                <Row>
                    <div>
                        <Button
                            onClick={() => {
                                props.setModalLoading(true);
                                setUpdated(
                                    updated.map((e) => {
                                        e.status = false;
                                        return e;
                                    }),
                                );
                                setOperationWrapper("deleteUnselected");
                                setSelectedTrigger(!selectedTrigger);
                                props.setModalLoading(false);
                            }}
                            styles={{root: {mr: 10}}}
                        >
                            Delete Unselected
                        </Button>
                    </div>
                    <div className={"mx-2"}>
                        <Button
                            className={"mx-0"}
                            variant="secondary"
                            onClick={() => {
                                props.setTableAlarmTypes([]);
                                setUpdated([]);
                            }}
                            styles={{
                                root: {mr: 10},
                            }}
                            style={{padding: "12px 16px"}}
                            iconProps={{glyph: icons.TRASH}}
                        >
                            Delete All
                        </Button>
                    </div>
                </Row>
            </Col>
        );
        table = (
            <Table striped={true} bordered={true} hover={true}>
                <thead>
                <tr>
                    {
                        columns.map((col) => (
                            <th key={col.key} style={col.style}>{col.render}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    props.tableAlarmTypes.map((value) => (
                        <CompanyAlarmTypeRow
                            value={value}
                            setUpdated={setUpdated}
                            updated={updated}
                            setShowTranslationModal={setShowTranslationModal}
                            setCompanyAlarmTypeToTranslate={setCompanyAlarmTypeToTranslate}
                            setSelectedTranslation={setSelectedTranslation}
                            tableAlarmTypes={props.tableAlarmTypes}
                            setTableAlarmTypes={props.setTableAlarmTypes}
                            selectedTrigger={selectedTrigger}
                            severitySuggestions={props.severitySuggestions}
                            setAllCheckedStatus={setAllCheckedStatus}
                        />
                    ))
                }
                </tbody>
            </Table>
        );
    } else {
        deleteSection = <></>;
        table = <></>;
    }

    React.useEffect(() => {
        if (props.alarmTypesName.length > 3) {
            if (selectedCompany != null) {
                setSelectedCompany(undefined);
            }
            companyController.getAlarmTypesByPartialName(props.alarmTypesName).then(
                (response) => {
                    setAlarmTypeSuggestions(response.map((e) => {
                        return {
                            item: e,
                            label: e.name,
                            value: e.guid,
                        };
                    }));
                },
            );
        }
    }, [props.alarmTypesName]);
    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <div className={"mx-4"}>
                <Form>
                    <Row className="w-100 m-0">
                        <GeneralAlert
                            message={infoMessage}
                        />
                    </Row>
                    <Row>
                        <Col style={{maxWidth: "23%"}}>
                            <p>Alarm Type</p>
                            <>
                                <AutoSuggest
                                    label="Insert Alarm type"
                                    emptyPlaceholder="No Alarm Type found"
                                    suggestions={alarmTypeSuggestions}
                                    inputValue={props.alarmTypesName}
                                    isInputDisabled={selectedCompany != null || companyName !== ""}
                                    onSelectedValueChange={services.onSelectedItemAndLabel(
                                        props.setAlarmTypesName,
                                        props.setSelectedAlarmType,
                                    )}
                                    itemToString={services.itemToString}
                                    onStateChange={handleAlarmTypeStateChange}
                                />
                            </>
                        </Col>
                        <Col style={{maxWidth: "26%"}}>
                            <p>Import all from Company</p>
                            <AutoSuggest
                                label="Insert  Company"
                                emptyPlaceholder="No  Company Found"
                                suggestions={companies}
                                inputValue={companyName}
                                isInputDisabled={props.selectedAlarmType != null || props.alarmTypesName !== ""}
                                onSelectedValueChange={services.onSelectedItemAndLabel(
                                    setCompanyName,
                                    setSelectedCompany)}
                                itemToString={services.itemToString}
                                onStateChange={handleCompanyStateChange}
                            />
                        </Col>
                        <Col className={"align-self-end mx-0"} style={{maxWidth: "5%"}}>
                            <Button
                                className={"mx-0"}
                                style={{padding: "13px 20px"}}
                                variant="primary"
                                disabled={props.selectedAlarmType == null && selectedCompany == null}
                                onClick={onSearchButton}
                            >
                                +
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        {table}
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        {deleteSection}
                        <Col>
                            <Row style={{justifyContent: "flex-end"}}>
                                <div className={"mx-2"}>
                                    <Button
                                        onClick={() => props.changeStep(props.step - 1)}
                                        styles={{root: {mr: 10}}}
                                        disabled={!actionsAvailable}
                                    >
                                        Previous
                                    </Button>
                                </div>
                                <div className={"mx-2"}>
                                    <Button
                                        onClick={() => {
                                            props.setModalLoading(true);
                                            setUpdated(
                                                updated.map((e) => {
                                                    e.status = false;
                                                    return e;
                                                }),
                                            );
                                            setOperationWrapper("next");
                                            setSelectedTrigger(!selectedTrigger);
                                        }}
                                        variant="strong"
                                        disabled={!actionsAvailable}
                                    >
                                        Finish
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                {/* Loading modal */}
                <LoadingModal
                    loading={props.loading}
                />
                {/* Translation modal */}
                <TranslationModal
                    currentCompany={props.currentCompany}
                    alarmTypeToTranslate={companyAlarmTypeToTranslate}
                    onHide={services.setShowModalWrapper(setShowTranslationModal, false)}
                    show={showTranslationModal}
                    type={selectedTranslation}
                />
            </div>
        </>
    );
};

export default CompanyAlarmTypeSection;
