import {
    ComputationSettingsEnumsJson,
    InterfaceSettingEnumsJson,
} from "../../models/configurability/configurabilityEnumsModel";
import {DeviceModelInterfaceTypeSet} from "../../models/configurability/interfaceType/deviceModelInterfaceType";

import {RawDataAlarmTypeList} from "../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticTypeList} from "../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawDataList} from "../../models/configurability/rawData/rawDataModel";
import {SettingTemplateSet} from "../../models/configurability/template/settingTemplate";
import {DeviceModelList} from "../../models/device/deviceModelModel";
import {ProcessingFunctionList} from "../../models/processingFunction/procesingFunctionModel";
import {PropertiesGroupList} from "../../models/property/property";

class ConfigurabilityController {
    public async findRawDataByDeviceModelGuid(guid: string): Promise<RawDataList> {
        const response = await fetch("configurability/rawData?deviceModelGuid=" + guid);
        return response.json();
    }

    public async findRawDataAlarmTypeByDeviceModelGuid(guid: string): Promise<RawDataAlarmTypeList> {
        const response = await fetch("configurability/rawDataAlarmType?deviceModelGuid=" + guid);
        return response.json();
    }

    public async findRawDataDiagnosticTypeByDeviceModelGuid(guid: string): Promise<RawDataDiagnosticTypeList> {
        const response = await fetch("configurability/rawDataDiagnosticType?deviceModelGuid=" + guid);
        return response.json();
    }

    public async findSettingTemplateByDeviceModelGuid(guid: string, visibility?: boolean): Promise<SettingTemplateSet> {
        const response = await fetch("configurability/settingTemplates?deviceModelGuid=" + guid + (visibility ? "&withVisibility=true" : ""));
        return response.json();
    }

    public async findInterfaceTypesByDeviceModelGuid(guid: string): Promise<DeviceModelInterfaceTypeSet> {
        const response = await fetch("configurability/interfaceTypes?deviceModelGuid=" + guid);
        return response.json();
    }

    public async getCompatibleDeviceModelsWithInterface(guid: string): Promise<DeviceModelList> {
        const response = await fetch("configurability/interfaceTypes/" + guid + "/deviceModels");
        return response.json();
    }

    public async findAllProcessingFunction(): Promise<ProcessingFunctionList> {
        const response = await fetch("configurability/processingFunction/");
        return response.json();
    }

    public async findComputationSettingsEnums(): Promise<ComputationSettingsEnumsJson> {
        const response = await fetch("configurability/rawData/computationSettings/environment/");
        return response.json();
    }

    public async findInterfaceSettingEnums(): Promise<InterfaceSettingEnumsJson> {
        const response = await fetch("configurability/settingTemplate/interfaceSetting/environment/");
        return response.json();
    }

    public async findAllPropertiesGroups(): Promise<PropertiesGroupList> {
        const response = await fetch("configurability/propertiesGroups");
        return response.json();
    }

    public async createRawData(section: string, payload): Promise<any> {
        const response = await fetch("configurability/" + section, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async createSettingTemplateVisibility(payload): Promise<any> {
        const response = await fetch("configurability/settingTemplateVisibility", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async deleteRawData(section: string, guid): Promise<any> {
        return fetch("configurability/" + section + "/" + guid, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: null,
        });
    }

    public async deleteSettingTemplate(guid): Promise<any> {
        return fetch("configurability/settingTemplate/" + guid, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: null,
        });
    }

    public async deleteSettingTemplateVisibility(guid): Promise<any> {
        return fetch("configurability/settingTemplateVisibility/" + guid, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: null,
        });
    }

    public async patchRawData(section: string, payload, guid): Promise<any> {
        const response = await fetch("configurability/" + section + "/" + guid, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async patchSettingTemplate(payload, guid): Promise<any> {
        const response = await fetch("configurability/settingTemplate/" + guid, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async patchSettingTemplateVisibility(payload, guid): Promise<any> {
        const response = await fetch("configurability/settingTemplateVisibility/" + guid, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

}

export default ConfigurabilityController;
