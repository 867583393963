import * as React from "react";
import {Button, ButtonGroup, Dropdown, SplitButton} from "react-bootstrap";
import {DeviceSearchResultListAction, DeviceSearchResultsStats} from "../../types";

interface InvalidDevicesActionsButtonProps {
    stats: DeviceSearchResultsStats;
    actionPerformerFactory(action: DeviceSearchResultListAction): () => void;
}

export function InvalidDevicesActionsButton({stats, actionPerformerFactory}: InvalidDevicesActionsButtonProps) {
    const invalidDevicesCount = stats.alreadyInstalled + stats.notFound;

    if (stats.notFound > 0 && stats.alreadyInstalled > 0 && invalidDevicesCount < stats.count) {
        return (
            <SplitButton
                id={"invalid-devices-search-result-action-button"}
                title={"Remove not valid"}
                onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_NOT_VALID)}
                variant={"dark"}
            >
                <Dropdown.Item
                    as={Button}
                    onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_INSTALLED)}
                >
                    Remove installed
                </Dropdown.Item>
                <Dropdown.Item
                    as={Button}
                    onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_NOT_FOUND)}
                >
                    Remove not found
                </Dropdown.Item>
            </SplitButton>
        );
    }

    if (stats.notFound > 0 && stats.alreadyInstalled > 0 && invalidDevicesCount === stats.count) {
        return (
            <ButtonGroup>
                <Button
                    variant={"dark"}
                    onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_INSTALLED)}
                >
                    Remove installed
                </Button>
                <Button
                    variant={"dark"}
                    onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_NOT_FOUND)}
                >
                    Remove not found
                </Button>
            </ButtonGroup>
        );
    }

    if (stats.notFound > 0 && stats.notFound < stats.count) {
        return (
            <Button
                variant={"dark"}
                onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_NOT_FOUND)}
            >
                Remove not found
            </Button>
        );
    }

    if (stats.alreadyInstalled > 0 && stats.alreadyInstalled < stats.count) {
        return (
            <Button
                variant={"dark"}
                onClick={actionPerformerFactory(DeviceSearchResultListAction.REMOVE_INSTALLED)}
            >
                Remove installed
            </Button>
        );
    }

    return null;
}
