import {Button, SelectInput, SelectInputOption, TextInput, Toggle} from "@danfoss/etui-core";
import {Check, Error} from "@danfoss/etui-icons";
import * as React from "react";
import {Col, Form, Row} from "react-bootstrap";
import TranslationController from "../../../controllers/api/TranslationController";
import {ComponentCategory} from "../../../models/component/componentCategory";
import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentType} from "../../../models/component/componentType";
import {Manufacturer} from "../../../models/manufacturer/manufacturer";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";

interface InfoSectionProps {
    categories: ComponentCategory[];
    types: ComponentType[];
    selectedType: ComponentType;
    setSelectedType: (t: ComponentType) => void;
    currentComponentModel: ComponentModel;
    setSelectedCategory: (c: ComponentCategory) => void;
    setManufacturerInputValue: (s: string) => void;
    updateManufacturer: (s: string) => void;
    setManufacturer: (manufacturer: Manufacturer) => void;
    selectedCategory: ComponentCategory;
    modelName: string;
    manufacturerNameKey: string;
    nameKey: string;
    nameKeyAvailable: boolean;
    manufacturerInputValue: string;
    manufacturers: Manufacturer[];
    image: string;
    generic: boolean;
    setGeneric: (generic: boolean) => void;
    genVeboxCode: boolean;
    loading: boolean;
    submit: () => void;
    setInputValue: (value: any, field: string) => void;
    nameAvailable: boolean;
}

const InfoSection: React.FC<InfoSectionProps> = (props) => {
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.component_models_creation.info_section.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [categoryElements, setCategoryElements] = React.useState([]);
    const [typeElements, setTypeElements] = React.useState([]);
    const [manufacturerElements, setManufacturerElements] = React.useState([]);
    const [inputCategoryValue, setInputCategoryValue] = React.useState("");
    const [inputTypeValue, setInputTypeValue] = React.useState("");

    const [selectedCategory, setSelectedCategory] = React.useState();
    const [selectedType, setSelectedType] = React.useState();
    const [selectedManufacturer, setSelectedManufacturer] = React.useState();

    React.useEffect(() => {
        const cats = props.categories.map(
            (item) => {
                return {
                    item,
                    label: item.name,
                    value: item.guid,
                };
            },
        );
        setCategoryElements(cats);
    }, [props.categories]);
    React.useEffect(() => {
        const newTypeElements = props.types.map(
            (item) => {
                return {
                    item,
                    label: item.name,
                    value: item.guid,
                };
            },
        );
        setTypeElements(newTypeElements);
    }, [props.types]);
    React.useEffect(() => {
        const newManufacturerElements = props.manufacturers.map(
            (item) => {
                return {
                    item,
                    label: item.englishSource,
                    value: item.textKey,
                };
            },
        );
        setManufacturerElements(newManufacturerElements);
    }, [props.manufacturers]);
    React.useEffect(() => {
        if (props.selectedType !== undefined) {
            setInputTypeValue(props.selectedType.name);
        }
        if (props.selectedCategory !== undefined) {
            setInputCategoryValue(props.selectedCategory.name);
        }
    }, []);

    function onStateChange(params, downshiftStateAndHelpers, type) {
        if (!downshiftStateAndHelpers.isOpen) {
            switch (type) {
                case "category":
                    setInputCategoryValue("");
                    break;
                case "type":
                    setInputTypeValue("");
                    break;
            }
        } else if (params.hasOwnProperty("inputValue")) {
            switch (type) {
                case "category":
                    setInputCategoryValue(params.inputValue);
                    if (params.inputValue) {
                        props.setSelectedCategory(undefined);
                        setInputTypeValue("");
                        const filteredCategories = props.categories.filter(
                            (item) => item.name.toLowerCase()
                                .includes(params.inputValue.toLowerCase()));
                        setCategoryElements(filteredCategories.map((item) => {
                            return {
                                item,
                                label: item.name,
                                value: item.guid,
                            };
                        }));
                    }
                    break;
                case "type":
                    setInputTypeValue(params.inputValue);
                    if (params.inputValue) {
                        const filteredTypes = props.types.filter(
                            (item) => item.name.toLowerCase().includes(params.inputValue.toLowerCase()));
                        setTypeElements(filteredTypes.map((item) => {
                            return {
                                item,
                                label: item.name,
                                value: item.guid,
                            };
                        }));
                    }
                    break;
                case "manufacturer":
                    props.setManufacturer(undefined);
                    props.setManufacturerInputValue(params.inputValue);
                    props.updateManufacturer(params.inputValue);
                    break;
            }
        }
    }

    function changeCategory({value, item}: any) {
        setSelectedCategory(value);
        props.setSelectedCategory(item);
    }

    function changeType({value, item}: any) {
        setSelectedType(value);
        props.setSelectedType(item);
    }

    function changeManufacturer({value, item}: any) {
        props.setManufacturerInputValue(value);
        props.setManufacturer(item);
    }

    function validate(): boolean {
        if (props.selectedCategory === undefined) {
            return false;
        }
        if (props.selectedType === undefined) {
            return false;
        }
        if (props.manufacturerInputValue === "") {
            return false;
        }
        if (props.manufacturerNameKey === "") {
            return false;
        }
        if (props.modelName.length === 0) {
            return false;
        }
        return props.nameAvailable && props.nameKeyAvailable;
    }

    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <Form>
                <Row className="w-100 flex-row m-0">
                    <GeneralAlert
                        message={infoMessage}
                    />
                </Row>
                <Row className="mx-2 pb-3">
                    <Col>
                        {/* Category input */}
                        <Form.Label>
                            Category *
                        </Form.Label>
                        <SelectInput
                            name={"componentCategory"}
                            label={"Select category"}
                            searchable={true}
                            value={categoryElements.filter(({value}) => value === selectedCategory)[0]}
                            options={categoryElements}
                            onChange={changeCategory}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                {/* Type input */}
                                <Form.Label>
                                    Type *
                                </Form.Label>
                                <SelectInput
                                    name={"componentType"}
                                    label={"Select type"}
                                    searchable={true}
                                    value={typeElements.filter(({value}) => value === selectedType)[0]}
                                    options={typeElements}
                                    onChange={changeType}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">
                    <Col>
                        {/* Model name input */}
                        <Row className="mx-0 w-100 align-items-center align-content-center">
                            <Form.Label className="pr-2">
                                Model name *
                            </Form.Label>
                            {
                                props.modelName !== "" && (
                                    props.nameAvailable ? (
                                        <Row className="mx-0">
                                            <Check fontSize={18} color="green"/>
                                            <p style={{marginBottom: ".5rem", color: "green", fontSize: "12px"}}>
                                                Name is available!
                                            </p>
                                        </Row>
                                    ) : (
                                        <Row className="mx-0">
                                            <Error fontSize={24} color="red"/>
                                            <p style={{marginBottom: ".5rem", color: "red"}}>
                                                Name already exists!
                                            </p>
                                        </Row>
                                    )
                                )
                            }
                        </Row>
                        <Row className="mx-0">
                            <Col className="mx-0 px-0">
                                <TextInput
                                    type="text"
                                    label="Insert model name"
                                    value={props.modelName}
                                    onChange={(v) => props.setInputValue(v.target.value, "modelName")}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {/* Name key text input */}
                        <Row className="mx-0 w-100 align-items-center align-content-center">
                            <Form.Label className="pr-2">
                                Name key
                            </Form.Label>
                            {
                                props.nameKey !== "component_models.." && (
                                    props.nameKeyAvailable ? (
                                        <Row className="mx-0">
                                            <Check fontSize={18} color="green"/>
                                            <p style={{marginBottom: ".5rem", color: "green", fontSize: "12px"}}>
                                                Name key is available!
                                            </p>
                                        </Row>
                                    ) : (
                                        <Row className="mx-0">
                                            <Error fontSize={24} color="red"/>
                                            <p style={{marginBottom: ".5rem", color: "red"}}>
                                                Name key already exists!
                                            </p>
                                        </Row>
                                    )
                                )
                            }
                        </Row>
                        <Row className="mx-0">
                            <Col className="mx-0 px-0">
                                <TextInput
                                    type="text"
                                    label="Insert name key"
                                    value={props.nameKey}
                                    onChange={(v) => props.setInputValue(v.target.value, "nameKey")}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">
                    <Col>
                        {/* Manufacturer text input */}
                        <Form.Label>
                            Manufacturer *
                        </Form.Label>
                        <SelectInput
                            name={"componentManufacturer"}
                            label={"Select manufacturer"}
                            searchable={true}
                            value={manufacturerElements.filter(({value}) => value === props.manufacturerInputValue)[0]}
                            options={manufacturerElements}
                            onChange={changeManufacturer}
                        />
                    </Col>
                    <Col>
                        {/* Manufacturer name key text input */}
                        <Form.Label>
                            Manufacturer name key *
                        </Form.Label>
                        <TextInput
                            type="text"
                            label="Insert manufacturer name key"
                            value={props.manufacturerNameKey}
                            onChange={(v) => props.setInputValue(v.target.value, "manufacturerNameKey")}
                        />
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">
                    <Col>
                        {/* Image text input */}
                        <Form.Label>
                            Image
                        </Form.Label>
                        <TextInput
                            type="text"
                            label="Insert image"
                            value={props.image}
                            onChange={(v) => props.setInputValue(v.target.value, "image")}
                        />
                    </Col>
                    <Col>
                        {/* Media input image */}
                        <Form.Label>
                            Media
                        </Form.Label>
                        <Form.File
                            disabled={props.currentComponentModel != null}
                            id="exampleFormControlFile1"
                            onChange={(v) => props.setInputValue(v.target.files[0], "media")}
                        />
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">
                    <Col>
                        {/* Vebox code text input */}
                        <Form.Label>
                            Generate vebox code (support for SC2 POM)
                        </Form.Label>
                        <Row className="mx-0 w-100">
                            <Toggle
                                name={"veboxCode"}
                                isChecked={props.genVeboxCode}
                                onChange={(v, c) => props.setInputValue(c, "veboxcode")}
                                renderLabels={(checked) => (checked ? "YES" : "NO")}
                            />
                            {/*</Col>*/}
                        </Row>
                    </Col>
                    <Col>
                        {/* Generic checkbox */}
                        <Form.Label>
                            Generic
                        </Form.Label>
                        <br/>
                        <Toggle
                            name={"Generic"}
                            isChecked={props.generic}
                            onChange={(v, checked) => props.setGeneric(checked)}
                            renderLabels={(checked) => (checked ? "YES" : "NO")}
                        />
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">
                    <Col className="my-auto">
                        <b><p style={{fontSize: 14}}>* mandatory fields</p></b>
                    </Col>
                    <Col className="my-auto">
                        <div className={"text-right"}>
                            <Button
                                variant="strong"
                                disabled={!validate()}
                                onClick={props.submit}
                            >
                                {
                                    props.currentComponentModel != null ?
                                        "Update & next" : "Create & next"
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            {/* Loading modal */}
            <LoadingModal loading={props.loading}/>
        </>
    );
};

export default InfoSection;
