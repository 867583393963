import * as React from "react";
import {useStatefulObserver, useStatelessObserver} from "../../../../../hooks/observables";
import {ComponentModelCompaniesChangesManagerContext} from "./context";
import {ChangesManageType, ComponentModelChangeEvent} from "./types";

export function useComponentModelChangeManager() {
    return React.useContext(ComponentModelCompaniesChangesManagerContext);
}

type ComponentModelChangeObserverAction<S, Args extends unknown[]>
    = (event: ComponentModelChangeEvent, prevState: S, ...args: Args) => S;

export function useStatefulComponentModelChangeObserver<S, Args extends unknown[]>(
    action: ComponentModelChangeObserverAction<S, Args>,
    initialState: S | (() => S),
    args?: Args,
): [S, ChangesManageType] {
    const contextValue = React.useContext(ComponentModelCompaniesChangesManagerContext);
    const [state] = useStatefulObserver(contextValue.observeChanges, action, initialState, args);
    return [state, contextValue];
}

export function useStatelessComponentModelChangeObserver<Args extends readonly unknown[]>(
    callback: (event: ComponentModelChangeEvent, ...args: Args) => void,
    args?: Args,
) {
    const contextValue = React.useContext(ComponentModelCompaniesChangesManagerContext);
    useStatelessObserver(contextValue.observeChanges, callback, args);
}
