import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {Button} from "../../../../common/Button";
import {constant} from "../../../../../utils/functions";
import {Icon} from "../../../../common/Icon";
import {Overlay} from "../../../../common/Overlay";
import {ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX} from "../../../../../hooks/useOverlay";
import {SortButton} from "./SortButton";

type CreatedAtColumnFilterProps = {};

export function CreatedAtColumnFilter({}: CreatedAtColumnFilterProps) {

    const [open, setOpen] = React.useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const canSortBeDisabled = searchParams
        .getAll("sort")
        .findIndex((str) => str.startsWith("createdAt")) > -1;

    return (
        <>
            <Button onClick={constant(setOpen, !open)}><Icon name={"filter_list"}/></Button>
            {open &&
                <Overlay asTag={"menu"} positioned={ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX}>
                    <li>
                        <SortButton propertyName={"createdAt"} order={"desc"} onChange={setSearchParams}>
                            From most recent
                        </SortButton>
                    </li>
                    <li>
                        <SortButton propertyName={"createdAt"} order={"asc"} onChange={setSearchParams}>
                            From least recent
                        </SortButton>
                    </li>
                    {canSortBeDisabled && (
                        <li>
                            <SortButton propertyName={"createdAt"} order={"none"} onChange={setSearchParams}>
                                Disable sort
                            </SortButton>
                        </li>
                    )}
                </Overlay>
            }
        </>
    );
}