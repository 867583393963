import {AutoSuggest, Button, Notification} from "@danfoss/etui-core";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {Modal} from "react-bootstrap";
import ComponentModelController from "../../../controllers/api/ComponentModelController";
import Services from "../../../controllers/utils/Services";
import {TableAlarmType} from "../../../models/alarm/alarmType";
import {ComponentModel} from "../../../models/component/componentModel";
import {TableComponentModelDiagnosticType} from "../../../models/diagnostic/componentModelDiagnosticType";
import {DiagnosticType} from "../../../models/diagnostic/diagnosticType";
import {ConfirmModal} from "../../common/ConfirmModal";

interface ImportModalProps {
    show: boolean;
    onHide: () => void;
    section: string;
    currentComponentModel: ComponentModel;
    diagnostics?: DiagnosticType[];
    setDiagnostics?: (t) => void;
    setTableDiagnostics?: (t) => void;
    setAlarms?: (t) => void;
}

const ImportModal: React.FC<ImportModalProps> = (props) => {
    // controllers
    const componentModelController: ComponentModelController = new ComponentModelController();
    const services: Services = new Services();

    const [inputSearch, setInputSearch] = React.useState("");
    const [suggestions, setSuggestions] = React.useState([]);
    const [selectedComponentModel, setSelectedComponentModel] = React.useState<ComponentModel>(undefined);
    const [confirmShow, setConfirmShow] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (inputSearch.length >= 3) {
            componentModelController.getComponentModelsByName(inputSearch).then(
                (response) => {
                    setSuggestions(props.currentComponentModel != null ?
                        response.list.filter((item) => item.guid !== props.currentComponentModel.guid)
                            .map((item) => {
                                    return {
                                        item,
                                        label: item.name + " (" + item.type + ")",
                                        value: item.guid,
                                    };
                                },
                            ) : response.list.map((item) => {
                                return {
                                    item,
                                    label: item.name + " (" + item.type + ")",
                                    value: item.guid,
                                };
                            },
                        ),
                    );
                },
            );
        } else {
            setSuggestions([]);
        }
    }, [inputSearch]);

    function onConfirmButtonPressed() {
        if (selectedComponentModel !== undefined) {
            switch (props.section) {
                case "diagnostics":
                    componentModelController.getComponentModelDiagnosticTypes(selectedComponentModel.guid).then(
                        (response) => {
                            props.setDiagnostics(
                                [...props.diagnostics,
                                    ...response.list.filter((e) =>
                                        !props.diagnostics.map((t) => t.guid).includes(e.diagnosticType.guid))],
                            );
                            const sorted = response.list.sort((a, b) =>
                                a.diagnosticType.name >= b.diagnosticType.name ? 1 : -1);
                            const newDiagnostics = sorted.map(
                                (item) => {
                                    const body = {
                                        componentModelGuid: item.componentModelGuid,
                                        diagnosticTypeGuid: item.diagnosticTypeGuid,
                                        diagnosticType: item.diagnosticType,
                                        availableOnCloud: item.availableOnCloud != null ? item.availableOnCloud : false,
                                        deviceRequired: item.deviceRequired != null ? item.deviceRequired : false,
                                        energyConsumption: item.energyConsumption,
                                        energyConsumptionAlwaysOn: item.energyConsumptionAlwaysOn != null ?
                                            item.energyConsumptionAlwaysOn : false,
                                    };
                                    const i: TableComponentModelDiagnosticType = {
                                        item: body,
                                        checked: true,
                                    };
                                    return i;
                                },
                            );
                            props.setTableDiagnostics(newDiagnostics);
                            if (response.list.length > 0) {
                                services.successNotification("Success",
                                    "Diagnostic types imported " +
                                    (selectedComponentModel != null ?
                                        ("from " + selectedComponentModel.name + "!") : "correctly!"));
                            } else {
                                services.warningNotification("No Diagnostic type found!",
                                    "Try with different input");
                            }
                        },
                    );
                    break;
                case "alarms":
                    componentModelController.getComponentModelAlarmTypes(selectedComponentModel.guid).then(
                        (response) => {
                            const sorted = response.set.sort((a, b) => a.name >= b.name ? 1 : -1);
                            const newAlarms = sorted.map(
                                (item) => {
                                    const i: TableAlarmType = {
                                        item,
                                        checked: true,
                                    };
                                    return i;
                                },
                            );
                            props.setAlarms(newAlarms);
                            if (response.set.length > 0) {
                                services.successNotification("Success",
                                    "Alarm types imported " +
                                    (selectedComponentModel != null ?
                                        ("from " + selectedComponentModel.name + "!") : "correctly!"));
                            } else {
                                services.warningNotification("No Alarm type found!",
                                    "Try with different input");
                            }
                        },
                    );
                    break;
            }
        }
        setConfirmShow(false);
        props.onHide();
    }

    function onStateChange(params, downshiftStateAndHelpers) {
        if (!downshiftStateAndHelpers.isOpen) {
            setInputSearch("");
        } else if (params.hasOwnProperty("inputValue")) {
            setInputSearch(params.inputValue);
        }
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Import from component model
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AutoSuggest
                        label={"Search component model"}
                        inputValue={inputSearch}
                        suggestions={suggestions}
                        itemToString={(item) => {
                            return item ? item.label : "";
                        }}
                        onSelectedValueChange={(e) => {
                            if (e !== null) {
                                setInputSearch(e.label);
                                setSelectedComponentModel(e.item);
                            }
                        }}
                        onStateChange={onStateChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="strong" onClick={() => setConfirmShow(true)}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                onConfirm={onConfirmButtonPressed}
                show={confirmShow}
                onHide={() => {
                    props.onHide();
                    setConfirmShow(false);
                }}
            />
        </>

    );
};

export default ImportModal;
