import {Button, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import LoadingModal from "../../common/LoadingModal";

import ReactJson from "react-json-view";
import {FunctionCodeModbus} from "../../../models/configurability/configurabilityEnumsModel";
import {AcquisitionSetting} from "../../../models/configurability/rawData/acquisitionSettingModel";
import {ComputationSetting} from "../../../models/configurability/rawData/computationSettings";
import {ProcessingSetting} from "../../../models/configurability/rawData/processingSetting";
import {ProcessingFunction} from "../../../models/processingFunction/procesingFunctionModel";
import {Suggestion} from "../../../models/Suggestion";
import {ConfirmModal} from "../../common/ConfirmModal";
import {Sections} from "../CreateRawData";
import RawDataAcquisitionModal from "../modals/RawDataAcquisitionModal";
import RawDataProcessingModal from "../modals/RawDataProcessingModal";

interface RawDataSectionProps {
    currentStep: number;
    setCurrentStep: (num: number) => void;
    computationSetting: ComputationSetting;
    loading: boolean;
    currentRD: RawData;
    rawDataName: string;
    pos: number;
    groups: Array<Suggestion<string>>;
    categories: Array<Suggestion<string>>;
    processingParametersTypes: Array<Suggestion<any>>;
    samplings: Array<Suggestion<any>>;
    embeddedTypes: Array<Suggestion<string>>;
    functionCodeModbusSuggestion: Array<Suggestion<FunctionCodeModbus>>;
    conversionFunctions: Array<Suggestion<any>>;
    selectedDeviceModel: DeviceModel;
    setCurrentRD: (currentRD: RawData) => void;
    setRawDataName: (RawDataName: string) => void;
    setPos: (pos: number) => void;
    processingFunctions: Array<Suggestion<ProcessingFunction>>;
    onSubmit: () => void;
    rawDataList: RawData[];
}

// tslint:disable:max-line-length
const RawDataSection: React.FC<RawDataSectionProps> = (props) => {
    const services = new Services();
    const [confirmProcShow, setConfirmProcShow] = React.useState<boolean>(false);
    const [confirmAcqShow, setConfirmAcqShow] = React.useState<boolean>(false);
    const [processingModalShow, setProcessingModalShow] = React.useState(false);

    const [firstRender, setFirstRender] = React.useState<boolean>(false);

    const [acquisitionModalShow, setAcquisitionModalShow] = React.useState(false);
    const [param, setParam] = React.useState<AcquisitionSetting | ProcessingSetting>(undefined);
    const initProcessingSettings = props.currentRD && props.currentRD.params ? props.currentRD.params.processing : [];
    const initAcquisitions = props.currentRD && props.currentRD.params ? props.currentRD.params.acquisitions : [];

    const [processingSettings, setProcessingSettings] = React.useState<ProcessingSetting[]>(initProcessingSettings);
    const [acquisitionsSettings, setAcquisitionSettings] = React.useState<AcquisitionSetting[]>(initAcquisitions);

    function onAddOrCreateAcquisition(acquisitionSetting: AcquisitionSetting) {
        return () => {
            if (acquisitionSetting != null) {
                setParam(acquisitionSetting);
            } else {
                setParam(undefined);
            }
            setAcquisitionModalShow(true);
        };
    }

    function onAddOrCreateProcessing(processingSetting: ProcessingSetting) {
        return () => {
            if (processingSetting != null) {
                setParam(processingSetting);
            } else {
                setParam(undefined);
            }
            setProcessingModalShow(true);
        };
    }

    function onConfirmRemoveAcquisition() {
        return () => {
            const acquisition = acquisitionsSettings.filter((ps) => ps !== param);
            props.currentRD.params.acquisitions = acquisition;
            props.setCurrentRD(props.currentRD);
            setAcquisitionSettings(acquisition);
            setConfirmAcqShow(false);
        };
    }

    function onConfirmRemoveProcessing() {
        return () => {
            const psArr = processingSettings.filter((ps) => ps !== param);
            if (psArr.length > 0) {
                psArr.map((e) => e.main = false);
                psArr[psArr.length - 1].main = true;
            }
            props.currentRD.params.processing = psArr;
            props.setCurrentRD(props.currentRD);
            setProcessingSettings(psArr);
            setConfirmProcShow(false);
        };
    }

    function findFunctionFromCode(processingSetting: ProcessingSetting) {
        return props.processingFunctions.find((e) => e.value === processingSetting.functionCode.toString());
    }

    function onRemoveAcquisition(acquisitionSetting: AcquisitionSetting) {
        return () => {
            setParam(acquisitionSetting);
            setConfirmAcqShow(true);
        };
    }

    function onRemoveProcessing(processingSetting: ProcessingSetting) {
        return () => {
            setParam(processingSetting);
            setConfirmProcShow(true);
        };
    }

    function mandatoryFieldsCheck() {
        return (services.isEmpty(props.rawDataName) || props.computationSetting.acquisitions.length === 0
            || props.computationSetting.processing.length === 0
        );
    }

    const getAcquisitionModal = () => {
        return (
            <RawDataAcquisitionModal
                setParam={setParam}
                param={param}
                modalProps={{show: acquisitionModalShow, onHide: services.onHideModal(setAcquisitionModalShow)}}
                currentRD={props.currentRD}
                rawDataName={props.rawDataName}
                samplings={props.samplings}
                embeddedTypes={props.embeddedTypes}
                conversionFunctions={props.conversionFunctions}
                setCurrentRD={props.setCurrentRD}
                functionCodeModbusSuggestion={props.functionCodeModbusSuggestion}
                setAcquisitions={setAcquisitionSettings}
            />
        );
    };
    const memoizedAcquisitionModal = React.useMemo(getAcquisitionModal, [acquisitionModalShow]);

    const getProcessingModal = () => {
        return (
            <RawDataProcessingModal
                modalProps={{
                    show: processingModalShow,
                    onHide: services.onHideModalWithParam(setProcessingModalShow, setFirstRender),
                }}
                currentRD={props.currentRD}
                currentSelections={{...props.currentRD}}
                acquisitions={acquisitionsSettings}
                param={param}
                groups={props.groups}
                categories={props.categories}
                processingFunctions={props.processingFunctions}
                setCurrentRD={props.setCurrentRD}
                firstRender={firstRender}
                setFirstRender={setFirstRender}
                processingParametersTypes={props.processingParametersTypes}
                section={Sections.rawData}
                rawDataList={props.rawDataList}
                setProcessing={setProcessingSettings}
                setParam={setParam}
                rawDataName={props.rawDataName}
            />
        );
    };
    const memoizedProcessingModal = React.useMemo(getProcessingModal, [processingModalShow]);

    const AcquisitionList = () => {
        return (
            <ListGroup style={{maxHeight: "34vh", overflow: "auto"}}>
                {acquisitionsSettings ? acquisitionsSettings.map((value, index) => (
                    <ListGroup.Item className={"onListHover d-flex"} key={index}>
                        <span className={"my-auto"}>{index}, {value.conversionFunction}, {value.sampling},{(value.register ? value.register.code : "")}</span>
                        <Button
                            className={"ml-auto"}
                            iconProps={services.dotsIconButton()}
                            onClick={onAddOrCreateAcquisition(value)}
                        />
                        <Button
                            iconProps={services.removeIconButton()}
                            onClick={onRemoveAcquisition(value)}
                        />
                    </ListGroup.Item>
                )) : <></>}
            </ListGroup>
        );
    };
    const memoizedAcquisitionList = React.useMemo(AcquisitionList, [acquisitionsSettings]);

    const ProcessingList = () => {
        return (
            <ListGroup style={{maxHeight: "34vh", overflow: "auto"}}>
                {processingSettings ? processingSettings.map((value, index) => (
                    <ListGroup.Item className={"onListHover d-flex"} key={index}>
                        <span className={"my-auto"}>{index}, {value.category}, {value.group},{findFunctionFromCode(value).label}</span>
                        <Button
                            className={"ml-auto"}
                            iconProps={services.dotsIconButton()}
                            onClick={onAddOrCreateProcessing(value)}
                        />
                        <Button
                            iconProps={services.removeIconButton()}
                            onClick={onRemoveProcessing(value)}
                        />
                    </ListGroup.Item>
                )) : <></>
                }
            </ListGroup>
        );
    };
    const memoizedProcessingList = React.useMemo(ProcessingList, [processingSettings]);

    return (
        <>
            <Form>
                <Row>
                    <Col className={"mx-2"}>
                        <Row className="mx-2 pb-3">
                            <p>
                                {"Raw data for: " + props.selectedDeviceModel.name}
                            </p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Raw Data Name *</Form.Label>
                                <TextInput
                                    name={"formName"}
                                    label={"Insert name"}
                                    type={"text"}
                                    onChange={services.handleTextOnChange(props.setRawDataName)}
                                    value={props.rawDataName}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Position</Form.Label>
                                <TextInput
                                    name={"formPos"}
                                    label={"Insert position"}
                                    type={"text"}
                                    value={props.pos == null ? "" : props.pos}
                                    onChange={services.handleNumberOnChange(props.setPos)}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col className={"mx-4"}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Acquisitions
                                    </h5>
                                    <Button
                                        className={"mx-0"}
                                        iconProps={services.addCircleIconButton()}
                                        onClick={onAddOrCreateAcquisition(undefined)}
                                    />
                                </Row>
                            </Col>
                            <Col className={"mx-4"}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Processing
                                    </h5>
                                    <Button
                                        className={"mx-0"}
                                        iconProps={services.addCircleIconButton()}
                                        onClick={onAddOrCreateProcessing(undefined)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {memoizedAcquisitionList}
                            </Col>
                            <Col>
                                {memoizedProcessingList}
                            </Col>
                        </Row>
                    </Col>
                    <Col className={"mx-2"} style={{maxWidth: "30%"}}>
                        <Row className="mx-2 pb-3">
                            Preview
                        </Row>
                        <Row style={{maxHeight: "62vh", overflow: "auto"}}>
                            <ReactJson src={props.computationSetting}/>
                        </Row>
                    </Col>
                </Row>
                {/* Bottom nav buttons */}
                <Row className="w-100 d-flex flex-column">
                    <div className="text-right pt-4">
                        <Button
                            onClick={services.previousStep(props.setCurrentStep, props.currentStep)}
                        >
                            Previous
                        </Button>
                        {" "}
                        <Button
                            disabled={mandatoryFieldsCheck()}
                            variant="strong"
                            onClick={props.onSubmit}
                        >
                            {props.currentRD.guid === "" ? "Save" : "Update"}
                        </Button>
                    </div>
                </Row>
            </Form>
            {memoizedAcquisitionModal}
            {memoizedProcessingModal}
            <ConfirmModal
                onConfirm={onConfirmRemoveProcessing()}
                show={confirmProcShow}
                onHide={services.onHideModal(setConfirmProcShow)}
            />
            <ConfirmModal
                onConfirm={onConfirmRemoveAcquisition()}
                show={confirmAcqShow}
                onHide={services.onHideModal(setConfirmAcqShow)}
            />
            {/* Loading modal */}
            <LoadingModal loading={props.loading}/>
        </>
    );
};

export default RawDataSection;
