import * as React from "react";

import {Toggle} from "@danfoss/etui-core";
import {Col, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {SettingTemplate, SettingTemplateCreation} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import SelectInputTitle from "./SelectInputTitle";

interface DefaultInputProps {
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    hasDefault: boolean;
    selectedDeviceModel: Suggestion<DeviceModel>;
    settingTemplateCreation: SettingTemplateCreation;
    setSettingTemplateCreation: (settingTemplateCreation: SettingTemplateCreation) => void;
    setHasDefault: (b: boolean) => void;
}

const DefaultInput: React.FC<DefaultInputProps> = (props) => {
    const [isDefault, setIsDefault] = React.useState<boolean>(false);
    const services = new Services();

    React.useEffect(() => {
        setIsDefault(props.currentSettingTemplate.default);
    }, []);

    function handleDefault() {
        return (value) => {
            const b = value.target.checked;
            if (b && props.hasDefault) {
                services.warningNotification(" ", props.selectedDeviceModel.label + " has already a default");
            } else {
                props.setHasDefault(b);
                setIsDefault(b);
                props.currentSettingTemplate.default = b;
                props.settingTemplateCreation.default = b;
                props.setCurrentSettingTemplate({...props.currentSettingTemplate});
                props.setSettingTemplateCreation({...props.settingTemplateCreation});
            }
        };
    }

    return (
        <Col style={{maxWidth: "10%"}}>
            <SelectInputTitle
                label={"Default"}
                tooltipText={"only one setting template for device model can be default"}
            />
            <Row>
                <Col>
                    <Toggle
                        name={"isDefault"}
                        isChecked={isDefault}
                        onChange={handleDefault()}
                    />
                </Col>

            </Row>
        </Col>
    );
};

export default DefaultInput;
