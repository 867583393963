import * as React from "react";
import {SearchResultView} from "./SearchResultView";

interface NotFoundSearchResultProps {
    deviceCode: string;
}

export function NotFoundSearchResult(
    {
        deviceCode,
        ...rest
    }: NotFoundSearchResultProps & React.LiHTMLAttributes<HTMLLIElement>) {
    return (
        <SearchResultView
            title={deviceCode}
            subTitle={"Not found"}
            {...rest}
            className={`not-found-search-result ${rest.className ?? ""}`}
        />
    );
}
