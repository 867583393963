import * as React from "react";
import {useMap} from "../../../../../../hooks/useMap";
import {useComponentModelChangeManager} from "../../../contexts/ComonentModelChangesContext";
import {useComponentBrands} from "../../../contexts/ComponentBrandsProvider";
import {AbstractComponentBrandsContext} from "./context";
import {AbstractComponentBrand, VirtualComponentBrand} from "./types";

export function AbstractComponentBrandsProvider({children}: React.PropsWithChildren<unknown>) {
    const { componentBrands, refresh} = useComponentBrands();
    const abstractComponentModels = useMap<number | string, AbstractComponentBrand>();

    const changeManager = useComponentModelChangeManager();

    React.useEffect(() => {
        return changeManager.observeChanges((event) => {
            if (event.type !== "result") {
                return;
            }
            const attemptedToCreateNewBrand = event.results.findIndex((r) => r.change.type === "create-new-brand") > -1;
            if (attemptedToCreateNewBrand) {
                refresh();
            }
        });
    }, []);

    React.useEffect(() => {
        abstractComponentModels.from(componentBrands.toArray());
    }, [componentBrands.mapRef()]);

    return (
        <AbstractComponentBrandsContext.Provider
            value={{
                componentBrands: abstractComponentModels,
                addBrand(description: string): VirtualComponentBrand {
                    const timestamp = Number(new Date());
                    const newVirtualBrand: VirtualComponentBrand = {
                        timestamp,
                        description,
                        __virtual: "add",
                    };
                    abstractComponentModels.set(timestamp, newVirtualBrand);
                    changeManager.addComponentBrand(newVirtualBrand);
                    return newVirtualBrand;
                },
            }}
        >
            {children}
        </AbstractComponentBrandsContext.Provider>
    );
}
