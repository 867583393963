import {JSONList} from "../models/utils/jsonList";

export function consumeJSONList<T, C extends ((list: T[]) => any)>(
    consumer: C,
) {
    return ({list}: JSONList<T>): ReturnType<C> => {
        return consumer(list);
    };
}

type JSONPatchOperation =
    {
       op: "ADD",
       path: string,
       value: unknown,
    } | {
        op: "REPLACE",
        path: string,
        value: unknown,
    } | {
        op: "REMOVE",
        path: string,
    } | {
        op: "TEST",
        path: string,
        value: unknown,
    };

type JSONPatch = JSONList<JSONPatchOperation>;

function replace(key: string, value: unknown): JSONPatchOperation {
    return {
        op: "REPLACE",
        path: key.startsWith("/") ? key : `/${key}`,
        value,
    };
}

type Operation = (key: string, value: unknown) => JSONPatchOperation;

export function toJSONPatch(o: object, operation: Operation = replace): JSONPatch {
    return {
        list: Object
            .entries(o)
            .map(([key, value]) => {
                return operation(key, value);
            }),
    };
}
