export function doNothing(): void {
    return;
}

export function identity<T>(x: T): T {
    return x;
}

export function constant<T>(x: T): () => T;
export function constant<F extends (...x: any[]) => any>(f: F, ...constants: Parameters<F>): () => ReturnType<F>;
export function constant<F extends (...x: any[]) => any, P extends readonly unknown[]>(f: F, ...constants: P): () => ReturnType<F>;
export function constant(f, ...constants) {
    return () => {
        if (typeof f === "function") {
            return f(...constants);
        }
        return f;
    };
}

export function over<F extends (...x: any[]) => any>(
    ...functions: F[]
): (...args: Parameters<F>) => Array<ReturnType<F>> {
    return (...args) => {
        return functions
            .map((f) => {
                return (typeof f !== "function") ? null : f(...args);
            });
    };
}
