import * as React from "react";

export function useRefInit<T>(initializer: () => T): React.MutableRefObject<T> {
    const isFirstRender = React.useRef(true);
    const ref = React.useRef<T>(null);
    if (isFirstRender.current) {
        ref.current = initializer();
        isFirstRender.current = false;
    }
    return ref;
}
