import * as React from "react";

import {Checkbox, EmptyState, H6, Spinner, TextInput} from "@danfoss/etui-core";
import {Col, Row, Table} from "react-bootstrap";
import AlarmController from "../../../controllers/api/AlarmController";
import ConfigurabilityController from "../../../controllers/api/ConfigurabilityController";
import DiagnosticController from "../../../controllers/api/DiagnosticController";
import {AlarmTypeCustom} from "../../../models/alarm/alarmType";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {
    SettingTemplate,
    TemplateAlarm,
    TemplateConfigurationSlave,
    TemplateConfigurationTelemetry,
    TemplateData,
    TemplateDiagnostic,
} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {DiagnosticTypeCustom} from "../../../models/diagnostic/diagnosticType";
import {Suggestion} from "../../../models/Suggestion";
import {SectionsModal} from "../CreateSettingTemplate";
import SelectInputCustomType from "./SelectInputCustomType";
import SelectInputTitle from "./SelectInputTitle";

interface FirstFormProps {
    section: SectionsModal;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    currentInterfaceStep?: number;
    currentSlaveIndex?: number;
    selectedDeviceModel?: Suggestion<DeviceModel>;
}

// tslint:disable:max-line-length
const RawDataTableCustom: React.FC<FirstFormProps> = (props) => {

    const alarmController = new AlarmController();
    const diagnosticController = new DiagnosticController();
    const configurabilityController: ConfigurabilityController = new ConfigurabilityController();

    const [customAlarmTypes, setCustomAlarmTypes] = React.useState<Array<Suggestion<AlarmTypeCustom>>>([]);
    const [customDiagnosticTypes, setCustomDiagnosticTypes] =
        React.useState<Array<Suggestion<DiagnosticTypeCustom>>>([]);

    const [rawDataDiagnosticTypeList, setRawDataDiagnosticTypeList] = React.useState<RawDataDiagnosticType[]>([]);
    const [rawDataAlarmTypeList, setRawDataAlarmTypeList] = React.useState<RawDataAlarmType[]>([]);

    // Table Hooks
    const [allChecked, setAllChecked] = React.useState<boolean>(false);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(false);

    const [isEmpty, setIsEmpty] = React.useState<boolean>(false);

    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        props.currentSettingTemplate.templateContent.type === "slave" ? fetchSlave() : fetchTelemetry();
    }, []);

    function setCustomTypesForAlarms(rawDataAlarmTypes: RawDataAlarmType[]) {
        if (rawDataAlarmTypes != null && rawDataAlarmTypes.length > 0) {
            alarmController.findAlarmTypesWithChildren(
                rawDataAlarmTypes.map((e) => e.childAlarmTypeGuid ? e.childAlarmTypeGuid : e.alarmTypeGuid)).then((response) => {
                    const alarmTypeCustom: Array<Suggestion<AlarmTypeCustom>> = response.set
                        .map((item) => ({item, label: item.name, value: item.guid}));
                    setCustomAlarmTypes(alarmTypeCustom);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    function getTemplateData() {
        let settingTemplateSlave;
        if (props.currentSettingTemplate.templateContent.type === "slave") {
            settingTemplateSlave = (props.currentSettingTemplate.templateContent as TemplateConfigurationSlave).dataTemplate;
        } else {
            const templateInterfaces = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep];
            settingTemplateSlave = templateInterfaces == null || templateInterfaces.slaves == null ? (templateInterfaces != null && templateInterfaces.embeddedDataTemplate) ? templateInterfaces.embeddedDataTemplate : null : templateInterfaces.slaves[props.currentSlaveIndex].dataTemplate;
        }
        return settingTemplateSlave;
    }

    function setCustomTypesForDiagnostics(rawDataDiagnosticTypes) {
        if (rawDataDiagnosticTypes != null && rawDataDiagnosticTypes.length > 0) {
            diagnosticController.findDiagnosticTypesWithChildren(rawDataDiagnosticTypes.map((e) => e.diagnosticTypeGuid))
                .then((response) => {
                    const diagnosticTypeCustom: Array<Suggestion<DiagnosticTypeCustom>> = response.set
                        .map((item) => ({item, label: item.name, value: item.guid}));
                    setCustomDiagnosticTypes(diagnosticTypeCustom);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    function fetchSlave() {
        const settingTemplateSlave = JSON.parse(JSON.stringify((props.currentSettingTemplate.templateContent as TemplateConfigurationSlave)));
        if (settingTemplateSlave.deviceModelGuid != null) {
            if (props.section === SectionsModal.rawDataAlarmType) {
                configurabilityController.findRawDataAlarmTypeByDeviceModelGuid(settingTemplateSlave.deviceModelGuid).then((rawDataAlarmTypeResponse) => {
                    setIsEmpty(rawDataAlarmTypeResponse != null && rawDataAlarmTypeResponse.set.length === 0);
                    setRawDataAlarmTypeList(rawDataAlarmTypeResponse.set);
                    setCustomTypesForAlarms(rawDataAlarmTypeResponse.set);
                    setAlarmForSlave(rawDataAlarmTypeResponse.set);
                });
            } else {
                configurabilityController.findRawDataDiagnosticTypeByDeviceModelGuid(settingTemplateSlave.deviceModelGuid).then((rawDataDiagnosticTypeResponse) => {
                    setIsEmpty(rawDataDiagnosticTypeResponse != null && rawDataDiagnosticTypeResponse.set.length === 0);
                    setRawDataDiagnosticTypeList(rawDataDiagnosticTypeResponse.set);
                    setCustomTypesForDiagnostics(rawDataDiagnosticTypeResponse.set);
                    setDiagnosticForSlave(rawDataDiagnosticTypeResponse.set);
                });
            }
        }
    }

    function fetchTelemetry() {
        const templateInterfaces = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep];
        let isEmbedded;
        let guid;
        if (templateInterfaces == null || templateInterfaces.slaves == null) {
            isEmbedded = true;
            guid = props.selectedDeviceModel.value;
        } else {
            isEmbedded = false;
            guid = templateInterfaces.slaves[props.currentSlaveIndex].deviceModelGuid;
        }
        if (props.section === SectionsModal.rawDataAlarmType) {
            configurabilityController.findRawDataAlarmTypeByDeviceModelGuid(guid).then((rawDataAlarmTypeResponse) => {
                setIsEmpty(rawDataAlarmTypeResponse != null && rawDataAlarmTypeResponse.set.length === 0);
                setRawDataAlarmTypeList(rawDataAlarmTypeResponse.set);
                setCustomTypesForAlarms(rawDataAlarmTypeResponse.set);
                if (isEmbedded) {
                    setAlarmEmbedded(rawDataAlarmTypeResponse.set);
                } else {
                    setAlarmForIntegratedSlave(props.currentSlaveIndex, rawDataAlarmTypeResponse.set);
                }
            });
        } else {
            configurabilityController.findRawDataDiagnosticTypeByDeviceModelGuid(guid).then((rawDataDiagnosticTypeResponse) => {
                setIsEmpty(rawDataDiagnosticTypeResponse != null && rawDataDiagnosticTypeResponse.set.length === 0);
                setRawDataDiagnosticTypeList(rawDataDiagnosticTypeResponse.set);
                setCustomTypesForDiagnostics(rawDataDiagnosticTypeResponse.set);
                if (isEmbedded) {
                    setDiagnosticEmbedded(rawDataDiagnosticTypeResponse.set);
                } else {
                    setDiagnosticForIntegratedSlave(props.currentSlaveIndex, rawDataDiagnosticTypeResponse.set);
                }
            });
        }
    }

    function getRawDataAlarmTypeForConfiguration(rawDataAlarmTypeResponse: RawDataAlarmType[]) {
        let templateAlarms: TemplateAlarm[] = [];
        if (rawDataAlarmTypeResponse != null) {
            templateAlarms = rawDataAlarmTypeResponse.map((e) => ({
                rawDataAlarmTypeGuid: e.guid,
                defaultAlarmTypeGuid: e.childAlarmTypeGuid ? e.childAlarmTypeGuid : e.alarmTypeGuid,
                customAlarmTypeGuid: "",
                settingProcessParamsValues: [],
                checked: false,
            }));
        }
        return templateAlarms;
    }

    function getRawDataDiagnosticTypeForConfiguration(rawDataDiagnosticTypeResponse: RawDataDiagnosticType[]) {
        let templateDiagnostics: TemplateDiagnostic[] = [];
        if (rawDataDiagnosticTypeResponse != null) {
            templateDiagnostics = rawDataDiagnosticTypeResponse.map((e) => ({
                rawDataDiagnosticTypeGuid: e.guid,
                defaultDiagnosticTypeGuid: e.diagnosticTypeGuid,
                customDiagnosticTypeGuid: "",
                dataGroupType: e.params.processing[0].group,
                settingProcessParamsValues: [],
                checked: false,
            }));
        }
        return templateDiagnostics;
    }

    function setDiagnosticForSlave(rawDataDiagnosticTypeResponse: RawDataDiagnosticType[]) {

        const templateConfigurationSlave = props.currentSettingTemplate.templateContent as TemplateConfigurationSlave;
        const templateDiagnostics = getRawDataDiagnosticTypeForConfiguration(rawDataDiagnosticTypeResponse);
        const tempTemplateData = JSON.parse(JSON.stringify((templateConfigurationSlave.dataTemplate.diagnostics.length > 0) ?
            alreadyPresentDiagnostic(templateConfigurationSlave.dataTemplate, templateDiagnostics) :
            {alarms: templateConfigurationSlave.dataTemplate.alarms, diagnostics: templateDiagnostics}));
        handleIndeterminateCheckBox(tempTemplateData.diagnostics);
        setTemplate(tempTemplateData);
    }

    function setAlarmForSlave(rawDataAlarmTypeResponse: RawDataAlarmType[]) {
        const templateConfigurationSlave = props.currentSettingTemplate.templateContent as TemplateConfigurationSlave;
        const templateAlarms = getRawDataAlarmTypeForConfiguration(rawDataAlarmTypeResponse);
        const tempTemplateData = JSON.parse(JSON.stringify((templateConfigurationSlave.dataTemplate.alarms.length > 0) ?
            alreadyPresentAlarm(templateConfigurationSlave.dataTemplate, templateAlarms) :
            {alarms: templateAlarms, diagnostics: templateConfigurationSlave.dataTemplate.diagnostics}));
        handleIndeterminateCheckBox(tempTemplateData.alarms);
        setTemplate(tempTemplateData);
    }

    function setDiagnosticForIntegratedSlave(index, rawDataDiagnosticTypeResponse: RawDataDiagnosticType[]) {
        const templateConfigurationTelemetry = props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry;
        const templateDiagnostics = getRawDataDiagnosticTypeForConfiguration(rawDataDiagnosticTypeResponse);

        const dataAlarms = templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate;
        const tempTemplateData = JSON.parse(JSON.stringify((
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate == null ||
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate.diagnostics.length === 0) ?
            {
                alarms: (dataAlarms != null && dataAlarms.alarms.length > 0) ? dataAlarms.alarms : [],
                diagnostics: templateDiagnostics,
            } :
            alreadyPresentDiagnostic(templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate, templateDiagnostics)));
        handleIndeterminateCheckBox(tempTemplateData.diagnostics);
        setTemplate(tempTemplateData);
    }

    function setAlarmForIntegratedSlave(index, rawDataAlarmTypeResponse: RawDataAlarmType[]) {
        const templateConfigurationTelemetry = props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry;
        const templateAlarms = getRawDataAlarmTypeForConfiguration(rawDataAlarmTypeResponse);

        const dataDiagnostics = templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate;
        const tempTemplateData = JSON.parse(JSON.stringify((
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate == null ||
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate.alarms.length === 0) ?
            {
                alarms: templateAlarms,
                diagnostics: (dataDiagnostics != null && dataDiagnostics.diagnostics.length > 0) ? dataDiagnostics.diagnostics : [],
            } :
            alreadyPresentAlarm(templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[index].dataTemplate, templateAlarms)));
        handleIndeterminateCheckBox(tempTemplateData.alarms);
        setTemplate(tempTemplateData);
    }

    function setDiagnosticEmbedded(rawDataDiagnosticTypeResponse: RawDataDiagnosticType[]) {

        const templateConfigurationTelemetry =
            props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry;

        const templateDiagnostics = getRawDataDiagnosticTypeForConfiguration(rawDataDiagnosticTypeResponse);
        const tempTemplateData = JSON.parse(JSON.stringify((
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate == null ||
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate.diagnostics.length === 0) ?
            {
                alarms: templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate.alarms,
                diagnostics: templateDiagnostics,
            } :
            alreadyPresentDiagnostic(templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate, templateDiagnostics)));
        handleIndeterminateCheckBox(tempTemplateData.diagnostics);
        setTemplate(tempTemplateData);
    }

    function setAlarmEmbedded(rawDataAlarmTypeResponse: RawDataAlarmType[]) {

        const templateConfigurationTelemetry =
            props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry;

        const templateAlarms = getRawDataAlarmTypeForConfiguration(rawDataAlarmTypeResponse);

        const tempTemplateData = JSON.parse(JSON.stringify((
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate == null ||
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate.alarms.length === 0) ?
            {
                alarms: templateAlarms,
                diagnostics: templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate.diagnostics,
            } :
            alreadyPresentAlarm(templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate, templateAlarms)));
        handleIndeterminateCheckBox(tempTemplateData.alarms);
        setTemplate(tempTemplateData);
    }

    function alreadyPresentAlarm(t: TemplateData, templateAlarms: TemplateAlarm[]) {
        if (t.alarms != null && t.alarms.length > 0) {
            templateAlarms = templateAlarms.map((e) => {
                const templateAlarm = t.alarms.find((ta) => e.rawDataAlarmTypeGuid === ta.rawDataAlarmTypeGuid);
                if (templateAlarm) {
                    e.checked = true;
                    e.customAlarmTypeGuid = templateAlarm.customAlarmTypeGuid;
                }
                return e;
            });
        }
        t.alarms = templateAlarms;
        return t;
    }

    function alreadyPresentDiagnostic(t: TemplateData, templateDiagnostics: TemplateDiagnostic[]) {
        if (t.diagnostics != null && t.diagnostics.length > 0) {
            templateDiagnostics = templateDiagnostics.map((e) => {
                const templateDiagnostic = t.diagnostics.find((ta) => e.rawDataDiagnosticTypeGuid === ta.rawDataDiagnosticTypeGuid);
                if (templateDiagnostic) {
                    e.checked = true;
                    e.customDiagnosticTypeGuid = templateDiagnostic.customDiagnosticTypeGuid;
                }
                return e;
            });
        }
        t.diagnostics = templateDiagnostics;
        return t;
    }

    function setTemplate(dataTemplate: TemplateData) {
        if (props.currentSettingTemplate.templateContent.type === "slave") {
            const temSettingTemplate = {...props.currentSettingTemplate};
            (temSettingTemplate.templateContent as TemplateConfigurationSlave).dataTemplate = {...dataTemplate};
            props.setCurrentSettingTemplate(temSettingTemplate);
        } else {
            const temSettingTemplate = {...props.currentSettingTemplate};

            const templateInterfaces = (temSettingTemplate.templateContent as TemplateConfigurationTelemetry)
                .interfaces[props.currentInterfaceStep];

            const isEmbedded = templateInterfaces.slaves == null;
            if (isEmbedded) {
                (temSettingTemplate.templateContent as TemplateConfigurationTelemetry)
                    .interfaces[props.currentInterfaceStep]
                    .embeddedDataTemplate = {...dataTemplate};
            } else {
                (temSettingTemplate.templateContent as TemplateConfigurationTelemetry)
                    .interfaces[props.currentInterfaceStep]
                    .slaves[props.currentSlaveIndex].dataTemplate = {...dataTemplate};
            }
            props.setCurrentSettingTemplate(temSettingTemplate);
        }
    }

    function handleCheckbox() {
        const temp = {...getTemplateData()};
        setIndeterminate(false);
        setAllChecked(!allChecked);
        if (props.section === SectionsModal.rawDataAlarmType) {
            temp.alarms.map((e) => {
                e.checked = !allChecked;
                return e;
            });
        } else {
            temp.diagnostics.map((e) => {
                e.checked = !allChecked;
                return e;
            });
        }
        setTemplate(temp);
    }

    // function handleTextOnChange(currentPos: number, index: number) {
    //     return (value) => {
    //         const temp = props.currentTemplateData;
    //         if (props.section === SectionsModal.rawDataAlarmType) {
    //             temp.alarms[currentPos].settingProcessParamsValues[index] = value.target.value;
    //         } else {
    //             temp.diagnostics[currentPos].settingProcessParamsValues[index] = value.target.value;
    //         }
    //         props.setCurrentTemplateData(temp);
    //     };
    // }

    function handleCheckboxOnChange(currentPos: number) {
        return (value) => {
            const temp = {...getTemplateData()};
            if (props.section === SectionsModal.rawDataAlarmType) {
                temp.alarms[currentPos].checked = value.target.checked;
                handleIndeterminateCheckBox(temp.alarms);
            } else {
                temp.diagnostics[currentPos].checked = value.target.checked;
                handleIndeterminateCheckBox(temp.diagnostics);
            }
            setTemplate(temp);
        };
    }

    function handleIndeterminateCheckBox(array: any) {
        const a = array.filter((e) => e.checked).length;
        if (a === array.length) {
            setIndeterminate(false);
            setAllChecked(true);
        } else if (a === 0) {
            setIndeterminate(false);
            setAllChecked(false);
        } else {
            setIndeterminate(true);
            setAllChecked(false);
        }
    }

    const columns = [
        {
            key: "checkbox",
            className: "col-2",
            render: (
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <span> Select all</span>
                    <br/>
                    <Checkbox
                        key={"selectAll"}
                        checked={allChecked}
                        indeterminate={indeterminate}
                        onChange={handleCheckbox}
                    />
                </div>

            ),
        },
        {key: "name", className: "col-4", render: "Name"},
        {
            key: "template",
            className: "col-3",
            render: (
                <SelectInputTitle
                    label={"Custom " + getSection() + " Type"}
                    tooltipText={"Select to specify the custom " + getSection() + ". If the input is disabled it's because there are no custom types"}
                />
            ),
        },
        {key: "processing_param", className: "col-3", render: "Processing Param"},
    ];

    function findRawData(value): RawDataAlarmType | RawDataDiagnosticType {
        if (props.section === SectionsModal.rawDataAlarmType) {
            return rawDataAlarmTypeList.find((e) => e.guid === value.rawDataAlarmTypeGuid);
        } else {
            return rawDataDiagnosticTypeList.find((e) => e.guid === value.rawDataDiagnosticTypeGuid);
        }
    }

    function findRawDataName(value) {
        if (props.section === SectionsModal.rawDataAlarmType) {
            const rawDataAlarm = findRawData(value) as RawDataAlarmType;
            return rawDataAlarm != null ? (
                <>
                    {rawDataAlarm.name}
                    {(rawDataAlarm.propertyGroupGuid ? (<><br/>{" Properties Group: " + rawDataAlarm.propertyGroupName}</>) : "")}
                </>
            ) : "";
        } else {
            const rawDataDiagnostic = findRawData(value) as RawDataDiagnosticType;
            return rawDataDiagnostic != null ? rawDataDiagnostic.name : "";
        }
    }

    function findSuggestion(child) {
        if (props.section === SectionsModal.rawDataAlarmType) {
            if (child != null) {
                return child.item.childrenAlarmTypes
                    .map((item) => ({item, label: item.name, value: item.guid}));
            } else {
                return [];
            }
        } else {
            if (child != null) {
                return child.item.childrenDiagnosticTypes
                    .map((item) => ({item, label: item.name, value: item.guid}));
            } else {
                return [];
            }
        }
    }

    function findChild(value): Suggestion<any> {
        const guid = findRawDataADTypeGuid(value);
        if (guid != null) {
            if (props.section === SectionsModal.rawDataAlarmType) {
                if (customAlarmTypes != null && customAlarmTypes.length > 0) {
                    const child = customAlarmTypes.find((e) => (e.item.guid === guid));
                    if (child != null) {
                        return child;
                    }
                }
            } else {
                if (customDiagnosticTypes != null && customDiagnosticTypes.length > 0) {
                    const child = customDiagnosticTypes.find((e) => (e.item.guid === guid));
                    if (child != null) {
                        return child;
                    }
                }
            }
        }
        return null;
    }

    function findRawDataADTypeGuid(value) {
        if (props.section === SectionsModal.rawDataAlarmType) {
            const rawDataAlarm = findRawData(value) as RawDataAlarmType;
            return rawDataAlarm
                ? rawDataAlarm.childAlarmTypeGuid ? rawDataAlarm.childAlarmTypeGuid : rawDataAlarm.alarmTypeGuid
                : undefined;
        } else {
            const rawDataDiagnostic = findRawData(value) as RawDataDiagnosticType;
            return rawDataDiagnostic != null ? rawDataDiagnostic.diagnosticTypeGuid : undefined;
        }
    }

    function objectToMap() {
        const currentTemplateData = getTemplateData();
        if (currentTemplateData != null) {
            if (props.section === SectionsModal.rawDataAlarmType) {
                if (currentTemplateData.alarms != null) {
                    return currentTemplateData.alarms
                        .map((value, index) => (element(value, index)));
                }
            } else {
                if (currentTemplateData.diagnostics != null) {
                    return currentTemplateData.diagnostics
                        .map((value, index) => (element(value, index)));
                }
            }
        }
        return (<></>);
    }

    function getSection() {
        return (props.section === SectionsModal.rawDataAlarmType ? "Alarm" : "Diagnostic");
    }

    const element = (value, index) => {
        const child = findChild(value);
        return ((
            <tr key={index}>
                <td style={{verticalAlign: "middle"}}>
                    <div style={{margin: "0 auto", textAlign: "center"}}>
                        <Checkbox
                            checked={value.checked}
                            onChange={handleCheckboxOnChange(index)}
                        />
                    </div>
                </td>
                <td style={{verticalAlign: "middle"}}>
                    {findRawDataName(value)} <br/>
                    <h6>{(child != null ? "Generic " + getSection() + " type: " + child.item.name : "")}</h6>
                </td>
                <td style={{verticalAlign: "middle"}}>
                    <SelectInputCustomType
                        index={index}
                        currentInterfaceStep={props.currentInterfaceStep}
                        suggestions={findSuggestion(child)}
                        currentTemplateData={getTemplateData()}
                        section={props.section}
                        setTemplate={setTemplate}
                    />
                </td>
                <td style={{verticalAlign: "middle"}}>
                    <Row>
                        <Col>
                            <TextInput
                                name={"formName"}
                                label={"Process Param Value 1"}
                                type={"text"}
                                // onChange={handleTextOnChange(index, 0)}
                                // value={value.settingProcessParamsValues[0]}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <TextInput
                                name={"formName"}
                                label={"Process Param Value 2"}
                                type={"text"}
                                // onChange={handleTextOnChange(index, 1)}
                                // value={value.settingProcessParamsValues[1]}
                                disabled={true}
                            /></Col>
                    </Row>
                </td>
            </tr>
        ));
    };

    const table = (
        <Table striped={true} bordered={true} hover={true}>
            <thead>
            <tr>
                {columns.map((col) => (<th key={col.key} className={col.className}>{col.render}</th>))}
            </tr>
            </thead>
            <tbody>
            {objectToMap()}
            </tbody>
        </Table>
    );

    const emptyState = (
        <EmptyState>
            No Raw data {getSection()} found
        </EmptyState>
    );

    return (
        <>
            <Row className={"mx-2 pb-3 my-2"}>
                <Col>
                    <H6>
                        {"Configure " + getSection()}
                    </H6>
                </Col>
            </Row>
            {loading ? (
                <Col>
                    <p>Loading...</p>
                    <Spinner/>
                </Col>
            ) : (
                <Row className={"mx-2 pb-3"}>
                    <Col>
                        {(getTemplateData() != null && !isEmpty) ? table : emptyState}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default RawDataTableCustom;
