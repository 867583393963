import * as React from "react";

export interface StatefulSet<T> {
    set: Set<T>;
    toArray(): T[];
    add(value: T): void;
    delete(value: T): void;
    clear(): void;
    has(value: T): boolean;
    isEmpty(): boolean;
    from(values: T[] | Set<T>): void;
}

export function useSet<T>(initialValues?: T[]): StatefulSet<T> {
    const [set, setSet] = React.useState(() => new Set<T>(initialValues));

    return {
        set,
        add(value: T) {
            setSet((prevValues) => {
                if (prevValues.has(value)) {
                    return prevValues;
                }
                return new Set<T>([...Array.from(prevValues), value]);
            });
        },
        delete(value: T) {
           setSet((prevState) => {
               if (!prevState.has(value)) {
                   return prevState;
               }
               return new Set<T>(Array.from(prevState).filter((v) => v !== value));
           });
        },
        clear() {
            setSet((prevState) => prevState.size === 0 ? prevState : new Set());
        },
        toArray() {
            return Array.from(set.values());
        },
        has(value: T) {
            return set.has(value);
        },
        isEmpty(): boolean {
            return set.size === 0;
        },
        from(values) {
            setSet(new Set(values));
        },
    };
}
