import {LegacyInterface} from "./index";

export class CustomAjax {
    public static simplePostRequest(url: string, successCallback: (data) => void) {
        $.ajax({
            url,
            type: "POST",
            success: (data) => {
                if (successCallback) {
                    successCallback(data);
                }
            },
            error: (xhr, status, error) => {
                alert("Error: " + xhr.responseText);
            },
        });
    }
}

export class BreadcrumbItem {
    constructor(protected href: string, protected label: string, protected isActive: boolean) {
    }

    public render(): string {
        let result = "<li export class='breadcrumb-item";
        if (this.isActive) {
            result += " active'>" + this.label;
        } else {
            if (this.href == null) {
                result += "'>" + this.label;
            } else {
                result += "'><a href='" + this.href + "'>" + this.label + "</a>";
            }
        }
        result += "</li>";
        return result;
    }
}

export class Breadcrumb {
    constructor(protected items: BreadcrumbItem[]) {
    }

    public render(): string {
        if (this.items == null || this.items.length === 0) {
            return null;
        }
        let result = "<ol export class='breadcrumb'>";
        for (const item of this.items) {
            result += item.render();
        }
        result += "</ol>";
        return result;
    }
}

export class Page {
    constructor(protected breadcrumb: Breadcrumb) {
        this.initBreadcrumb();
    }

    private initBreadcrumb(): void {
        if (this.breadcrumb) {
            const rendered: string = this.breadcrumb.render();
            if (rendered == null) {
                $(".breadcrumb").remove();
            } else {
                $(".breadcrumb").replaceWith(rendered);
            }
        }
    }
}

export abstract class LoadablePage extends Page {
    constructor(protected getUrl: string, breadcrumb: Breadcrumb) {
        super(breadcrumb);
        this.load();
    }

    protected abstract onLoadSuccess(): void;

    private load() {

        LegacyInterface.onLoad = () => {
            this.onLoadSuccess();
        };
        LegacyInterface.push("/" + this.getUrl);
    }
}
