import * as React from "react";

import {Button, Checkbox, H5, Toggle} from "@danfoss/etui-core";
import {Col, Form, Row, Table} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {
    DeviceModelInterfaceTypeToCheck,
    PropertiesStructure,
} from "../../../models/configurability/interfaceType/deviceModelInterfaceType";
import {SettingTemplate, SettingTemplateCreation} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import DefaultInput from "../common/DefaultInput";
import NameInput from "../common/NameInput";
import SelectInputTitle from "../common/SelectInputTitle";
import {Sections} from "../CreateSettingTemplate";

interface InterfaceAvailableSectionProps {
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    onSubmit: (templateData: Sections) => void;
    availableDMInterface: DeviceModelInterfaceTypeToCheck[];
    setAvailableDMInterface: (set: DeviceModelInterfaceTypeToCheck[]) => void;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    settingTemplateCreation: SettingTemplateCreation;
    setSettingTemplateCreation: (settingTemplateCreation: SettingTemplateCreation) => void;
    hasDefault: boolean;
    setHasDefault: (b: boolean) => void;
}

const InterfaceAvailableSection: React.FC<InterfaceAvailableSectionProps> = (props) => {
    const services = new Services();

    enum Type {
        SLAVE, TELEMETRY, EMBEDDED,
    }

    const [disabled, setDisabled] = React.useState<boolean>(true);

    const [isEmbedded, setIsEmbedded] = React.useState<boolean>(true);

    React.useEffect(() => {
        setDisabled(props.selectedDeviceModel == null || services.isEmpty(props.currentSettingTemplate.name));
        const isEmb = props.currentSettingTemplate.type === Type[Type.EMBEDDED];
        if (isEmb) {
            const temp: DeviceModelInterfaceTypeToCheck[] = props.availableDMInterface.map((c) => {
                if (c.item.propertiesStructure !== PropertiesStructure.EMBEDDED) {
                    c.checked = false;
                    c.disabled = true;
                    return c;
                }
                return c;
            });
            props.setAvailableDMInterface(temp);
        }
        setIsEmbedded(isEmb);
        setType(isEmb);
    }, [props.selectedDeviceModel, props.currentSettingTemplate.name]);

    function setType(isEmb: boolean) {
        if (isEmb) {
            props.currentSettingTemplate.type = Type[Type.EMBEDDED];
            props.settingTemplateCreation.type = Type[Type.EMBEDDED];
        } else {
            props.currentSettingTemplate.type = Type[Type.TELEMETRY];
            props.settingTemplateCreation.type = Type[Type.TELEMETRY];
        }
        props.setCurrentSettingTemplate({...props.currentSettingTemplate});
        props.setSettingTemplateCreation({...props.settingTemplateCreation});
    }

    function handleEmbeddedOnChange() {
        return (value) => {
            setIsEmbedded(value.target.checked);
            setType(value.target.checked);
            const temp: DeviceModelInterfaceTypeToCheck[] = props.availableDMInterface.map((c) => {
                if (value.target.checked) {
                    if (c.item.propertiesStructure !== PropertiesStructure.EMBEDDED) {
                        c.checked = false;
                        c.disabled = true;
                        return c;
                    }
                    return c;
                } else {
                    if (c.item.propertiesStructure === PropertiesStructure.EMBEDDED) {
                        c.checked = true;
                        c.disabled = true;
                        return c;
                    } else {
                        c.checked = false;
                        c.disabled = false;
                        return c;
                    }
                }
            });
            props.setAvailableDMInterface(temp);
        };
    }

    function mandatoryFieldsCheck() {
        return !props.availableDMInterface.some((e) => e.checked) || disabled;
    }

    function onSubmit() {
        return () => props.onSubmit(Sections.deviceModelSlaveSection);
    }

    function onPrevious() {
        return () => props.setCurrentStep(Sections.deviceModelSection);
    }

    function onCheckboxSelected(value: DeviceModelInterfaceTypeToCheck, index: number) {
        return () => {
            let temp: DeviceModelInterfaceTypeToCheck[] = props.availableDMInterface.map((c) => {
                if (c.item.guid === value.item.guid && props.availableDMInterface.indexOf(c) === index) {
                    c.checked = !c.checked;
                }
                return c;
            });
            temp = temp.map((e) => { // remove embedded then set incompatibilities
                if (e.item.propertiesStructure === PropertiesStructure.EMBEDDED) {
                    return e;
                } else {
                    e.disabled = e.item.incompatibles != null && e.item.incompatibles.length > 0
                        && e.item.incompatibles
                            .some((j) => props.availableDMInterface
                                .some((p) => p.item.code === j && p.checked));
                    return e;
                }
            });
            props.setAvailableDMInterface(temp);
        };
    }

    const columns = [
        {key: "checkbox", className: "col-2", render: "Select compatible interfaces"},
        {key: "name", className: "col-4", render: "Name"},
        {key: "interface_type_name", className: "col-3", render: "Interface Type Name"},
        {key: "support_bus", className: "col-3", render: "Support Bus"},
    ];

    return (
        <>
            <Form>
                <Row className={"justify-content-center"}>
                    <Col style={{textAlign: "center"}}>
                        <H5> {props.currentSettingTemplate.guid == null ? "Define " : "Update "}
                            the interfaces for the setting template telemetry.</H5>
                    </Col>
                </Row>
                <NameInput
                    settingTemplateCreation={props.settingTemplateCreation}
                    setSettingTemplateCreation={props.setSettingTemplateCreation}
                    currentSettingTemplate={props.currentSettingTemplate}
                    setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                />
                <Row className={"mx-2 pb-3"}>
                    <Col style={{maxWidth: "10%"}}>
                        <SelectInputTitle
                            label={"Embedded"}
                            tooltipText={"If true only embedded interface will be selected"}
                        />
                        <Row>
                            <Col>
                                <Toggle
                                    name={"isEmbedded"}
                                    isChecked={isEmbedded}
                                    onChange={handleEmbeddedOnChange()}
                                />
                            </Col>

                        </Row>
                    </Col>
                    <DefaultInput
                        hasDefault={props.hasDefault}
                        settingTemplateCreation={props.settingTemplateCreation}
                        setSettingTemplateCreation={props.setSettingTemplateCreation}
                        currentSettingTemplate={props.currentSettingTemplate}
                        setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                        selectedDeviceModel={props.selectedDeviceModel}
                        setHasDefault={props.setHasDefault}
                    />
                </Row>

                <Row className={"mx-2 pb-3"}>
                    <Col>
                        <Row>
                            <Col>
                                <p>Interfaces Available for: <b>{props.selectedDeviceModel.item.name}</b></p>
                            </Col>
                        </Row>
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                            <tr>
                                {columns.map((col) => (<th key={col.key} className={col.className}>{col.render}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {(props.availableDMInterface != null && props.availableDMInterface.length > 0) ?
                                props.availableDMInterface.map(
                                    (value, index) => (
                                        <tr key={value.item.guid + index}>
                                            <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                                <Checkbox
                                                    key={value.item.guid + index + value.checked}
                                                    className="my-auto"
                                                    style={{padding: "0", textAlign: "center"}}
                                                    checked={value.checked}
                                                    disabled={value.disabled}
                                                    onChange={onCheckboxSelected(value, index)}
                                                />
                                            </td>
                                            <td style={{verticalAlign: "middle"}}>
                                                {value.item.name}
                                            </td>
                                            <td style={{verticalAlign: "middle"}}>
                                                {value.item.interfaceTypeName}
                                            </td>
                                            <td style={{verticalAlign: "middle"}}>
                                                {value.item.supportBus ?
                                                    <span style={{color: "green"}}>True</span> :
                                                    <span style={{color: "red"}}> False</span>}
                                            </td>
                                        </tr>
                                    )) : <></>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {/* Bottom nav buttons */}
                <Row className="w-100 d-flex flex-column">
                    <div className="text-right pt-4">
                        <Button onClick={onPrevious()}>Previous</Button>
                        {" "}
                        <Button disabled={mandatoryFieldsCheck()} variant="strong" onClick={onSubmit()}>
                            {props.currentSettingTemplate.guid == null ? "Next" : "Update"}
                        </Button>
                    </div>
                </Row>
            </Form>
        </>
    );
};

export default InterfaceAvailableSection;
