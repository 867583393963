import {Company} from "../../../../models/company/companyModel";
import {ComponentBrand} from "../../../../models/component/componentBrand";
import {ComponentModel} from "../../../../models/component/componentModel";
import {PickGuid} from "../../../../models/types";
import {AbstractCompanyComponentModel, VirtualCompanyComponentModel} from "./types";

export function isVirtualCompanyComponentModel(x: AbstractCompanyComponentModel): x is VirtualCompanyComponentModel {
    return "__virtual" in x;
}

export function newVirtualCompanyComponentModel(
    company: Company,
    componentModel: Pick<ComponentModel, "name">,
    componentBrand: PickGuid<ComponentBrand>,
): VirtualCompanyComponentModel {
    return {
        __virtual: "add",
        clientName: componentModel.name,
        company,
        componentBrand,
    };
}
