import * as React from "react";

import {SelectInput} from "@danfoss/etui-core";
import {Col, Form, Row} from "react-bootstrap";
import {InterfaceSettingEnumsJson} from "../../../../models/configurability/configurabilityEnumsModel";
import {
    OutputSetting,
    SettingTemplate,
    TemplateConfigurationTelemetry,
} from "../../../../models/configurability/template/settingTemplate";
import {Suggestion} from "../../../../models/Suggestion";

interface OutputSettingProps {
    currentInterfaceStep: number;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    suggestions: InterfaceSettingEnumsJson;
}

const OutputSettingInput: React.FC<OutputSettingProps> = (props) => {
    const [mode, setMode] = React.useState<Suggestion<string>>(undefined);
    const [modeSuggestion, setModeSuggestion] = React.useState<Array<Suggestion<string>>>([]);

    React.useEffect(() => {
        const temp = {...props.currentSettingTemplate};
        let outputSetting = ((temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting as OutputSetting);
        if (outputSetting == null) {
            outputSetting = {type: "output", mode: null};
            setMode(null);
        } else {
            setMode({
                item: outputSetting.mode,
                value: outputSetting.mode,
                label: outputSetting.mode,
            });
        }
        (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting = outputSetting;
        props.setCurrentSettingTemplate(temp);
    }, [props.currentInterfaceStep]);

    React.useEffect(() => {
        if (props.suggestions != null) {
            setModeSuggestion(props.suggestions.outputMode.map((item) => ({item, label: item, value: item})));
        }
    }, [props.suggestions]);

    function onChangeMode(value: Suggestion<string>) {
        setMode(value);
        const temp = {...props.currentSettingTemplate};
        let outputSetting = (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting as OutputSetting;
        if (outputSetting == null) {
            outputSetting = {type: "output", mode: null};
        }
        outputSetting.mode = value != null ? value.item : null;
        (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting = outputSetting;
        props.setCurrentSettingTemplate(temp);
    }

    return (
        <Row>
            <Col style={{maxWidth: "30%"}}>
                <Form.Label>Select pull value</Form.Label>
                <SelectInput
                    clearable={true}
                    onChange={onChangeMode}
                    name="Output prop"
                    options={modeSuggestion}
                    searchable={true}
                    value={mode}
                    disabled={props.suggestions == null}
                />
            </Col>
        </Row>
    );
};

export default OutputSettingInput;
