import {Device, DeviceInstallation} from "../../models/device/device";
import {DeviceStorage} from "../../models/device/storage";
import {FailureType, IdentifierType, JSONResourceNotFound} from "../../models/utils/jsonFailure";
import {JSONResult} from "../../models/utils/jsonResult";
import {SearchResultError} from "./types";

export function getCurrentInstallationOfDevice(device: Device): undefined | DeviceInstallation {
    const installations: DeviceInstallation[] = device.deviceInstallations;

    if (!installations || installations.length === 0) {
        return undefined;
    }
    const installation: DeviceInstallation = installations
        .sort((a, b) => {
            return -a.installationDate.localeCompare(b.installationDate);
        })[0];

    if (installation.removalDate === undefined) {
        return undefined;
    }

    return installation;
}

export function isDeviceValidForStorage(device: Device): boolean {
    return !getCurrentInstallationOfDevice(device);
}

export function voidFunction() {
    return undefined;
}

export function getCodeFromSearchResult(
    searchResult: JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>,
): string | undefined {
    if (searchResult.successful === true) {
        return searchResult.result.device.code;
    } else if (searchResult.error.type === FailureType.RESOURCE_NOT_FOUND
        && searchResult.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP) {
        return searchResult.error.identifierValue.core.code;
    } else if (searchResult.error.type === FailureType.RESOURCE_NOT_FOUND
        && searchResult.error.identifierType === IdentifierType.CODE) {
        return searchResult.error.identifierValue;
    }
    return undefined;
}
