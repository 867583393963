import * as React from "react";
import {Company} from "../../../models/company/companyModel";
import {TableView} from "./TableView";
import {usePageNavigation} from "./hooks";

import "./styles.scss";
import {SearchParamsPagination} from "./SearchParamsPagination";


export function CompaniesPageView() {

    const {data, state} = usePageNavigation();

    return (
        <main className={"companies"}>
            <header>
                <hgroup>
                   <h2>{data?.total ?? 0}</h2>
                   <p>{data?.total === 1 ? "Company" : "Companies"}</p>
               </hgroup>
            </header>
            <section>
                <TableView data={data} state={state}/>
            </section>
            <footer>
                {state === "fulfilled" && <SearchParamsPagination totalNumberOfRows={data?.total ?? 0} width={9}/>}
            </footer>
        </main>
   );
}


