import * as React from "react";
import {OverlayParams, UseOverlayReturnType} from "./types";
import {getRelativePositionObject} from "./utils";

export function useOverlay<T extends HTMLElement>(params?: OverlayParams): UseOverlayReturnType<T> {
    const overlayElementRef = React.useRef<T>();
    React.useImperativeHandle(params?.target, () => overlayElementRef.current, [params?.target]);

    function updateOverlayPosition() {
        overlayElementRef.current.style.position = params?.stylePosition ?? "fixed";
        if (Number(window.getComputedStyle(overlayElementRef.current).zIndex) < Number(params.styleZIndex)) {
            overlayElementRef.current.style.zIndex = `${params?.styleZIndex ?? "var(--overlay-z-index)"}`;
        }

        const overlayAnchor = params?.anchor?.current ?? overlayElementRef.current.parentElement;

        const anchorDomRect = overlayAnchor.getBoundingClientRect();
        const overlayDomRect = overlayElementRef.current.getBoundingClientRect();

        const { top: dAnchorTop = 0, right: dAnchorRight, bottom: dAnchorBottom, left: dAnchorLeft = 0}
            = getRelativePositionObject(params?.anchorPoint, overlayAnchor, overlayElementRef.current);

        const { top: dOverlayTop = 0, right: dOverlayRight, left: dOverlayLeft = 0, bottom: dOverlayBottom }
            = getRelativePositionObject(params?.overlayOrigin, overlayAnchor, overlayElementRef.current);

        const overlayVerticalOffset = typeof dOverlayBottom === "number"
            ? (dOverlayBottom - 1) * overlayDomRect.height
            : -dOverlayTop * overlayDomRect.height;

        const overlayHorizontalOffset = typeof dOverlayRight === "number"
            ? (dOverlayRight - 1) * overlayDomRect.width
            : -dOverlayLeft * overlayDomRect.width;

        overlayElementRef.current.style.top = typeof dAnchorBottom === "number"
            ? `${anchorDomRect.bottom - dAnchorBottom * anchorDomRect.height + overlayVerticalOffset}px`
            : `${anchorDomRect.top + dAnchorTop * anchorDomRect.height + overlayVerticalOffset}px`;

        overlayElementRef.current.style.left = typeof dAnchorRight === "number"
            ? `${anchorDomRect.right - dAnchorRight * anchorDomRect.width + overlayHorizontalOffset}px`
            : `${anchorDomRect.left + dAnchorLeft * anchorDomRect.width + overlayHorizontalOffset}px`;
    }

    React.useLayoutEffect(() => {
        updateOverlayPosition();
    }, []);

    return { overlayRef: overlayElementRef, updateOverlayPosition };
}

export * from "./utils";
