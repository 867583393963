import * as React from "react";



export type ETUIValueToggleStateProps = {
    isChecked: boolean,
    onChange(x: unknown, isChecked: boolean): void,
};

type UseETUIValueToggleType<T> = {
    value: T,
    etuiToggleProps: ETUIValueToggleStateProps,
};

type UseETUIValueToggleOptions<T> = {
    initialState?: boolean,
    valueIfUnchecked?: T,
};

export function useETUIValueToggle<T>(value: T, options?: UseETUIValueToggleOptions<T>): UseETUIValueToggleType<T> {

    const [checked, setChecked] = React.useState(options?.initialState ?? false);
    const [currentValue, setCurrentValue] = React.useState<T>(!checked ? (options?.valueIfUnchecked ?? null) : value);

    return {
        value: currentValue,
        etuiToggleProps: {
            isChecked: checked,
            onChange(_, isChecked: boolean) {
                setChecked(isChecked);
                setCurrentValue(isChecked ? value : (options?.valueIfUnchecked ?? null));
            }
        },
    };
}