import * as React from "react";

import "./style.scss";
import {isChip} from "../Chip";

type ChipGroupProps = React.PropsWithChildren<React.HTMLAttributes<HTMLUListElement>> & {
    fallback?: React.ReactNode;
};

export function ChipGroup({ children, fallback, ...ulProps}: ChipGroupProps) {

    const onlyChipChildren = React.Children.map( children, (node) => {
        if(!isChip(node)) {
            return null;
        }
        return (
            <li> {node} </li>
        );
    });

    if (React.Children.count(onlyChipChildren) === 0 ) {
        return (
            <> {fallback} </>
        );
    }

    return (
        <ul {...ulProps} className={`chip-group ${ulProps.className?? ""}`}>
            {onlyChipChildren}
        </ul>
    );
}