import {SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {AcquisitionSetting} from "../../../models/configurability/rawData/acquisitionSettingModel";
import {ProcessingParameter} from "../../../models/configurability/rawData/processingParameter";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {Suggestion} from "../../../models/Suggestion";
import {Sections} from "../CreateRawData";

interface ReferenceProcessedDataValueProps {
    processingParameters: ProcessingParameter[];
    setProcessingParameters: (state: ProcessingParameter[]) => void;
    index: number;
    setDisabledParam: (status: boolean) => void;
    currentRD: RawData | RawDataAlarmType | RawDataDiagnosticType;
    section: Sections;
}

// tslint:disable:max-line-length
const ReferenceDataValue: React.FC<ReferenceProcessedDataValueProps> = (props) => {

    const [acquisitionValue, setAcquisitionValue] = React.useState<Suggestion<AcquisitionSetting>>(undefined);
    const [acquisitionSuggestions, setAcquisitionSuggestions] = React.useState<Array<Suggestion<AcquisitionSetting>>>([]);
    const services: Services = new Services();

    React.useEffect(() => {
        if (props.processingParameters != null) {
            const temp: any = props.processingParameters[props.index];
            if (temp != null) {
                const suggestions = createAcquisitionSuggestions();
                setAcquisitionSuggestions(suggestions);
                const value = temp.value !== "" ? suggestions.find((e) => e.value === temp.value) : undefined;
                onSelectValue(value);
            }
        }
    }, []);

    function createAcquisitionSuggestions() {
        const rawData = services.castRawData(props.section, props.currentRD);
        const suggestions: Array<Suggestion<AcquisitionSetting>> = rawData.params.acquisitions.map((e, index) => ({
            item: e,
            label: index + ", " + e.conversionFunction + ", " + e.sampling,
            value: index.toString(),
        }));
        return suggestions;
    }

    function onSelectValue(suggestion: Suggestion<AcquisitionSetting>) {
        if (props.processingParameters) {
            setAcquisitionValue(suggestion);
            const newValues = props.processingParameters;
            newValues[props.index].value = suggestion ? suggestion.value : undefined;
            props.setProcessingParameters(newValues);
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        }
    }

    return (
        <Row className={"mt-2 d-flex flex-column"}>
            <Form.Label>Processing Param Value {props.index}*</Form.Label>
            <SelectInput
                onChange={onSelectValue}
                name="Type"
                label={"Select Acquisition"}
                options={acquisitionSuggestions}
                searchable={true}
                clearable={true}
                disabled={props.section === Sections.rawDataDiagnosticType}
                value={acquisitionValue}
            />
        </Row>
    );
};
export default ReferenceDataValue;
