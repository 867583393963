import * as React from "react";

type IconName =
    "edit"
    | "filter_list"
    | "select_window"
    | "undo"
    | "unfold_more" | "unfold_less"
    | "open_in_full"
    | "pan_zoom"
    | "expand_content"
    | "open_in_new"
    | "add"
    | "add_circle"
    | "delete"
    | "backspace"
    | "first_page"
    | "last_page"
    | "chevron_left"
    | "chevron_right"
    | "close";

type IconProps = React.HTMLProps<HTMLSpanElement> &  {
    name: IconName;
};

export function Icon({name, ...props}: IconProps) {
    return (
        <span {...props} className={`icon material-symbols-outlined ${props.className ?? ""}`}>{name}</span>
    );
}
