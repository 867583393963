import * as React from "react";
import {StatelessStream} from "../hooks/observables";


export type DataState<T, E = unknown> = {
   state: "loading" | "successful" | "error",
   data: Awaited<T>,
   error: E,
};

type DataStoreContextType = {
   sharedDataStore: Map<string, DataState<unknown>>;
   stream: StatelessStream<[string, DataState<unknown>]>
};

export const DataStoreContext = React.createContext<DataStoreContextType>(null);