import * as React from "react";
import {
    addComponentModelToCompanies,
    addComponentTypeToCompanies, createNewComponentBrand,
    removeComponentModelFromCompanies,
    updateCompanyComponentModelValues,
} from "../../../api";
import {uploadImage} from "../../../../../controllers/api/medias";
import {useStream} from "../../../../../hooks/observables";
import {useFunctionQueue} from "../../../../../hooks/useFunctionQueue";
import {Company, CompanyComponentModel} from "../../../../../models/company/companyModel";
import {ComponentBrand} from "../../../../../models/component/componentBrand";
import {constant} from "../../../../../utils/functions";
import {isEmptyObject} from "../../../../../utils/objects";
import {AbstractCompanyComponentModel, isVirtualCompanyComponentModel} from "../../CompaniesV1";
import {
    VirtualComponentBrand,
} from "../../CompaniesV1/ModalView/AbstractComponentBrandsContext/types";
import {VirtualMedia} from "../../CompaniesV1/ModalView/AbstractMediasContext";
import {useComponentModel} from "../ComponentModelProvider";
import {ComponentModelCompaniesChangesManagerContext} from "./context";
import {Change, ComponentModelChangeEvent} from "./types";
import {changesInterceptors, changeWithKey, generateKey} from "./utils";

export function ComponentModelCompaniesChangesManagerProvider({children}: React.PropsWithChildren<unknown>) {

    const componentModel = useComponentModel();
    const changesStream = useStream<ComponentModelChangeEvent>();

    const fq = useFunctionQueue<Change>({interceptors: changesInterceptors(changesStream)});

    function addComponentTypeToCompany(company: Company) {
        fq.enqueue({
            type: "add-component-type-to-company",
            priority: 1,
            key: generateKey(company),
            fun: addComponentTypeToCompanies,
            params: [{guid: componentModel.typeGuid}, [company]],
        });
    }

    function addToCompany(company: Company, componentBrand: ComponentBrand): void {
        const changesCount = fq.remove(changeWithKey(
            company,
            componentBrand,
            "remove-component-model-from-company",
        ));

        if (changesCount === 0) {
            fq.enqueue({
                type: "add-component-model-to-company",
                priority: 2,
                key: generateKey(company, componentBrand),
                fun: addComponentModelToCompanies,
                params: [componentModel, componentBrand, [company]],
            });
        }
    }

    function removeFromCompany({company, componentBrand}: AbstractCompanyComponentModel): void {
        // Todo should remove also "add component type to company"
        const wasBeingAdded = fq.remove(changeWithKey(
            company,
            componentBrand,
            "add-component-model-to-company", "update-new-company-component-model",
        )) > 0;

        fq.remove(changeWithKey(
            company,
            componentBrand,
            "update-existing-company-component-model",
        ));

        const alreadyBeingRemoved =
            fq.find(changeWithKey(company, componentBrand, "remove-component-model-from-company")).length > 0;

        if (!alreadyBeingRemoved && !wasBeingAdded) {
            fq.enqueue({
                type: "remove-component-model-from-company",
                priority: 2,
                key: generateKey(company, componentBrand),
                fun: removeComponentModelFromCompanies,
                params: [componentModel, componentBrand, [company]],
            });
        } else if (wasBeingAdded) {
            const thereAreAdditionToCompany =
                fq.find(changeWithKey(company, undefined, "add-component-model-to-company")).length > 0;
            if (!thereAreAdditionToCompany) {
                fq.remove(changeWithKey(company, undefined, "add-component-type-to-company"));
            }
        }
    }

    function updateCompanyComponentModelParams(
        companyComponentModel: AbstractCompanyComponentModel,
        updatedCompanyComponentModelParams: Partial<CompanyComponentModel>,
    ) {
        const changeType = isVirtualCompanyComponentModel(companyComponentModel) &&
            companyComponentModel.__virtual === "add" ? "update-new-company-component-model"
            : "update-existing-company-component-model";

        if (isEmptyObject(updatedCompanyComponentModelParams)) {
            fq.remove(changeWithKey(
                companyComponentModel.company,
                companyComponentModel.componentBrand,
                changeType,
            ));
            return;
        }

        if ("componentBrand" in updatedCompanyComponentModelParams &&
            changeType === "update-new-company-component-model"
        ) {
            fq.update(
                (change) =>
                    change.type === "add-component-model-to-company" &&
                    change.key !== generateKey(companyComponentModel.company, companyComponentModel.componentBrand) &&
                    change.params[1]?.guid !== updatedCompanyComponentModelParams.componentBrand?.guid,
                ({key, params}) => ({
                        type: "add-component-model-to-company",
                        priority: 2,
                        key,
                        fun: addComponentModelToCompanies,
                        params: [
                            componentModel,
                            updatedCompanyComponentModelParams.componentBrand,
                            params[2],
                        ],
                }),
            );
        }

        const updateChange: Change = isVirtualCompanyComponentModel(companyComponentModel)
            && companyComponentModel.__virtual === "add"
            ? {
                type: "update-new-company-component-model",
                priority: 3,
                key: generateKey(companyComponentModel.company, companyComponentModel.componentBrand),
                fun: null,
                params: [companyComponentModel, updatedCompanyComponentModelParams],
            } : {
                type: "update-existing-company-component-model",
                priority: 3,
                key: generateKey(companyComponentModel.company, companyComponentModel.componentBrand),
                fun: updateCompanyComponentModelValues,
                params: [companyComponentModel, updatedCompanyComponentModelParams],
            };

        const wasAlreadyBeingEdited = fq.update(
            changeWithKey(
                companyComponentModel.company,
                companyComponentModel.componentBrand,
                changeType,
            ),
            () => updateChange,
        ) > 0;

        if (!wasAlreadyBeingEdited) {
            fq.enqueue(updateChange);
        }
    }

    function addComponentBrand({description, timestamp}: VirtualComponentBrand) {
        const alreadyBeingAdded = fq.find(description).length > 0;
        if (!alreadyBeingAdded) {
            fq.enqueue({
                key: timestamp,
                priority: 1,
                type: "create-new-brand",
                fun: createNewComponentBrand,
                params: [description],
            });
        }
    }

    function addMedia({timestamp, file}: VirtualMedia) {
        fq.enqueue({
            key: timestamp,
            priority: 1,
            type: "upload-media",
            fun: uploadImage,
            params: [[file]],
        });
    }

    async function applyChanges() {
        const results = await fq.callAll();
        console.log("Results", results);
        fq.clearResults();
    }

    function discardChanges() {
        fq.remove(constant(true));
    }

    return (
        <ComponentModelCompaniesChangesManagerContext.Provider
            value={{
                addComponentTypeToCompany,
                addToCompany,
                removeFromCompany,
                updateCompanyComponentModelParams,
                addComponentBrand,
                addMedia,
                discardChanges,
                applyChanges,
                observeChanges: changesStream.observe,
            }}
        >
            {children}
        </ComponentModelCompaniesChangesManagerContext.Provider>
    );
}
