import * as React from "react";
import {useData} from "../../../../DataLoader";
import {range} from "../../../../utils/numbers";
import {Skeleton} from "../../../common/Skeleton";
import {Table, Td, Th, Tr} from "../../../common/DataTable";
import {LocationCellView} from "../../../Locations";
import {Chip, ChipGroup} from "../../../common/chips";
import {UsePromiseResultState} from "../../../../hooks/usePromise";
import {identity} from "../../../../utils/functions";
import {JSONList} from "../../../../models/utils/jsonList";
import {getAllCurrencies} from "../../../Currencies";
import {consumeJSONList} from "../../../../utils/json";
import {toMap} from "../../../../utils/maps";
import {Currency} from "../../../../models/currency/currencyModel";
import {getCountries} from "../../../../controllers/api/support";
import {Country} from "../../../../models/country/countryModel";
import {CompanyWithExtras} from "../types";
import {CreatedAtColumnFilter,CompanyNameColumnFilterProps} from "./filters";

import "./filters/styles.scss";

type TableViewProps = {
    data: JSONList<CompanyWithExtras>;
    state: UsePromiseResultState;
};


const CURRENCY_DATA_LOADER_DEFINITION = Object.freeze({
    loader: getAllCurrencies,
    map: consumeJSONList(toMap<Currency, string>((c) => c.guid, identity)),
    keys: ["currencies"],
});


const COUNTRIES_DATA_LOADER_DEFINITION = Object.freeze({
    loader: getCountries,
    map: consumeJSONList(toMap<Country, string>((c) => c.alpha3Code, identity)),
    keys: ["currencies"],
});

export function TableView({data, state}: TableViewProps) {
    const currenciesLoader = useData(CURRENCY_DATA_LOADER_DEFINITION);
    const countriesLoader = useData(COUNTRIES_DATA_LOADER_DEFINITION);


    const tBody = state === "pending" ? range(20).map((i) => <Skeleton key={i} type={"table-row"} size={12}/>)
        : state === "fulfilled" ? data.list.map((c) => {
            const currency = currenciesLoader.state === "successful" ? currenciesLoader.data.get(c.currencyGuid) : null;
            const country = countriesLoader.state === "successful" ? countriesLoader.data.get(c.country) : null;
            return (
                <Tr key={c.guid}>
                    <Td ordinal={"first"} className={"company-name"}>{c.name}</Td>
                    <Td>{c.type?.name}</Td>
                    <Td>{c.parentCompany?.name}</Td>
                    <Td>{c.companyCode}</Td>
                    <Td>{country?.name}</Td>
                    <Td>{currency?.name} [{currency?.iso3}]</Td>
                    <Td>{c.vatCode}</Td>
                    <LocationCellView
                        company={c}
                        className={"company-default-warehouse"}
                        location={c.defaultWarehouse}
                        fallback={<span className={"empty-cell-fallback"}>No warehouse</span>}
                    />
                    <Td>
                        <ChipGroup
                            className={"company-1-n-relations"}
                            fallback={<span className={"empty-cell-fallback"}>No component types</span>}
                        >
                            {c.componentTypes
                                .slice(0, c.componentTypesOptimalEnd)
                                .map(({guid, name}) => <Chip key={guid}>{name}</Chip>)}
                            {c.componentTypes?.length > c.componentTypesOptimalEnd && (
                                <Chip className={"more-indicator"}>
                                    +{c.componentTypes?.length - c.componentTypesOptimalEnd} more
                                </Chip>
                            )}
                        </ChipGroup>
                    </Td>
                    <Td>
                        <ChipGroup
                            className={"company-1-n-relations"}
                            fallback={<span className={"empty-cell-fallback"}>No component models</span>}
                        >
                            {c.componentModels?.slice(0, c.componentModelsOptimalEnd)?.map(({guid, name}) => {
                                return <Chip key={guid}>{name}</Chip>
                            })}
                            {c.componentModels?.length > c.componentModelsOptimalEnd && (
                                <Chip className={"more-indicator"}>
                                    +{c.componentModels?.length - c.componentModelsOptimalEnd} more
                                </Chip>
                            )}
                        </ChipGroup>
                    </Td>
                    <Td>
                        <ChipGroup
                            className={"company-1-n-relations"}
                            fallback={<span className={"empty-cell-fallback"}>No alarm types</span>}
                        >
                            {c.alarmTypes
                                .slice(0, c.alarmTypesOptimalEnd)
                                .map((at) => <Chip key={at.guid}>{at.name}</Chip>)}
                            {c.alarmTypes?.length > c.alarmTypesOptimalEnd && (
                                <Chip className={"more-indicator"}>
                                    +{c.alarmTypes?.length - c.alarmTypesOptimalEnd} more
                                </Chip>
                            )}
                        </ChipGroup>
                    </Td>
                    <Td ordinal={"last"}>{c.createdAt}</Td>
                </Tr>
            );
        }) : null;


    return (
        <Table>
            <thead>
            <tr>
                <Th ordinal={"first"}>Company <CompanyNameColumnFilterProps/></Th>
                <Th>Type</Th>
                <Th>Parent</Th>
                <Th>Code</Th>
                <Th>Country</Th>
                <Th>Currency</Th>
                <Th>VAT</Th>
                <Th>Default warehouse</Th>
                <Th>Component types</Th>
                <Th>Component models</Th>
                <Th>Alarm types</Th>
                <Th ordinal={"last"}>Created<CreatedAtColumnFilter/></Th>
            </tr>
            </thead>
            <tbody>{tBody}</tbody>
        </Table>
    );
}

