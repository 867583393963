import {ComponentCategoryList} from "../../models/component/componentCategory";
import {ComponentTypeList} from "../../models/component/componentType";

class ComponentCategoryController {
    // function used to get a list of all component categories
    public async getComponentCategories(): Promise<ComponentCategoryList> {
        const response = await fetch("/componentCategories");
        return response.json();
    }

    // function used to get a list of all types filtered by category guid
    public async getCategoryTypes(categoryGuid: string): Promise<ComponentTypeList> {
        const response = await fetch("/componentCategories/" + categoryGuid + "/componentTypes");
        return response.json();
    }

    public async getComponentModelsByCategoryGuid(categoryGuid: string): Promise<any> {
        const response = await fetch("/componentCategories/" + categoryGuid + "/models");
        return response.json();
    }
}

export default ComponentCategoryController;
