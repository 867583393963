import * as React from "react";
import {useOverlay} from "../../../hooks/useOverlay";
import {OverlayParams} from "../../../hooks/useOverlay/types";

import "./styles.scss";

export type OverlayProps = React.HTMLProps<HTMLElement> & {
    asTag?: keyof JSX.IntrinsicElements;
    params?: OverlayParams; // Todo should extend this
    positioned?: Pick<OverlayParams, "anchorPoint" | "overlayOrigin">;
};

export function Overlay({children, asTag, params, positioned , ...props}: OverlayProps) {
    const { overlayRef } = useOverlay({...params, ...positioned, target: params?.target});
    return React.createElement(
        asTag ?? "div",
        {
            ...props,
            ref: overlayRef,
            className: `overlay ${props.className ?? ""}`,
        },
        children,
    );
}
