import * as React from "react";
import {HTMLElementValue} from "../presentation/common/types";

type FilterPredicate<T> = (point: T, textValue: HTMLElementValue) => boolean;

export function useInputValueAsFilter<T>(data: T[], filterPredicate: FilterPredicate<T>) {
    const [filteredData, setFilteredData] = React.useState(data);

    React.useEffect(() => {
        setFilteredData(data);
    }, [data]);

    return {
        filteredData,
        inputProps: {
            onChange(e: React.ChangeEvent<HTMLInputElement>) {
                const newFilteredData = data.filter((p) => filterPredicate(p, e.target.value));
                setFilteredData(newFilteredData);
            },
        },
        reset() {
            setFilteredData(data);
        },
    };
}
