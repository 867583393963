import * as React from "react";
import {useInputValueAsFilter} from "../../../hooks/useInputValueAsFilter";
import {Company} from "../../../models/company/companyModel";
import {ComponentCategory} from "../../../models/component/componentCategory";
import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentType} from "../../../models/component/componentType";
import {byEntityName} from "../../../utils/arrays";
import {CompanyItemView} from "../../common/CompanyItemView";
import {Table, Td, Th, Tr} from "../../common/DataTable";
import {FilterView} from "../../common/FilterView";
import {Option} from "../../common/form/Option";
import {
    ComponentModelAlarmsTypesCell,
    ComponentModelCompaniesCell,
    ComponentModelCreationDateDataCell,
    ComponentModelDiagnosticsTypesCell, ComponentModelManufacturer,
    ComponentModelName,
    ComponentModelPropertiesCell,
} from "./ComponentModelRowView";
import {CompaniesComponentModelsMediasProvider} from "./contexts/CompaniesComponentModelsMediasProvider";
import {ComponentBrandsProvider} from "./contexts/ComponentBrandsProvider";
import {ComponentModelProvider} from "./contexts/ComponentModelProvider";
import {useComponentModelChangesManager, useComponentModelTable} from "./hooks";
import {ColumnsData, ComponentModelRowProps, FilterColumnProps, FilterState} from "./types";
import {Skeleton} from "../../common/Skeleton";
import {constant} from "../../../utils/functions";

import "./styles.scss";

interface TableColumnFilterProps<T> {
    options: T[];
    value: FilterState<string>;
    onChange(value: FilterState<string>);
}

function ModelNameColumnHead({options, value, onChange}: TableColumnFilterProps<string>) {
    const { filteredData, reset, inputProps } = useInputValueAsFilter(options, (name, inputValue) => {
        return typeof inputValue === "string" && name.toLowerCase().includes(inputValue.toLowerCase());
    });

    React.useEffect(() => {
        reset();
    }, [value]);

    return (
        <Th ordinal={"first"} className={"component-model-name-column-head"}>
            Model
            <FilterView value={value} onChange={onChange}>
                <section className={"options-filter-section"}>
                    <input type={"text"} {...inputProps} className={"input-field"} placeholder={"Search"}/>
                </section>
                {filteredData.sort().map((name) => {
                    return (
                        <Option key={name} value={name}>
                            {name}
                        </Option>
                    );
                })}
            </FilterView>
        </Th>
    );
}

function ComponentCategoryColumnHead({options, value, onChange}: TableColumnFilterProps<ComponentCategory>) {
    const { filteredData, reset, inputProps } = useInputValueAsFilter(options, (o, v) => {
        return typeof v === "string" && o.name.toLowerCase().startsWith(v.toLowerCase());
    });

    React.useEffect(() => {
        reset();
    }, [value]);

    return (
        <Th>
            Category
            <FilterView value={value} onChange={onChange}>
                <section className={"options-filter-section"}>
                    <input type={"text"} {...inputProps} className={"input-field"} placeholder={"Search"}/>
                </section>
                {filteredData.sort(byEntityName).map((cc) => {
                    return (
                        <Option key={cc.guid} value={cc.guid}>
                            {cc.name}
                        </Option>
                    );
                })}
            </FilterView>
        </Th>
    );
}

function ComponentModelTypeColumnHead({options, value, onChange}: TableColumnFilterProps<ComponentType>) {
    const { filteredData, reset, inputProps } = useInputValueAsFilter(options, (o, inputValue) => {
        return typeof inputValue === "string" && o.name.toLowerCase().startsWith(inputValue.toLowerCase());
    });

    React.useEffect(() => {
        reset();
    }, [value]);

    return (
        <Th>
            Type
            <FilterView value={value} onChange={onChange}>
                <section className={"options-filter-section"}>
                    <input type={"text"} {...inputProps}  className={"input-field"} placeholder={"Search"} />
                </section>
                {filteredData.sort(byEntityName).map((ct) => {
                    return (
                        <Option key={ct.guid} value={ct.guid} keywords={[ct.name]}>
                            {ct.name}
                        </Option>
                    );
                })}
            </FilterView>
        </Th>
    );
}

function ComponentModelCompaniesColumnHead({options, value, onChange}: TableColumnFilterProps<Company>) {
    const { filteredData, reset, inputProps } = useInputValueAsFilter(options, (o, inputValue) => {
        return typeof inputValue === "string" && o.name.toLowerCase().startsWith(inputValue.toLowerCase());
    });

    React.useEffect(() => {
        reset();
    }, [value]);

    return (
        <Th>
            Companies
            <FilterView value={value} onChange={onChange}>
                <section className={"options-filter-section"}>
                    <input type={"text"} {...inputProps}  className={"input-field"} placeholder={"Search"} />
                </section>
                {filteredData.map((company) => {
                    return (
                        <Option key={company.guid} value={company.guid} keywords={[company.name]}>
                            <CompanyItemView company={company}/>
                        </Option>
                    );
                })}
            </FilterView>
        </Th>
    );
}

interface ComponentModelsDataTableViewProps {
    componentModels: ComponentModel[];
    loading: boolean;
    filterColumnProps<K extends keyof ColumnsData>(columnName: K): FilterColumnProps<K>;
    rowRegister(componentModel: ComponentModel): ComponentModelRowProps;
}

export function ComponentModelsDataTableView(
    {
        componentModels,
        filterColumnProps,
        loading,
        rowRegister,
    }: ComponentModelsDataTableViewProps,
) {
    return (
        <Table className={"component-model-table"}>
            <thead>
            <tr>
                <Th/>
                <ModelNameColumnHead {...filterColumnProps("names")}/>
                <ComponentCategoryColumnHead {...filterColumnProps("componentCategories")}/>
                <ComponentModelTypeColumnHead {...filterColumnProps("componentTypes")}/>
                <Th> Manufacturer </Th>
                <Th> Components </Th>
                <ComponentModelCompaniesColumnHead {...filterColumnProps("companies")}/>
                <Th> Properties </Th>
                <Th> Diagnostics type </Th>
                <Th> Alarms type </Th>
                <Th> created </Th>
            </tr>
            </thead>
            <tbody>
                { loading ? Array.from({length: 11}, (_, i) => (
                        <Skeleton key={i} type={"table-row"} animationDelay={i * .2} size={11}/>
                    )) : (
                    <CompaniesComponentModelsMediasProvider>
                        <ComponentBrandsProvider>
                            {componentModels.map((cm) => {
                                if (cm.variant !== "FULL") {
                                    return null;
                                }
                                const {value, change, state, selected, toggleSelection} = rowRegister(cm);
                                return (
                                    <ComponentModelProvider key={cm.guid} componentModel={cm}>
                                        <Tr>
                                            <Td className={"component-model-row-selection"}>
                                                <input type={"checkbox"} checked={selected} onChange={toggleSelection}/>
                                            </Td>
                                            <ComponentModelName
                                                state={state("name")}
                                                value={value("name")}
                                                onSubmit={change("name")}
                                                onReset={constant(change("name"), cm.name)}
                                            />
                                            <Td>{cm.category}</Td>
                                            <Td>{cm.type}</Td>
                                            <ComponentModelManufacturer
                                                state={state("manufacturerNameKey")}
                                                value={value("manufacturerNameKey")}
                                                onSubmit={change("manufacturerNameKey")}
                                                onReset={constant(
                                                    change("manufacturerNameKey"),
                                                    cm.manufacturerNameKey,
                                                )}
                                            />
                                            <Td className={"table-cell-text-align-right"}>{cm.componentCount}</Td>
                                            <ComponentModelCompaniesCell companies={cm.companies}/>
                                            <ComponentModelPropertiesCell properties={cm.properties}/>
                                            <ComponentModelDiagnosticsTypesCell diagnosticsTypes={cm.diagnosticsType}/>
                                            <ComponentModelAlarmsTypesCell alarmsType={cm.alarmsType}/>
                                            <ComponentModelCreationDateDataCell value={new Date(cm.createdAt)}/>
                                        </Tr>
                                    </ComponentModelProvider>
                                );
                            })}
                        </ComponentBrandsProvider>
                    </CompaniesComponentModelsMediasProvider>
                )}
            </tbody>
        </Table>
    );
}

export {useComponentModelTable, useComponentModelChangesManager} from "./hooks";
export {ComponentModelTableFilters} from "./types";
