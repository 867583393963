import {Spinner} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Modal} from "react-bootstrap";

interface LoadingModalProps {
    loading: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = (props) => {
    return (
        <Modal
            show={props.loading}
            centered={true}
            style={{
                // display: "flex !important",
                width: "300px",
                height: "150px",
                // flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Modal.Body className="my-auto mx-auto">
                <Col>
                    <p>Loading...</p>
                    <Spinner/>
                </Col>
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;
