import React = require("react");
import "./ValidationStatusMessage.scss";
import {ValidationErrorMessage, ValidationWarningMessage} from "./ValidationStatusMessageTypes";

export const enum StatusMessageType {
    ERROR,
    WARNING
}

export interface ValidationStatusMessageProps {
    status: StatusMessageType
    message: string
}

export const ValidationStatusMessage: React.FC<ValidationStatusMessageProps> = ({status, message}) => {

    const renderStatusMessage = (): JSX.Element => {
        switch (status) {
            case StatusMessageType.ERROR:
                return <ValidationErrorMessage message={message}/>
            case StatusMessageType.WARNING:
                return <ValidationWarningMessage message={message}/>
        }
    }
    return renderStatusMessage();
}