import {ValidatedDevice} from "../Transfer";

export const enum DeviceIdentifierType {
    DEVICE_CODE = "DEVICE_CODE",
    DEVICE_CUSTOMER_CODE = "DEVICE_CUSTOMER_CODE",
    EQUIPMENT_MANUFACTURER_CODE = "EQUIPMENT_MANUFACTURER_CODE",
    EQUIPMENT_CUSTOMER_CODE = "EQUIPMENT_CUSTOMER_CODE",
}


export function getCode(device, identifierType: DeviceIdentifierType): string {
    switch (identifierType) {
        case DeviceIdentifierType.DEVICE_CODE:
            return device.deviceCode;
        case DeviceIdentifierType.DEVICE_CUSTOMER_CODE:
            return device.customerDeviceCode;
        case DeviceIdentifierType.EQUIPMENT_CUSTOMER_CODE:
            return device.equipmentCode;
        case DeviceIdentifierType.EQUIPMENT_MANUFACTURER_CODE:
            return device.equipmentManufacturerCode;
    }
}

export interface ConflictData {
    deviceCode: string;
    dataOwnerCompany: string;
    validatedDevice: ValidatedDevice;
}

export interface PartialDevice {
    code: string;
    deviceCode: string;
    dataOwnerCompany: string;
    conflicts?: ConflictData[];
}

export function reduceByCode<T extends PartialDevice>(list: T[]): T[] {
    return list.reduce<T[]>((listAccumulation, elem) => {
        const prevElemIndex = listAccumulation.findIndex(({code}) => code === elem.code);
        if (prevElemIndex === -1) {
            return [...listAccumulation, elem];
        }

        const newElement: T = {
            ...elem,
            dataOwnerCompany: null,
            conflicts: listAccumulation[prevElemIndex].conflicts ? [
                ...listAccumulation[prevElemIndex].conflicts,
                {
                    deviceCode: elem.deviceCode,
                    dataOwnerCompany: elem.dataOwnerCompany,
                    validatedDevice: elem,
                },
            ] : [
                {
                    deviceCode: listAccumulation[prevElemIndex].deviceCode,
                    dataOwnerCompany: listAccumulation[prevElemIndex].dataOwnerCompany,
                    validatedDevice: listAccumulation[prevElemIndex],
                },
                {
                    deviceCode: elem.deviceCode,
                    dataOwnerCompany: elem.dataOwnerCompany,
                    validatedDevice: elem,
                },
            ],
        };

        return [
            ...(listAccumulation.slice(0, prevElemIndex)),
            newElement,
            ...(listAccumulation.slice(prevElemIndex + 1)),
        ];
    }, []);
}