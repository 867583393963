import * as React from "react";
import {OptgroupProps} from "./types";

export function Optgroup({ asTag, children, ...props}: OptgroupProps) {
    return React.createElement(
        asTag ?? "div",
        {
            ...props,
            className: `option-group ${props.className ?? ""}`,
        },
        children,
    );
}
