import {Device} from "../../../models/device/device";
import {DeviceStorage} from "../../../models/device/storage";
import {FailureType, IdentifierType, JSONResourceNotFound} from "../../../models/utils/jsonFailure";
import {JSONResult} from "../../../models/utils/jsonResult";
import {DataOwnerInfo, DeviceSearchResultsStats, SearchResultError} from "../types";
import {isDeviceValidForStorage} from "../utils";

export function getDeviceSearchResultDeviceGuid(
    result: JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>,
): string | undefined {
    if (result.successful === true) {
        return result.result.device.guid;
    }
    if ((result.error.type === FailureType.RESOURCE_NOT_FOUND)
        && (result.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP)
        && isDeviceValidForStorage(result.error.identifierValue)) {
        return result.error.identifierValue.core.guid;
    }
    return null;
}

function getDataOwnerInfoFromSearchResult(
    searchResult: JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>,
): DataOwnerInfo | undefined {
    if (searchResult.successful === true) {
        const guid = searchResult.result.device.dataOwnerGuid;
        const name = searchResult.result.device.dataOwnerName;
        return {guid, name};
    } else if (searchResult.error.type === FailureType.RESOURCE_NOT_FOUND
        && searchResult.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP) {
        const guid = searchResult.error.identifierValue.core.dataOwnerGuid;
        const name = searchResult.error.identifierValue.core.dataOwnerName;
        return {guid, name};
    }
    return undefined;
}

export function searchResultToDataOwnerInfoToTuple(
    searchResult: JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>,
): [string, string] | undefined {
    const info = getDataOwnerInfoFromSearchResult(searchResult);
    if (!info) {
        return undefined;
    }
    return [info.guid, info.name];
}

export function deviceSearchResultByCompanyGuid(companyGuid: string) {
    return (result: JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>) => {
        const dataOwnerInfo = getDataOwnerInfoFromSearchResult(result);
        return dataOwnerInfo?.guid === companyGuid;
    };
}

export function createDeviceSearchResultsStats(
    list: Array<JSONResult<unknown, SearchResultError<JSONResourceNotFound<Device | string>>>>,
): DeviceSearchResultsStats {
    return list.reduce((stat, searchResult) => {
        if (searchResult.successful === true
            || (searchResult.error.type === FailureType.RESOURCE_NOT_FOUND
                && searchResult.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP
                && isDeviceValidForStorage(searchResult.error.identifierValue))) {
            return {
                ...stat,
                count: stat.count + 1,
            };
        }
        if (searchResult.error.type === FailureType.RESOURCE_NOT_FOUND
            && searchResult.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP
            && !isDeviceValidForStorage(searchResult.error.identifierValue)) {
            return {
                ...stat,
                alreadyInstalled: stat.alreadyInstalled + 1,
                count: stat.count + 1,
            };
        }
        return {
            ...stat,
            notFound: stat.notFound + 1,
            count: stat.count + 1,
        };
    }, { count: 0, alreadyInstalled: 0, notFound: 0});
}

export function isSearchResultValid(
    result: JSONResult<unknown, SearchResultError<JSONResourceNotFound<Device | string>>>,
) {
    return result.successful === true
        || (
            result.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP
            && isDeviceValidForStorage(result.error.identifierValue)
        );
}

export function isSearchResultNotInstalled(
    result: JSONResult<unknown, SearchResultError<JSONResourceNotFound<Device | string>>>,
) {
    return result.successful === true
        || result.error.identifierType === IdentifierType.CODE
        || (
            result.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP
            && isDeviceValidForStorage(result.error.identifierValue)
        );
}

export function isSearchResultDeviceFound(
    result: JSONResult<unknown, SearchResultError<JSONResourceNotFound<Device | string>>>,
) {
    return result.successful === true || result.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP;
}

export function bubbleInvalid(
    a: JSONResult<unknown, SearchResultError<JSONResourceNotFound<Device | string>>>,
    b: JSONResult<unknown, SearchResultError<JSONResourceNotFound<Device | string>>>,
) {
    return Number(isSearchResultValid(a)) - Number(isSearchResultValid(b));
}

export const HTTP_CLIENT_ERROR_GROUP_START = 400;
export const HTTP_SERVER_ERROR_GROUP_START = 500;
