import * as React from "react";
import {DeviceCore} from "../../../../models/device/device";

interface DeviceCoreInfoViewProps {
    device: DeviceCore;
}

export function DeviceCoreInfoView({device}: DeviceCoreInfoViewProps) {
    return (
        <section className={"device-core-info"}>
            <h5>Info</h5>
            <div className={"device-info-grid"}>
                <span>Code</span>
                <h6 className={"device-info-grid-value-cell"}>{device.code}</h6>
                <span>Telemetry status</span>
                <h6 className={"device-info-grid-value-cell"}>{device.telemetryStatus}</h6>
                <span>Model</span>
                <h6 className={"device-info-grid-value-cell"}>{device.model.name}</h6>
                <span>Firmware version</span>
                <h6 className={"device-info-grid-value-cell"}>{device.firmware.version}</h6>
                <span>Company</span>
                <h6 className={"device-info-grid-value-cell"}>{device.dataOwnerName}</h6>
            </div>
        </section>
    );
}