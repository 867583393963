import * as React from "react";
import {
    useComponentModelChangeManager,
    useStatefulComponentModelChangeObserver
} from "../../../contexts/ComonentModelChangesContext";
import {ComponentModelChangeEvent} from "../../../contexts/ComonentModelChangesContext/types";
import {Button} from "../../../../../common/Button";
import {stopPropagation} from "../../../../../../utils/eventsHandling";

function updateChangesCount(event: ComponentModelChangeEvent, prevCount: number): number {
    switch (event.type) {
        case "result": {
            return 0;
        }
        case "updating": {
            return prevCount;
        }
        case "add":
        case "remove":
        case "edit": {
            return event.pending.reduce((count, change) => {
                return count + (change.type === "update-existing-company-component-model"
                    ? Object.keys(change.params[1]).length
                    : 1
                );
            }, 0);
        }
    }
}

type ChangesIndicatorProps = {
    onClose(): void;
};

export function Footer({onClose}: ChangesIndicatorProps) {
    const [changesCount] = useStatefulComponentModelChangeObserver(updateChangesCount, 0);
    const {applyChanges, discardChanges} = useComponentModelChangeManager();

    return (
        <footer>
            {changesCount === 0 ? (
                <Button  variant={"primary"} onClick={onClose}>Close</Button>
            ): (
                <>
                    <Button variant={"secondary"} onClick={discardChanges}> Cancel</Button>
                    <Button variant={"primary"} onClick={applyChanges}> Save </Button>
                </>
            )}
        </footer>
    );
}
