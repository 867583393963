import * as React from "react";
import {Pagination} from "react-bootstrap";

interface TablePaginationProps {
    currentPage: number;
    totalPages: number;
    pagesShown: number;
    onClick: (page: number) => void;
}

export const TablePagination: React.FC<TablePaginationProps> = (props) => {
    const pagesShown = Math.min(props.pagesShown, props.totalPages);
    const startPage = props.currentPage - Math.floor(pagesShown / 2);
    const endPage = props.currentPage + Math.ceil(pagesShown / 2);

    const pagesNumbers = Array
        .from({ length: pagesShown }, (_, i) => i)
        .map((v) =>
            startPage <= 0 ? v + 1
                : endPage <= props.totalPages ? startPage + v
                : props.totalPages - pagesShown  + v +  1,
        );

    function onPageClick(page: number) {
        return () => props.onClick(page);
    }

    return (
        <Pagination>
            {pagesNumbers
                .map((page, i, array) => {
                    return (
                        <TablePaginationItem
                            key={page}
                            i={i}
                            page={page}
                            pagesShown={props.pagesShown}
                            currentPage={props.currentPage}
                            totalPages={props.totalPages}
                            startPage={startPage}
                            onPageClick={onPageClick}
                            endPage={endPage}
                            length={array.length}
                        />
                    );
                })}
        </Pagination>
    );
};

interface TablePaginationItemProps {
    i: number;
    page: number;
    pagesShown: number;
    currentPage: number;
    totalPages: number;
    startPage: number;
    onPageClick: (x: number) => () => void;
    endPage: number;
    length: number;
}

const TablePaginationItem: React.FC<TablePaginationItemProps> = (
    {
        i,
        page,
        pagesShown,
        currentPage,
        totalPages,
        startPage,
        onPageClick,
        endPage,
        length,
    }) => {
    if (pagesShown >= totalPages) {
        return (
            <Pagination.Item
                key={page}
                active={currentPage === page}
                onClick={onPageClick(page)}
            >
                {page}
            </Pagination.Item>
        );
    }
    if (i === 0 && startPage > 1) {
        return (
            <Pagination.Item
                key={page}
                active={currentPage === page}
                onClick={onPageClick(1)}
            >
                {1}
            </Pagination.Item>
        );
    }
    if (i === 1 && startPage > 1) {
        return (
            <Pagination.Ellipsis/>
        );
    }
    if (i === length - 1 && endPage < totalPages) {
        return (
            <Pagination.Item
                key={page}
                active={currentPage === page}
                onClick={onPageClick(totalPages)}
            >
                {totalPages}
            </Pagination.Item>
        );
    }
    if (i === length - 2 && endPage < totalPages) {
        return (
            <Pagination.Ellipsis/>
        );
    }
    return (
        <Pagination.Item
            key={page}
            active={currentPage === page}
            onClick={onPageClick(page)}
        >
            {page}
        </Pagination.Item>
    );
};
