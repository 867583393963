import {Badge, Icon, icons} from "@danfoss/etui-core";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {DeviceCore, TelemetryStatus} from "../../../../../models/device/device";
import {LocationCore} from "../../../../../models/location/location";
import {SearchResultView} from "../SearchResultView";
import {ValidDeviceInfoModal} from "./ValidDeviceInfoModal";

interface SuccessfulSearchResultProps {
    device: DeviceCore;
    location?: LocationCore;
    enterDate?: string;
}

export function SuccessfulSearchResult(
    {
        device,
        location,
        enterDate,
        ...rest
    }: SuccessfulSearchResultProps & React.LiHTMLAttributes<HTMLLIElement>,
) {

    const [showInfo, setShowInfo] = React.useState(false);

    function closeDeviceInfoModal() {
        setShowInfo(false);
    }

    function openDeviceInfoModal() {
        setShowInfo(true);
    }

    const badgeText = device.telemetryStatus === TelemetryStatus.ACTIVATED
        ? "Sim active"
        : "Sim disabled";

    const badgeColour = device.telemetryStatus === TelemetryStatus.ACTIVATED
        ? defaultTheme.palette.success.main
        : defaultTheme.palette.error.main;

    return (
        <>
            <SearchResultView
                title={device.code}
                subTitle={(location?.description || location?.address?.fullAddress)
                    ? `Location ${location?.description ?? location.address.fullAddress}`
                    : device.dataOwnerName}
                onClick={openDeviceInfoModal}
                {...rest}
                className={`successful-search-result ${rest.className ?? ""}`}
            >
                <span className={"device-sim-status-badge"}>
                    <Badge
                        text={badgeText}
                        styles={{root: {bg: badgeColour}}}
                    />
                </span>
                <span className={"device-info-expand-icon"}>
                    <Icon glyph={icons.EXPAND}/>
                </span>
            </SearchResultView>
            <ValidDeviceInfoModal
                show={showInfo}
                device={device}
                location={location}
                enterDateStr={enterDate}
                onClose={closeDeviceInfoModal}
            />
        </>
    );
}
