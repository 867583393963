import * as React from "react";

import {AutoSuggest, H5, SelectInput, Toggle} from "@danfoss/etui-core";
import {Col, Row} from "react-bootstrap";
import ComponentTypeController from "../../../controllers/api/ComponentTypeController";
import {Company, CompanySuggestion} from "../../../models/company/companyModel";
import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentType} from "../../../models/component/componentType";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import {companyController} from "../../movements/Transfer";
import SelectInputTitle from "./SelectInputTitle";

interface CreateVisibilityProps {
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    selectedInputComponentModel: Suggestion<ComponentModel>;
    setSelectedInputComponentModel: (mc: Suggestion<ComponentModel>) => void;
    parentCompanyName: string;
    setParentCompanyName: (name: string) => void;
    selectedComponentType: Suggestion<ComponentType>;
    setSelectedComponentType: (ct: Suggestion<ComponentType>) => void;
    setCompany: (c: Suggestion<Company>) => void;
    company: Suggestion<Company>;
    isGlobal: boolean;
    setIsGlobal: (g: boolean) => void;

}

/* tslint:disable:max-line-length */
const VisibilityComponent: React.FC<CreateVisibilityProps> = (props) => {
    const [componentTypes, setComponentTypes] = React.useState<Array<Suggestion<ComponentType>>>([]);
    const [inputComponentModels, setInputComponentModels] = React.useState<Array<Suggestion<ComponentModel>>>([]);
    const [parentCompanies, setParentCompanies] = React.useState<Array<Suggestion<Company>>>([]);

    React.useEffect(() => {
        props.setSelectedInputComponentModel(null);
        if (props.selectedComponentType != null) {
            componentTypeController.getComponentModelsByTypeGuid(props.selectedComponentType.value).then(
                (response) => {
                    const componentModelSuggestions: Array<Suggestion<ComponentModel>> = response.list
                        .map((item) => ({item, label: item.name, value: item.guid}));
                    setInputComponentModels(componentModelSuggestions);
                },
            );
        }
    }, [props.selectedComponentType]);

    const componentTypeController: ComponentTypeController = new ComponentTypeController();
    React.useEffect(() => {
        componentTypeController.getComponentTypes().then(
            (response) => {
                const componentTypeSuggestion: Array<Suggestion<ComponentType>> =
                    response.list.map((item) => ({item, label: item.name, value: item.guid}));
                setComponentTypes(componentTypeSuggestion);
            },
        );
    }, []);

    React.useEffect(() => {
        if (props.parentCompanyName.length >= 3) {
            companyController.getCompaniesByPatternAndRelationType(props.parentCompanyName, "PARENT_COMPANIES").then(
                (response) => {
                    const companySuggestion: CompanySuggestion[] = response.list
                        .map((item) => ({item, label: item.name, value: item.guid}));
                    setParentCompanies(companySuggestion);
                },
            );
        } else {
            setParentCompanies([]);
        }
    }, [props.parentCompanyName]);

    const handleParentCompanyStateChange = (params, downshiftStateAndHelpers) => {
        if (!downshiftStateAndHelpers.isOpen) {
            props.setParentCompanyName("");
        } else if (params.hasOwnProperty("inputValue")) {
            props.setParentCompanyName(params.inputValue);
        }
    };

    function onSelectedItemAndLabel() {
        return (suggestion) => {
            if (suggestion != null) {
                props.setParentCompanyName(suggestion.label);
                props.setCompany(suggestion);
            }
        };
    }

    function handleGlobal() {
        return (value) => {
            const b = value.target.checked;
            props.setIsGlobal(b);
            if (b) {
                props.setParentCompanyName("");
                props.setCompany(null);
                props.setSelectedComponentType(null);
                props.setSelectedInputComponentModel(null);
            }
        };
    }

    return (
        <>
            <Row className={"mx-2 pb-3 justify-content-center"}>
                <Col style={{textAlign: "center"}}>
                    <H5> Visibility Definition</H5>
                </Col>
            </Row>
            <Row className={"mx-4 pb-3"}>
                <Col>
                    <SelectInputTitle
                        label={"Is global"}
                        tooltipText={"If global it wont have visibility limitations"}
                    />
                    <Toggle
                        name={"isGlobal"}
                        isChecked={props.isGlobal}
                        onChange={handleGlobal()}
                    />
                </Col>
            </Row>

            <Row className={"mx-2 pb-3"}>
                <Col>
                    <SelectInputTitle
                        label={"Insert Company"}
                        tooltipText={"Select something to limit visibility only for this company"}
                    />
                    <AutoSuggest
                        label="Insert Company"
                        emptyPlaceholder="No Company Found"
                        suggestions={parentCompanies}
                        inputValue={props.parentCompanyName}
                        isInputDisabled={props.isGlobal}
                        onSelectedValueChange={onSelectedItemAndLabel()}
                        onStateChange={handleParentCompanyStateChange}
                        itemToString={itemToString}
                    />
                </Col>
            </Row>
            {props.selectedDeviceModel != null && props.selectedDeviceModel.item.isSlave && (
                <>
                    <Row className={"mx-2 pb-3"}>
                        <Col>
                            <SelectInputTitle
                                label={"Limit for Component Type"}
                                tooltipText={"Select something to limit visibility only for this component type"}
                            />
                            <SelectInput
                                onChange={props.setSelectedComponentType}
                                name="Component Type"
                                label="Insert Component Type"
                                options={componentTypes}
                                searchable={true}
                                disabled={props.isGlobal}
                                clearable={true}
                                value={props.selectedComponentType}
                            />
                        </Col>
                    </Row>
                    <Row className={"mx-2 pb-3"}>
                        <Col>
                            <SelectInputTitle
                                label={"Limit for Component Model"}
                                tooltipText={"Select something to limit visibility only fot this component model"}
                            />
                            <SelectInput
                                onChange={props.setSelectedInputComponentModel}
                                name="Component Model"
                                label="Insert Component Model"
                                options={inputComponentModels}
                                searchable={true}
                                clearable={true}
                                value={props.selectedInputComponentModel}
                                disabled={props.selectedComponentType == null || inputComponentModels.length === 0 || props.isGlobal}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

function itemToString(item: any) {
    if (item) {
        return item.label;
    }
    return "";
}

export default VisibilityComponent;
