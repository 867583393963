import * as React from "react";
import {useRefInit} from "./useRefInit";
import {mergeDeep, objectValuePathExists, pick, update} from "../utils/objects";
import {checkInput, setInputValue} from "../utils/DOM";
import {HTMLElementValue} from "../presentation/common/types";

type InputRegister<I, R> = {
    name: string;
    ref: React.RefCallback<R>;
    onChange(e: React.ChangeEvent<I>): void;
};

export type FormInputRegister<I = HTMLInputElement, R = I> = (name: string) => InputRegister<I, R>;


type UseFormType<T> = {
    register<I extends HTMLElement, R = I>(name: string): InputRegister<I, R>;
    watch(): T;
    handleSubmit(handler: (data: T, e: React.FormEvent) => void): React.FormEventHandler;
};


type FormChange = {
    name: string,
    value: HTMLElementValue,
    defaultValue: HTMLElementValue,
    event: React.ChangeEvent<HTMLInputElement>;
};

type UseFormProps<T> = {
    defaultValues: T,
    listen?(change: FormChange): void;
};


export function useForm<T extends object>(props: UseFormProps<T>): UseFormType<T> {
    const values = React.useRef<T>({} as T);

    const inputsRefs = useRefInit(() => new Map<string, HTMLInputElement | Map<HTMLElementValue, HTMLInputElement>>());
    const watching = useRefInit(() => ({
        list: new Set<string>(),
        everything: false,
    }));


    const [watchedInput, setWatchedInputs] = React.useState<T>();

    React.useLayoutEffect(() => {
        inputsRefs.current.forEach((inputOrMap, name) => {
            const path = name.split("/");
            const defaultValue = pick(props.defaultValues, path);
            const valueAlreadySet = objectValuePathExists(values.current, path);
            if (inputOrMap == null) {
                return;
            } else if (inputOrMap instanceof Map) {
                inputOrMap.forEach((checkbox) => {
                    const currentValues = pick(values.current ?? {}, path);
                    if (Array.isArray(currentValues) && currentValues.includes(checkbox.value) === checkbox.checked) {
                        return;
                    }
                    const isChecked = Array.isArray(defaultValue) ? defaultValue.includes(checkbox.value) : Boolean(defaultValue);
                    checkInput(checkbox, isChecked);
                });
            } else if (!valueAlreadySet && inputOrMap.type === "checkbox") {
                const isChecked = Boolean(defaultValue);
                if (!isChecked) {
                    values.current = update(values.current, path, isChecked);
                }
                checkInput(inputOrMap, isChecked);
            } else if (!valueAlreadySet) {
                setInputValue(inputOrMap, defaultValue);
            }
        });
    }, [props.defaultValues]);

    return {
        register(name: string): InputRegister<any, any> {
            return {
                name,
                ref(instance: HTMLInputElement | null) {
                    if (instance == null) {
                        return;
                    }
                    if (!inputsRefs.current.has(name)) {
                        inputsRefs.current.set(
                            name,
                            instance.type === "checkbox" && (instance.value ?? "on") !== "on" ? new Map() : instance,
                        );
                    }
                    if (instance.type === "checkbox" && (instance.value ?? "on") !== "on") {
                        const checkboxes = inputsRefs.current.get(name);
                        if (checkboxes instanceof Map && !checkboxes.has(instance.value)) {
                            checkboxes.set(instance.value, instance);
                        }
                    }
                },
                onChange(e: React.ChangeEvent<HTMLInputElement>) {
                    const path = name.split("/");
                    const newValue = e.target.type === "checkbox" ? e.target.checked
                        : e.target.type === "number" ? e.target.valueAsNumber
                        : e.target.value;
                    const isCheckboxWithValue = e.target.type === "checkbox" && (e.target.value ?? "on") !== "on";
                    if(isCheckboxWithValue) {
                        const value: HTMLElementValue[] = pick(values.current, path);
                        const tmp = (value == null
                            ? pick<T, HTMLElementValue[]>(props.defaultValues, path)
                            : value
                        ).filter((v) => v !== e.target.value);

                        values.current = update(
                            values.current,
                            path,
                            e.target.checked ? tmp.concat(e.target.value) : tmp
                        );
                    } else {
                        values.current = update(values.current, path, newValue);
                    }
                    if (watching.current.everything || watching.current.list.has(name)) {
                        setWatchedInputs((prev) => {
                            return update(
                                prev ?? props.defaultValues,
                                path,
                                isCheckboxWithValue ? pick(values.current, path) : newValue
                            );
                        });
                    }
                }
            }
        },
        watch() {
            // todo add possibility to choose what property to watch
            watching.current.everything = true;
            return watchedInput ?? props.defaultValues; // fixme should deep merge
        },
        handleSubmit(handler) {
            return (e) => {
                e.preventDefault();
                handler(mergeDeep([props.defaultValues, values.current], { arrayMerge: "keep-right"}), e);
            }
        }
    }
}