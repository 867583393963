import {StyleProps} from "@danfoss/etui-system";
import {Alert} from "@danfoss/etui-core";
import {ValidatedEquipment} from "../../Transfer";
import React = require("react");


export interface InstallAndMoveAlertProps {
    invalidEquipment: number;
    validEquipmentWithWarnings: number;
    equipment: ValidatedEquipment[];
    completeEquipment: number;
}

export const InstallAndMoveAlert: React.FC<InstallAndMoveAlertProps> = ({
                                                                            validEquipmentWithWarnings,
                                                                            invalidEquipment,
                                                                            equipment,
                                                                            completeEquipment
                                                                        }) => {
    const getInstallAndMoveMessage = (type: "error" | "warning" | "success"): string => {
        switch (type) {
            case "success":
                let validEquipment = equipment.length - invalidEquipment - validEquipmentWithWarnings;
                return `${validEquipment} valid row${validEquipment > 1 ? "s" : ""}${completeEquipment > 0 ? `, ${completeEquipment} complete` : ""}`;
            case "error":
                return `${invalidEquipment} invalid row${invalidEquipment > 1 ? "s" : ""} (may contain warnings)`
            case "warning":
                return `${validEquipmentWithWarnings} valid row${validEquipmentWithWarnings > 1 ? "s" : ""} with warning${validEquipmentWithWarnings > 1 ? "s" : ""}`;
        }
    }

    const getInstallAndMoveAlertRootStyle = (type: "error" | "warning" | "success"): StyleProps => {
        let style: StyleProps = {
            bg: "white",
            fontWeight: "bold",
        }
        switch (type) {
            case "success":
                style.color = "#1AC632";
                break;
            case "error":
                style.color = "#e2000f"
                break;
            case "warning":
                style.color = "#FFAA00";
                break;
        }
        return style
    }


    return <>
        {invalidEquipment > 0 &&
            <Alert
                type={"error"}
                styles={{root: getInstallAndMoveAlertRootStyle("error")}}
                message={getInstallAndMoveMessage("error")}
            />}
        {validEquipmentWithWarnings > 0
            && <Alert
                type={"warning"}
                styles={{root: getInstallAndMoveAlertRootStyle("warning")}}
                message={getInstallAndMoveMessage("warning")}
            />}
        {equipment.length - invalidEquipment - validEquipmentWithWarnings > 0 &&
            <Alert
                type={"success"}
                styles={{root: getInstallAndMoveAlertRootStyle("success")}}
                message={getInstallAndMoveMessage("success")}
            />}
    </>
}