import {Button, icons, Spinner, SpinnerSize} from "@danfoss/etui-core";
import * as React from "react";
import {useDeviceCodeSelectionContext} from "../../DeviceCodeSelectionContext";
import {useDeviceStorageManager} from "../../DeviceStorageManagerContext";
import {DataOwnerInfo, DeviceStorageManagerState} from "../../types";
import {getCodeFromSearchResult} from "../../utils";
import {SectionTitle} from "../SectionTitle";
import {MultipleCompaniesResolutionModalDialog} from "./MultipleCompaniesResolutionModalDialog";
import {escapeRegExp} from "./utils";
import {distinct} from "../../../../utils/arrays";

import "./devices-codes-input.scss";

export function DevicesCodesInput() {

    const textareaRef = React.useRef<HTMLTextAreaElement>();

    const {
        state,
        searchDevices,
        dataOwnersMap,
        updateCompanyGuid,
        devicesSearchResults,
    } = useDeviceStorageManager();

    const { highlightedCode, codes, updateCodes, updateInputCodes } = useDeviceCodeSelectionContext();

    const [devicesCodesString, setDevicesCodesString] = React.useState<string>("");

    function updateDeviceCodesString(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setDevicesCodesString(e.target.value);
    }

    function findDevices() {
        const devicesCodes = distinct(
            devicesCodesString
                .split("\n")
                .map((code) => code.trim())
                .filter(Boolean),
        );
        setDevicesCodesString(devicesCodes.join("\n"));
        updateCodes(devicesCodes);
        searchDevices(devicesCodes);
    }

    React.useEffect(() => {
        switch (state) {
            case DeviceStorageManagerState.ERROR_MULTIPLE_COMPANIES:
            case DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES:
            case DeviceStorageManagerState.DEVICE_SEARCH_COMPLETE:
                const codeCheckupTable = new Set(devicesSearchResults.map(getCodeFromSearchResult));
                const devicesCodes = devicesCodesString
                    .split("\n")
                    .filter((code) => codeCheckupTable.has(code.trim().toUpperCase()));
                setDevicesCodesString(devicesCodes.join("\n"));
                updateCodes(devicesCodes);
                updateInputCodes(devicesCodes.join(","));
                break;
            case DeviceStorageManagerState.INITIAL:
                setDevicesCodesString("");
                updateCodes([]);
                updateInputCodes("");
                break;
        }
    }, [state]);

    React.useEffect(() => {
        const codeStrStart = devicesCodesString.search(new RegExp(`^${escapeRegExp(highlightedCode)}$`, "mi"));
        const codeRow = codes.indexOf(highlightedCode);
        if (codeStrStart < 0) {
            return;
        }
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(codeStrStart, codeStrStart + highlightedCode.length);

        const lineHeight = textareaRef.current.clientHeight / textareaRef.current.rows;
        textareaRef.current.scrollTo({
            top: codeRow * lineHeight,
        });
    }, [highlightedCode]);

    React.useEffect(() => {
        setDevicesCodesString(codes.join("\n"));
    }, [codes]);

    React.useEffect(() => {
        if (state !== DeviceStorageManagerState.INITIAL && state !== DeviceStorageManagerState.SEARCHING_DEVICES) {
            updateInputCodes(
                devicesCodesString
                    .split("\n")
                    .map((code) => code.trim())
                    .filter(Boolean)
                    .join(","),
            );
        }
    }, [devicesCodesString]);

    const dataOwnersInfo = dataOwnersMap
        ? Array.from(dataOwnersMap)
            .map<DataOwnerInfo>(([guid, name]) => ({guid, name}))
        : [];

    const canFindDevices = state === DeviceStorageManagerState.SEARCHING_DEVICES
        || state === DeviceStorageManagerState.STORING_DEVICES
        || devicesCodesString.trim().length === 0;

    return (
        <>
            <SectionTitle
                glyph={icons.HELP_CIRCLE}
                explanationTitle={"Device codes"}
                explanation={"Add in the text area a list of device's code you wish to be put into storage. " +
                    "After listing all the codes press the find button 'Search Devices' to retrieve the devices information"}
            >
                Device codes
            </SectionTitle>
            <form className={"device-search-input-form"}>
                <textarea
                    ref={textareaRef}
                    className={"device-codes-input"}
                    placeholder={"Type/Paste devices' codes here"}
                    rows={25}
                    value={devicesCodesString}
                    onChange={updateDeviceCodesString}
                    disabled={
                        state === DeviceStorageManagerState.SEARCHING_DEVICES
                        || state === DeviceStorageManagerState.STORING_DEVICES}
                />
                <Button
                    onClick={findDevices}
                    variant={"primary"}
                    disabled={canFindDevices}
                    styles={{root: {width: "100%"}}}
                >
                    { state === DeviceStorageManagerState.SEARCHING_DEVICES
                        ? <Spinner size={SpinnerSize.small}  styles={{circle: {color: "#ffffff"}}}/>
                        : "Search devices"
                    }
                </Button>
                <MultipleCompaniesResolutionModalDialog
                    show={state === DeviceStorageManagerState.ERROR_MULTIPLE_COMPANIES}
                    companies={dataOwnersInfo}
                    onCompanySelected={updateCompanyGuid}
                />
            </form>
        </>
    );
}
