import * as React from "react";
import {Button, SelectInput} from "@danfoss/etui-core";
import {Modal} from "react-bootstrap";
import {ConflictData, DeviceIdentifierType, PartialDevice} from "../common";

import "./style.scss";

interface DeviceConflictsResolutionModalProps {
    conflictsCount: number;
    conflicts: PartialDevice[];
    identifierType: DeviceIdentifierType;
    onResolve(resolution: PartialDevice[]): void;
    onCancel(): void;
}

// tslint:disable-next-line:no-empty
function voidFunction() {}

function conflictDataToSelectInputOption({dataOwnerCompany, deviceCode, validatedDevice}: ConflictData) {
    return {
        value: deviceCode,
        label: dataOwnerCompany,
        validatedDevice,
    };
}

function partialDeviceToLiElement(conflictResolutionListFactory, identifierType: DeviceIdentifierType) {
    const identifierText = identifierType === DeviceIdentifierType.DEVICE_CODE ? "Device code"
        : identifierType === DeviceIdentifierType.DEVICE_CUSTOMER_CODE ? "Customer's device code"
            : identifierType === DeviceIdentifierType.EQUIPMENT_CUSTOMER_CODE ? "Customer's equipment code"
                : identifierType === DeviceIdentifierType.EQUIPMENT_MANUFACTURER_CODE ? "Equipment's manufacturer code"
                    : "";
    return (conflict: PartialDevice) => {
        return (
            <li key={conflict.deviceCode} className={"conflict-resolution-list-item"}>
                <h6 className={"conflict-resolution-list-item-title"}>
                    <span className={"conflict-resolution-list-item-title-identifier-text"}>
                        {identifierText}
                    </span> {conflict.code}
                </h6>
                <SelectInput
                    name={`data-owner-company-selector-${conflict.code}`}
                    label={"Company"}
                    options={conflict.conflicts.map(conflictDataToSelectInputOption)}
                    onChange={conflictResolutionListFactory(conflict)}
                    styles={{root: {width: "90%"}}}
                />
            </li>
        );
    };
}

export function DeviceConflictsResolutionModal(
    {
        conflictsCount,
        conflicts,
        identifierType,
        onResolve,
        onCancel,
    }: DeviceConflictsResolutionModalProps,
) {

    const [conflictResolutionList, setConflictResolutionList] = React.useState<PartialDevice[]>([]);

    function conflictResolutionListFactory({code, ...rest}: PartialDevice) {
        return ({value: deviceCode, label: dataOwnerCompany, validatedDevice}) => {
            const conflictIndex = conflictResolutionList.findIndex((c) => c.code === code);
            const newSolution = {
                ...rest,
                ...validatedDevice,
                code,
                dataOwnerCompany,
                deviceCode,
            };

            if (conflictIndex === -1) {
                setConflictResolutionList([
                    ...conflictResolutionList,
                    newSolution,
                ]);
                return;
            }

            setConflictResolutionList([
                ...conflictResolutionList.slice(0, conflictIndex),
                newSolution,
                ...conflictResolutionList.slice(conflictIndex + 1),
            ]);
        };
    }
    function resolve() {
        onResolve([
            ...conflicts.filter((c) => !c.conflicts?.length),
            ...conflictResolutionList,
        ]);
    }

    React.useEffect(() => {
        setConflictResolutionList([]);
    }, [conflictsCount]);

    return (
        <Modal
            show={conflictsCount > 0}
            onHide={voidFunction}
            keyboard={false}
        >
            <Modal.Header>
                <h4>Customer's codes conflicts</h4>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Multiple devices found with the same code.
                    Choose which company the device should be from.
                </p>
                <ul className={"conflict-resolution-list"}>
                    {conflicts
                        .filter((c) => c.conflicts?.length > 0)
                        .map(partialDeviceToLiElement(conflictResolutionListFactory, identifierType))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={onCancel} >Cancel</Button>
                <Button
                    variant={"strong"}
                    disabled={conflictResolutionList.length < conflictsCount}
                    onClick={resolve}
                >
                    Resolve
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
