import {Button, SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Row} from "react-bootstrap";
import TranslationController from "../../../controllers/api/TranslationController";
import {Company} from "../../../models/company/companyModel";
import {ComponentModel} from "../../../models/component/componentModel";
import GeneralAlert from "../../common/GeneralAlert";
import {SelectionConfigurationModal} from "../modals/SelectionConfigurationModal";

interface SelectionProductSectionProps {
    selections: [];
    companies: Company[];
    componentModel: ComponentModel;
    changeStep?: (forward: boolean) => void;
    step?: number;
    submit: () => void;
}

export const SelectionProductSection: React.FC<SelectionProductSectionProps> = ({
    changeStep,
    companies,
    componentModel,
    submit,
}) => {
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.component_models_creation.default_selection.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [selectedCompany, setSelectedCompany] = React.useState<Company>(undefined);
    const [companiesProcessed, setCompaniesProcessed] = React.useState<Company[]>([]);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);
    return (
        <>
            <Form className="d-flex flex-column">
                <Row className="w-100 m-0">
                    <GeneralAlert
                        message={infoMessage}
                    />
                </Row>
                <Row className="w-50 mx-auto">
                    <Col className="d-flex flex-column">
                        <Form.Label className="mx-auto">
                            Choose company
                        </Form.Label>
                        <SelectInput
                            name="Companies"
                            searchable={true}
                            options={companies.filter((e) => !companiesProcessed.includes(e)).map((item) => {
                                return {
                                    item,
                                    label: item.name,
                                    value: item.guid,
                                };
                            })}
                            onChange={(v: any) => {
                                setSelectedCompany(v.item);
                            }}
                        />
                    </Col>
                </Row>
                <Row className="w-50 mx-auto pt-5">
                    <Button
                        variant="strong"
                        className="mx-auto"
                        disabled={selectedCompany === undefined}
                        onClick={() => setShowModal(true)}
                    >
                        Create default selection configuration
                    </Button>
                </Row>
                {/* Bottom nav buttons */}
                <Row className="w-100 d-flex flex-column">
                    <div className="text-right pb-4 pt-4">

                        {changeStep ? (
                            <>
                                <Button
                                    onClick={() => changeStep(false)}
                                >
                                    Previous
                                </Button>
                                {" "}
                                <Button variant="strong" onClick={submit}>
                                    Next
                                </Button>
                            </>
                            )
                            : (
                                <Button variant="strong" onClick={submit}>
                                    Save
                                </Button>
                            )
                        }
                    </div>
                </Row>
            </Form>
            <SelectionConfigurationModal
                company={selectedCompany}
                onHide={() => setShowModal(false)}
                show={showModal}
                componentModel={componentModel}
                setCompaniesProcessed={setCompaniesProcessed}
                companiesProcessed={companiesProcessed}
                setSelectedCompany={setSelectedCompany}
            />
        </>
    );
};
