import * as React from "react";

import "./styles.scss";


type ChipProps = React.HTMLProps<HTMLSpanElement>;

export function isChip(element: unknown): element is React.ReactElement<ChipProps> {
    return React.isValidElement<ChipProps>(element) && element.type === Chip;
}


export function Chip({children, ...props}: ChipProps) {
    return (
        <span {...props} className={`chip ${props.className ?? ""}`}>{children}</span>
    );
}
