import {Button, Checkbox, icons, Spinner, SpinnerSize, Toggle, Tooltip} from "@danfoss/etui-core";
// tslint:disable-next-line:no-submodule-imports
import {EmptyState} from "@danfoss/etui-core/EmptyState";
import * as React from "react";
import {Col, Form, FormControl, Row, Table} from "react-bootstrap";
import TranslationController from "../../../controllers/api/TranslationController";
import {ComponentModel} from "../../../models/component/componentModel";
import {TableComponentModelDiagnosticType} from "../../../models/diagnostic/componentModelDiagnosticType";
import {DiagnosticType} from "../../../models/diagnostic/diagnosticType";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";
import ImportModal from "../modals/ImportModal";

interface DiagnosticSectionProps {
    diagnostics: DiagnosticType[];
    setDiagnostics: (t: DiagnosticType[]) => void;
    defaultTableDiagnosticTypes: TableComponentModelDiagnosticType[];
    tableComponentModelDiagnosticTypes: TableComponentModelDiagnosticType[];
    setTableComponentModelDiagnosticTypes: (t: TableComponentModelDiagnosticType[]) => void;
    currentComponentModel: ComponentModel;
    changeStep?: (t: number) => void;
    step?: number;
    submit: (custom: boolean) => void;
    loading: boolean;
    refreshDiagnostics: () => void;

    disabled?: boolean;
    updateComponentModel?: boolean;
}

// tslint:disable:max-line-length
// tslint:disable:jsx-no-lambda
export const DiagnosticSection: React.FC<DiagnosticSectionProps> = ({
    changeStep,
    currentComponentModel,
    defaultTableDiagnosticTypes,
    diagnostics,
    disabled,
    loading,
    refreshDiagnostics,
    setDiagnostics,
    setTableComponentModelDiagnosticTypes,
    step,
    submit,
    tableComponentModelDiagnosticTypes,
    updateComponentModel
}) => {
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.component_models_creation.diagnostic_types .generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [allChecked, setAllChecked] = React.useState<boolean>(true);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(false);
    const [modalShow, setModalShow] = React.useState<boolean>(false);
    const [isCustom, setIsCustom] = React.useState<boolean>(Boolean(updateComponentModel));

    const columns = [
        {
            key: "checkbox",
            style: {width: "10%"},
            render: (
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <span>Select all</span>
                    <br/>
                    <Checkbox
                        key={"selectAll"}
                        checked={allChecked}
                        indeterminate={indeterminate}
                        disabled={!isCustom || disabled}
                        onChange={() => {
                            setAllChecked(!allChecked);
                            setTableComponentModelDiagnosticTypes(
                                tableComponentModelDiagnosticTypes.map((t) => {
                                    t.checked = !allChecked;
                                    return t;
                                }));
                        }}
                    />
                </div>
            ),
        },
        {
            key: "diagnostics",
            style: {width: "30%"},
            render: "Diagnostic types",
        },
        {
            key: "device_required",
            style: {width: "15%"},
            render: "Device required",
        },
        {
            key: "available_on_cloud",
            style: {width: "15%"},
            render: "Available on cloud",
        },
        {
            key: "energy_consumption_always_on",
            style: {width: "15%"},
            render: "Energy consumption always on",
        },
        {
            key: "energy_consumption",
            style: {width: "15%"},
            render: "Energy consumption factor",
        },
    ];

    /* Function used to remove the selected diagnostic */
    function prepareDiagnostic(item, value, col) {
        const newDiagnostics = [];
        tableComponentModelDiagnosticTypes.forEach((d) => {
            if (d.item.diagnosticTypeGuid === item.item.diagnosticTypeGuid) {
                switch (col) {
                    case "diagnostic":
                        d.checked = value;
                        break;
                    case "device_required":
                        d.item.deviceRequired = value;
                        break;
                    case "energy_consumption":
                        if (value === "0") {
                            d.item.energyConsumption = d.item.energyConsumption > 0 ? -0.5 : 0.5;
                        }
                        if (value === "") {
                            if (!d.item.energyConsumptionAlwaysOn) {
                                d.item.energyConsumption = null;
                            }
                        } else {
                            d.item.energyConsumption = parseFloat(value.replace(/^0+/, "")) || d.item.energyConsumption;
                        }
                        break;
                    case "energy_consumption_always_on":
                        d.item.energyConsumptionAlwaysOn = value;
                        if (value && !d.item.energyConsumption) {
                            d.item.energyConsumption = 0.5;
                        }
                        break;
                    case "available_on_cloud":
                        d.item.availableOnCloud = value;
                        break;
                }
            }
            newDiagnostics.push(d);
        });
        setTableComponentModelDiagnosticTypes(newDiagnostics);
    }

    function deleteUnselected() {
        setTableComponentModelDiagnosticTypes(
            tableComponentModelDiagnosticTypes.filter((e) => e.checked),
        );
    }

    function deleteAll() {
        setTableComponentModelDiagnosticTypes([]);
    }

    React.useEffect(() => {
        if (tableComponentModelDiagnosticTypes !== undefined) {
            if (tableComponentModelDiagnosticTypes.every((v) => v.checked)) {
                setAllChecked(true);
                setIndeterminate(false);
            } else if (tableComponentModelDiagnosticTypes.some((v) => v.checked)) {
                setAllChecked(false);
                setIndeterminate(true);
            } else {
                setAllChecked(false);
                setIndeterminate(false);
            }
        }
    }, [tableComponentModelDiagnosticTypes]);
    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);
    // @ts-ignore
    return (
        <>
            <Form>
                {changeStep && (
                    <Row className="w-100 flex-row m-0">
                        <GeneralAlert
                            message={infoMessage}
                        />
                    </Row>
                )}
                {/* Import buttons */}
                <Form.Group>
                    <Row className="w-100 m-0">
                        <Col className="d-flex justify-content-start align-items-center w-100 p-0">
                            <Row>
                                {!(updateComponentModel) && (
                                    <>
                                        <p className="mx-auto px-3 my-auto">
                                            Custom configuration:
                                        </p>
                                        <Toggle
                                            name="default/custom"
                                            isChecked={isCustom}
                                            onChange={(e, checked) => {
                                                setIsCustom(checked);
                                                if (!checked) {
                                                setTableComponentModelDiagnosticTypes(
                                                defaultTableDiagnosticTypes);
                                            }
                                            }}
                                        />
                                    </>
                                )}
                            </Row>
                        </Col>
                        {
                            (!updateComponentModel) && (<Col className="d-flex justify-content-end align-items-center w-100 p-0">
                                <Tooltip message={"Refresh configurations"} placement={"left"}>
                                    <Button
                                        iconProps={{glyph: icons.REFRESH}}
                                        onClick={refreshDiagnostics}
                                        styles={{root: {mr: 10, height: 50, width: 50}}}
                                        variant={"secondary"}
                                    />
                                </Tooltip>
                                <Button variant="secondary" onClick={() => setModalShow(true)} disabled={!isCustom}>
                                    Import from model
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Form.Group>
                {
                    tableComponentModelDiagnosticTypes === undefined ?
                        (
                            <Spinner size={SpinnerSize.small}/>
                        ) : (
                            tableComponentModelDiagnosticTypes.length === 0 ?
                                (
                                    <div style={{marginTop: "40px", textAlign: "center", marginRight: "60px"}}>
                                        <EmptyState>
                                            <EmptyState.Icon size={144} glyph={icons.DISCONNECTED_CLOUD}/>
                                            <EmptyState.Title color="inherit">
                                                No diagnostic types found!
                                            </EmptyState.Title>
                                            <EmptyState.SubTitle color="inherit">
                                                Try to import from another model!
                                            </EmptyState.SubTitle>
                                        </EmptyState>
                                    </div>
                                ) :
                                (
                                    <Table striped={true} bordered={true} hover={true}>
                                        <thead>
                                        <tr key={"columns"}>
                                            {
                                                columns.map((col) => (
                                                    <th key={col.key} style={col.style}>
                                                        {col.render}
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            tableComponentModelDiagnosticTypes.map((value) => (
                                                <tr key={value.item.diagnosticTypeGuid}>
                                                    <td>
                                                        <div style={{margin: "0 auto", textAlign: "center"}}>
                                                            <Checkbox
                                                                checked={value.checked}
                                                                disabled={!isCustom || disabled}
                                                                onChange={(t) =>
                                                                    prepareDiagnostic(
                                                                        value,
                                                                        t.target.checked,
                                                                        "diagnostic",
                                                                    )}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {value.item.diagnosticType != null ?
                                                            value.item.diagnosticType.name : (
                                                                diagnostics
                                                                    .find(
                                                                        (item) => item.guid === value.item.diagnosticTypeGuid) !== undefined ?
                                                                    diagnostics
                                                                        .find(
                                                                            (item) => item.guid === value.item.diagnosticTypeGuid).name : ""
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        <div style={{margin: "0 auto", textAlign: "center"}}>
                                                            <Checkbox
                                                                checked={value.item.deviceRequired}
                                                                disabled={!isCustom || disabled}
                                                                onChange={(t) =>
                                                                    prepareDiagnostic(
                                                                        value,
                                                                        t.target.checked,
                                                                        "device_required",
                                                                    )}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{margin: "0 auto", textAlign: "center"}}>
                                                            <Checkbox
                                                                checked={value.item.availableOnCloud}
                                                                disabled={!isCustom || disabled}
                                                                onChange={(t) =>
                                                                    prepareDiagnostic(
                                                                        value,
                                                                        t.target.checked,
                                                                        "available_on_cloud",
                                                                    )}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{margin: "0 auto", textAlign: "center"}}>
                                                            <Checkbox
                                                                checked={value.item.energyConsumptionAlwaysOn}
                                                                disabled={!isCustom || disabled}
                                                                onChange={(t) =>
                                                                    prepareDiagnostic(
                                                                        value,
                                                                        t.target.checked,
                                                                        "energy_consumption_always_on",
                                                                    )}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{margin: "0 auto", textAlign: "center"}}>
                                                            <FormControl
                                                                type={"number"}
                                                                disabled={!isCustom}
                                                                step={0.5}
                                                                value={value.item.energyConsumption}
                                                                onChange={(v) =>
                                                                    prepareDiagnostic(
                                                                        value,
                                                                        v.target.value,
                                                                        "energy_consumption",
                                                                    )}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                )
                        )

                }
                {/* Bottom nav buttons */}
                <Row className={"pb-4 pt-4"}>
                    {changeStep && (
                        <Col>
                            <div className={"text-left"}>
                                <Button
                                    disabled={!isCustom}
                                    onClick={deleteUnselected}
                                >
                                    Delete unselected
                                </Button>
                                {" "}
                                <Button
                                    variant="secondary"
                                    disabled={!isCustom}
                                    onClick={deleteAll}
                                >
                                    Delete all
                                </Button>
                            </div>
                        </Col>
                    )}
                    <Col>
                        <div className={"text-right"}>
                            { changeStep && (
                                <Button
                                    onClick={() => changeStep(step - 1)}
                                >
                                    Previous
                                </Button>
                            )}
                            {" "}
                            {!disabled && (
                                <Button
                                    variant="strong"
                                    onClick={() => submit(isCustom)}
                                >
                                    {!updateComponentModel ? "Next" : "Save"}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </Form>
            <ImportModal
                diagnostics={diagnostics}
                show={modalShow}
                onHide={() => setModalShow(false)}
                setDiagnostics={setDiagnostics}
                setTableDiagnostics={setTableComponentModelDiagnosticTypes}
                section={"diagnostics"}
                currentComponentModel={currentComponentModel}
            />
            <LoadingModal loading={loading}/>
        </>
    );
};

export default DiagnosticSection;
