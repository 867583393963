import * as React from "react";

export function NonLegacyLayout({children}: React.PropsWithChildren<unknown>) {
    React.useEffect(() => {
        $(".breadcrumb").css("display", "none");
        return () => {
            $(".breadcrumb").css("display", "");
        };
    }, []);

    return <> {children} </>;
}