import * as React from "react";

export * from "./Td";
export * from "./Tr";
export * from "./Th";

import "./styles.scss";

type TableProps = React.HTMLProps<HTMLTableElement>;
export function Table({children, ...props}: TableProps) {
    return (
        <table {...props} className={`datatable ${props.className ?? ""}`}>
            {children}
        </table>
    );
}
