import {useRefInit} from "../useRefInit";
import {Disconnect, Observer} from "./types";

export interface StatelessStream<T> {
    observe(observer: Observer<T>): Disconnect;
    push(value: T): void;
}

export function useStream<T>(): StatelessStream<T> {
    const observers = useRefInit(() => {
        return new Set<Observer<T>>();
    });

    function observe(observer: Observer<T>): Disconnect {
        observers.current.add(observer);
        return () => {
            observers.current.delete(observer);
        };
    }

    function push(value: T): void {
        Array.from(observers.current).forEach((subscriber) => subscriber(value));
    }

    return { observe, push };
}
