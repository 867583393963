import {Button, EmptyState, icons, SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {DiagnosticType} from "../../../models/diagnostic/diagnosticType";
import {Suggestion} from "../../../models/Suggestion";
import {ConfirmModal} from "../../common/ConfirmModal";
import LoadingModal from "../../common/LoadingModal";
import {Sections} from "../CreateRawData";

interface DeviceModelSectionProps {
    loading: boolean;
    rawDataList: RawData[];
    rawDataAlarmTypeList: RawDataAlarmType[];
    rawDataDiagnosticTypeList: RawDataDiagnosticType[];
    deviceModel: string;
    selectedDeviceModel: DeviceModel;
    deviceModels: Array<Suggestion<DeviceModel>>;
    onUpdateButton: (section: Sections, rawData: RawData | RawDataDiagnosticType | RawDataAlarmType) => () => void;
    onCreateButton: (section: Sections) => () => void;
    onDeleteRawData: (section: string, rawData: RawData | RawDataDiagnosticType | RawDataAlarmType) => () => void;
    setConfirmDeleteShow: (loading: boolean) => void;
    confirmDeleteShow: boolean;
    onSearchDeviceModel: (suggestion: any) => void;
    diagnosticTypes: Array<Suggestion<DiagnosticType>>;
}

// tslint:disable:max-line-length
const DeviceModelSection: React.FC<DeviceModelSectionProps> = (props) => {
    const services = new Services();
    const [paramToDelete, setParamToDelete] = React
        .useState<RawData | RawDataDiagnosticType | RawDataAlarmType>(undefined);
    const [selectedSection, setSelectedSection] = React.useState<string>(undefined);

    function onRemoveRawData(section: string, param: RawData | RawDataDiagnosticType | RawDataAlarmType) {
        return () => {
            setSelectedSection(section);
            setParamToDelete(param);
            props.setConfirmDeleteShow(true);
        };
    }

    function onSearchDeviceModel() {
        return (suggestion) => props.onSearchDeviceModel(suggestion);
    }

    return (
        <>
            <Form id={"deviceModelSection"}>
                <Row>
                    <Col style={{maxWidth: "30%"}}>
                        <Form.Label>Device Model *</Form.Label>
                        <>
                            <SelectInput
                                onChange={onSearchDeviceModel()}
                                name="Device Model"
                                options={props.deviceModels}
                                searchable={true}
                                value={props.deviceModels.find((object) => object.label === props.deviceModel)}
                            />
                        </>
                    </Col>
                </Row>
                {props.selectedDeviceModel != null && (
                    <>
                        <Row className={"mt-5"}>
                            <Col className={"mx-4"}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Raw Data
                                    </h5>
                                    <Button
                                        className={"mx-0"}
                                        iconProps={services.addCircleIconButton()}
                                        onClick={props.onCreateButton(Sections.rawData)}
                                    />
                                </Row>
                            </Col>
                            <Col className={"mx-4 "}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Raw Data Alarm Type
                                    </h5>
                                    <Button
                                        className={"mx-0"}
                                        iconProps={services.addCircleIconButton()}
                                        onClick={props.onCreateButton(Sections.rawDataAlarmType)}
                                        disabled={props.rawDataList == null || props.rawDataList.length === 0}
                                    />
                                </Row>
                            </Col>
                            <Col className={"mx-4"}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Raw Data Diagnostic Type
                                    </h5>
                                    <Button
                                        className={"mx-0"}
                                        iconProps={services.addCircleIconButton()}
                                        onClick={props.onCreateButton(Sections.rawDataDiagnosticType)}
                                        disabled={props.rawDataList == null || props.rawDataList.length === 0}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"mx-4"} style={{maxHeight: "50vh", overflow: "auto"}}>
                                <ListGroup>
                                    {props.rawDataList != null && props.rawDataList.length > 0 ? props.rawDataList.map(
                                        (value) => (
                                            <ListGroup.Item className={"onListHover d-flex"} key={value.guid}>
                                                <span className={"my-auto"}>{value.name}</span>
                                                <Button
                                                    className={"ml-auto"}
                                                    iconProps={services.dotsIconButton()}
                                                    onClick={props.onUpdateButton(Sections.rawData, JSON.parse(JSON.stringify(value)))}
                                                />
                                                <Button
                                                    iconProps={services.removeIconButton()}
                                                    onClick={onRemoveRawData(Sections[Sections.rawData], value)}
                                                />
                                            </ListGroup.Item>
                                        )) : (
                                        <EmptyState>
                                            <EmptyState.Icon size={144} glyph={icons.DISCONNECTED_CLOUD}/>
                                            <EmptyState.Title color="inherit">
                                                No RawDataAlarmType found
                                            </EmptyState.Title>
                                        </EmptyState>
                                    )
                                    }
                                </ListGroup>
                            </Col>
                            <Col className={"mx-4"} style={{maxHeight: "50vh", overflow: "auto"}}>
                                <ListGroup>
                                    {props.rawDataAlarmTypeList != null &&
                                    props.rawDataAlarmTypeList.length > 0 ?
                                        props.rawDataAlarmTypeList.map(
                                            (value) => (
                                                <ListGroup.Item className={"onListHover d-flex"} key={value.guid}>
                                                    <span className={"my-auto"}>
                                                        {value.name}
                                                        <br/>
                                                        {(value.propertiesGroup ? "Properties group: " + value.propertiesGroup.name : "")}
                                                    </span>
                                                    <Button
                                                        className={"ml-auto"}
                                                        iconProps={services.dotsIconButton()}
                                                        onClick={props.onUpdateButton(Sections.rawDataAlarmType, JSON.parse(JSON.stringify(value)))}
                                                    />
                                                    <Button
                                                        iconProps={services.removeIconButton()}
                                                        onClick={onRemoveRawData(Sections[Sections.rawDataAlarmType], value)}
                                                    />
                                                </ListGroup.Item>
                                            )) : (
                                            <EmptyState>
                                                <EmptyState.Icon size={144} glyph={icons.DISCONNECTED_CLOUD}/>
                                                <EmptyState.Title color="inherit">
                                                    No RawDataAlarmType found
                                                </EmptyState.Title>
                                            </EmptyState>
                                        )}
                                </ListGroup>
                            </Col>
                            <Col className={"mx-4"} style={{maxHeight: "50vh", overflow: "auto"}}>
                                <ListGroup>
                                    {props.rawDataDiagnosticTypeList != null &&
                                    props.rawDataDiagnosticTypeList.length > 0 ?
                                        props.rawDataDiagnosticTypeList.map(
                                            (value) => {
                                                const dt = props.diagnosticTypes.find((e) => e.item.guid === value.diagnosticTypeGuid);
                                                return (
                                                    <ListGroup.Item className={"onListHover d-flex"} key={value.guid}>
                                                    <span className={"my-auto"}>
                                                        {dt !== undefined ? dt.label : value.diagnosticTypeGuid}
                                                        <br/>
                                                        {value.name != null ? "(" + value.name + ")" : ""}
                                                        </span>
                                                        <Button
                                                            className={"ml-auto"}
                                                            iconProps={services.dotsIconButton()}
                                                            onClick={props.onUpdateButton(Sections.rawDataDiagnosticType, JSON.parse(JSON.stringify(value)))}
                                                        />
                                                        <Button
                                                            iconProps={services.removeIconButton()}
                                                            onClick={onRemoveRawData(Sections[Sections.rawDataDiagnosticType], value)}
                                                        />
                                                    </ListGroup.Item>
                                                );
                                            }) : (
                                            <EmptyState>
                                                <EmptyState.Icon size={144} glyph={icons.DISCONNECTED_CLOUD}/>
                                                <EmptyState.Title color="inherit">
                                                    No RawDataDiagnosticType found
                                                </EmptyState.Title>
                                            </EmptyState>
                                        )}
                                </ListGroup>
                            </Col>
                        </Row>
                    </>
                )}
                <ConfirmModal
                    onConfirm={props.onDeleteRawData(selectedSection, paramToDelete)}
                    show={props.confirmDeleteShow}
                    onHide={services.onHideModal(props.setConfirmDeleteShow)}
                />
                <LoadingModal loading={props.loading}/>
            </Form>
        </>
    );
};

export default DeviceModelSection;
