import {useRefInit} from "../useRefInit";
import {
    FnCall,
    FunctionQueue,
    FunctionQueueParams,
} from "./types";
import {newFunctionQueue} from "./utils";

export function useFunctionQueue<FC extends FnCall = FnCall>(params?: FunctionQueueParams<FC>): FunctionQueue<FC> {
    const queueRef = useRefInit<FunctionQueue<FC>>(() => newFunctionQueue(params?.interceptors));
    return queueRef.current;
}

export {
    FunctionQueue,
    FnCall,
    FnCallResult,
    SuccessfulFnCallResult,
    ErrorFnCallResult,
    CancelledFnCallResult,
} from "./types";
