import {usePromise} from "../../../hooks/usePromise";
import {JSONEntitiesCollection} from "../../../models/utils/jsonList";
import {CompanyWithExtras} from "./types";
import {
    findCompanies,
    getCompaniesAlarmTypes,
    getCompaniesComponentModels,
    getCompaniesComponentTypes,
    getCompaniesDefaultWarehouse
} from "../api";
import {localeCompare} from "../../../utils/strings";
import {useLocation, useSearchParams} from "react-router-dom";
import * as React from "react";

const MAX_CHIP_SIZE = 45;

export function usePageNavigation() {
    const {data, state, refresh} = usePromise(
        async (params): Promise<JSONEntitiesCollection<CompanyWithExtras>> => {
            const paginatedCompanies = await findCompanies(params);

            const [companyComponentTypes, companiesComponentModels, companiesAlarmTypes, defaultWarehouses] =
                await Promise.all([
                    getCompaniesComponentTypes(paginatedCompanies.list),
                    getCompaniesComponentModels(paginatedCompanies.list),
                    getCompaniesAlarmTypes(paginatedCompanies.list),
                    getCompaniesDefaultWarehouse(paginatedCompanies.list)
                ]);

            return {
                list: paginatedCompanies.list.map((c) => {
                    const alarmTypes = companiesAlarmTypes.list
                        .filter(({companyGuid}) => c.guid === companyGuid)
                        .map(({alarmType}) => alarmType)
                        .sort(localeCompare(({name}) => name));
                    const componentTypes = companyComponentTypes.list
                        .filter(({companyGuid}) => c.guid === companyGuid)
                        .map(({componentType}) => componentType)
                        .sort(localeCompare(({name}) => name));
                    const componentModels = companiesComponentModels.list
                        .filter(({companyGuid}) => c.guid === companyGuid)
                        .map(({componentModel}) => componentModel)
                        .sort(localeCompare(({name}) => name));

                    const {location: defaultWarehouse} = defaultWarehouses.list
                        .find(({companyGuid}) => c.guid === companyGuid);

                    return {
                        ...c,
                        defaultWarehouse: defaultWarehouse,
                        alarmTypes, componentTypes, componentModels,
                        alarmTypesOptimalEnd: findOptimalEnd(alarmTypes.map(({name}) => name), MAX_CHIP_SIZE),
                        componentTypesOptimalEnd: findOptimalEnd(componentTypes.map(({name}) => name), MAX_CHIP_SIZE),
                        componentModelsOptimalEnd: findOptimalEnd(componentModels.map(({name}) => name), MAX_CHIP_SIZE),
                    } as any; // fixme
                }),
                count: paginatedCompanies.count,
                total: paginatedCompanies.total,
                links: null,
            };
        },
        [{page: 0, size: 0}],
        {callOnMount: false},
    );

    const [searchParams] = useSearchParams();

    const location = useLocation();

    React.useEffect(() => {
        if (state === "fulfilled"
            && (typeof location.state?.origin !== "undefined" || location.state?.reFetch === false)
        ) {
            return;
        }

        const parameter = searchParams.size === 0
            ? {page: 0, size: 10, sort: ["createdAt,desc"]}
            : {
                page: searchParams.get("page"),
                size: searchParams.get("size"),
                sort: searchParams.getAll("sort"),
                company: searchParams.getAll("company")
            }

        refresh([parameter]);
    }, [searchParams]);

    return {data, state};
}

function findOptimalEnd(list: string[], cutoff: number, minCount = 0) {
    return Math.max(list.reduce(
        (prev, item) => {
            if (item.length + prev.sum >= cutoff) {
                return prev;
            }
            return {sum: item.length + prev.sum, count: prev.count + 1};
        },
        {sum: 0, count: 0}
    ).count, minCount);
}