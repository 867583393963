import * as React from "react";
import {Disconnect, useStream} from "../../../hooks/observables";

type DatalistProps = React.HTMLProps<HTMLElement>;

export interface DatalistRef {
    observe(observer: (props: DatalistProps) => void): Disconnect;
}

function DatalistDef(props: DatalistProps, ref: React.ForwardedRef<DatalistRef>) {
    const propsStream = useStream<DatalistProps>();
    React.useImperativeHandle(ref, () => {
        return {
            observe: propsStream.observe,
        };
    }, []);

    React.useEffect(() => {
        propsStream.push(props);
    }, [props]);
    return null;
}

export const Datalist = React.forwardRef(DatalistDef);
