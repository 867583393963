import {SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {ProcessingParameter} from "../../../models/configurability/rawData/processingParameter";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {Suggestion} from "../../../models/Suggestion";
import {Sections} from "../CreateRawData";

interface REFValueValueProps {
    processingParameters: ProcessingParameter[];
    setProcessingParameters: (state: ProcessingParameter[]) => void;
    index: number;
    setDisabledParam: (status: boolean) => void;
    currentRD: RawData | RawDataAlarmType | RawDataDiagnosticType;
    section: Sections;
    rawDataList: RawData[];
}

// tslint:disable:max-line-length
const REFValue: React.FC<REFValueValueProps> = (props) => {

    const [rawDataValue, setRawDataValue] = React.useState<Suggestion<RawData>>(undefined);
    const [rawDataSuggestions, setRawDataSuggestions] = React.useState<Array<Suggestion<RawData>>>([]);
    const services: Services = new Services();

    React.useEffect(() => {
        if (props.processingParameters != null) {
            const temp: any = props.processingParameters[props.index];
            if (temp != null) {
                const suggestions = createRawDataSuggestions();
                setRawDataSuggestions(suggestions);
                const value = temp.value !== "" ? suggestions.find((e) => e.value === temp.value) : undefined;
                onSelectValue(value);
            }
        }
    }, []);

    function createRawDataSuggestions() {
        const suggestions: Array<Suggestion<RawData>> = props.rawDataList
            .map((e, index) => ({item: e, label: index + ", " + e.name, value: e.guid}));
        return suggestions;
    }

    function onSelectValue(suggestion: Suggestion<RawData>) {
        if (props.processingParameters) {
            setRawDataValue(suggestion);
            const newValues = props.processingParameters;
            newValues[props.index].value = suggestion ? suggestion.value : undefined;
            props.setProcessingParameters(newValues);
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        }
    }

    return (
        <Row className={"mt-2 d-flex flex-column"}>
            <Form.Label>Processing Param Value {props.index}*</Form.Label>
            <SelectInput
                onChange={onSelectValue}
                name="Type"
                label={"Select Raw Data"}
                options={rawDataSuggestions}
                searchable={true}
                clearable={true}
                disabled={props.section === Sections.rawDataDiagnosticType}
                value={rawDataValue}
            />
        </Row>
    );
};
export default REFValue;
