import {Button} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";
import {DataOwnerInfo} from "../../types";
import {voidFunction} from "../../utils";
import {ItemDetailView} from "../../../common/ItemDetailView";

interface MultipleCompaniesResolutionModalDialogProps {
    show: boolean;
    companies: DataOwnerInfo[];

    onCompanySelected(companyGuid: string);
}

export function MultipleCompaniesResolutionModalDialog(
    {
        show,
        companies,
        onCompanySelected,
    }: MultipleCompaniesResolutionModalDialogProps,
) {

    const [companyGuid, setCompanyGuid] = React.useState<string>();

    function createChangeSelectedCompany(dataOwnerGuid: string): React.ChangeEventHandler<HTMLInputElement> {
        return (event) => {
            if (event.target.checked) {
                setCompanyGuid(dataOwnerGuid);
            }
        };
    }

    function selectCompanyGuid() {
        onCompanySelected(companyGuid);
    }

    return (
        <Modal
            show={show}
            onHide={voidFunction}
            backdrop={"static"}
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>Multiple Companies Resolution</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"companies-selection"}>
                {
                    companies.map((company) => {
                        return (
                            <label key={company.guid} className={"rectangular-radio-button"}>
                                <input
                                    name={"companyGuid"}
                                    type={"radio"}
                                    value={company.guid}
                                    onChange={createChangeSelectedCompany(company.guid)}
                                />
                                <ItemDetailView
                                    heading={company.name}
                                    subheading={`Guid ${company.guid}`}
                                />
                            </label>
                        );
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={selectCompanyGuid}
                    variant={"primary"}
                    styles={{root: {width: "100%"}}}
                    disabled={!companyGuid}
                >
                    Use devices from company
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
