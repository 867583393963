import * as React from "react";

import {SelectInput} from "@danfoss/etui-core";
import {Col, Form, Row} from "react-bootstrap";
import {InterfaceSettingEnumsJson} from "../../../../models/configurability/configurabilityEnumsModel";
import {
    InputSetting,
    SettingTemplate,
    TemplateConfigurationTelemetry,
} from "../../../../models/configurability/template/settingTemplate";
import {Suggestion} from "../../../../models/Suggestion";

interface InputSettingProps {
    currentInterfaceStep: number;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    suggestions: InterfaceSettingEnumsJson;
}

const InputSettingInput: React.FC<InputSettingProps> = (props) => {
    const [pull, setPull] = React.useState<Suggestion<string>>(undefined);
    const [inputSettingSuggestion, setInputSettingSuggestion] = React.useState<Array<Suggestion<string>>>([]);

    React.useEffect(() => {
        const temp = {...props.currentSettingTemplate};
        let inputSetting = ((temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting as InputSetting);
        if (inputSetting == null) {
            inputSetting = {type: "input", pull: null};
            setPull(null);
        } else {
            setPull({
                item: inputSetting.pull,
                value: inputSetting.pull,
                label: inputSetting.pull,
            });
        }
        (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting = inputSetting;
        props.setCurrentSettingTemplate(temp);
    }, [props.currentInterfaceStep]);

    React.useEffect(() => {
        if (props.suggestions != null) {
            setInputSettingSuggestion(props.suggestions.pull.map((item) => ({item, label: item, value: item})));
        }
    }, [props.suggestions]);

    function onChangePull(value: Suggestion<string>) {
        setPull(value);
        const temp = {...props.currentSettingTemplate};
        const inputSetting = (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting as InputSetting;
        inputSetting.pull = value != null ? value.item : null;
        (temp.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].interfaceSetting = inputSetting;
        props.setCurrentSettingTemplate(temp);
    }

    return (
        <Row>
            <Col style={{maxWidth: "30%"}}>
                <Form.Label>Select pull value</Form.Label>
                <SelectInput
                    clearable={true}
                    onChange={onChangePull}
                    name="Input prop"
                    options={inputSettingSuggestion}
                    searchable={true}
                    value={pull}
                    disabled={props.suggestions == null}
                />
            </Col>
        </Row>

    );
};

export default InputSettingInput;
