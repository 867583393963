import {Alert, Badge, List, Spinner, SpinnerSize} from "@danfoss/etui-core";
import {defaultTheme} from "@danfoss/etui-themes";
import {useState} from "react";
import {Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import {MovType, ValidatedDevice, ValidatedEquipment} from "../Transfer";
import {StatusMessageType} from "../../common/ValidationStatusMessage";
import {ValidationStatusMessages} from "../../common/ValidationStatusMessages";
import {InstallAndMoveAlert} from "./utilsComponents/InstallAndMoveAlert";
import React = require("react");

const enum statusValue {
    activated = "activated",
    disabled = "disabled",
}

interface SimStatusChangeEvent {
    value: statusValue;
    beginDate: string;
    endDate: string;
}

interface TelemetryInfo {
    active: boolean;
    history: SimStatusChangeEvent[];
}

const CentralColumn = (
    {
        movType,
        devices,
        packages,
        equipment,
        invalidDevices,
        invalidPackages,
        invalidEquipment,
        validEquipmentWithWarnings,
        completeEquipment,
        packagesDevices,
        loading,
        duplicateElements,
    }) => {
    const [itemStateMap, setItemStateMap] = useState(new Map());
    const [cursorStyle, setCursorStyle] = useState("unset");

    React.useEffect(() => {
        packages.map((pkg) => {
            setItemStateMap(new Map(itemStateMap.set(pkg.code, false)));
        });
    }, [packages]);

    const entitiesList = () => {
        switch (movType) {
            case MovType.DevicesOrEquipments: {
                return (
                    <>
                        <Alert
                            type={
                                invalidDevices > 0 ?
                                    "error" :
                                    "success"
                            }
                            styles={
                                invalidDevices > 0 ?
                                    {
                                        root: {
                                            bg: "white",
                                            color: "#e2000f",
                                            fontWeight: "bold",
                                        },
                                    } : {
                                        root: {
                                            bg: "white",
                                            color: "#1AC632",
                                            fontWeight: "bold",
                                        },
                                    }
                            }
                            message={
                                invalidDevices > 0 ?
                                    `${invalidDevices} invalid device${invalidDevices > 1 ? "s" : ""} ${duplicateElements > 0 ? `(${duplicateElements} duplicate${duplicateElements > 1 ? "s" : ""} excluded)` : ""}` :
                                    `${devices.length} validated device${devices.length > 1 ? "s" : ""} ${duplicateElements > 0 ? `(${duplicateElements} duplicate${duplicateElements > 1 ? "s" : ""} excluded)` : ""}`
                            }
                        />
                        <List
                            dataSource={devices}
                            styles={{
                                root: {
                                    maxHeight: "700px",
                                    overflow: "auto",
                                    pb: "0 !important",
                                },
                            }}
                            renderItem={
                                renderItem(packages,
                                    itemStateMap,
                                    setItemStateMap,
                                    movType,
                                    cursorStyle,
                                    setCursorStyle)
                            }
                        />
                    </>
                );
            }

            case MovType.Packages: {
                return (
                    <>
                        <Alert
                            type={
                                invalidPackages > 0 ?
                                    "error" :
                                    "success"
                            }
                            styles={
                                invalidPackages > 0 ?
                                    {
                                        root: {
                                            bg: "white",
                                            color: "#e2000f",
                                            fontWeight: "bold",
                                        },
                                    } : {
                                        root: {
                                            bg: "white",
                                            color: "#1AC632",
                                            fontWeight: "bold",
                                        },
                                    }
                            }
                            message={
                                invalidPackages > 0 ?
                                    `${invalidPackages} invalid package${invalidPackages > 1 ? "s" : ""} ${duplicateElements > 0 ? ` (${duplicateElements} duplicate package${duplicateElements > 1 ? "s" : ""} excluded)` : ""}` : // e.g. "3 invalid packages (2 duplicate packages)"
                                    `${packages.length} valid package${packages.length > 1 ? "s" : ""} (${packagesDevices} devices${duplicateElements > 0 ? `, ${duplicateElements} duplicate package${duplicateElements > 1 ? "s" : ""} excluded` : ""})`
                            }
                        />
                        <div className="w-100">
                            <List
                                dataSource={packages}
                                styles={{
                                    root: {
                                        maxHeight: "700px",
                                        overflow: "auto",
                                        pb: "0 !important",
                                    },
                                }}
                                renderItem={
                                    renderItem(packages,
                                        itemStateMap,
                                        setItemStateMap,
                                        movType,
                                        cursorStyle,
                                        setCursorStyle)
                                }
                            />
                        </div>
                    </>

                );
            }

            case MovType.InstallAndMove: {
                return (
                    <>
                        <InstallAndMoveAlert
                            completeEquipment={completeEquipment}
                            invalidEquipment={invalidEquipment}
                            validEquipmentWithWarnings={validEquipmentWithWarnings}
                            equipment={equipment}
                        />
                        <div className="w-100">
                            <List
                                dataSource={equipment}
                                styles={{
                                    root: {
                                        maxHeight: "700px",
                                        overflow: "auto",
                                        pb: "0 !important",
                                    },
                                }}
                                renderItem={
                                    renderItem(packages,
                                        itemStateMap,
                                        setItemStateMap,
                                        movType,
                                        cursorStyle,
                                        setCursorStyle)
                                }
                            />
                        </div>
                    </>
                );
            }

            default:
                return (
                    <div/>
                );
        }
    };

    return (
        <Col className="movimentationsColumn col-4">
            {
                (loading) && (
                    <div
                        style={{
                            marginTop: "50%",
                        }}
                    >
                        <Spinner size={SpinnerSize.large}/>
                    </div>
                )
            }
            {
                (devices.length !== 0 || packages.length !== 0 || equipment.length !== 0) && entitiesList()
            }
        </Col>
    );
};

const getEquipmentInfo = (device: ValidatedDevice): string => {
    if (device?.equipmentCode !== null || device?.equipmentManufacturerCode !== null) {
        return `${device?.equipmentCode ? device?.equipmentCode : "no equipment code"}
        - ${device?.equipmentManufacturerCode ? device?.equipmentManufacturerCode : "no equipment manufacturer code"}
        - ${device?.equipmentModel ? device?.equipmentModel : "no equipment model"}`;
    } else {
        return device?.dataOwnerCompany;
    }
};

const ListItem = (item, packages, itemStateMap, setItemStateMap, movType, cursorStyle, setCursorStyle) => {

        const ListItemTitle = () => {
            if (movType === MovType.DevicesOrEquipments && item.valid) {
                return `${item.code} ${(item.equipmentCode !== null || item.equipmentManufacturerCode !== null) ? ` (${item.dataOwnerCompany})` : ""}`;
            } else if (movType === MovType.InstallAndMove) {
                return item.deviceCode;
            } else {
                if (item.valid) {
                    return `${item.code} (${
                        item.openingDate ? item.openingDate
                            .toString()
                            .slice(0, item.openingDate.toString().indexOf("T")) : "none"
                    }, ${
                        item.closureDate ? item.closureDate
                            .toString()
                            .slice(0, item.closureDate.toString().indexOf("T")) : "none"
                    })`;
                } else {
                    return item.code;
                }
            }
        };

        const ListItemSubtitle = () => {
            if (item.valid) {
                switch (movType) {
                    case MovType.DevicesOrEquipments: {
                        return getEquipmentInfo(item);
                    }
                    case MovType.Packages: {
                        return `${item.devicesCount} devices, ${item.devicesModel}`;
                    }
                    case MovType.InstallAndMove: {
                        const list: any[] = [];
                        if ((item as ValidatedEquipment).equipmentWarningMessages.length > 0) {
                            list.push(...(item as ValidatedEquipment).equipmentWarningMessages.map(message => {
                                return {
                                    status: StatusMessageType.WARNING,
                                    message: message
                                }
                            }))
                        }
                        return <>
                            {item.model} - {item.manufacturerCode} - {item.deviceCode}
                            {list.length > 0 && <ValidationStatusMessages statuses={list}/>}
                        </>
                    }
                }
            } else {
                if (movType === MovType.InstallAndMove) {
                    const list: any[] = [];
                    (item as ValidatedEquipment).equipmentErrorMessages?.forEach((message) => {
                        list.push({
                            status: StatusMessageType.ERROR,
                            message: message
                        })
                    });
                    (item as ValidatedEquipment).equipmentWarningMessages?.forEach((message) => {
                        list.push({
                            status: StatusMessageType.WARNING,
                            message: message
                        })
                    })

                    return <ValidationStatusMessages statuses={list}/>
                } else {
                    return item.status;
                }
            }
        };

        const ListItemSublist = () => {
            return (
                <>
                    {
                        item.devices.map((device) => (
                            <div
                                key={device.deviceCode}
                                style={{
                                    height: "22px",
                                    marginLeft: "2.5%",
                                }}
                            >
                            <span
                                style={{
                                    color: "#212529",
                                    fontSize: "0.8rem",
                                    fontWeight: 400,
                                    fontFamily: "'myriad-pro',sans-serif",
                                    marginTop: "8px",
                                    paddingBottom: "8px",
                                    display: "block",
                                }}
                            >
                                {device.deviceCode}, {device.dataOwnerCompany}
                            </span>
                            </div>
                        ))
                    }
                </>
            );
        };

        const ItemIcon = ({iconName, overlayPlaceholder, path}) => {
            return (
                <div className="mr-3">
                    <OverlayTrigger
                        placement="top"
                        delay={{show: 50, hide: 400}}
                        overlay={renderTooltip(overlayPlaceholder)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#212529"
                            className={`bi ${iconName} mt-1`}
                            viewBox="0 0 16 16"
                        >
                            {path}
                        </svg>
                    </OverlayTrigger>
                </div>
            );
        };

        const handleClick = () => {
            console.log("handleClick called");
            if (movType === MovType.Packages) {
                // Render a sublist only if moving and installing
                setItemStateMap(new Map(itemStateMap.set(item.code, !itemStateMap.get(item.code))));
                console.log("Changing item map");
            }
        };

        const onMouseEnter = () => {
            return (movType === MovType.Packages && item.valid) && setCursorStyle("pointer");
        };

        const onMouseLeave = () => {
            return (movType === MovType.Packages && item.valid) && setCursorStyle("unset");
        };

        return (
            <div
                id={movType === MovType.Packages ? item.code : ""}
                style={
                    {
                        backgroundColor: (!item.valid && movType !== MovType.InstallAndMove) ? "#dc3545" : "#e9e9ed",
                        borderRadius: "3px",
                        marginTop: "1px",
                        display: "block",
                    }
                }
                className="pl-3 pr-3"
            >
                <div
                    className="w-100 d-flex"
                    onClick={handleClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        cursor: cursorStyle,
                    }}
                >
                    <div className="w-100">
                    <span
                        style={{
                            color: (!item.valid && movType !== MovType.InstallAndMove) ? "white" : "#212529",
                            fontSize: "0.875rem",
                            fontWeight: 600,
                            fontFamily: "'myriad-pro',sans-serif",
                            lineHeight: "5px",
                            paddingTop: "14px",
                            display: "block",
                            width: "100%",
                        }}
                    >
                        <ListItemTitle/>
                    </span>
                        <span
                            style={{
                                color: item.valid ? "#212529" : "white",
                                fontSize: "0.75rem",
                                fontWeight: 400,
                                fontFamily: "'myriad-pro',sans-serif",
                                marginTop: "8px",
                                paddingBottom: "8px",
                                display: "block",
                            }}
                        >
                        <ListItemSubtitle/>
                    </span>
                    </div>
                    {movType === MovType.DevicesOrEquipments && item?.telemetryInfo && (
                        <div className="w-75" style={{textAlign: "end"}}>
                            <SimInfoSection info={item.telemetryInfo} item={item}/>
                        </div>
                    )}
                    {
                        (
                            movType === MovType.InstallAndMove
                            && item.complete
                        ) &&
                        (
                            <div
                                style={{
                                    margin: "13px 20px 0 0",
                                    display: "flex",
                                }}
                            >
                                <ItemIcon
                                    iconName="bi-check2"
                                    overlayPlaceholder="This csv record is complete"
                                    path={(
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>)}
                                />
                            </div>
                        )
                    }
                    {
                        (movType === MovType.Packages && item.valid) && (
                            <div
                                style={{
                                    margin: "13px 20px 0 0",
                                    display: "flex",
                                }}
                            >
                                {
                                    // Showing a warning icon if not all devices in the package are into Prosa
                                    (!item.devices.every((device) => device.dataOwnerCompany === "Prosa")) && (
                                        <ItemIcon
                                            iconName="bi-exclamation-triangle"
                                            overlayPlaceholder="At least one device is not into Prosa"
                                            path={(
                                                <>
                                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                                </>
                                            )}
                                        />
                                    )
                                }
                                <svg
                                    style={{
                                        transform: itemStateMap.get(item.code) ? "rotate(180deg)" : "rotate(0deg)",
                                        transition: "0.5s",
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    fill="#212529"
                                    className="bi bi-chevron-down"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    />
                                </svg>
                            </div>
                        )
                    }
                </div>
                {
                    (itemStateMap.get(item.code)) && (
                        <div
                            className="w-100"
                            style={{
                                paddingBottom: "2%",
                            }}
                        >
                            {
                                (movType === MovType.Packages && item.valid) && <ListItemSublist/>
                            }
                        </div>
                    )
                }
            </div>
        );
    }
;

interface BadgeSimInfoProps {
    info: TelemetryInfo;
    item: ValidatedDevice;
}

const SimInfoSection: React.FC<BadgeSimInfoProps> = ({info, item}) => {
    const {active, history} = info;
    const text = active ? "Sim active" : "Sim disabled";
    let deactivationDate = "";
    if (!active) {
        const date: Date = new Date(history[0].beginDate);
        deactivationDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    }

    return (
        <div
            style={{
                marginRight: "8px",
            }}
        >
            <Badge
                text={text}
                styles={{
                    root:
                        {bg: (active ? defaultTheme.palette.success.main : defaultTheme.palette.error.main)},
                }}
            />
            {deactivationDate && (
                <span
                    style={{
                        color: item.valid ? "#212529" : "white",
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        fontFamily: "'myriad-pro',sans-serif",
                        marginTop: "3px",
                        paddingBottom: "8px",
                        display: "block",
                    }}
                >
                        {`Sim disabled on: ${deactivationDate}`}
                </span>
            )}
        </div>
    );
};

const renderTooltip = (overlayPlaceholder) => (props) => {
    return (
        <Tooltip id="icon-tooltip" {...props}>
            {overlayPlaceholder}
        </Tooltip>
    );
};

const renderItem = (packages,
                    itemStateMap,
                    setItemStateMap,
                    movType,
                    cursorStyle,
                    setCursorStyle) => (item: ValidatedEquipment) => {

    return ListItem(
        item,
        packages,
        itemStateMap,
        setItemStateMap,
        movType,
        cursorStyle,
        setCursorStyle,
    );
};
export default CentralColumn;
