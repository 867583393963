import * as React from "react";
import {flatten} from "../../../utils/objects";

import "./styles.scss";

type ButtonVariant =
    "primary" | "primary-positive" | "primary-negative"
    | "secondary"
    | "tertiary";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariant;
    ordinal?: "first" | "last" | number,
    data?: Record<string, string>;
};

export function Button({variant, children, data, ...props}: React.PropsWithChildren<ButtonProps>) {

    const variantClassName = variant === "primary" ? "primary-btn"
        : variant === "primary-positive" ? "primary-positive-btn"
        : variant === "primary-negative" ? "primary-negative-btn"
        : variant === "secondary" ? "secondary-btn"
        : "tertiary-btn";

    return (
        <button {...props} className={`button ${variantClassName} ${props.className ?? ""}`} {...flatten({data}, "-")}>
            {children}
        </button>
    );
}


export function isButton(elem: unknown): elem is React.ReactElement<ButtonProps> {
    return React.isValidElement<ButtonProps>(elem);
}