import * as React from "react";

import {Button} from "@danfoss/etui-core";
import {Row} from "react-bootstrap";
import ConfigurabilityController from "../../../controllers/api/ConfigurabilityController";
import Services from "../../../controllers/utils/Services";
import {Company} from "../../../models/company/companyModel";
import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentType} from "../../../models/component/componentType";
import {SettingTemplateVisibilityCreation} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import VisibilityComponent from "../common/VisibilityComponent";
import {Sections} from "../CreateSettingTemplate";

interface CreateVisibilityProps {
    setLoading: (status: boolean) => void;
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    currentSTGuid: string;
}

/* tslint:disable:max-line-length */
const VisibilitySection: React.FC<CreateVisibilityProps> = (props) => {
    const services = new Services();
    const configurabilityController: ConfigurabilityController = new ConfigurabilityController();
    const [selectedComponentType, setSelectedComponentType] = React.useState<Suggestion<ComponentType>>(undefined);
    const [selectedInputComponentModel, setSelectedInputComponentModel] = React
        .useState<Suggestion<ComponentModel>>(undefined);
    const [company, setCompany] = React.useState<Suggestion<Company>>(undefined);
    const [parentCompanyName, setParentCompanyName] = React.useState<string>("");
    const [isGlobal, setIsGlobal] = React.useState<boolean>(true);

    function setResponse(response: { status: number; body: any }) {
        if (response.status >= 400 && response.status <= 500) {
            services.errorNotification(" ", response.body.message);
        }
        if (response.status >= 200 && response.status <= 299) {
            services.successNotification(
                " ",
                "visibility created successfully",
            );
        }
        props.setCurrentStep(Sections.deviceModelSection);
    }

    function onConfirmButton() {
        return () => {
            props.setLoading(true);
            configurabilityController.createSettingTemplateVisibility(createVisibilityPayload()).then(
                (response: { status: number, body: any }) => {
                    setResponse(response);
                });
        };
    }

    function onSkipButton() {
        return () => {
            props.setCurrentStep(Sections.deviceModelSection);
        };
    }

    function createVisibilityPayload() {
        const cm = selectedInputComponentModel != null ? selectedInputComponentModel.value : null;
        const payload: SettingTemplateVisibilityCreation = {
            settingTemplateGuid: props.currentSTGuid,
            companyGuid: services.isEmpty(parentCompanyName) ? null : company.value,
            componentTypeGuid: cm ? null : (selectedComponentType != null ? selectedComponentType.value : null),
            componentModelGuid: cm,
        };
        return JSON.parse(JSON.stringify(payload));
    }

    function mandatoryFieldsCheck() {
        if (props.selectedDeviceModel != null && props.selectedDeviceModel.item.isSlave) {
            return isGlobal ? false : (!company || services.isEmpty(parentCompanyName)) && !selectedComponentType && !selectedInputComponentModel;
        } else {
            return isGlobal ? false : (!company || services.isEmpty(parentCompanyName));
        }
    }

    return (
        <>
            <VisibilityComponent
                isGlobal={isGlobal}
                setIsGlobal={setIsGlobal}
                selectedDeviceModel={props.selectedDeviceModel}
                selectedInputComponentModel={selectedInputComponentModel}
                setSelectedInputComponentModel={setSelectedInputComponentModel}
                parentCompanyName={parentCompanyName}
                setParentCompanyName={setParentCompanyName}
                selectedComponentType={selectedComponentType}
                setSelectedComponentType={setSelectedComponentType}
                setCompany={setCompany}
                company={company}
                setCurrentStep={props.setCurrentStep}
            />
            {/* Bottom nav buttons */}
            <Row className="w-100 d-flex flex-column">
                <div className="text-right pt-4">
                    <Button onClick={onSkipButton()}> Skip</Button>
                    {" "}
                    <Button variant="strong" disabled={mandatoryFieldsCheck()} onClick={onConfirmButton()}>
                        Create Setting Template Visibility
                    </Button>
                </div>
            </Row>
        </>
    );
};

export default VisibilitySection;
