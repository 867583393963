import {Icon, icons} from "@danfoss/etui-core";
import * as React from "react";
import {consumeTargetValue, stopPropagation, toOutsideElements} from "../../../../../../../../utils/eventsHandling";
import {constant} from "../../../../../../../../utils/functions";
import {similar} from "../../../../../../../../utils/strings";
import {After} from "../../../../../../../common/After";
import {Td} from "../../../../../../../common/DataTable";
import {FileUploadButton} from "../../../../../../../common/FileUploadButton";
import {Option} from "../../../../../../../common/form/Option";
import {Select, SelectRef} from "../../../../../../../common/form/Select";
import { AbstractMedia, isVirtualMedia, useAbstractMedia} from "../../../AbstractMediasContext";
import {ComponentModelCompaniesTableDataCellProps} from "../types";

import "./styles.scss";

export function ClientMediaDataCell(
    {
        value,
        disabled,
        state,
        onValueChange,
        onResetValue,
    }: ComponentModelCompaniesTableDataCellProps,
) {

    const inputRef = React.useRef<SelectRef>();

    const {medias, addMedia} = useAbstractMedia();

    const [mediaSearchText, setMediaSearchText] = React.useState("");

    const [open, setOpen] = React.useState(false);

    const mediaOptions = open
        ? medias
            .toArray((_, m) => m)
            .filter((media) => similar(isVirtualMedia(media) ? media.file.name : media.name, mediaSearchText))
            .map((media) => {
                const {url, name, value: mediaValue} = getMediaInfo(media);
                return (
                    <Option key={mediaValue} value={mediaValue} className={"media-option"}>
                        <div className={"media-item"}>
                            <img className={"media-img"} src={url} alt={name}/>
                            <span className={"media-name"}>{name}</span>
                        </div>
                    </Option>
                );
            })
        : (
            medias.get(value, (media) => {
                const {url, name, value: mediaValue} = getMediaInfo(media);
                return [(
                    <Option key={mediaValue} value={mediaValue} className={"media-option"}>
                        <div className={"media-item"}>
                            <img className={"media-img"} src={url} alt={name}/>
                            <span className={"media-name"}>{name}</span>
                        </div>
                    </Option>
                )];
            }) ?? []
        );

    function addAndUseImage(e: React.ChangeEvent<unknown>) {
        if (!(e.target instanceof HTMLInputElement)) {
            return;
        }
        inputRef.current.toggle();
        const virtualMedia = addMedia(e.target.files[0]);
        onValueChange(virtualMedia.timestamp);
    }

    function consumeValue(str: string) {
        const valueAsNumber = Number(str);
        onValueChange(medias.has(valueAsNumber) ? valueAsNumber : str);
    }

    return (
        <Td
            state={state}
            className={"image-cell-company-component-model-cell company-component-model-field-data-cell"}
            onBlur={toOutsideElements(constant(setOpen, false))}
            onFocus={constant(setOpen, true)}
        >
            <Select value={value ?? ""} onChange={consumeTargetValue(consumeValue)} disabled={disabled} ref={inputRef}>
                <input
                    type={"text"}
                    value={mediaSearchText}
                    placeholder={"Search media by name"}
                    autoFocus={true}
                    onChange={consumeTargetValue(setMediaSearchText)}
                    onFocus={constant(setMediaSearchText, "")}
                />
                <After>
                    {state === "edited" ? (
                        <span
                            className={"undo-changed tertiary-btn"}
                            onClick={stopPropagation(onResetValue)}
                        >
                            <Icon glyph={icons.UNDO} size={16}/>
                        </span>
                    ) : null}
                </After>
                <menu className={"media-option-grid-dropdown"}>
                    <Option value={""} className={"media-option no-media-option"}>[No Media]</Option>
                    {mediaOptions}
                    <FileUploadButton
                        className={"upload-new-media menu-li-white"}
                        accept={"image/png, image/jpeg"}
                        onChange={stopPropagation(addAndUseImage)}
                        onBlur={stopPropagation(console.log)}
                    >
                        <Icon glyph={icons.UPLOAD} size={96}/>
                        Upload Media
                    </FileUploadButton>
                </menu>
            </Select>
        </Td>
    );
}

function getMediaInfo(media: AbstractMedia) {
    return isVirtualMedia(media) ? {
        value: media.timestamp,
        name: media.file.name,
        url: window.URL.createObjectURL(media.file),
    } : {
        value: media.guid,
        name: media.name,
        url: `/media/${media.guid}`,
    };
}

function tryToConvert() {

}