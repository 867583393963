import {MultiSwitcher} from "@danfoss/etui-core";
import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {Card} from "react-bootstrap";
import CompanyController from "../../controllers/api/CompanyController";
import ComponentCategoryController from "../../controllers/api/ComponentCategoryController";
import ComponentModelController from "../../controllers/api/ComponentModelController";
import ComponentTypeController from "../../controllers/api/ComponentTypeController";
import Services from "../../controllers/utils/Services";
import {setLegacyBreadcrumb} from "../../index";
import {AlarmType, TableAlarmType} from "../../models/alarm/alarmType";
import {CompanyComponentModelCreation} from "../../models/company/companyComponentModelCreation";
import {TableCompany} from "../../models/company/companyModel";
import {PatchList} from "../../models/company/patchModel";
import {ComponentBrand, ComponentBrandList} from "../../models/component/componentBrand";
import {ComponentCategory} from "../../models/component/componentCategory";
import {ComponentModel} from "../../models/component/componentModel";
import {ComponentType} from "../../models/component/componentType";
import {TableComponentModelDiagnosticType} from "../../models/diagnostic/componentModelDiagnosticType";
import {DiagnosticType} from "../../models/diagnostic/diagnosticType";
import {Manufacturer} from "../../models/manufacturer/manufacturer";
import {Property, PropertyConfiguration, TablePropertyConfiguration} from "../../models/property/property";
import * as LegacyPage from "../../page";
import AlarmSection from "./sections/AlarmSection";
import CompanySection from "./sections/CompanySection";
import DiagnosticSection from "./sections/DiagnosticSection";
import InfoSection from "./sections/InfoSection";
import PropertySection from "./sections/PropertySection";
import {SelectionProductSection} from "./sections/SelectionProductSection";

export const CreateComponentForm: React.FC = () => {
    // Controllers instance
    const componentModelController: ComponentModelController = new ComponentModelController();
    const componentCategoryController: ComponentCategoryController = new ComponentCategoryController();
    const componentTypeController: ComponentTypeController = new ComponentTypeController();
    const companyController: CompanyController = new CompanyController();
    const services: Services = new Services();

    setLegacyBreadcrumb(
        new LegacyPage.Breadcrumb([
            new LegacyPage.BreadcrumbItem("./", "Dashboard", false),
            new LegacyPage.BreadcrumbItem(null, "Web operations", false),
            new LegacyPage.BreadcrumbItem(null, "Create component model", true),
        ]),
    );

    const [currentComponentModel, setCurrentComponentModel] = React.useState<ComponentModel>(undefined);
    /* Info section hooks */
    const [infoSectionLoading, setInfoSectionLoading] = React.useState<boolean>(false);
    const [categories, setCategories] = React.useState<ComponentCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = React.useState<ComponentCategory>(undefined);
    const [types, setTypes] = React.useState<ComponentType[]>([]);
    const [selectedType, setSelectedType] = React.useState<ComponentType>(undefined);
    const [modelName, setModelName] = React.useState<string>("");
    const [image, setImage] = React.useState<string>("");
    const [media, setMedia] = React.useState<File>(undefined);
    const [generic, setGeneric] = React.useState<boolean>(false);
    const [genVeboxCode, setGenVeboxCode] = React.useState<boolean>(false);
    const [nameKey, setNameKey] = React.useState<string>("");
    const [manufacturerNameKey, setManufacturerNameKey] = React.useState<string>("");
    const [selectedManufacturer, setSelectedManufacturer] = React.useState<Manufacturer>(undefined);
    const [manufacturers, setManufacturers] = React.useState<Manufacturer[]>([]);
    const [manufacturerInputValue, setManufacturerInputValue] = React.useState<string>("");
    const [nameAvailable, setNameAvailable] = React.useState<boolean>(false);
    const [nameKeyAvailable, setNameKeyAvailable] = React.useState<boolean>(false);
    const [errorCreateNewBrandModal, setErrorCreateNewBrandModal] = React.useState<string>("");

    /* Steps hooks */
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    /* Diagnostics hooks */
    const [diagnostics, setDiagnostics] = React.useState<DiagnosticType[]>(undefined);
    const [componentTypeDiagnostics, setComponentTypeDiagnostics] =
        React.useState<TableComponentModelDiagnosticType[]>(undefined);
    const [componentModelDiagnostics, setComponentModelDiagnostics] =
        React.useState<TableComponentModelDiagnosticType[]>(undefined);
    const [diagnosticSectionLoading, setDiagnosticSectionLoading] = React.useState<boolean>(false);
    /* Alarms hooks */
    const [alarms, setAlarms] = React.useState<AlarmType[]>([]);
    const [tableAlarms, setTableAlarms] = React.useState<TableAlarmType[]>([]);
    const [defaultTableAlarms, setDefaultTableAlarms] = React.useState<TableAlarmType[]>([]);
    const [alarmSectionLoading, setAlarmSectionLoading] = React.useState<boolean>(false);
    /* Selection product configuration hooks */
    const [selections, setSelections] = React.useState<[]>(undefined);
    /* Companies hooks */
    const [companies, setCompanies] = React.useState([]);
    const [tableCompanies, setTableCompanies] = React.useState<TableCompany[]>([]);
    const [searchCompanyInputValue, setSearchCompanyInputValue] = React.useState<string>("");
    const [componentBrands, setComponentBrands] = React.useState<ComponentBrand[]>([]);
    const [companySectionLoading, setCompanySectionLoading] = React.useState<boolean>(false);
    /* Properties hooks */
    const [properties, setProperties] = React.useState<Property[]>([]);
    const [propertyConfigurations, setPropertyConfigurations] = React.useState<PropertyConfiguration[]>([]);
    const [tablePropertyConfigurations, setTablePropertyConfigurations]
        = React.useState<TablePropertyConfiguration[]>([]);

    /* Defining steps */
    const infoSection = (
        <InfoSection
            generic={generic}
            image={image}
            currentComponentModel={currentComponentModel}
            nameKeyAvailable={nameKeyAvailable}
            manufacturerInputValue={manufacturerInputValue}
            manufacturers={manufacturers}
            modelName={modelName}
            nameKey={nameKey}
            selectedCategory={selectedCategory}
            genVeboxCode={genVeboxCode}
            submit={submit}
            manufacturerNameKey={manufacturerNameKey}
            setGeneric={setGeneric}
            setInputValue={setInputValue}
            loading={infoSectionLoading}
            categories={categories}
            selectedType={selectedType}
            setManufacturer={setSelectedManufacturer}
            setManufacturerInputValue={setManufacturerInputValue}
            setSelectedCategory={setSelectedCategory}
            types={types}
            updateManufacturer={updateManufacturers}
            setSelectedType={setSelectedType}
            nameAvailable={nameAvailable}
        />
    );
    const diagnosticSection = (
        <DiagnosticSection
            diagnostics={diagnostics}
            setDiagnostics={setDiagnostics}
            tableComponentModelDiagnosticTypes={componentModelDiagnostics}
            setTableComponentModelDiagnosticTypes={setComponentModelDiagnostics}
            changeStep={setCurrentStep}
            defaultTableDiagnosticTypes={componentTypeDiagnostics}
            step={currentStep}
            currentComponentModel={currentComponentModel}
            refreshDiagnostics={refreshDiagnostics}
            submit={(custom: boolean) => {
                setDiagnosticSectionLoading(true);
                if (custom) {
                    const checkedDiagnostics = componentModelDiagnostics.filter((item) => {
                        return item.checked;
                    }).map((item) => {
                        return item.item;
                    });
                    componentModelController.linkDiagnosticTypes(
                        currentComponentModel,
                        checkedDiagnostics,
                    ).then(
                        (response) => {
                            setDiagnosticSectionLoading(false);
                            if (response.status === 201) {
                                setCurrentStep(currentStep + 1);
                                $("html, body").animate({scrollTop: 0}, "fast");
                            } else {
                                services.errorNotification(
                                    "Cannot link component model diagnostic types",
                                    "An error occurred!",
                                );
                            }
                        },
                    );
                } else {
                    setDiagnosticSectionLoading(false);
                    setCurrentStep(currentStep + 1);
                    $("html, body").animate({scrollTop: 0}, "fast");
                }
            }}
            loading={diagnosticSectionLoading}
        />
    );
    const alarmSection = (
        <AlarmSection
            alarms={alarms}
            tableAlarms={tableAlarms}
            defaultAlarms={defaultTableAlarms}
            setTableAlarms={setTableAlarms}
            changeStep={setCurrentStep}
            step={currentStep}
            currentComponentModel={currentComponentModel}
            loading={alarmSectionLoading}
            submit={(custom: boolean) => {
                setAlarmSectionLoading(true);
                if (custom) {
                    const checkedAlarmTypes = tableAlarms.filter((item) => {
                        return item.checked;
                    }).map((item) => {
                        return item.item;
                    });
                    componentModelController.linkAlarmTypes(
                        currentComponentModel,
                        checkedAlarmTypes,
                    ).then(
                        (response) => {
                            setAlarmSectionLoading(false);
                            if (response.status === 201) {
                                setCurrentStep(currentStep + 1);
                                $("html, body").animate({scrollTop: 0}, "fast");
                            } else {
                                services.errorNotification(
                                    "Cannot link component model alarm types",
                                    "An error occurred!",
                                );
                            }
                        },
                    );
                } else {
                    setAlarmSectionLoading(true);
                    setCurrentStep(currentStep + 1);
                    $("html, body").animate({scrollTop: 0}, "fast");
                }
            }}
        />
    );
    const selectionProductionConfigurationSection = (
        <SelectionProductSection
            selections={selections}
            companies={tableCompanies.filter((t) => t.checked).map((t2) => t2.item)}
            changeStep={changeStep}
            step={currentStep}
            submit={() => {
                setCurrentStep(currentStep + 1);
            }}
            componentModel={currentComponentModel}
        />
    );
    const companySection = (
        <CompanySection
            refreshCompaniesList={refreshCompaniesList}
            companies={companies}
            setCompanies={setCompanies}
            tableCompanies={tableCompanies}
            loading={companySectionLoading}
            currentComponentModel={currentComponentModel}
            setTableCompanies={setTableCompanies}
            searchCompanyInputValue={searchCompanyInputValue}
            setSearchCompanyInputValue={setSearchCompanyInputValue}
            changeStep={changeStep}
            componentBrands={componentBrands}
            addNewComponentBrand={addNewComponentBrand}
            findSimilarBrands={findSimilarBrands}
            errorCreateNewBrandModal={errorCreateNewBrandModal}
            errorCreateNewBrandModalManager={{state: errorCreateNewBrandModal, setState: setErrorCreateNewBrandModal}}
            submit={(imageList: File[]) => {
                setCompanySectionLoading(true);
                const companiesCreation: CompanyComponentModelCreation[] =
                    tableCompanies
                        .filter((e) => e.checked)
                        .map((e) => {
                            const creation: CompanyComponentModelCreation = {
                                clientCode: e.clientCode,
                                clientName: e.clientName,
                                clientNameKey: e.clientNameKey,
                                companyGuid: e.item.guid,
                                nickname: e.nickname,
                                newMediaName: e.newMedia !== undefined ? e.newMedia.name : null,
                                existingMediaGuid: e.existingMedia !== undefined ? e.existingMedia.guid : null,
                                componentBrandGuid: e.brand !== undefined ? e.brand.item.guid : null,
                                componentModelGuid: currentComponentModel.guid,
                            };
                            return creation;
                        });
                componentModelController.linkCompanies(
                    currentComponentModel,
                    imageList,
                    companiesCreation,
                ).then(
                    (response) => {
                        setCompanySectionLoading(false);
                        if (response.status === 201) {
                            setCurrentStep(currentStep + 1);
                            $("html, body").animate({scrollTop: 0}, "fast");
                        } else if (response.status === 409) {
                            // show 409: Conflict banner
                            services.errorNotification(
                                " ",
                                response.content.message.split(":")[1],
                            );
                        } else {
                            // show general server error banner
                            services.errorNotification(
                                "Error",
                                "General server error!",
                            );
                        }
                    },
                );
            }}
        />
    );
    const propertiesSection = (
        <PropertySection
            properties={properties}
            propertyConfigurations={propertyConfigurations}
            setPropertyConfigurations={setPropertyConfigurations}
            changeStep={setCurrentStep}
            step={currentStep}
            tablePropertyConfigurations={tablePropertyConfigurations}
            setTablePropertyConfigurations={setTablePropertyConfigurations}
            submit={() => {
                const checkedProperties = tablePropertyConfigurations.filter(
                    (e) => e.checked,
                ).map(
                    (d) => d.item,
                );
                return componentModelController.linkProperties(
                    currentComponentModel,
                    checkedProperties,
                ).then(
                    (response) => {
                        if (response.status === 201) {
                            services.successNotification(
                                "Success",
                                "Component model configuration completed! " +
                                "Remember to configure firmware type for current component model!",
                            );
                            return true;
                        } else {
                            services.errorNotification(
                                "Cannot link component model properties",
                                "An error occurred!",
                            );
                            return false;
                        }
                    },
                );
            }}
        />
    );
    const steps = [
        {
            title: "Main information",
            route: infoSection,
            value: 0,
            optional: false,
        },
        {
            title: "Companies configuration",
            route: companySection,
            value: 1,
            optional: false,
        },
        {
            title: "Diagnostics configuration",
            route: diagnosticSection,
            value: 2,
            optional: true,
        },
        {
            title: "Alarms configuration",
            route: alarmSection,
            value: 3,
            optional: true,
        },
        {
            title: "Default selection configuration",
            route: selectionProductionConfigurationSection,
            value: 4,
            optional: true,
        },
        {
            title: "Properties configuration",
            route: propertiesSection,
            value: 5,
            optional: false,
        },
    ];

    function setInputValue(value: any, field: string) {
        switch (field) {
            case "modelName":
                setModelName(value);
                break;
            case "nameKey":
                setNameKey(value);
                break;
            case "image":
                setImage(value);
                break;
            case "manufacturerNameKey":
                setManufacturerNameKey(value);
                break;
            case "media":
                setMedia(value);
                break;
            case "veboxcode":
                setGenVeboxCode(value);
                break;
        }
    }

    function changeStep(forward: boolean) {
        if (selectedCategory !== undefined) {
            if (!selectedCategory.isEquipment && steps[forward ? currentStep + 1 : currentStep - 1].optional) {
                setCurrentStep(forward ? currentStep + 4 : currentStep - 4);
            } else {
                setCurrentStep(forward ? currentStep + 1 : currentStep - 1);
            }
        }
    }

    function submit() {
        // if current component model is undefined makes a POST request
        // else make a PATCH request to update current component model
        setInfoSectionLoading(true);
        if (currentComponentModel !== undefined) {
            patchMainInfo();
        } else {
            postMainInfo();
        }
    }

    function patchMainInfo() {
        const payload: PatchList = {
            list: [
                services.patchOption("replace", "type", selectedType.guid),
                services.patchOption("replace", "name", modelName),
                services.patchOption("replace", "generic", generic),
                services.patchOption("replace", "genVeboxCode", genVeboxCode),
                services.patchOption("replace", "manufacturer", manufacturerInputValue),
            ],
        };

        services.replaceOrRemove(payload, currentComponentModel.image, image, "image");
        services.replaceOrRemove(payload, currentComponentModel.nameKey, nameKey, "nameKey");
        services.addReplaceOpIfValueChanged(payload, currentComponentModel.manufacturerName, manufacturerNameKey,
            "manufacturerNameKey");

        componentModelController.updateComponentModel(
            payload,
            media,
            currentComponentModel.guid,
        ).then((response) => {
                setInfoSectionLoading(false);
                if (response.status === 200) {
                    setCurrentComponentModel(response.body as ComponentModel);
                    services.successNotification(
                        "Success",
                        "Component model updated!",
                    );
                    changeStep(true);
                    $("html, body").animate({scrollTop: 0}, "fast");
                } else {
                    services.errorNotification(
                        "Cannot update component model",
                        "An error occurred during component model update!",
                    );
                }
            },
        );
    }

    function refreshDiagnostics() {
        setDiagnosticSectionLoading(true);
        updateDiagnostics();
    }

    function addNewComponentBrand(brandDescription: string) {
        setCompanySectionLoading(true);
        componentModelController.createComponentModelBrand({description: brandDescription})
            .then((response) => {
                setCompanySectionLoading(false);
                if (response.status === 201) {
                    setComponentBrands((prevState) => [...[...prevState, response.content as ComponentBrand].sort(
                        (a, b) => a.description.toLowerCase() >= b.description.toLowerCase() ? 1 : -1)]);
                    services.successNotification(
                        "Success",
                        "Component model brand created!",
                    );
                    setErrorCreateNewBrandModal("");
                } else if (response.content.errorType === "DUPLICATED_BRAND" || response.content.errorType === "INVALID_BRAND_DESCRIPTION") {
                    setErrorCreateNewBrandModal(response.content.message);
                } else {
                    services.errorNotification(
                        "",
                        response.content.message,
                    );
                }
            });
    }

    function findSimilarBrands(brandDescription: string) {
        return componentModelController.findComponentBrands(brandDescription, false);
    }

    function updateDiagnostics() {
        componentTypeController.getDiagnosticTypesByComponentType(selectedType.guid).then(
            (response) => {
                const sorted = response.list.sort((a, b) => a.name >= b.name ? 1 : -1);
                setDiagnostics(sorted);
                const list = sorted.map((item) => {
                    const body = {
                        diagnosticTypeGuid: item.guid,
                        availableOnCloud: true,
                        deviceRequired: true,
                        energyConsumption: null,
                        energyConsumptionAlwaysOn: false,
                    };
                    const i = {
                        item: body,
                        checked: true,
                    };
                    return i as TableComponentModelDiagnosticType;
                });
                setComponentModelDiagnostics(list);
                setComponentTypeDiagnostics(list);
                setDiagnosticSectionLoading(false);
            },
        );
    }

    function postMainInfo() {
        componentModelController.postMainInfo(
            selectedType.guid,
            modelName,
            image,
            media,
            generic,
            genVeboxCode,
            nameKey,
            manufacturerInputValue,
            manufacturerNameKey,
        ).then((response) => {
            setInfoSectionLoading(false);
            if (response.status === 201) {
                setCurrentComponentModel(response.body as ComponentModel);
                services.successNotification(
                    "Success",
                    "Component model created!",
                );
                changeStep(true);
                $("html, body").animate({scrollTop: 0}, "fast");
            } else {
                services.errorNotification(
                    "Cannot create component model",
                    "An error occurred during component model creation!",
                );
            }
        });
    }

    function updateManufacturers(filter: string) {
        if (filter !== "") {
            componentModelController.getManufacturers(filter).then(
                (response) => {
                    setManufacturers(response.translations);
                },
            );
        }
    }

    function refreshCompaniesList() {
        setCompanySectionLoading(true);
        updateCompaniesList();
    }

    function updateCompaniesList() {
        companyController.findComponentTypeCompanies(selectedType.guid).then(
            (response) => {
                const sorted = response.list.sort((a, b) => a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1);
                setCompanies(sorted);
                setTableCompanies(sorted.map((item) => {
                    const tableItem: TableCompany = {
                        item,
                        checked: false,
                        clientCode: "",
                        nickname: "",
                        clientName: currentComponentModel.name,
                        clientNameKey: currentComponentModel.nameKey,
                    };
                    return tableItem;
                }));
                setCompanySectionLoading(false);
            },
        );
    }

    React.useEffect(() => {
        componentCategoryController.getComponentCategories().then(
            (response) => {
                setCategories(response.list);
            },
        );
        componentModelController.getComponentModelProperties().then(
            (response) => {
                setProperties(response.list);
                setPropertyConfigurations(response.list.map((item) => {
                    const config = {
                        propertyGuid: item.guid,
                    };
                    return config as PropertyConfiguration;
                }));
            },
        );
        componentModelController.findComponentBrands().then(
            (response) => {
                setComponentBrands((response.content as ComponentBrandList).list);
            },
        );
        componentModelController.getManufacturers("").then(
            (response) => {
                setManufacturers(response.translations);
            },
        );
    }, []);
    React.useEffect(() => {
        if (selectedCategory !== undefined) {
            componentCategoryController.getCategoryTypes(selectedCategory.guid).then(
                (response) => {
                    setTypes(response.list);
                },
            );
        } else {
            setTypes([]);
            setSelectedType(undefined);
        }
    }, [selectedCategory]);
    React.useEffect(() => {
        if (selectedManufacturer !== undefined) {
            setManufacturerNameKey(selectedManufacturer.textKey);
            const nameKeyGenerated = selectedManufacturer.textKey + "." + modelName.toLowerCase().split(" ").join("_");
            setNameKey(nameKeyGenerated);
        } else {
            const nameKeyGenerated = "component_models."
                + manufacturerInputValue.toLowerCase().split(" ").join("_");
            setManufacturerNameKey(nameKeyGenerated);
            setNameKey(nameKeyGenerated + "." + modelName.toLowerCase().split(" ").join("_"));
        }
    }, [manufacturerInputValue, modelName]);

    React.useEffect(() => {
        if (currentComponentModel !== undefined && selectedType !== undefined) {
            // get request to retrieve diagnostic types
            updateDiagnostics();
            // componentTypeController.getAlarmTypesByComponentType(selectedType.guid).then(
            //     (response) => {
            //         const sorted = response.list.sort((a, b) => a.name >= b.name ? 1 : -1);
            //         setAlarms(sorted);
            //         const list = sorted.map((item) => {
            //             const tableItem: TableAlarmType = {item, checked: true};
            //             return  tableItem;
            //         });
            //         setTableAlarms(list);
            //         setDefaultTableAlarms(list);
            //     },
            // );

            // get request to retrieve companies
            updateCompaniesList();
        }
    }, [currentComponentModel, selectedType]);
    React.useEffect(() => {
        if (modelName !== "") {
            componentModelController.findComponentModelByName(modelName).then(
                (response) => {
                    if (currentComponentModel !== undefined) {
                        setNameAvailable(!response && modelName.length > 0 || modelName === currentComponentModel.name);
                    } else {
                        setNameAvailable(!response && modelName.length > 0);
                    }
                },
            );
        }
    }, [modelName]);
    React.useEffect(() => {
        if (nameKey !== "") {
            componentModelController.getComponentModelByNameKey(nameKey).then(
                (response) => {
                    if (currentComponentModel !== undefined) {
                        setNameKeyAvailable(!response && nameKey.length > 0 ||
                            nameKey === currentComponentModel.nameKey);
                    } else {
                        setNameKeyAvailable(!response && nameKey.length > 0);
                    }
                },
            );
        }
    }, [nameKey]);
    React.useEffect(() => {
        setTablePropertyConfigurations(propertyConfigurations.map(
            (item) => {
                const i: TablePropertyConfiguration = {
                    item,
                    checked: true,
                };
                return i;
            },
        ));
    }, [propertyConfigurations]);

    React.useEffect(() => {
        services.loadingModal();
    }, [infoSectionLoading, alarmSectionLoading, companySectionLoading, diagnosticSectionLoading]);
    return (
        <div className="w-100 mx-auto">
            <Card>
                <Card.Body>
                    <ThemeProvider theme={defaultTheme}>
                        <MultiSwitcher
                            points={steps}
                            selectedValue={currentStep}
                            styles={{root: {pointerEvents: "none"}}}
                            label={""}
                        />
                        <div style={{height: "20px"}}/>
                        {steps[currentStep].route}
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </div>
    );
};
