import {DeviceStorage} from "../../models/device/storage";
import {JSONFailure} from "../../models/utils/jsonFailure";
import {JSONList} from "../../models/utils/jsonList";
import {JSONResult} from "../../models/utils/jsonResult";
import {toRequestParamsString} from "../../utils/requests";

interface GetCurrentDevicesStorageParams {
    deviceCodes?: string[];
    deviceGuid?: string[];
}

export async function getCurrentDevicesStorage<E extends JSONFailure = JSONFailure>(
    params: GetCurrentDevicesStorageParams,
): Promise<JSONList<JSONResult<DeviceStorage, E>>> {

    const paramString: string = toRequestParamsString(params);

    const response = await fetch(`/devices/storage/current?${paramString}`);

    if (response.ok) {
        return response.json();
    }

    throw response;
}

interface StoreDevicesParams {
    devicesGuid: string[];
    locationGuid: string;
    enterDate?: Date;
}

export async function storeDevices(
    params: StoreDevicesParams,
): Promise<JSONList<JSONResult<DeviceStorage, JSONFailure>>> {

    const response = await fetch(
        `/devices/store`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        },
    );

    if (response.ok) {
        return response.json();
    }

    throw response;
}
