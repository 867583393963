import {Button} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";
import {
    SettingTemplate,
    TemplateConfigurationTelemetry,
} from "../../../models/configurability/template/settingTemplate";
import RawDataTableCustom from "../common/RawDataTable";
import ConfigurabilityService from "../ConfigurabilityService";
import {SectionsModal} from "../CreateSettingTemplate";

interface RawDataParamProps {
    show: boolean;
    loading: boolean;
    onHide: () => void;
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    currentInterfaceStep: number;
    currentSlaveIndex: number;
}

const RawDataModal: React.FC<RawDataParamProps> = (props) => {
    const configurabilityService = new ConfigurabilityService();

// tslint:disable:max-line-length
    function onConfirmButton() {
        return () => {
            const tempTemplate = {...props.currentSettingTemplate};
            const templateConfigurationTelemetry = tempTemplate.templateContent as TemplateConfigurationTelemetry;
            const tempTemplateData = templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[props.currentSlaveIndex].dataTemplate;

            if (tempTemplateData != null) {
                configurabilityService.filterUncheckAndSetCustomType(tempTemplateData);
            }

            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[props.currentSlaveIndex].dataTemplate = {...tempTemplateData};
            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].slaves[props.currentSlaveIndex].configured = true;
            props.setCurrentSettingTemplate(tempTemplate);
            props.onHide();
        };
    }

    function mandatoryFieldsCheck() {
        const slaves = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves;
        if (slaves && slaves[props.currentSlaveIndex] && slaves[props.currentSlaveIndex].dataTemplate) {
            const tempTemplateData = slaves[props.currentSlaveIndex].dataTemplate;
            const alarms = tempTemplateData.alarms.filter((e) => e.checked).length;
            const diagnostics = tempTemplateData.diagnostics.filter((e) => e.checked).length;
            return alarms + diagnostics === 0;
        } else {
            return true;
        }
    }

    return (
        <Modal{...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered={true}>
            <Modal.Header closeButton={true}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Configuration of the data to be acquired for the slave device in position
                    {" " + props.currentSlaveIndex}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RawDataTableCustom
                    section={SectionsModal.rawDataAlarmType}
                    currentInterfaceStep={props.currentInterfaceStep}
                    currentSettingTemplate={props.currentSettingTemplate}
                    currentSlaveIndex={props.currentSlaveIndex}
                    setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                />
                <RawDataTableCustom
                    section={SectionsModal.rawDataDiagnosticType}
                    currentInterfaceStep={props.currentInterfaceStep}
                    currentSettingTemplate={props.currentSettingTemplate}
                    currentSlaveIndex={props.currentSlaveIndex}
                    setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="strong"
                    disabled={props.loading || mandatoryFieldsCheck()}
                    onClick={onConfirmButton()}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RawDataModal;
