import {LockUnlockReasonsRecord} from "./types";

// lock reasons
export const MOVEMENT_ALARM = Object.freeze({
    guid: "83ecf540-93d7-4ea1-96ce-fdaf37cceedc",
    name: "movement alarm",
    nameKey: "equipment_lock.reasons.movement_alarm",
});

export const EQUIPMENT_LOST = Object.freeze({
    guid: "5791f4b2-b0cd-4068-9057-e5338dda1a6f",
    name: "equipment lost",
    nameKey: "equipment_lock.reasons.equipment_lost",
});

export const NON_COMPLIANT_PRODUCT = Object.freeze({
    guid: "e71f6f3b-b5d9-4321-9057-def59f418566",
    name: "non compliant product",
    nameKey: "equipment_lock.reasons.non_compliant_product",
});


export const CEASED_CONTRACT = Object.freeze({
    guid: "7038f38e-321f-44c5-bbd1-abc90062bff8",
    name: "ceased contract",
    nameKey: "equipment_lock.reasons.ceased_contract",
});

export const OTHER_LOCK_REASON = Object.freeze({
    guid: "7d146a4e-52ea-41fa-bb48-55de923786bc",
    name: "other",
    nameKey: "equipment_lock.reasons.other",
});

// unlock reasons
export const FALSE_MOVEMENT_ALARM = Object.freeze({
    guid: "f4cfe4cf-b1c7-4081-b9a7-2cde428bccc0",
    name: "false movement alarm",
    nameKey: "equipment_unlock.reasons.false_movement_alarm",
});
export const NEW_COSTUMER_AGREEMENT = Object.freeze({
    guid: "52e58725-5f38-4dd7-ae12-b8efd9818211",
    name: "new customer agreement",
    nameKey: "equipment_unlock.reasons.new_customer_agreement",
});
export const COOLER_RECOVERED = Object.freeze({
    guid: "777855e5-549d-4d47-a896-2d12553805aa",
    name: "cooler recovered",
    nameKey: "",
});
export const OTHER_UNLOCK_REASON = Object.freeze({
    guid: "48258ae6-5241-4e59-b596-007fe377d2a5",
    name: "other",
    nameKey: "equipment_unlock.reasons.other",
});

export const lockReasons = Object.freeze([
    MOVEMENT_ALARM, EQUIPMENT_LOST, NON_COMPLIANT_PRODUCT, CEASED_CONTRACT, OTHER_LOCK_REASON
]);

export const unlockReasons =  Object.freeze([
    COOLER_RECOVERED, NEW_COSTUMER_AGREEMENT, FALSE_MOVEMENT_ALARM, OTHER_UNLOCK_REASON
]);

export const lockUnlockReasons: LockUnlockReasonsRecord = Object.freeze({lock: lockReasons, unlock: unlockReasons});
