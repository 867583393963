import {Button, SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import ReactJson from "react-json-view";
import Services from "../../../controllers/utils/Services";
import {PatchList} from "../../../models/company/patchModel";
import {AcquisitionSetting} from "../../../models/configurability/rawData/acquisitionSettingModel";
import {ProcessingSetting} from "../../../models/configurability/rawData/processingSetting";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {DiagnosticType, ProcessingParam} from "../../../models/diagnostic/diagnosticType";
import {ProcessingFunction} from "../../../models/processingFunction/procesingFunctionModel";
import {Suggestion} from "../../../models/Suggestion";
import LoadingModal from "../../common/LoadingModal";
import {ProcessingParamType, Sections} from "../CreateRawData";
import RawDataProcessingModal from "../modals/RawDataProcessingModal";

interface RawDataSectionProps {
    setCurrentStep: (num: number) => void;
    loading: boolean;
    currentRawDataDiagnosticType: RawDataDiagnosticType;
    groups: Array<Suggestion<string>>;
    categories: Array<Suggestion<string>>;
    processingParametersTypes: Array<Suggestion<any>>;
    selectedDeviceModel: DeviceModel;
    setCurrentRawDataDiagnosticType: (currentRawDataDiagnosticType: RawDataDiagnosticType) => void;
    diagnosticTypes: Array<Suggestion<DiagnosticType>>;
    processingFunctions: Array<Suggestion<ProcessingFunction>>;
    rawDataSuggestionList: Array<Suggestion<RawData>>;
    onSubmit: (payload: any) => void;
    section: Sections;
    rawDataList: RawData[];
    selectedInputRawData: RawData;
    setSelectedInputRawData: (rawData: RawData) => void;
    selectedInputRawDataName: string;
    setSelectedInputRawDataName: (rawDataName: string) => void;
    group: string;
    setGroup: (groupName: string) => void;
}

// tslint:disable:max-line-length
const RawDataSection: React.FC<RawDataSectionProps> = (props) => {
    const services = new Services();
    const [processingModalShow, setProcessingModalShow] = React.useState(false);
    const [firstRender, setFirstRender] = React.useState<boolean>(false);

    const [param, setParam] = React.useState<AcquisitionSetting | ProcessingSetting>(undefined);

    const [selectedDiagnosticType, setSelectedDiagnosticType] = React.useState<Suggestion<DiagnosticType>>(undefined);

    React.useEffect(() => {
        const rddt = props.currentRawDataDiagnosticType;
        setSelectedDiagnosticType(props.diagnosticTypes.find((object) => object.value === rddt.diagnosticTypeGuid));
    }, []);

    function isUpdate() {
        return (props.currentRawDataDiagnosticType && props.currentRawDataDiagnosticType.guid);
    }

    function onSubmit() {
        return () => {
            props.onSubmit(isUpdate() ? rawDataDiagnosticTypePatchPayload() : rawDataDiagnosticTypePayload());
        };
    }

    function rawDataDiagnosticTypePayload() {
        const newRawDataDiagnosticType: RawDataDiagnosticType = {
            guid: "",
            name: props.currentRawDataDiagnosticType.name,
            diagnosticTypeGuid: selectedDiagnosticType ? selectedDiagnosticType.item.guid : null,
            params: props.currentRawDataDiagnosticType.params,
        };
        return JSON.parse(JSON.stringify(newRawDataDiagnosticType));
    }

    function rawDataDiagnosticTypePatchPayload() {
        const rddt = props.currentRawDataDiagnosticType;
        const payload: PatchList = {
            list: [
                services.patchOption("replace", "params",
                    rddt.params), // fixme viene cambiato sempre
            ],
        };
        services.addReplaceOpIfValueChanged(payload, rddt.diagnosticTypeGuid, selectedDiagnosticType.item.guid, "diagnosticTypeGuid");
        return JSON.parse(JSON.stringify(payload));
    }

    function onEditProcessing(processingSetting: ProcessingSetting) {
        return () => {
            if (processingSetting != null) {
                setParam(processingSetting);
                props.setGroup(processingSetting.group);
            }
            setProcessingModalShow(true);
        };
    }

    function mandatoryFieldsCheck() {
        return !selectedDiagnosticType || services.isEmpty(props.group) || services.isEmpty(props.selectedInputRawDataName) ||
            props.currentRawDataDiagnosticType == null || props.currentRawDataDiagnosticType.params == null ||
            props.currentRawDataDiagnosticType.params.processing == null ||
            props.currentRawDataDiagnosticType.params.processing.length === 0 ||
            props.currentRawDataDiagnosticType.params.processing
                .map((j) => j.parameters.find((e) => e.type === ProcessingParamType.REF) == null).length < 1;
    }

    function findFunctionFromCode(processingSetting: ProcessingSetting) {
        if (processingSetting != null && processingSetting.functionCode != null) {
            return props.processingFunctions.find((e) => e.value === processingSetting.functionCode.toString());
        } else {
            return undefined;
        }
    }

    function onSelectedDiagnosticType() {
        return (suggestion: Suggestion<DiagnosticType>) => {
            setSelectedDiagnosticType(suggestion);
            if (suggestion != null) {
                const dts = suggestion.item;
                if (dts && dts.diagnosticStructure && dts.diagnosticStructure.paramProcessingFunctions && dts.diagnosticStructure.paramProcessingFunctions.length) {
                    const length = dts.diagnosticStructure.paramProcessingFunctions.length;
                    const tempDt: RawDataDiagnosticType = {
                        name: undefined,
                        guid: props.currentRawDataDiagnosticType != null ? props.currentRawDataDiagnosticType.guid : "",
                        diagnosticTypeGuid: props.currentRawDataDiagnosticType != null ? props.currentRawDataDiagnosticType.diagnosticTypeGuid : "",
                        params: {processing: []},
                    };

                    let pp: ProcessingParam;
                    for (let i = 0; i < length; i++) {
                        const a: ProcessingSetting = {
                            category: "DIAGNOSTIC",
                            group: props.group,
                            functionCode: undefined,
                            main: true,
                            parameters: [{
                                type: "REF",
                                value: props.selectedInputRawData != null ? props.selectedInputRawData.guid : "",
                            }],
                        };
                        pp = dts.diagnosticStructure.paramProcessingFunctions[i];
                        if (!pp.calculated) {
                            a.functionCode = pp.processingFunctionCode;
                            tempDt.params.processing.push(a);
                        }
                    }
                    props.setCurrentRawDataDiagnosticType(tempDt);
                }
            }
        };
    }

    function onSelectedRawData(setInputValueFunction, setSelectedItem) {
        return (suggestion: Suggestion<RawData>) => {
            if (suggestion != null) {
                setInputValueFunction(suggestion.label);
                setSelectedItem(suggestion.item);
                if (suggestion.item != null) {
                    const tempDt = props.currentRawDataDiagnosticType;
                    if (tempDt.params != null) {
                        for (const tempDtElement of tempDt.params.processing) {
                            tempDtElement.parameters.forEach((e) => {
                                e.value = suggestion.item.guid;
                                e.type = "REF";
                            });
                        }
                        props.setCurrentRawDataDiagnosticType(tempDt);
                    }
                }
            }
        };
    }

    function onSelectedGroup(setSelectedItem) {
        return (suggestion: Suggestion<any>) => {
            if (suggestion != null) {
                setSelectedItem(suggestion.label);
                const tempDt = props.currentRawDataDiagnosticType;
                if (tempDt.params != null) {
                    for (const tempDtElement of tempDt.params.processing) {
                        tempDtElement.group = suggestion.label;
                    }
                    props.setCurrentRawDataDiagnosticType(tempDt);
                }
            }
        };
    }

    return (
        <>
            <Form>
                <Row>
                    <Col className={"mx-2"}>
                        <Row className="mx-2 pb-3">
                            <p>
                                {"Raw data Diagnostic Type for: " + props.selectedDeviceModel.name}
                            </p>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col>
                                <p>Diagnostic Type *</p>
                                <>
                                    <SelectInput
                                        onChange={onSelectedDiagnosticType()}
                                        name="Diagnostic Type"
                                        label="Insert Diagnostic Type"
                                        options={props.diagnosticTypes}
                                        searchable={true}
                                        value={selectedDiagnosticType}
                                    />
                                </>
                            </Col>
                            <Col>
                                <p>RawData *</p>
                                <>
                                    <SelectInput
                                        onChange={onSelectedRawData(
                                            props.setSelectedInputRawDataName,
                                            props.setSelectedInputRawData,
                                        )}
                                        name="RawData"
                                        label={"Select rawData"}
                                        options={props.rawDataSuggestionList}
                                        searchable={true}
                                        disabled={selectedDiagnosticType == null}
                                        value={props.selectedInputRawData != null ?
                                            props.rawDataSuggestionList
                                                .find((object) =>
                                                    object.value === props.selectedInputRawData.guid) : undefined}
                                    />
                                </>
                            </Col>
                            <Col>
                                <p>Group *</p>
                                <>
                                    <SelectInput
                                        onChange={onSelectedGroup(props.setGroup)}
                                        name="Group"
                                        label="Insert Group"
                                        options={props.groups}
                                        searchable={true}
                                        value={props.groups.find
                                        ((object) => object.label === props.group)}
                                        disabled={selectedDiagnosticType == null}
                                    />
                                </>
                            </Col>
                        </Row>
                        <Row className={"mt-4 mb-2"}>
                            <Col className={"mx-4"}>
                                <Row className={"justify-content-center"}>
                                    <h5
                                        style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                                        className={"mb-0"}
                                    >
                                        Processing
                                    </h5>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup style={{maxHeight: "34vh", overflow: "auto"}}>
                                    {(props.currentRawDataDiagnosticType && props.currentRawDataDiagnosticType.params && props.currentRawDataDiagnosticType.params.processing) ?
                                        props.currentRawDataDiagnosticType.params.processing.map(
                                            (value, index) => (
                                                <ListGroup.Item
                                                    className={"onListHover d-flex"}
                                                    key={index}
                                                >
                                                    <span className={"my-auto"}>
                                                        {index}, {value.category}, {value.group},
                                                        {findFunctionFromCode(value).label}
                                                    </span>
                                                    <Button
                                                        className={"ml-auto"}
                                                        iconProps={services.dotsIconButton()}
                                                        onClick={onEditProcessing(value)}
                                                    />
                                                </ListGroup.Item>
                                            )) : <></>
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={"mx-2"} style={{maxWidth: "30%"}}>
                        <Row className="mx-2 pb-3">
                            Preview
                        </Row>
                        <Row style={{maxHeight: "62vh", overflow: "auto"}}>
                            <ReactJson
                                src={props.currentRawDataDiagnosticType != null ? props.currentRawDataDiagnosticType.params : undefined}
                            />
                        </Row>
                    </Col>
                </Row>
                {/* Bottom nav buttons */}
                <Row className="w-100 d-flex flex-column">
                    <div className="text-right pt-4">
                        <Button onClick={() => props.setCurrentStep(Sections.deviceModel)}>
                            Previous
                        </Button>
                        {" "}
                        <Button disabled={mandatoryFieldsCheck()} variant="strong" onClick={onSubmit()}>
                            {(!props.currentRawDataDiagnosticType || !props.currentRawDataDiagnosticType.guid || !props.currentRawDataDiagnosticType.guid) ? "Save" : "Update"}
                        </Button>
                    </div>
                </Row>
            </Form>
            <RawDataProcessingModal
                modalProps={{
                    show: processingModalShow,
                    onHide: services.onHideModalWithParam(setProcessingModalShow, setFirstRender),
                }}
                rawDataName={"Raw data Diagnostic Type"}
                param={param}
                groups={props.groups}
                categories={props.categories}
                processingFunctions={props.processingFunctions}
                firstRender={firstRender}
                setParam={setParam}
                setFirstRender={setFirstRender}
                processingParametersTypes={props.processingParametersTypes}
                currentRD={props.currentRawDataDiagnosticType}
                currentSelections={{...props.currentRawDataDiagnosticType}}
                section={props.section}
                setCurrentRD={props.setCurrentRawDataDiagnosticType}
                rawDataList={props.rawDataList}
                setProcessing={undefined}
            />
            {/*Loading modal*/}
            <LoadingModal loading={props.loading}/>
        </>
    );
};

export default RawDataSection;
