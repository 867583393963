import {useState} from "react";

import CentralColumn from "./components/CentralColumn";
import LeftColumn from "./components/LeftColumn";
import RightColumn from "./components/RightColumn";

import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import CompanyController from "../../controllers/api/CompanyController";
import MovementController from "../../controllers/api/MovementController";
import {setLegacyBreadcrumb} from "../../index";
import {Company} from "../../models/company/companyModel";
import {Location} from "../../models/location/locationModel";
import * as LegacyPage from "../../page";
import React = require("react");

export enum MovType {
    DevicesOrEquipments = "0",
    Packages = "1",
    InstallAndMove = "2",
}

export interface ValidatedDevice {
    code: string;
    status: string;
    dataOwnerCompany: string;
    valid: boolean;
    equipmentCode: string | null;
    equipmentManufacturerCode: string | null;
    equipmentModel: string | null;
}

export interface ValidatedPackage {
    code: string | null;
    openingDate: Date;
    closureDate: Date | null;
    devicesModel: string;
    devicesCount: number;
    devices: ValidatedDevice[];
    valid: boolean;
    status: string;
}

export interface ValidatedEquipment {
    customerCode: string;
    deviceCode: string;
    equipmentErrorMessages: string[];
    equipmentWarningMessages: string[];
    manufacturerCode: string;
    model: string;
    valid: boolean;
    complete: boolean;
}

export const movementController: MovementController = new MovementController();
export const companyController: CompanyController = new CompanyController();

const Transfer = () => {
    setLegacyBreadcrumb(
        new LegacyPage.Breadcrumb([
            new LegacyPage.BreadcrumbItem("./", "Dashboard", false),
            new LegacyPage.BreadcrumbItem(null, "Web operations", false),
            new LegacyPage.BreadcrumbItem(null, "Transfer", true),
        ]),
    );

    const [movementType, setMovementType] = useState<MovType>(null);

// Entities Hooks
    const [devices, setDevices] = useState([]);
    const [packages, setPackages] = useState([]);
    const [equipment, setEquipment] = useState<ValidatedEquipment[]>([]);
    const [deviceOnly, setDeviceOnly] = useState(false);

// Invalid entities Hooks
    const [invalidDevices, setInvalidDevices] = useState(0);
    const [invalidPackages, setInvalidPackages] = useState(0);
    const [invalidEquipment, setInvalidEquipment] = useState(0);
    const [validEquipmentWithWarnings, setValidEquipmentWithWarnings] = useState(0);

// Complete entities Hooks
    const [completeEquipment, setCompleteEquipment] = useState(0);
    const [validEquipment, setValidEquipment] = useState(0);

// User selected params Hooks
    const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();
    const [inputCompanyValue, setInputCompanyValue] = useState("");
    const [selectedLocation, setSelectedLocation] = useState<Location | undefined>();
    const [shipmentDate, setShipmentDate] = useState<Date>(undefined);

// Utility Hooks
    const [packagesDevices, setPackagesDevices] = useState(0);
    const [companyWarehouses, setCompanyWarehouses] = useState([]);
    const [csvFile, setCsvFile] = useState<File | undefined>();
    const [loading, setLoading] = useState(false);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const [duplicateElements, setDuplicateElements] = useState(0);

    const resetParams = () => {
        setDevices([]);
        setPackages([]);
        setEquipment([]);
        setInvalidEquipment(0);
        setInvalidPackages(0);
        setInvalidDevices(0);
        setCompleteEquipment(0);
        setDuplicateElements(0);
        setCompanyWarehouses([]);
        setSelectedCompany(undefined);
        setDeviceOnly(false);
        setSelectedLocation(undefined);
        setShipmentDate(undefined);
        setInputCompanyValue("");
    };

    return (
        <>
            <ThemeProvider
                theme={defaultTheme}
            >
                {
                    waitingForResponse && (
                        <div
                            style={{
                                transition: "0.3s",
                                position: "absolute",
                                backgroundColor: "#cccccc",
                                opacity: ".5",
                                zIndex: 200,
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    )
                }
                <LeftColumn
                    setDevices={setDevices}
                    setPackages={setPackages}
                    setEquipment={setEquipment}
                    setInvalidDevices={setInvalidDevices}
                    setInvalidPackages={setInvalidPackages}
                    setInvalidEquipment={setInvalidEquipment}
                    setValidEquipmentWithWarnings={setValidEquipmentWithWarnings}
                    setCompleteEquipment={setCompleteEquipment}
                    setValidEquipment={setValidEquipment}
                    movType={movementType}
                    setMovType={setMovementType}
                    setPackagesDevices={setPackagesDevices}
                    csvFile={csvFile}
                    setCsvFile={setCsvFile}
                    isLoading={loading}
                    setLoading={setLoading}
                    resetParams={resetParams}
                    setDuplicateElements={setDuplicateElements}
                />
                <CentralColumn
                    devices={devices}
                    packages={packages}
                    equipment={equipment}
                    invalidPackages={invalidPackages}
                    invalidDevices={invalidDevices}
                    invalidEquipment={invalidEquipment}
                    validEquipmentWithWarnings={validEquipmentWithWarnings}
                    completeEquipment={completeEquipment}
                    movType={movementType}
                    packagesDevices={packagesDevices}
                    loading={loading}
                    duplicateElements={duplicateElements}
                />
                <RightColumn
                    movementType={movementType}
                    invalidDevices={invalidDevices}
                    devices={devices}
                    packages={packages}
                    packagesDevices={packagesDevices}
                    equipment={equipment}
                    invalidEquipment={invalidEquipment}
                    completeEquipment={completeEquipment}
                    validEquipment={validEquipment}
                    invalidPackages={invalidPackages}
                    csvFile={csvFile}
                    deviceOnly={deviceOnly}
                    setDeviceOnly={setDeviceOnly}
                    inputCompanyValue={inputCompanyValue}
                    setInputCompanyValue={setInputCompanyValue}
                    companyWarehouses={companyWarehouses}
                    setCompanyWarehouses={setCompanyWarehouses}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    shipmentDate={shipmentDate}
                    setShipmentDate={setShipmentDate}
                    setWaitingForResponse={setWaitingForResponse}
                    resetParams={resetParams}
                />
            </ThemeProvider>
        </>
    );
};

export default Transfer;
