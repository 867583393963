import {SelectInput, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {ProcessingParameter} from "../../../models/configurability/rawData/processingParameter";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {GroupProperty} from "../../../models/property/property";
import {Suggestion} from "../../../models/Suggestion";
import {Sections} from "../CreateRawData";

interface PropertyValueProps {
    processingParameters: ProcessingParameter[];
    setProcessingParameters: (state: ProcessingParameter[]) => void;
    index: number;
    setDisabledParam: (status: boolean) => void;
    currentRD: RawData | RawDataAlarmType | RawDataDiagnosticType;
    section: Sections;
}

// tslint:disable:max-line-length
const PropertyValue: React.FC<PropertyValueProps> = (props) => {

    const [propertyValue, setPropertyValue] = React.useState<Suggestion<GroupProperty>>(undefined);
    const [propertySuggestions, setPropertySuggestions] = React.useState<Array<Suggestion<GroupProperty>>>([]);
    const [propertyDefaultValue, setPropertyDefaultValue] = React.useState<string>("");
    const services: Services = new Services();

    React.useEffect(() => {
        if (props.processingParameters != null) {
            const temp: any = props.processingParameters[props.index];
            if (temp != null) {
                const suggestions = createPropertiesSuggestions();
                setPropertySuggestions(suggestions);
                const value = temp.value !== "" ? suggestions.find((e) => e.value === temp.value) : undefined;
                onSelectValue(value);
                setDefaultValue(temp.defaultValue);
            }
        }
    }, []);

    function createPropertiesSuggestions() {
        const rawData = services.castRawData(props.section, props.currentRD);
        if (rawData.propertiesGroup) {
            const suggestions: Array<Suggestion<GroupProperty>> = rawData.propertiesGroup.properties
                .map((e) => ({item: e, label: e.name, value: e.guid}));
            return suggestions;
        } else {
            services.warningNotification(" ", "Select a property group");
            return [];
        }
    }

    function onSelectValue(suggestion: Suggestion<GroupProperty>) {
        if (props.processingParameters) {
            setPropertyValue(suggestion);
            const newValues = props.processingParameters;
            newValues[props.index].value = suggestion ? suggestion.value : undefined;
            props.setProcessingParameters(newValues);
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        }
    }

    function onDefaultValueChange(suggestion) {
        if (suggestion != null) {
            setDefaultValue(suggestion.target.value);
        }
    }

    function setDefaultValue(value) {
        if (value && props.processingParameters) {
            setPropertyDefaultValue(value);
            const newValues = props.processingParameters;
            newValues[props.index].defaultValue = value;
            props.setProcessingParameters(newValues);
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        } else {
            setPropertyDefaultValue(null);
            props.setDisabledParam(true);
        }
    }

    return (
        <>
            <Row className={"mt-2 d-flex flex-column"}>
                <Form.Label>Processing Param Value {props.index}*</Form.Label>
                <SelectInput
                    onChange={onSelectValue}
                    name="Type"
                    label={"Select Property"}
                    options={propertySuggestions}
                    searchable={true}
                    clearable={true}
                    disabled={props.section === Sections.rawDataDiagnosticType}
                    value={propertyValue}
                />
            </Row>
            <Row className={"mt-2 d-flex flex-column"}>
                <TextInput
                    name={"formDefaultValue"}
                    label={"Insert Property default value"}
                    type={"text"}
                    disabled={props.section === Sections.rawDataDiagnosticType}
                    value={propertyDefaultValue}
                    onChange={onDefaultValueChange}
                />
            </Row>
        </>
    );
};
export default PropertyValue;
