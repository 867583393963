import {Button} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";
import ConfigurabilityController from "../../../controllers/api/ConfigurabilityController";
import Services from "../../../controllers/utils/Services";
import {Company} from "../../../models/company/companyModel";
import {PatchList} from "../../../models/company/patchModel";
import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentType} from "../../../models/component/componentType";
import {
    SettingTemplateVisibility,
    SettingTemplateVisibilityCreation,
} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import VisibilityComponent from "../common/VisibilityComponent";
import {Sections} from "../CreateSettingTemplate";

interface CreateVisibilityModalProps {
    show: boolean;
    onHide: () => void;
    currentSTV: SettingTemplateVisibility;
    setLoading: (status: boolean) => void;
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    onSearchDeviceModel: (suggestion: Suggestion<DeviceModel>) => void;
    currentSTGuid: string;
}

/* tslint:disable:max-line-length */
const CreateVisibilityModal: React.FC<CreateVisibilityModalProps> = (props) => {
    const services = new Services();
    const configurabilityController: ConfigurabilityController = new ConfigurabilityController();
    const [selectedComponentType, setSelectedComponentType] = React.useState<Suggestion<ComponentType>>(undefined);
    const [selectedInputComponentModel, setSelectedInputComponentModel] = React
        .useState<Suggestion<ComponentModel>>(undefined);
    const [company, setCompany] = React.useState<Suggestion<Company>>(undefined);
    const [parentCompanyName, setParentCompanyName] = React.useState<string>("");
    const [isGlobal, setIsGlobal] = React.useState<boolean>(true);

    function setResponse(response: { status: number; body: any }, isCreation: boolean) {
        if (response.status >= 400 && response.status <= 500) {
            services.errorNotification(" ", response.body.message);
        }
        if (response.status >= 200 && response.status <= 299) {
            services.successNotification(
                " ",
                "visibility " + (isCreation ? "created" : "updated") + " successfully",
            );
        }
        props.onHide();
        props.setCurrentStep(Sections.deviceModelSection);
        props.onSearchDeviceModel(props.selectedDeviceModel);
    }

    function onConfirmButton() {
        return () => {
            props.setLoading(true);
            if (props.currentSTV != null && props.currentSTV.guid != null) {
                configurabilityController.patchSettingTemplateVisibility(patchVisibilityPayload(), props.currentSTV.guid).then(
                    (response: { status: number, body: any }) => {
                        setResponse(response, false);
                    });
            } else {
                configurabilityController.createSettingTemplateVisibility(createVisibilityPayload()).then(
                    (response: { status: number, body: any }) => {
                        setResponse(response, true);
                    });
            }
        };
    }

    function createVisibilityPayload() {
        const cm = selectedInputComponentModel != null ? selectedInputComponentModel.value : null;
        const payload: SettingTemplateVisibilityCreation = {
            settingTemplateGuid: props.currentSTGuid,
            companyGuid: services.isEmpty(parentCompanyName) ? null : company.value,
            componentTypeGuid: cm ? null : (selectedComponentType != null ? selectedComponentType.value : null),
            componentModelGuid: cm,
        };
        return JSON.parse(JSON.stringify(payload));
    }

    function patchVisibilityPayload() {
        const payload: PatchList = {list: []};
        services.replaceOrRemove(payload, props.currentSTV.companyGuid, services.isEmpty(parentCompanyName) ? "" : company.value, "company");
        if (props.selectedDeviceModel.item.isSlave) {
            services.replaceOrRemove(payload, props.currentSTV.componentTypeGuid, selectedComponentType != null ? selectedComponentType.value : "", "componentType");
            services.replaceOrRemove(payload, props.currentSTV.componentModelGuid, selectedInputComponentModel != null ? selectedInputComponentModel.value : "", "componentModel");
        }
        return JSON.parse(JSON.stringify(payload));
    }

    function mandatoryFieldsCheck() {
        if (props.selectedDeviceModel != null && props.selectedDeviceModel.item.isSlave) {
            return isGlobal ? false : (!company || services.isEmpty(parentCompanyName)) && !selectedComponentType && !selectedInputComponentModel;
        } else {
            return isGlobal ? false : (!company || services.isEmpty(parentCompanyName));
        }
    }

    return (
        <>
            <Modal{...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Setting Template Visibility
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VisibilityComponent
                        isGlobal={isGlobal}
                        setIsGlobal={setIsGlobal}
                        selectedDeviceModel={props.selectedDeviceModel}
                        selectedInputComponentModel={selectedInputComponentModel}
                        setSelectedInputComponentModel={setSelectedInputComponentModel}
                        parentCompanyName={parentCompanyName}
                        setParentCompanyName={setParentCompanyName}
                        selectedComponentType={selectedComponentType}
                        setSelectedComponentType={setSelectedComponentType}
                        setCompany={setCompany}
                        company={company}
                        setCurrentStep={props.setCurrentStep}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="strong" disabled={mandatoryFieldsCheck()} onClick={onConfirmButton()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateVisibilityModal;
