import * as React from "react";
import {
    getComponentModelsCompanies,
    getComponentModelsComponentTypes,
    getComponentModelsNames, updateComponentModel,
} from "../api";
import {StatefulMap, useMap} from "../../../hooks/useMap";
import {StatefulSet, useSet} from "../../../hooks/useSet";
import {Company} from "../../../models/company/companyModel";
import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentType} from "../../../models/component/componentType";
import {JSONList} from "../../../models/utils/jsonList";
import {distinct} from "../../../utils/arrays";
import {isEmptyObject, objectKeys, omit, pickGuid} from "../../../utils/objects";
import {
    ColumnDataType,
    ColumnsData,
    ComponentModelRowProps,
    ComponentModelTableFilters,
    FilterColumnProps,
} from "./types";

function getFilterName(columnName: keyof ColumnsData): keyof ComponentModelTableFilters {
    switch (columnName) {
        case "names": {
            return "names";
        }
        case "componentCategories": {
            return "componentCategoriesGuids";
        }
        case "componentTypes": {
            return "componentTypesGuids";
        }
        case "companies": {
            return "companiesGuids";
        }
    }
}

function getColumnOptions(
    columnName: keyof ColumnsData,
    columnsData: ColumnsData,
    filterState: ComponentModelTableFilters,
): ColumnDataType<typeof columnName> {
    if (columnName === "componentTypes" && filterState.componentCategoriesGuids.selected.length > 0) {
        return columnsData
            .componentTypes
            .filter((ct) => filterState.componentCategoriesGuids.selected.includes(ct.category.guid));
    }
    return columnsData[columnName];
}

function extractComponentCategories(componentTypes: ComponentType[]) {
    return distinct(componentTypes.filter((ct) => ct.category).map((ct) => ct.category), pickGuid);
}

interface ComponentModelTableProps {
    filterColumnsOptions: ColumnsData;
    filterColumnProps<K extends keyof ColumnsData>(columnName: K): FilterColumnProps<K>;
    clear(): void;
}

type ComponentModelTableFiltersEventHandler = (newFilter: ComponentModelTableFilters) => void;

export function useComponentModelTable(
    filterState: ComponentModelTableFilters,
    onFilterChange: ComponentModelTableFiltersEventHandler,
): ComponentModelTableProps {
    const [columnsData, setColumnsData] = React.useState<ColumnsData>({
        names: [],
        componentTypes: [],
        componentCategories: [],
        companies: [],
    });

    React.useEffect(() => {
        Promise
            .all([
                getComponentModelsNames(),
                getComponentModelsComponentTypes(),
                getComponentModelsCompanies(),
            ])
            .then(([{list: names}, {list: componentTypes}, {list: companies}]: [JSONList<string>, JSONList<ComponentType>, JSONList<Company>]) => {
                setColumnsData({
                    names,
                    componentTypes,
                    componentCategories: extractComponentCategories(componentTypes),
                    companies,
                });
            });
    }, []);

    React.useEffect(() => {
        if (columnsData.names.length === 0) {
            return;
        }
        Promise
            .all([
                getComponentModelsComponentTypes({
                    componentModelsNames: filterState.names.selected,
                    companiesGuids: filterState.companiesGuids.selected,
                }),
                getComponentModelsCompanies({
                    componentModelsNames: filterState.names.selected,
                    componentsTypeGuids: filterState.componentTypesGuids.selected,
                }),
            ])
            .then(([{list: componentTypes}, {list: companies}]: [JSONList<ComponentType>, JSONList<Company>]) => {
                setColumnsData((prevState) => ({
                    ...prevState,
                    componentTypes,
                    componentCategories: extractComponentCategories(componentTypes),
                    companies,
                }));
            });
    }, [filterState.names.selected]);

    React.useEffect(() => {
        if (columnsData.componentCategories.length === 0) {
            return;
        }

        const componentTypesGuidsFromComponentCategories = columnsData
            .componentTypes
            .filter((ct) => ct.category && filterState.componentCategoriesGuids.selected.includes(ct.category.guid))
            .map((ct) => ct.guid);

        Promise
            .all([
                getComponentModelsNames({
                    componentsTypeGuids: componentTypesGuidsFromComponentCategories,
                    companiesGuids: filterState.companiesGuids.selected,
                }),
                getComponentModelsCompanies({
                    componentsTypeGuids: componentTypesGuidsFromComponentCategories,
                    componentModelsNames: filterState.names.selected,
                }),
            ])
            .then(([{list: names}, {list: companies}]: [JSONList<string>, JSONList<Company>]) => {
                setColumnsData((prevState) => ({...prevState, names, companies}));
            });
    }, [filterState.componentCategoriesGuids.selected]);

    React.useEffect(() => {
        if (columnsData.componentTypes.length === 0) {
            return;
        }
        Promise
            .all([
                getComponentModelsNames({
                    componentsTypeGuids: filterState.componentTypesGuids.selected,
                    companiesGuids: filterState.companiesGuids.selected,
                }),
                getComponentModelsCompanies({
                    componentsTypeGuids: filterState.componentTypesGuids.selected,
                    componentModelsNames: filterState.names.selected,
                }),
            ])
            .then(([{list: names}, {list: companies}]: [JSONList<string>, JSONList<Company>]) => {
                setColumnsData((prevState) => ({...prevState, names, companies}));
            });
    }, [filterState.componentTypesGuids.selected]);

    React.useEffect(() => {
        if (columnsData.companies.length === 0) {
            return;
        }
        Promise
            .all([
                getComponentModelsNames({
                    companiesGuids: filterState.companiesGuids.selected,
                    componentsTypeGuids: filterState.componentTypesGuids.selected,
                }),
                getComponentModelsComponentTypes({
                    companiesGuids: filterState.companiesGuids.selected,
                    componentModelsNames: filterState.names.selected,
                }),
            ])
            .then(([{list: names}, {list: componentTypes}]: [JSONList<string>, JSONList<ComponentType>]) => {
                setColumnsData((prevState) => ({...prevState, names, componentTypes}));
            });
    }, [filterState.companiesGuids.selected]);

    return {
        filterColumnsOptions: columnsData,
        filterColumnProps(columnName) {
            const filterName = getFilterName(columnName);
            return {
                options: getColumnOptions(columnName, columnsData, filterState) as ColumnDataType<typeof columnName>,
                value: filterState[filterName],
                onChange(value) {
                    if (value === filterState[filterName]) {
                        return;
                    }
                    onFilterChange({
                        ...filterState,
                        [filterName]: value,
                    });
                },
            };
        },
        clear() {
            onFilterChange({
                names: {
                    selected: [],
                    sortDirection: "none",
                },
                componentCategoriesGuids: {
                    selected: [],
                    sortDirection: "none",
                },
                componentTypesGuids: {
                    selected: [],
                    sortDirection: "none",
                },
                companiesGuids: {
                    selected: [],
                    sortDirection: "none",
                },
            });
        },
    };
}

interface ChangeMangerProps {
    onUpdate?(updatedComponentModel: ComponentModel[]): void;
}

interface ComponentModelChangesManager {
    selected: StatefulSet<string>;
    changes: StatefulMap<string, Partial<ComponentModel>>;
    register(componentModel: ComponentModel): ComponentModelRowProps;
    save(): void;
    discard(): void;
}

export function useComponentModelChangesManager(params?: ChangeMangerProps): ComponentModelChangesManager {

    const changes = useMap<string, Partial<ComponentModel>>();
    const selected = useSet<string>();

    return {
        changes,
        selected,
        register(componentModel: ComponentModel): ComponentModelRowProps {
            return {
                selected: selected.has(componentModel.guid),
                state(paramName) {
                    const requestedValue = changes.get(componentModel.guid, (cm) => cm[paramName]);
                    return requestedValue === undefined ? "default" :
                        requestedValue !== componentModel[paramName] ? "edited"
                            : "default";
                },
                value(paramName): any {
                    return changes.get(componentModel.guid, (cm) => cm[paramName]) ?? componentModel[paramName];
                },
                change(paramName) {
                    return (newValue: unknown) => {
                        changes.from((prevEntries) => {
                            const prevRequest = prevEntries.get(componentModel.guid);
                            if (newValue === componentModel[paramName] && prevEntries.has(componentModel.guid)) {
                                prevEntries.set(componentModel.guid, omit(prevRequest, paramName));
                            } else {
                                prevEntries.set(componentModel.guid, {
                                    ...prevRequest,
                                    [paramName]: newValue,
                                } as Partial<ComponentModel>);
                            }

                            if (isEmptyObject(prevEntries.get(componentModel.guid))) {
                                prevEntries.delete(componentModel.guid);
                            }

                            return prevEntries;
                        });
                    };
                },
                toggleSelection() {
                    if (selected.has(componentModel.guid)) {
                        selected.delete(componentModel.guid);
                    } else {
                        selected.add(componentModel.guid);
                    }
                }
            };
        },
        save() {
            Promise.all(
                changes.toArray((guid, change) => updateComponentModel({guid}, change)),
            ).then((componentModels) => {
                changes.clear();
                params?.onUpdate?.(componentModels);
            });

        },
        discard() {
            changes.clear();
            selected.clear();
        },
    };
}
