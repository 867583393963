import {AlarmType} from "../../models/alarm/alarmType";
import {Company, CompanyComponentModel} from "../../models/company/companyModel";
import {ComponentBrand} from "../../models/component/componentBrand";
import {ComponentModel, ComponentModelAlarmType} from "../../models/component/componentModel";
import {CompanyComponentType, ComponentType, ComponentTypeDiagnosticType} from "../../models/component/componentType";
import {ComponentModelDiagnosticType} from "../../models/diagnostic/componentModelDiagnosticType";
import {DiagnosticType} from "../../models/diagnostic/diagnosticType";
import {Media} from "../../models/media/mediaModel";
import {Property, PropertyConfiguration} from "../../models/property/property";
import {PickGuid} from "../../models/types";
import {JSONEntitiesCollection, JSONList} from "../../models/utils/jsonList";
import {toJSONPatch} from "../../utils/json";
import {flatten} from "../../utils/objects";
import {assertOk, toRequestParamsString} from "../../utils/requests";
import {Translation} from "../../models/translations/translationsModels";

type ComponentModelFilter = {
    variant: "FULL",
    limit: number,
    offset: number,
    sortBy?: "NAME" | "CREATION_DATE",
    sortDirection?: "ASC" | "DESC",
    names?: string[],
    companiesGuids?: string[],
    componentsTypeGuids?: string[],
    alarmsTypeGuids?: string[],
    diagnosticsTypeGuids?: string[],
} | {};

export async function getComponentModels(
    filter: ComponentModelFilter,
): Promise<JSONEntitiesCollection<ComponentModel>> {
    const params = toRequestParamsString(filter);
    const response = await fetch(`/componentModels?${params}`);
    assertOk(response);
    return response.json();
}

export async function getComponentModelCompanies(
    componentModel: PickGuid<ComponentModel>,
): Promise<JSONList<CompanyComponentModel>> {
    const response = await fetch(`/componentModels/${componentModel.guid}/companies`);
    assertOk(response);
    return response.json();
}

export async function getAllComponentBrands(): Promise<JSONList<ComponentBrand>> {
    const response = await fetch("/componentModels/brands");
    assertOk(response);
    return response.json();
}

export async function getAllComponentsModelMedia(): Promise<JSONList<Media>> {
    const response = await fetch("/componentModels/media");
    assertOk(response);
    return response.json();
}

export async function getComponentModelDiagnosticsTypes(
    {guid}: PickGuid<ComponentModel>,
): Promise<JSONList<ComponentModelDiagnosticType>> {
    const response = await fetch(`/componentModels/${guid}/diagnosticTypes`);
    assertOk(response);
    return response.json();
}

export async function addComponentModelToCompanies(
    componentModel: PickGuid<ComponentModel>,
    componentBrand: PickGuid<ComponentBrand> | null,
    companies: Array<PickGuid<Company>>,
): Promise<JSONList<CompanyComponentModel>> {
    const response = await fetch(
        componentBrand == null
            ? `/componentModels/${componentModel.guid}/companies/unbranded`
            : `/componentModels/${componentModel.guid}/companies/componentBrands/${componentBrand.guid}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({list: companies.map((cm) => cm.guid)}),
        },
    );
    assertOk(response);
    return response.json();
}

export async function removeComponentModelFromCompanies(
    componentModel: PickGuid<ComponentModel>,
    componentBrand: PickGuid<ComponentBrand> | null,
    companies: Array<PickGuid<Company>>,
): Promise<void> {
    const response = await fetch(
        componentBrand == null
        ? `/componentModels/${componentModel.guid}/companies/unbranded`
        : `/componentModels/${componentModel.guid}/companies/componentBrands/${componentBrand.guid}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                list: companies.map((cm) => cm.guid),
            }),
        },
    );
    assertOk(response);
}

export async function addComponentTypeToCompanies(
    componentType: PickGuid<ComponentType>,
    companies: Array<PickGuid<Company>>,
): Promise<JSONList<CompanyComponentType>> {
    const response = await fetch(
        `/componentTypes/${componentType.guid}/companies`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                list: companies.map((cm) => cm.guid),
            }),
        },
    );
    assertOk(response);
    return response.json();
}

export async function updateCompanyComponentModelValues(
    companyComponentModel: PickGuid<CompanyComponentModel>,
    params: Partial<CompanyComponentModel>,
): Promise<CompanyComponentModel> {
    const response = await fetch(
        `/componentModels/companies/${companyComponentModel.guid}`,
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(toJSONPatch(flatten(params, "/"))),
        },
    );

    assertOk(response);
    return response.json();
}

export async function createNewComponentBrand(description: string): Promise<ComponentBrand> {
    const response = await fetch(
        `/componentModels/brands`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ description }),
        },
    );
    assertOk(response);
    return response.json();
}

export async function getComponentTypeDiagnosticsTypes(
    componentType: PickGuid<ComponentType>,
): Promise<JSONList<ComponentTypeDiagnosticType>> {
    const response = await fetch(`/componentTypes/${componentType.guid}/diagnosticTypes?variant=ComponentTypeDiagnosticType`);
    assertOk(response);
    return response.json();
}

export async function getComponentModelProperties(): Promise<JSONList<Property>> {
    const response = await fetch("/componentModels/properties");
    assertOk(response);
    return  response.json();
}

export async function addAlarmsTypesToComponentModel(
    alarmsTypes: Array<PickGuid<AlarmType>>,
    componentModel: PickGuid<ComponentModel>,
): Promise<JSONList<ComponentModelAlarmType>>  {
    const response = await fetch(
        `/componentModels/${componentModel.guid}/alarmTypes`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ list: alarmsTypes.map(({guid}) => guid)}),
        },
    );
    assertOk(response);
    return response.json();
}

export async function removeAlarmsTypesFromComponentModel(
    alarmsTypes: Array<PickGuid<AlarmType>>,
    componentModel: PickGuid<ComponentModel>,
): Promise<void>  {
    const response = await fetch(
        `/componentModels/${componentModel.guid}/alarmTypes`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ list: alarmsTypes.map(({guid}) => guid)}),
        },
    );
    assertOk(response);
}

interface ComponentModelsNamesRequestParams {
    componentsTypeGuids?: string[];
    companiesGuids?: string[];
}

export async function getComponentModelsNames(params?: ComponentModelsNamesRequestParams): Promise<JSONList<string>> {
    const requestString = toRequestParamsString(params);
    const response = await fetch(`/componentModels/name?${requestString}`);
    assertOk(response);
    return response.json();
}

interface ComponentModelsComponentTypesRequestParams {
    componentModelsNames?: string[];
    companiesGuids?: string[];
}

export async function getComponentModelsComponentTypes(
    params?: ComponentModelsComponentTypesRequestParams,
): Promise<JSONList<ComponentType>> {
    const requestString = toRequestParamsString(params);
    const response = await fetch(`/componentModels/componentType?${requestString}`);
    assertOk(response);
    return response.json();
}

interface ComponentModelsCompaniesRequestParams {
    componentModelsNames?: string[];
    componentsTypeGuids?: string[];
}

export async function getComponentModelsCompanies(
    params?: ComponentModelsCompaniesRequestParams,
): Promise<JSONList<Company>> {
    const requestString = toRequestParamsString(params);
    const response = await fetch(`/componentModels/companies?${requestString}`);
    assertOk(response);
    return response.json();
}

export async function updateComponentModel(
    componentModel: PickGuid<ComponentModel>,
    updatedComponentModel: Partial<ComponentModel>,
): Promise<ComponentModel> {
    const jsonPatch = toJSONPatch(updatedComponentModel);
    const response = await fetch(
        `/componentModels/${componentModel.guid}`,
        {
            method: "PATCH",
            body: JSON.stringify(jsonPatch),
            headers: {
                "Content-Type": "application/json",
            },
        },
    );
    assertOk(response);
    return response.json();
}

export async function getManufacturers(): Promise<Record<"translations", Translation[]>> {
    const response = await fetch("/componentModels/manufacturers");
    assertOk(response);
    return response.json();
}

export async function updateComponentModelPropertyValue(
    componentModel: PickGuid<ComponentModel>,
    property: PickGuid<Property>,
    value: unknown,
): Promise<PropertyConfiguration> {
    const response = await fetch(
        `/componentModels/${componentModel.guid}/properties/${property.guid}`,
        {
            method: "PATCH",
            body: JSON.stringify(toJSONPatch({value})),
        },
    );
    assertOk(response);
    return response.json();
}

export async function getComponentModelsProperties(
    componentModel: PickGuid<ComponentModel>,
): Promise<JSONList<PropertyConfiguration>> {
    const response = await fetch(`/componentModels/${componentModel.guid}/properties`);
    assertOk(response);
    return response.json();
}

export async function getComponentModelsAlarmsTypes(
    componentModel: PickGuid<ComponentModel>,
): Promise<JSONList<AlarmType>> {
    const response = await fetch(`/componentModels/${componentModel.guid}/alarmTypes`);
    assertOk(response);
    return response.json();
}

type ValidForUpdate = "deviceRequired" | "availableOnCloud" | "energyConsumptionAlwaysOn" | "energyConsumption";

export async function updateComponentModelDiagnosticTypes(
    componentModel: PickGuid<ComponentModel>,
    diagnosticType: PickGuid<DiagnosticType>,
    params: Partial<Pick<ComponentModelDiagnosticType, ValidForUpdate>>
) {
    const response = await fetch(
        `/componentModels/${componentModel.guid}/diagnosticTypes/${diagnosticType.guid}`,
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(toJSONPatch(flatten(params, "/"))),
        }
    );
    assertOk(response);
    return response.json();
}

export async function deleteComponentModel(componentModel: PickGuid<ComponentModel>): Promise<void> {
    const response = await fetch(
        `/componentModels/${componentModel.guid}`,
        {
            method: "DELETE",
        }
    );
    assertOk(response);
}