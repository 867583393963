import * as React from "react";
import {ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX} from "../../../../hooks/useOverlay";
import {putAll} from "../../../../utils/maps";
import {clamp} from "../../../../utils/numbers";
import {After, AfterProps} from "../../After";
import {Overlay} from "../../Overlay";
import {HTMLElementValue} from "../../types";
import {OptionProps} from "../Option/types";
import {isOption, registerOptions} from "../Option/utils";

export function getSelectChildren(children: React.ReactNode, value: HTMLElementValue) {
    const options = new Map<HTMLElementValue, React.ReactElement<OptionProps>>();

    let providedMenu: React.ReactNode = null;
    let afterElement: React.ReactNode = null;
    const nonOptions = [];

    const registeredOptions = React.Children.map(children, (child) => {
        if (isOption(child)) {
            options.set(child.props.value, child);
            return React.cloneElement(child, {selected: child.props.value === value});
        } else if (isMenu(child) && providedMenu === null) {
            const [menuRegisteredOptions, menuOptions] = registerOptions(value, child.props.children);
            putAll(options, menuOptions);
            providedMenu = (
                <Overlay
                    {...child.props}
                    asTag={"menu"}
                    positioned={ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX}
                    className={`pseudo-select-dropdown-menu ${child.props.className ?? ""}`}
                >
                    {menuRegisteredOptions}
                </Overlay>
            );
        } else if (isAfter(child)) {
            afterElement = React.cloneElement(
                child,
                {
                    className: `pseudo-select-after-element ${child.props.className ?? ""}`,
                },
            );
        } else {
            nonOptions.push(child);
        }
        return null;
    });

    const values = Array.from(options).filter(([, o]) => !o.props.disabled).map(([key]) => key);

    const htmlOptions = values.map((optionValue) => {
        return <option key={`${optionValue}`} value={optionValue}/>;
    });

    return {
        values,
        htmlOptions,
        valueIndex: clamp(0, values.length - 1, values.indexOf(value)),
        options,
        registeredOptions,
        nonOptions: (
            <>
                {nonOptions}
            </>
        ),
        afterElement,
        providedMenu,
    };
}

function isMenu(element: unknown): element is React.ReactElement<React.HTMLProps<HTMLMenuElement>> {
    return React.isValidElement(element) && element.type === "menu";
}

function isAfter(element: unknown): element is React.ReactElement<AfterProps> {
    return React.isValidElement(element) && element.type === After;
}
