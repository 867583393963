import {flatMap} from "./arrays";

export function isEmptyObject(o: object | undefined | null) {
    if (o == null) {
        return true;
    }
    return Object.keys(o).length === 0;
}

export function objectKeys<O extends object>(o: O): Array<keyof O> {
    return Object.keys(o) as Array<keyof O>;
}

export function omit<O extends object>(o: O, predicate: <K extends keyof O>(key: K, value: O[K]) => boolean): O;
export function omit<O extends object, P extends Array<keyof O> = []>(o: O, ...exclude: P): Omit<O, P[number]>;
export function omit<O extends object, P extends Array<keyof O> = []>(o: O,  predicatedOrStr, ...exclude: P) {
    return objectKeys(o)
        .filter((key) => {
            if (typeof predicatedOrStr === "string") {
                return !exclude.includes(key) && (key !== predicatedOrStr);
            }
            return !predicatedOrStr(key, o[key]);
        })
        .reduce<Omit<O, P[number]>>((newObject, key) => ({
            ...newObject,
            [key]: o[key],
        }), {} as any);
}

interface KV {
    key: string;
    value: unknown;
}

function flattenEntries(o: object, separator: string = "."): KV[] {
    return flatMap(
        Object.entries(o),
        ([key, value]) => {
            if (typeof value !== "object" || value == null) {
                return { key, value };
            }
            return flattenEntries(value, separator)
                .map<KV>(({key: flattenKey, value: primitiveValue}) => {
                    return {key: `${key}${separator}${flattenKey}`, value: primitiveValue};
                });
        },
    );
}

// Todo  make typesafe
type FlattenObject<O extends object, S extends string> = O;

export function flatten<O extends object>(o: O): FlattenObject<O, ".">;
export function flatten<O extends object, S extends string>(o: O, separator: S): FlattenObject<O, S>;
export function flatten<O extends object>(o: O, separator = "."): FlattenObject<O, typeof separator> {
    return flattenEntries(o, separator)
        .reduce< FlattenObject<O, typeof separator>>((newObject, {key, value}) => {
            return {
                ...newObject,
                [key]: value,
            };
        }, {} as any);
}

export function unflatten<O extends object>(o: Record<string, unknown>, separator = "."): O {
    return Object.entries(o).reduce(( obj, [path, value]) => {
        path.split(separator).reduce(( tmpObj, name, i, array) => {
            if (i === array.length - 1) {
                tmpObj[name] = value;
            } else {
                tmpObj[name] = {...tmpObj[name]};
            }
            return tmpObj[name];
        }, obj);
        return obj;
    }, {}) as O;
}

export function pick<O extends object, V>(o: O, path: string[]): V {
    return path.reduce((obj, fieldName) => {
        return obj != null && fieldName in obj ?  obj[fieldName] : undefined;
    }, o);
}

export function objectValuePathExists(o: object, path: string[]): boolean {
    return path.reduce<[boolean, object]>(([previousFound, obj], fieldName) => {
        if (!previousFound || ( obj != null &&  !(fieldName in obj))) {
            return [false, obj];
        }
        const valid = obj != null && fieldName in obj;
        return [ valid, valid ? obj[fieldName] : undefined]
    }, [true, o])[0];
}

export function update<O extends object, V>(o: O, path: string[], newValue: unknown): O {
    const newObject = structuredClone(o);
    path.reduce((tmpObj, name, i, array) => {
        if (i === array.length - 1) {
            tmpObj[name] = newValue;
        } else {
            tmpObj[name] = {...tmpObj[name]};
        }
        return  tmpObj[name];
    }, newObject);
    return newObject;
}


type ObjectValueMapper<O> = <K extends keyof O>(key: K, value: O[K]) => unknown;

export function objectValueMap<O extends object>(o: O, mapping: ObjectValueMapper<O>): object {
    return Object
        .entries(o)
        .map<[string, unknown]>(([key, value]) => [key, mapping(key as keyof O, value)])
        .reduce((newObject, [key, mappedValue]) => ({
            ...newObject,
            [key]: mappedValue,
        }), {} as any);
}

export function pickGuid<O extends {guid: string}>(o: O): string {
    return o.guid;
}

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}


type MergeDeepOptions = {
    arrayMerge: "concat" | "keep-left" | "keep-right"
};

export function mergeDeep(objects: any[], options?: MergeDeepOptions) {
    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach(key => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] =  options?.arrayMerge === "keep-left" ? pVal
                    : options?.arrayMerge === "keep-right" ? oVal
                    : pVal.concat(...oVal);
            } else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep([pVal, oVal]);
            } else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}