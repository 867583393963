import {clamp} from "./numbers";

export function inside<T>(list: T[], index: number): T {
    return list[clamp(0, list.length - 1, index)];
}

type MappingFn<T, U> = (value: T, index: number, array: T[]) => U;

export function map<U, T = unknown>(callback: MappingFn<T, U>): (list: T[]) => U[];
export function map<U, T = unknown>(list: T[], callback: MappingFn<T, U>): U[];

export function map<U, T>(listOrCallback: T[] | MappingFn<T, U>, callback?: MappingFn<T, U>) {
    if (typeof listOrCallback === "function") {
        return (list: T[]) => {
            return list.map(listOrCallback);
        };
    }
    return listOrCallback.map(callback);
}

export function flatMap<U, T = unknown>(callback: MappingFn<T, U | U[]>): (list: T[]) => U[];
export function flatMap<U, T = unknown>(list: T[], callback: MappingFn<T, U | U[]>): U[];
export function flatMap<U, T>(listOrCallback: T[] | MappingFn<T, U | U[]>, callback?: MappingFn<T, U | U[]>) {
    if (typeof listOrCallback === "function") {
        return (list: T[]) => {
            return list
                .map(listOrCallback)
                .reduce<U[]>((acc, item) => {
                    if (item instanceof Array) {
                        return acc.concat(...item);
                    }
                    return acc.concat(item);
                }, []);
        };
    }
    return listOrCallback
        .map(callback)
        .reduce<U[]>((acc, item) => {
            if (item instanceof Array) {
                return acc.concat(...item);
            }
            return acc.concat(item);
        }, []);
}

export function findLast<T>(list: T[], predicate: (value: T, index: number, obj: T[]) => unknown) {
    return list.reduceRight((result, value, ...rest) => {
        if (result === undefined && predicate(value, ...rest)) {
            return value;
        }
        return result;
    }, undefined);
}

export function concat<T>(...items: T[]) {
    return (list: T[] = []) => {
        return list.concat(...items);
    };
}

export function concatTo<T>(list: T[]) {
    return (otherList: T[] = []) => {
        return list.concat(...otherList);
    };
}

export function distinct<T>(list: T[]): T[];
export function distinct<T, K>(list: T[], keyMapper: (item: T) => K): T[];
export function distinct<T, K>(list: T[], keyMapper?: (item: T) => K): T[] {
    if (typeof keyMapper === "undefined") {
        return Array.from(new Set(list));
    }
    const mapOfItems = new Map(list.map((item) => [keyMapper(item), item]));
    return Array.from(mapOfItems.values());
}

export function byEntityName<E extends { name: string }>(a: E, b: E) {
    return a.name.localeCompare(b.name);
}
