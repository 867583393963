import * as React from "react";

interface SearchResultViewProps {
    title: string;
    subTitle: string;
}

export function SearchResultView(
    {
        title,
        subTitle,
        children,
        ...rest
    }: React.PropsWithChildren<SearchResultViewProps & React.LiHTMLAttributes<HTMLLIElement>>,
) {
    return (
        <li {...rest} className={`search-result ${rest.className ?? ""}`}>
            <span>
                <h6 className={"search-result-title"}>{title}</h6>
                <small>{subTitle}</small>
            </span>
            {children}
        </li>
    );
}
