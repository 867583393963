import {AlarmTypeCustomSet, AlarmTypeSet, AlarmTypeSeverityList} from "../../models/alarm/alarmType";

class AlarmController {
    public async findAlarmTypeSeverities(): Promise<AlarmTypeSeverityList> {
        const response = await fetch("/alarmTypes/severities");
        return response.json();
    }

    public async findAlarmTypes(categoryGuid: string): Promise<AlarmTypeSet> {
        const response = await fetch("/alarmCategories" + categoryGuid + "/alarmTypes");
        return response.json();
    }

    public async findGenericAlarmTypes(): Promise<AlarmTypeSet> {
        const response = await fetch("/alarmTypes/generic");
        return response.json();
    }

    public async findAlarmTypesWithChildren(guids: string[]): Promise<AlarmTypeCustomSet> {
        let link: string = "/alarmTypes/tree?";
        guids.map((e) => link = link.concat("alarmTypesGuids=" + e + "&"));
        const response = await fetch(link.slice(0, -1));
        return response.json();
    }
}

export default AlarmController;
