class MovementController {

    public async validateDevices(deviceCodes: string[], deviceIdentifierType: string): Promise<any> {
        const url = "/movements/validate/devices";

        const init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({deviceCodes, deviceIdentifierType}),
        };

        const response = await fetch(url, init);
        return response;
    }

    public async validatePackages(packagesCodes: string[]): Promise<any> {
        const url = "/movements/validate/packages";

        const init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                packagesCodes,
            }),
        };

        const response = await fetch(url, init);
        return response;
    }

    public async validateEquipment(csvFile) {
        const url = "/movements/validate/equipment";
        const formData = new FormData();
        formData.append("csvEquipment", csvFile);
        const init = {
            method: "POST",
            encoding: "utf-8",
            body: formData,
        };

        const response = await fetch(url, init);
        return response;
    }

    public async moveDevices(
        destinationCompanyGuid: string,
        destinationCompanyLocationCode: string,
        deviceCodes: string[],
        shipmentDate: Date,
        deviceOnly: boolean,
    ): Promise<any> {
        const url = "/movements/devices";

        const init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                destinationCompanyGuid,
                destinationCompanyLocationCode,
                deviceCodes,
                shipmentDate,
                deviceOnly,
            }),
        };

        return fetch(url, init);
    }

    public async movePackages(
        destinationCompanyGuid: string,
        destinationCompanyLocationCode: string,
        packageCodes: string[],
        shipmentDate: Date,
    ): Promise<any> {
        const url = "/movements/packages";

        const init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                destinationCompanyGuid,
                destinationCompanyLocationCode,
                packageCodes,
                shipmentDate,
            }),
        };

        return fetch(url, init);
    }

    public async installAndMoveDevices(
        csvFile: File,
        destinationCompanyGuid: string,
        destinationCompanyLocationCode: string,
        shipmentDate: Date,
    ): Promise<any> {
        const url = "/movements/equipment";
        const formData = new FormData();
        formData.append("csvEquipment", csvFile);
        formData.append("data", JSON.stringify({
            destinationCompanyGuid,
            destinationCompanyLocationCode,
            shipmentDate,
        }));
        const init = {
            method: "POST",
            body: formData,
        };

        return fetch(url, init);
    }
}

export default MovementController;
