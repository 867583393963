import {SetURLSearchParams} from "react-router-dom";
import * as React from "react";
import {Button} from "../../../../common/Button";

type SortButtonProps = {
    propertyName: string;
    order: "none" | "asc" | "desc";
    onChange: SetURLSearchParams;
};

export function SortButton({propertyName, order, onChange, children}: React.PropsWithChildren<SortButtonProps>) {
    function updateOrder() {
        onChange((prev) => {
            const otherSortedParams = prev.getAll("sort").filter((str) => !str.startsWith(propertyName));
            prev.delete("sort");
            otherSortedParams.forEach((value) => prev.append("sort", value));
            if (order !== "none") {
                prev.append("sort", `${propertyName},${order}`);
            }
            return prev;
        });
    }


    return (
        <Button onClick={updateOrder}>{children}</Button>
    );
}