export function clamp(min: number, max: number, val: number) {
    return Math.max(min, Math.min(val, max));
}

export function mod(x: number, base: number) {
    return (x % base + base) % base;
}

export function range(count: number): number[];
export function range(from: number, to: number): number[];
export function range(from: number, to?: number): number [] {
    if (to != null) {
        return Array.from({length: Math.abs(to - from)}, (_, i) => i + from)
    }
    return Array.from({length: from}, (_, i) => i)
}

export function numberOrElse(x: unknown, defaultValue = 0): number {
    return !isNaN(x as any) ? Number(x) : defaultValue;
}