import {Breadcrumb, BreadcrumbItem, LoadablePage} from "./page";
import {CreateUsersPage, InstallAndMoveDevicesPage, InstallDevicesPage, MoveDevicesPage} from "./task";

declare var main: () => void;

$(window).on("load", (): void => {
    $("#installAndMoveDevices").on("click", () => {
        new InstallAndMoveDevicesPage();
    });
    $("#installDevices").on("click", () => {
        new InstallDevicesPage();
    });
    $("#moveDevices").on("click", () => {
        new MoveDevicesPage();
    });
    $("#createUsers").on("click", () => {
        new CreateUsersPage();
    });
    $("#wCreateUser").on("click", () => {
        new CreateUserPage();
    });
    $("#wInsertReportModel").on("click", () => {
        new InsertReportPage();
    });
    $("#wGeoAlarm").on("click", () => {
        new GeoAlarmPage();
    });
    $("#wGeolocation").on("click", () => {
        new GeolocationStatisticsPage();
    });
    $("#wImagesUpload").on("click", () => {
        new ImagesUploadPage();
    });
    loadPageContent();
});

function loadPageContent() {
    switch (location.hash) {
        case "#/insertReportModelPage":
            new InsertReportPage();
            break;
        case "#/createUserPage":
            new CreateUserPage();
            break;
        case "#/geoAlarmPage":
            new GeolocationStatisticsPage();
            break;
        case "#/geolocationStatisticsPage":
            new GeolocationStatisticsPage();
            break;
        case "#/insertImagesPage":
            new ImagesUploadPage();
            break;
        case "#/installAndMoveDevicesPage":
            new InstallAndMoveDevicesPage();
            break;
        case "#/installDevicesPage":
            new InstallDevicesPage();
            break;
        case "#/moveDevicesPage":
            new MoveDevicesPage();
            break;
        case "#/createUsersPage":
            new CreateUsersPage();
            break;
        default:
            break;
    }
}

class UserCreationForm {
    constructor() {
        this.bindSubmitButton();
    }

    private bindSubmitButton(): void {
        $("#createUserButton").one("click", () => {
            this.submit();
        });
    }

    private getData(): UserCreationData {
        const data = new UserCreationData();
        data.username = $("#username").val() as string;
        data.name = $("#name").val() as string;
        data.surname = $("#surname").val() as string;
        data.email = $("#emailInput").val() as string;
        data.sendEmail = $("#sendWMail:checkbox:checked").length > 0;
        data.company = $("#companyInput").val() as string;
        data.locale = $("#locales").val() as string;
        data.timezone = $("#timezones").val() as string;
        $(".rolesCheckboxes:checkbox:checked").each(function () {
            data.roles.push($(this).val() as string);
        });
        return data;
    }

    private submit(): void {
        const data: UserCreationData = this.getData();
        $.ajax({
            url: "users",
            type: "POST",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            success: (response) => {
                alert("User has been created");
                this.bindSubmitButton();
            },
            error: (xhr, status, error) => {
                console.log(xhr.responseText);
                alert(xhr.responseText);
                this.bindSubmitButton();
            },
        });
    }
}

class UserCreationData {
    public username: string = null;
    public name: string = null;
    public surname: string = null;
    public email: string = null;
    public company: string = null;
    public locale: string = null;
    public timezone: string = null;
    public roles: string[] = [];
    public sendEmail: boolean = null;
}

class CreateUserPage extends LoadablePage {
    constructor() {
        super("createUserPage", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Web operations", false),
            new BreadcrumbItem(null, "Create user", true),
        ]));
    }

    protected onLoadSuccess(): void {
        new UserCreationForm();
    }
}

class InsertReportPage extends LoadablePage {
    constructor() {
        super("insertReportModelPage", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Web operations", false),
            new BreadcrumbItem(null, "Insert report model", true),
        ]));
        this.onLoadSuccess();
    }

    protected onLoadSuccess(): void {
        new InsertReportModelForm();
    }
}

class GeoAlarmPage extends LoadablePage {
    constructor() {
        super("geoAlarmPage", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Web operations", false),
            new BreadcrumbItem(null, "Geo Alarm promotion", true),
        ]));
    }

    protected onLoadSuccess(): void {
        main();
    }
}

class ImagesUploadPage extends LoadablePage {
    constructor() {
        super("insertImagesPage", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Web operations", false),
            new BreadcrumbItem(null, "Upload images", true),
        ]));
    }

    protected onLoadSuccess() {
        this.bindSubmitButton();
    }

    private bindSubmitButton = () => {
        $("#uploadModelImage").on("click", () => {
            this.submitImage();
        });
    }

    private submitImage = (): void => {
        // Submit Image
        const dataForm = new FormData();
        dataForm.append("imageFile", $("#imageFile").prop("files")[0]);
        $.ajax({
            url: "media/photo",
            type: "POST",
            cache: false,
            contentType: false,
            data: dataForm,
            processData: false,
            success: (data) => {
                alert("Image has been uploaded");
                new ImagesUploadPage();
            },
            error: (xhr, status, error) => {
                console.log(xhr.responseText);
                alert("Error: " + xhr.responseText);
                this.bindSubmitButton();
            },
        });
    }
}

class ReportModelData {
    public modelFile: any;
    public previewFile: any;
    public existingModelOption: string;
    public modelGuid: string;
    public modelName: string;
}

class InsertReportModelForm {
    constructor() {
        this.bindSubmitButton();
    }

    private bindSubmitButton(): void {
        $("#insertReportModelButton").one("click", () => {
            this.submit();
        });
    }

    private getData(): ReportModelData {
        const result: ReportModelData = new ReportModelData();
        result.modelFile = $("#modelFile").prop("files")[0];
        result.previewFile = $("#modelFilePreview").prop("files")[0];
        result.existingModelOption = $("#reportModelsSelect").val() as string;
        result.modelGuid = $("#modelUUID").val() as string;
        result.modelName = $("#modelName").val() as string;
        return result;
    }

    private submit(): void {
        const data: ReportModelData = this.getData();
        const dataForm = new FormData();
        dataForm.append("modelFile", data.modelFile);
        dataForm.append("previewFile", data.previewFile);
        dataForm.append("existingModelId", data.existingModelOption);
        if (data.modelGuid != null) {
            dataForm.append("modelUUID", data.modelGuid);
        }
        dataForm.append("modelName", data.modelName);
        $.ajax({
            url: "report-model",
            type: "POST",
            cache: false,
            contentType: false,
            data: dataForm,
            processData: false,
            success: (response) => {
                alert("Report model has been inserted");
                new InsertReportPage();
            },
            error: (xhr, status, error) => {
                console.log(xhr.responseText);
                alert("Error: " + xhr.responseText);
                this.bindSubmitButton();
            },
        });
    }
}

interface GeoStatResponse {
    list: GeoStat[];
    period: GeoStatPeriod;
}

interface GeoStatPeriod {
    startDate: string;
    endDate: string;
    timeGranularity: string;
}

interface GeoStat {
    key: string;
    values: GeoStatValue[];
}

interface GeoStatValue {
    accuracy: number;
    start: string;
    end: string;
    value: number;
}

const last = <T>(l: T[]): T => l[l.length - 1];

class GeolocationStatisticsPage extends LoadablePage {

    public static headingZero(n: number): string {
        return n < 10 ? "0" + n : "" + n;
    }

    private static formatDate(date: Date): string {
        if (date != null) {
            return `${date.getFullYear()}-${
                GeolocationStatisticsPage.headingZero(date.getMonth() + 1)
            }-${
                GeolocationStatisticsPage.headingZero(date.getDate())
            }`;
        }
        return "";
    }

    private static formatNumber(x: number) {
        if (x % 1 > 0) {
            return x.toFixed(2);
        }
        return x;
    }

    constructor() {
        super("geolocationStatisticsPage", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Web operations", false),
            new BreadcrumbItem(null, "Geolocation Statistics", true),
        ]));
    }

    protected onLoadSuccess(): void {
        // Retrieve Statistic & build table

        const granularity = "DAILY";

        const today = new Date();
        const week = 1000 * 60 * 60 * 24 * 7;
        const lastWeek = new Date(today.getTime() - week);

        $("#statisticsStartDate").val(GeolocationStatisticsPage.formatDate(lastWeek));
        $("#statisticsEndDate").val(GeolocationStatisticsPage.formatDate(today));

        this.getStatistics(
            GeolocationStatisticsPage.formatDate(lastWeek),
            GeolocationStatisticsPage.formatDate(today),
            granularity,
            this.buildStatList,
        );

        $("#filter-btn").click(() => {

            const startDateVal = $("#statisticsStartDate").val() as string;
            const endDateVal = $("#statisticsEndDate").val() as string;

            this.getStatistics(startDateVal, endDateVal, granularity, this.buildStatList);
        });
    }

    private getStatistics(
        startDate: string,
        endDate: string,
        granularity: string,
        onSuccess?: (result: any) => any,
    ): void {
        $.ajax({
            url: `/geolocation/statistics?endDate=${endDate}&startDate=${startDate}&timeGranularity=${granularity}`,
            method: "GET",
            success: (response) => {
                if (onSuccess) {
                    onSuccess(response);
                }
            },
            error: (xhr, status, error) => {
                console.error(xhr.responseText);
                alert("Error: " + xhr.responseText);
            },
        });
    }

    private buildStatList(data: GeoStatResponse): void {
        const context = {onLoad: []};

        $("#providers-list")
            .empty()
            .append(ProviderList(data, context).reduce((a, x) => a + x, ""));

        $("#cache-stat")
            .empty()
            .append(CacheStat(data, context));

        $("#geo-reqs-list")
            .empty()
            .append(GeoReqList(data, context).reduce((a, x) => a + x, ""));

        context.onLoad.forEach((f) => f());
    }
}

function ProviderList(data: GeoStatResponse, context: any) {
    const providersRelatedStats = data.list.filter((x) => x.key.indexOf("_PROVIDER_") > -1);

    const providerTable = providersRelatedStats.reduce<any>((table, x) => {
        const provider = x.key.slice(0, x.key.indexOf("_PROVIDER_"));
        const statName = x.key.slice(x.key.indexOf("_PROVIDER_") + "_PROVIDER_".length);
        table[provider] = {
            ...(table[provider] || {}),
            name: provider,
            [statName]: {
                name: statName,
                total: x.values.reduce<number>((acc, v) => acc + (v.value || 0), 0),
                values: x.values,
            },
        };
        return table;
    }, {});

    const providerStatOfInterest = ["TOTAL_REQ", "TOTAL_REQ_SUCCESSFUL", "TOTAL_REQ_FAILED", "TOTAL_REQ_BLOCKED"];

    return Object.keys(providerTable)
        .map((key) => providerTable[key])
        .map((providerStats) => {
            const stats = Object.keys(providerStats)
                .filter((x) => x !== "name")
                .filter((x) => providerStatOfInterest.indexOf(x) > -1)
                .map((x) => providerStats[x]);

            const timelineMap = {};
            stats.forEach((stat) => {
                stat.values.forEach((val) => {
                    if (!val.value) {
                        return;
                    }
                    if (!timelineMap[val.start]) {
                        timelineMap[val.start] = {date: val.start, values: []};
                    }
                    timelineMap[val.start].values.push({name: stat.name, value: val.value});
                });
            });

            const timeline = Object.keys(timelineMap)
                .map((x) => timelineMap[x])
                .map((block) => {
                    return {
                        date: block.date,
                        values: ReorderList(block.values, providerStatOfInterest, "name"),
                    };
                });

            return StatCard(
                providerStats.name,
                ReorderList(stats, providerStatOfInterest, "name"),
                "Daily stats",
                timeline,
                ["#4040BF", "#00DB00", "#FF4949", "#FFFF00"],
                context,
            );
        });
}

function CacheStat(data: GeoStatResponse, context: any) {
    const cacheStats = data.list.filter((x) => x.key.indexOf("CELL_CACHE_") > -1);
    const cacheStatsTable = cacheStats.reduce<any>((table, x) => {
        const statName = x.key.slice(x.key.indexOf("CELL_CACHE_") + "CELL_CACHE_".length);
        table[statName] = {
            name: statName,
            total: x.values.reduce<number>((acc, v) => acc + (v.value || 0), 0),
            values: x.values,
        };
        return table;
    }, {});

    const statOrder = ["REQ", "HIT", "MISS"];

    const stats = Object.keys(cacheStatsTable)
        .filter((x) => x !== "name")
        .map((x) => cacheStatsTable[x]);

    const timelineMap = {};
    stats.forEach((stat) => {
        stat.values.forEach((val) => {
            if (!val.value) {
                return;
            }
            if (!timelineMap[val.start]) {
                timelineMap[val.start] = {date: val.start, values: []};
            }
            timelineMap[val.start].values.push({name: stat.name, value: val.value});
        });
    });

    const timeline = Object.keys(timelineMap)
        .map((x) => timelineMap[x])
        .map((block) => {
            return {
                date: block.date,
                values: ReorderList(block.values, statOrder, "name"),
            };
        });

    return StatCard(
        "Cell cache",
        ReorderList(stats, statOrder, "name"),
        "Daily stats",
        timeline,
        ["#4040BF", "#00DB00", "#FF4949"],
        context,
        {width: 60, height: 60, barWidth: 5},
    );
}

function GeoReqList(data: GeoStatResponse, context: any) {
    const providersRelatedStats = data.list.filter((x) => x.key.indexOf("_GEO_REQ_") > -1);

    const providerTable = providersRelatedStats.reduce<any>((table, x) => {
        const provider = x.key.slice(0, x.key.indexOf("_GEO_REQ_"));
        const statName = x.key.slice(x.key.indexOf("_GEO_REQ_") + "_GEO_REQ_".length);
        table[provider] = {
            ...(table[provider] || {}),
            name: provider,
            [statName]: {
                name: statName,
                total: x.values.reduce<number>((acc, v) => acc + (v.value || 0), 0),
                values: x.values,
            },
        };
        return table;
    }, {});

    const providerStatOfInterest = ["SUCCESSFUL", "FAILED"];

    return Object.keys(providerTable)
        .filter((key) => key === "PROSA")
        .map((key) => providerTable[key])
        .map((providerStats) => {
            const stats = Object.keys(providerStats)
                .filter((x) => x !== "name")
                .filter((x) => providerStatOfInterest.indexOf(x) > -1)
                .map((x) => providerStats[x]);

            const timelineMap = {};
            stats.forEach((stat) => {
                stat.values.forEach((val) => {
                    if (!val.value) {
                        return;
                    }
                    if (!timelineMap[val.start]) {
                        timelineMap[val.start] = {date: val.start, values: []};
                    }
                    timelineMap[val.start].values.push({name: stat.name, value: val.value});
                });
            });

            const timeline = Object.keys(timelineMap)
                .map((x) => timelineMap[x])
                .map((block) => {
                    return {
                        date: block.date,
                        values: ReorderList(block.values, providerStatOfInterest, "name"),
                    };
                });

            return StatCard(
                providerStats.name,
                ReorderList(stats, providerStatOfInterest, "name"),
                "Daily stats",
                timeline,
                ["#00DB00", "#FF4949"],
                context,
                {width: 60, height: 60, barWidth: 5},
            );
        });
}

function StatCard(title: string, stats: any[], timelineTitle: string, timeline: any[], colors: string[], context: any,
                  chartOptions: any = {}) {
    return `
        <div class="stats-card card">
            <div class="stats-card-header">
                <h4 class="stats-card-title">${title}</h4>
                <div class="stats">
                    ${stats.map(({name, total}, i) => `
                        <div class="stat">
                            <div class="stat-name">
                                <span class="circle" style="background-color:${colors[i]}"></span>
                                ${name.replaceAll("_", " ").toLowerCase()}
                            </div>
                            <div class="stat-value">${total}</div>
                        </div>
                    ` + (i !== stats.length - 1 ? `<hr class="separator">` : "")).join("")}
                    </div>
                </div>
                <div class="stats-timeline">
                    <h5 class="stats-timeline-header">
                        ${timelineTitle}
                    </h5>
                    <div class="stats-timeline-body">
                        ${timeline.map((block) =>
        PeriodMicroChart(
            block.date,
            block.values,
            {
                width: 100,
                height: 100,
                barWidth: 7,
                colors,
                ...chartOptions,
            },
            context,
        ),
    ).join("")}
                    </div>
                </div>
        </div>`;
}

function PeriodMicroChart(label: string, values: any[], options: any, context: any) {
    const maxValue = values.reduce((a, b) => Math.max(a, b.value), 0);
    const barWidth = options.barWidth
        ? options.barWidth
        : options.width / values.length;

    const heightScale = options.height / maxValue;

    const px = (x: number) => "" + x + "px";

    const id = (Math.random() * 100000).toFixed(0);

    context.onLoad.push(() => {
        const chartBody = $(`#chart-body-${id}`);
        const details = $(`#daily-chart-details-${id}`);
        chartBody.mouseenter(() => {
            details.show();
            details.css("left", chartBody.position().left + (chartBody.width() / 2) + 10);
        });
        chartBody.mouseleave(() => details.hide());
    });

    return `
        <div class="period-micro-chart" id="chart-body-${id}">
            <div class="daily-chart-details" id="daily-chart-details-${id}" style="display:none;">
                ${values.map((x, i) => `
                        <div class="chart-info">
                            <span class="circle" style="background-color:${options.colors[i]}"></span>
                            ${x.value}
                        </div>
                `).join("")}
            </div>
            <div class="charts" style="height:${px(options.height)}; width: ${px(options.width)}">
                ${values.map((x, i) => {
        const barHeight = px(Math.max((x.value as number) * heightScale, barWidth));
        return `
                        <div
                            class="bar"
                            style="width:${px(barWidth)};height:${barHeight};background-color:${options.colors[i]};margin:0px 5px;"
                        >
                        </div>`;
    }).join("")}
            </div>
            <div class="chart-label"><span class="badge badge-secondary">${label}</span></div>
        </div>`;
}

function ReorderList(list: any[], order: any[], field: string) {
    return list.reduce((acc, x) => {
        acc[order.indexOf(x[field])] = x;
        return acc;
    }, new Array<any>(order.length));
}
