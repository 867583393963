import {SettingTemplateCreation} from "../../models/configurability/template/settingTemplate";
import {DeviceModelList} from "../../models/device/deviceModelModel";

class DeviceModelController {
    public async findDeviceModels(isSlave?: boolean): Promise<DeviceModelList> {
        let url = "deviceModels/";
        if (isSlave != null) {
            url += "?slave=" + isSlave;
        }
        const response = await fetch(url);
        return response.json();
    }

    public async createSettingTemplate(guid: string, payload: SettingTemplateCreation): Promise<any> {
        const response = await fetch("deviceModels/" + guid + "/settingTemplate", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }
}

export default DeviceModelController;
