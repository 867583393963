import {adjustToBeInViewport} from "../../utils/DOM";
import {OverlayParams, RelativePoint, RelativePointObject} from "./types";

export function getRelativePositionObject(
    factory: RelativePoint,
    anchor: Element,
    subject: HTMLElement,
): RelativePointObject {
    return typeof factory === "function" ? factory(anchor, subject) : (factory ?? {});
}

type Side = "top" | "bottom" | "left" | "right";
type Align = "start" | "center" | "end";

interface Placement {
    side: Side;
    alignment: Align;
    gap: number;
}

export function adjacentToAnchor(side: Side, alignment: Align, gap: number = 0): OverlayParams {
    return {
        anchorPoint(anchor) {
            return anchorPoint(anchor, {side, alignment, gap});
        },
        overlayOrigin() {
            return popoverOrigin({side, alignment, gap});
        },
    };
}

export function adjacentToAnchorFreeSide(...possiblePlacements: Placement[]): OverlayParams {
    return {
        anchorPoint(anchor, popover) {
            const bestPlacement = computeBestPlacement(anchor, popover, possiblePlacements);
            return anchorPoint(anchor, bestPlacement);
        },
        overlayOrigin(anchor, popover) {
            const bestPlacement = computeBestPlacement(anchor, popover, possiblePlacements);
            return popoverOrigin(bestPlacement);
        },
    };
}

export const ADJACENT_TO_ANCHOR_RIGHT_CENTER_ALIGNED_GAP_10PX = adjacentToAnchor("right", "center", 10);
export const ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX = adjacentToAnchor("bottom", "start", 10);
export const ADJACENT_TO_ANCHOR_BOTTOM_END_ALIGNED_GAP_10PX = adjacentToAnchor("bottom", "end", 10);

export const ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX = adjacentToAnchorFreeSide(
    {side: "bottom", alignment: "start", gap: 10},
    {side: "bottom", alignment: "center", gap: 10},
    {side: "bottom", alignment: "end", gap: 10},
    {side: "top", alignment: "start", gap: 10},
    {side: "top", alignment: "center", gap: 10},
    {side: "top", alignment: "end", gap: 10},
);

function anchorPoint(anchor: Element, placement: Placement): RelativePointObject {
    const { width, height } = anchor.getBoundingClientRect();
    const { side, alignment, gap } = placement;
    const gapOffset = (side === "bottom" || side === "top") ? -gap / height : -gap / width;
    const crossAxis: Side = (side === "bottom" || side === "top") ? "left" : "top";
    const crossAxisOffset = alignment === "center" ? 0.5 : alignment === "end" ? 1 : 0;
    return {
        [side]: gapOffset,
        [crossAxis]: crossAxisOffset,
    };
}

function popoverOrigin(placement: Placement) {
    const { side, alignment } = placement;
    const popoverSide: Side = side === "left" ? "right"
        : side === "right" ? "left"
        : side === "top" ? "bottom"
        : "top";
    const crossAxis: Side = (side === "bottom" || side === "top") ? "left" : "top";
    const crossAxisOffset = alignment === "center" ? 0.5 : alignment === "end" ? 1 : 0;
    return {
        [popoverSide]: 0,
        [crossAxis]: crossAxisOffset,
    };
}

// Fixme should also take in consideration of the popover staring point
function computeBestPlacement(anchor: Element, popover: HTMLElement, placements: Placement[]) {
    return placements.find((placement, index) => {
        const point = anchorPoint(anchor, placement);
        const { top, left, height, width} = anchor.getBoundingClientRect();
        const {xAdjust, yAdjust} = adjustToBeInViewport(popover, {
            top: top + height * (point.top ?? (1 - (point.bottom ?? 0))),
            left: left + width * (point.left ?? (1 - (point.right ?? 0))),
        });
        return (xAdjust === 0 && yAdjust === 0) || index === placements.length - 1;
    });
}
