import * as React from "react";
import {Row} from "react-bootstrap";
import {SketchPicker} from "react-color";
import Services from "../../../controllers/utils/Services";
// import {TableCompanyAlarmType} from "../../models/alarm/alarmType";
// import {TableComponentType} from "../../models/component/componentType";

interface RowColorPickerModalProps {
    color: string;
    setColor: (color: string) => void;
    selectedColor: {r: string, g: string, b: string, a: string};
    setSelectedColor: (color: {r: string, g: string, b: string, a: string}) => void;
    displayColorPicker: boolean;
    setDisplayColorPicker: (value: boolean) => void;
}

const RowColorPickerModal: React.FC<RowColorPickerModalProps> = (props) => {
    const services = new Services();

    // function onInputTextChange(table: TableComponentType | TableCompanyAlarmType, value: any, column: string) {
    //     const newComponentType = props.arrayTables.map((c) => {
    //         if (table.item.guid === c.item.guid &&
    //             props.arrayTables.indexOf(table) === props.arrayTables.indexOf(c)) {
    //             switch (column) {
    //                 case "color":
    //                     c.color = value;
    //                     break;
    //                 case "selectedColor":
    //                     c.selectedColor = value;
    //                     break;
    //             }
    //         }
    //         return c;
    //     });
    //     props.setArrayTables(newComponentType);
    // }

    function onColorChange() {
        return (t) => {
            // onInputTextChange(props.value, t.rgb, "selectedColor");
            // onInputTextChange(props.value, t.hex.toUpperCase(), "color");
            props.setColor(t.hex.toUpperCase());
            props.setSelectedColor(t.rgb);
        };
    }

    function onBooleanChange(value: boolean) {
        return () => {
            props.setDisplayColorPicker(value);
        };
        // return () => {
        //     const newComponentType = props.arrayTables.map((c) => {
        //         if (table.item.guid === c.item.guid &&
        //             props.arrayTables.indexOf(table) === props.arrayTables.indexOf(c)) {
        //             switch (column) {
        //                 case "displayColorPicker":
        //                     c.displayColorPicker = value;
        //                     break;
        //             }
        //         }
        //         return c;
        //     });
        //     props.setArrayTables(newComponentType);
        // };
    }

    return (
        <Row className={"px-3"} style={{justifyContent: "space-between"}}>
            <div>
                <div
                    style={services.styles.swatch}
                    onClick={onBooleanChange(!props.displayColorPicker)}
                >
                    <div
                        style={{
                            width: "150px",
                            height: "40px",
                            borderRadius: "2px",
                            background: `rgba(${props.selectedColor.r}, ${props.selectedColor.g}, ${props.selectedColor.b}, ${props.selectedColor.a})`,
                        }}
                    />
                </div>
                {
                    props.displayColorPicker ?
                        (
                            <div style={services.styles.popover}>
                                <div
                                    style={services.styles.cover}
                                    onClick={onBooleanChange(false)}
                                />
                                <SketchPicker
                                    color={props.selectedColor}
                                    presetColors={services.presetColors}
                                    onChange={onColorChange()}
                                />
                            </div>
                        ) : null
                }
            </div>
            <p>
                {props.color}
            </p>
        </Row>
    );
};

export default RowColorPickerModal;
