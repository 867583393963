import {Icon} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";

interface SectionTitleProps {
    glyph: string;
    iconColor?: string;
    explanationTitle?: string;
    explanation?: string | JSX.Element;
}

export function SectionTitle(
    {
        children,
        glyph,
        iconColor,
        explanationTitle,
        explanation,
        ...rest
    }: React.PropsWithChildren<SectionTitleProps & React.HTMLProps<HTMLHeadingElement>>,
) {
    const [showExplanationModal, setShowExplanationModal] = React.useState<boolean>(false);

    function openExplanationModal() {
        setShowExplanationModal(true);
    }

    function closeExplanationModal() {
        setShowExplanationModal(false);
    }

    if (!explanation) {
        return (
            <h3 {...rest} className={`section-title ${rest.className ?? ""}`}>
                {children}
            </h3>
        );
    }

    return (
        <>
            <h3 {...rest} className={`section-title ${rest.className ?? ""}`}>
                {children}
                <span
                    className={`section-title-info-icon`}
                    onClick={openExplanationModal}
                    title={explanationTitle ?? "Explanation"}
                >
                    <Icon
                        glyph={glyph}
                        size={32}
                        styles={{root: {color: iconColor}}}
                    />
                </span>
            </h3>
            <Modal
                onHide={closeExplanationModal}
                keyboard={true}
                show={showExplanationModal}
            >
                <Modal.Header closeButton={true}>
                    <h4>{explanationTitle ?? "Explanation"}</h4>
                </Modal.Header>
                <Modal.Body>
                    {explanation}
                </Modal.Body>
            </Modal>
        </>
    );
}
