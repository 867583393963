import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import React = require("react");
import {setLegacyBreadcrumb} from "../../index";
import * as LegacyPage from "../../page";
import {DevicesCodesInput} from "./components/DevicesCodesInput";
import {DevicesStorageList} from "./components/DevicesStorageList";
import {StoreDevices} from "./components/StoreDevices";
import {DeviceCodeSelectionProvider} from "./DeviceCodeSelectionContext";
import {DeviceStorageManagerContextProvider} from "./DeviceStorageManagerContext";

import "./device-storage-page-styles.scss";

export default function DeviceStorageManagement() {
    setLegacyBreadcrumb(
        new LegacyPage.Breadcrumb([
            new LegacyPage.BreadcrumbItem("./", "Dashboard", false),
            new LegacyPage.BreadcrumbItem(null, "Web operations", false),
            new LegacyPage.BreadcrumbItem(null, "Change Device Storage", true),
        ]),
    );

    return (
        <DeviceStorageManagerContextProvider>
            <ThemeProvider theme={defaultTheme}>
                <div className={"device-storage-page"}>
                    <DeviceCodeSelectionProvider>
                        <section className={"device-code-input-section"}>
                            <DevicesCodesInput/>
                        </section>
                        <section className={"valid-device-list-section"}>
                            <DevicesStorageList/>
                        </section>
                    </DeviceCodeSelectionProvider>
                    <section className={"storage-section"}>
                        <StoreDevices/>
                    </section>
                </div>
            </ThemeProvider>
        </DeviceStorageManagerContextProvider>
    );
}
