import * as React from "react";
import {DateRangePicker, TimePicker} from "@danfoss/etui-core";
import {MILLISECONDS_IN_A_HOUR, MILLISECONDS_IN_A_MINUTES} from "../../../../utils/datetime";

interface DateTimePickerProps {
    onChange?: React.ChangeEventHandler<{ value: number }>;
}

export function DateTimePicker({onChange}: DateTimePickerProps) {
    const [timeInMilliseconds, setTimeInMilliseconds] = React.useState<number>(0);
    const [dateInMilliseconds, setDateInMilliseconds] = React.useState<number>(0);

    const [startDate, setStartDate] = React.useState<Date>(null);

    function extractDate({startDate: newStartDate}: { startDate: Date }) {
        setDateInMilliseconds(newStartDate.getTime());
        setStartDate(newStartDate);
    }

    function extractTime(timestr: string) {
        const [hoursStr, minutesStr, secondsStr] = timestr.split(":");
        const hours = Number.parseInt(hoursStr);
        const minutes = Number.parseInt(minutesStr);
        const seconds = Number.parseInt(secondsStr);
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return;
        }
        setTimeInMilliseconds(hours * MILLISECONDS_IN_A_HOUR + minutes * MILLISECONDS_IN_A_MINUTES + seconds * 1000);
    }

    const timestamp = dateInMilliseconds + timeInMilliseconds;
    React.useEffect(() => {
        onChange?.({
            target: {
                value: timestamp !== 0 ? timestamp : (new Date()).getTime(),
            },
        } as any);
    }, [timestamp]);

    return (
        <div className={"storage-date-time-picker"}>
            <DateRangePicker
                label={"Date"}
                startDate={startDate}
                dateFormat={"DD MMMM YYYY"}
                onChange={extractDate}
                disableRange={true}
            />
            <TimePicker
                labels={{main: "Time"}}
                onChange={extractTime}
            />
        </div>
    );
}