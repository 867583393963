import {AutoSuggest, Button, Checkbox, Toggle} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import CompanyController from "../../../controllers/api/CompanyController";
import TranslationController from "../../../controllers/api/TranslationController";
import Services from "../../../controllers/utils/Services";
import {AlarmType, TableAlarmType} from "../../../models/alarm/alarmType";
import {ComponentModel} from "../../../models/component/componentModel";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";
import ImportModal from "../modals/ImportModal";

interface AlarmSectionProps {
    alarms: AlarmType[];
    tableAlarms: TableAlarmType[];
    defaultAlarms: TableAlarmType[];
    currentComponentModel: ComponentModel;
    setTableAlarms: (tableAlarms: TableAlarmType[]) => void;
    changeStep?: (step: number) => void;
    step?: number;
    submit: (custom: boolean) => void;
    loading: boolean;
}

const AlarmSection: React.FC<AlarmSectionProps> = ({
                                                       changeStep,
                                                       currentComponentModel,
                                                       defaultAlarms,
                                                       loading,
                                                       setTableAlarms,
                                                       step,
                                                       submit,
                                                       tableAlarms,
                                                   }) => {
    const services: Services = new Services();
    const companyController: CompanyController = new CompanyController();
    const translationController: TranslationController = new TranslationController();

    const INFO_TRANSLATION_TEXT_KEY = "operations.component_models_creation.alarm_types .generic_note";

    const [allChecked, setAllChecked] = React.useState(true);
    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [isCustom, setIsCustom] = React.useState<boolean>(false);
    const [selectedAlarmType, setSelectedAlarmType] = React.useState<AlarmType>(undefined);
    const [alarmTypeName, setAlarmTypeName] = React.useState<string>("");
    const [importedAlertShow, setImportedAlertShow] = React.useState(false);
    const [alarmTypeSuggestions, setAlarmTypeSuggestions] = React.useState<
        Array<{ item: AlarmType, label: string, value: string }>>([]);

    // table columns
    const columns = [
        {
            key: "checkbox",
            style: {width: "10%"},
            render: (
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <span>Select all</span>
                    <br/>
                    <Checkbox
                        key={"selectAll"}
                        checked={allChecked}
                        indeterminate={indeterminate}
                        disabled={!isCustom}
                        onChange={() => {
                            setAllChecked(!allChecked);
                            setTableAlarms(
                                tableAlarms.map((t) => {
                                    t.checked = !allChecked;
                                    return t;
                                }));
                        }}
                    />
                </div>
            ),
        },
        {
            key: "alarm",
            style: {width: "60%"},
            render: "Alarm",
        },
        {
            key: "severity",
            style: {width: "30%"},
            render: "Severity",
        },
    ];

    /* Function used to set the selected alarm checked param */
    function prepareAlarms(alarm, checked) {
        const newAlarms = tableAlarms.map((item) => {
            if (item.item.guid === alarm.item.guid) {
                item.checked = checked;
            }
            return item;
        });
        setTableAlarms(newAlarms);
    }

    function deleteAll() {
        setTableAlarms([]);
    }

    function deleteUnselected() {
        setTableAlarms(tableAlarms.filter((e) => e.checked));
    }

    function getColor(severity: string) {
        switch (severity) {
            case "High":
                return "orange";
            case "Warning":
                return "yellow";
            case "Urgent":
                return "red";
            default:
                return "black";
        }
    }

    const handleAlarmTypeStateChange = (params, downshiftStateAndHelpers) => {
        if (selectedAlarmType != null) {
            setSelectedAlarmType(undefined);
        }
        if (!downshiftStateAndHelpers.isOpen) {
            setAlarmTypeName("");
        } else if (params.hasOwnProperty("inputValue")) {
            setAlarmTypeName(params.inputValue);
        }
    };

    React.useEffect(() => {
        if (tableAlarms !== undefined) {
            if (tableAlarms.every((v) => v.checked)) {
                setAllChecked(true);
                setIndeterminate(false);
            } else if (tableAlarms.some((v) => v.checked)) {
                setAllChecked(false);
                setIndeterminate(true);
            } else {
                setAllChecked(false);
                setIndeterminate(false);
            }
        }
    }, [tableAlarms]);
    React.useEffect(() => {
        if (alarmTypeName.length > 3) {
            companyController.getAlarmTypesByPartialName(alarmTypeName).then(
                (response) => {
                    setAlarmTypeSuggestions(response.map((e) => {
                        return {
                            item: e,
                            label: e.name,
                            value: e.guid,
                        };
                    }));
                },
            );
        }
    }, [alarmTypeName]);

    function addSelectedAlarmType() {
        if (selectedAlarmType != null) {
            if (!tableAlarms.map((e) => e.item.guid).includes(selectedAlarmType.guid)) {
                const tableItem: TableAlarmType = {
                    item: selectedAlarmType,
                    checked: true,
                };
                setTableAlarms(
                    [...(tableAlarms), tableItem],
                );
                setAlarmTypeName("");
                setSelectedAlarmType(undefined);
                setAlarmTypeSuggestions([]);
                services.successNotification(" ", "Alarm type added!");
            } else {
                services.warningNotification(" ", "Alarm type already in list!");
            }
        }
    }

    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <Form>
                {/* Import buttons */}
                <Form.Group>
                    {changeStep && (
                        <Row className="w-100 flex-row m-0">
                            <GeneralAlert
                                message={infoMessage}
                            />
                        </Row>
                    )}
                    <Row className="w-100 m-0">
                        <Col className="d-flex justify-content-start align-items-center w-100 p-0">
                            <Row>
                                <p className="mx-auto px-3 my-auto">
                                    Custom configuration:
                                </p>
                                <Toggle
                                    name="default/custom"
                                    isChecked={isCustom}
                                    onChange={(e, checked) => {
                                        setIsCustom(checked);
                                        if (!checked) {
                                            setTableAlarms(defaultAlarms);
                                        }
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="w.100 m-0" style={{height: "50px"}}/>
                    <Row className="w-100 m-0">
                        <Col className="d-flex justify-content-start align-items-center w-100 p-0">
                            <AutoSuggest
                                label="Insert Alarm type"
                                emptyPlaceholder="No Alarm Type found"
                                isInputDisabled={!isCustom}
                                suggestions={alarmTypeSuggestions}
                                inputValue={alarmTypeName}
                                onSelectedValueChange={services.onSelectedItemAndLabel(
                                    setAlarmTypeName,
                                    setSelectedAlarmType,
                                )}
                                itemToString={services.itemToString}
                                onStateChange={handleAlarmTypeStateChange}
                            />
                            <div style={{width: "10px"}}/>
                            <Button
                                variant="primary"
                                onClick={() => addSelectedAlarmType()}
                                disabled={selectedAlarmType == null || !isCustom}
                            >
                                +
                            </Button>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center w-100 p-0">
                            <Button variant="secondary" onClick={() => setModalShow(true)} disabled={!isCustom}>
                                Import from model
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
                {
                    tableAlarms.length > 0 && (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                            <tr key={"columns"}>
                                {
                                    columns.map((col) => (
                                        <th key={col.key} style={col.style}>
                                            {col.render}
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                tableAlarms.map((value) => (
                                    <tr key={value.item.guid}>
                                        <td>
                                            <div style={{margin: "0 auto", textAlign: "center"}}>
                                                <Checkbox
                                                    checked={value.checked}
                                                    disabled={!isCustom}
                                                    onChange={(t) =>
                                                        prepareAlarms(value,
                                                            t.target.checked,
                                                        )}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            {value.item.name}
                                        </td>
                                        <td>
                                            <p style={{color: getColor(value.item.severity.name)}}>
                                                {value.item.severity.name}
                                            </p>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    )
                }
                {/* Bottom nav buttons */}
                <Row className={"pb-4 pt-4"}>
                    {changeStep && (
                        <Col>
                            <div className={"text-left"}>
                                <Button
                                    disabled={!isCustom}
                                    onClick={deleteUnselected}
                                >
                                    Delete unselected
                                </Button>
                                {" "}
                                <Button
                                    variant="secondary"
                                    disabled={!isCustom}
                                    onClick={deleteAll}
                                >
                                    Delete all
                                </Button>
                            </div>
                        </Col>
                    )}
                    <Col>
                        <div className={"text-right"}>
                            <Button
                                onClick={() => changeStep(step - 1)}
                            >
                                Previous
                            </Button>
                            {" "}
                            <Button
                                variant="strong"
                                onClick={() => submit(isCustom)}
                            >
                                Next
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            <ImportModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setAlarms={setTableAlarms}
                section={"alarms"}
                currentComponentModel={currentComponentModel}
            />
            <LoadingModal loading={loading}/>
        </>
    );
};

export default AlarmSection;
