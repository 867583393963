import {Button, NumberInput, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row, Table} from "react-bootstrap";
import TranslationController from "../../../controllers/api/TranslationController";
import {Property, PropertyConfiguration, TablePropertyConfiguration} from "../../../models/property/property";
import GeneralAlert from "../../common/GeneralAlert";

interface PropertySectionProps {
    properties: Property[];
    propertyConfigurations: PropertyConfiguration[];
    setPropertyConfigurations: (propertyConfigurations: PropertyConfiguration[]) => void;
    tablePropertyConfigurations: TablePropertyConfiguration[];
    setTablePropertyConfigurations: (p: TablePropertyConfiguration[]) => void;
    changeStep?: (step: number) => void;
    step?: number;
    disabled?: boolean;
    submit: () => Promise<boolean>;
}

const PropertySection: React.FC<PropertySectionProps> = ({
     changeStep,
     disabled,
     properties,
     setTablePropertyConfigurations,
     step,
     submit,
     tablePropertyConfigurations
 }) => {
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.component_models_creation.property_configurations.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [moveButtonsDisabled, setMoveButtonsDisabled] = React.useState<boolean>(false);

    const columns = [
        {
            key: "name",
            style: {width: "40%"},
            render: "Name",
        },
        {
            key: "value",
            style: {width: "45%"},
            render: "Value",
        },
        {
            key: "reset_default",
            style: {width: "15%"},
            render: "Reset value",
        },
    ];

    function prepareProperties(item: TablePropertyConfiguration, value, type: string) {
        setTablePropertyConfigurations(tablePropertyConfigurations.map(
            (e) => {
                if (e.item.propertyGuid === item.item.propertyGuid) {
                    switch (type) {
                        case "checked":
                            e.checked = value;
                            break;
                        case "value":
                            switch (properties.find((p) => p.guid === e.item.propertyGuid).propertyValueType) {
                                case "number":
                                    e.item.currentValue = value;
                                    break;
                                case "text":
                                    e.item.currentValue = value;
                                    break;
                            }
                            break;
                    }
                }
                return e;
            },
        ));
    }

    function resetToDefault(item: TablePropertyConfiguration) {
        setTablePropertyConfigurations(
            tablePropertyConfigurations.map(
                (t) => {
                    if (t.item.propertyGuid === item.item.propertyGuid) {
                        t.item = {
                            propertyGuid: item.item.propertyGuid,
                        };
                    }
                    return t;
                },
            ),
        );
    }

    function submitAndDisableButtons() {
        return () => {
            submit().then(
                (status) => {
                    setMoveButtonsDisabled(status);
                    if (status && changeStep) {
                        // wait 3 seconds before page refresh
                        setTimeout(() => location.reload(), 3000);
                    }
                },
            );
        };
    }

    function inputLabel(value: TablePropertyConfiguration, type: "text" | "number") {
        return properties.find(
            (p) => p.guid === value.item.propertyGuid,
        ).defaultValue !== null ?
            ("Default value: " + properties.find(
                (p) => p.guid === value.item.propertyGuid,
            ).defaultValue) :
            `No default value (${type})`;
    }

    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <Form>
                {changeStep && (
                    <Row className="w-100 m-0">
                        <GeneralAlert
                            message={infoMessage}
                        />
                    </Row>
                )}
                {
                    properties.length > 0 && (
                            <Table striped={true} bordered={true} hover={true}>
                                <thead>
                                <tr key={"columns"}>
                                    {
                                        columns.map((col) => (
                                            <th key={col.key} style={col.style}>{col.render}</th>
                                        ))
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    tablePropertyConfigurations.map((value) => (
                                        <tr
                                            key={properties.find(
                                                (p) => p.guid === value.item.propertyGuid).guid}
                                        >
                                            <td>{properties.find(
                                                (p) => p.guid === value.item.propertyGuid).name}
                                            </td>
                                            <td>
                                                <div style={{margin: "0 auto", textAlign: "center"}}>
                                                    {
                                                        properties.find((p) =>
                                                            p.guid === value.item.propertyGuid)
                                                            .propertyValueType === "number" ? (
                                                                disabled ? (
                                                                    <TextInput
                                                                        label={inputLabel(value, "number")}
                                                                        disabled={disabled}
                                                                        value={value.item.currentValue}
                                                                        onChange={(t) => prepareProperties(
                                                                            value,
                                                                            t,
                                                                            "value",
                                                                        )}
                                                                    />
                                                                ) : (
                                                                <NumberInput
                                                                    label={inputLabel(value, "number")}
                                                                    disabled={disabled}
                                                                    value={value.item.currentValue}
                                                                    onChange={(t) => prepareProperties(
                                                                        value,
                                                                        t,
                                                                        "value",
                                                                    )}
                                                                />
                                                        )
                                                        ) : (
                                                            <TextInput
                                                                label={inputLabel(value, "text")}
                                                                value={value.item.currentValue}
                                                                disabled={disabled}
                                                                onChange={(t) => prepareProperties(
                                                                    value,
                                                                    t.target.value,
                                                                    "value",
                                                                )}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => resetToDefault(value)}
                                                    disabled={disabled}
                                                >
                                                    RESET
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        )
                }
                {/* Bottom nav buttons */}
                <div className={"text-right pb-4 pt-4"}>
                    {changeStep ? (
                        <>
                            <Button
                                onClick={() => changeStep(step - 1)}
                                disabled={moveButtonsDisabled}
                            >
                                Previous
                            </Button>
                            {" "}
                            <Button
                                variant="strong"
                                onClick={submitAndDisableButtons()}
                                disabled={moveButtonsDisabled}
                            >
                                Finish
                            </Button>
                        </>
                    ) : !disabled && (
                        <Button
                            variant="strong"
                            onClick={submitAndDisableButtons()}
                            disabled={moveButtonsDisabled}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </Form>
        </>
    );
};

export default PropertySection;
