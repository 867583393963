import {DiagnosticTypeCustomSet, DiagnosticTypeSet} from "../../models/diagnostic/diagnosticType";

class DiagnosticController {

    public async findDiagnosticTypes(categoryGuid: string): Promise<DiagnosticTypeSet> {
        const response = await fetch("/diagnosticCategories" + categoryGuid + "/diagnosticTypes");
        return response.json();
    }

    public async findGenericDiagnosticTypes(): Promise<DiagnosticTypeSet> {
        const response = await fetch("/diagnosticTypes/generic");
        return response.json();
    }

    public async findDiagnosticTypesWithChildren(guids: string[]): Promise<DiagnosticTypeCustomSet> {
        let link: string = "/diagnosticTypes/tree?";
        guids.map((e) => link = link.concat("diagnosticTypesGuids=" + e + "&"));
        const response = await fetch(link.slice(0, -1));
        return response.json();
    }

}

export default DiagnosticController;
