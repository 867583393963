import * as React from "react";
import {useEventListener} from "../../../../hooks/useEventListener";
import {inside} from "../../../../utils/arrays";
import {setInputValue} from "../../../../utils/DOM";
import {onKey, toOutsideElements, toOutsideElementsIgnoring} from "../../../../utils/eventsHandling";
import {constant, doNothing, over} from "../../../../utils/functions";
import {clamp} from "../../../../utils/numbers";
import {HTMLElementValue} from "../../types";
import {DatalistRef} from "../Datalist";
import {registerOptions} from "../Option/utils";

export function useDatalist(
    inputRef: React.RefObject<HTMLInputElement>,
    overlayRef: React.RefObject<HTMLElement>,
    list: React.RefObject<DatalistRef> | string | undefined,
) {
    const [openDatalist, setOpenDatalist] = React.useState(false);
    const [selected, setSelected] = React.useState<HTMLElementValue>();
    const [datalistProps, setDatalistProps] = React.useState<React.HTMLProps<HTMLElement>>();
    const [datalistChildren, optionsRegistry] = registerOptions(selected, datalistProps?.children ?? null);
    const values = Array.from(optionsRegistry).filter(([, o]) => !o.props.disabled).map(([key]) => key);
    const currentSelectedIndex = clamp(-1, values.length - 1, values.indexOf(selected));

    useEventListener(inputRef.current, "focusin", constant(setOpenDatalist, true));
    useEventListener(inputRef.current, "focusout", toOutsideElementsIgnoring([overlayRef],
        constant(setSelected, undefined),
        constant(setOpenDatalist, false),
    ));
    useEventListener(overlayRef.current, "blur", toOutsideElements(close));

    useEventListener(inputRef.current, "keydown", onKey(
        "ArrowUp", constant(setSelected, inside(values, currentSelectedIndex - 1)),
        "ArrowDown", constant(setSelected, inside(values, currentSelectedIndex + 1)),
        "Enter", constant(setInputValue, inputRef.current, selected),
    ));

    React.useEffect(() => {
        return typeof list === "string" || list == null
            ? doNothing
            : list.current.observe((newDatalistProps) => {
                setDatalistProps(newDatalistProps);
            });
    }, []);

    return {
        selected,
        datalistProps : datalistProps ? {
            ...datalistProps,
            children: datalistChildren,
        } : null,
        isOpen: openDatalist,
    };
}
