import {AutoSuggest, Button, Notification, NumberInput, SelectInput} from "@danfoss/etui-core";
import {Check} from "@danfoss/etui-icons";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import CompanyController from "../../../controllers/api/CompanyController";
import ComponentModelController from "../../../controllers/api/ComponentModelController";
import ReceiptController from "../../../controllers/api/ReceiptController";
import {Company} from "../../../models/company/companyModel";
import {ComponentModel} from "../../../models/component/componentModel";
import {Receipt, Selection} from "../../../models/selection/selection";

interface SelectionConfigurationModalProps {
    show: boolean;
    onHide: () => void;
    company: Company;
    componentModel: ComponentModel;
    setCompaniesProcessed: (c: Company[]) => void;
    companiesProcessed: Company[];
    setSelectedCompany: (t: Company) => void;
}

export const SelectionConfigurationModal: React.FC<SelectionConfigurationModalProps> = (props) => {
    // controllers
    const componentModelController: ComponentModelController = new ComponentModelController();
    const receiptController: ReceiptController = new ReceiptController();
    const companyController: CompanyController = new CompanyController();

    const [selectionCount, setSelectionCount] = React.useState<number>(undefined);
    const [selections, setSelections] = React.useState<Selection[]>([]);
    const [receipts, setReceipts] = React.useState<Receipt[]>([]);
    const [isCustom, setCustom] = React.useState<boolean>(true);
    const [componentModelSuggestions, setComponentModelSuggestions] = React.useState([]);
    const [selectedComponentModel, setSelectedComponentModel] = React.useState<ComponentModel>(undefined);
    const [componentModelInputValue, setComponentModelInputValue] = React.useState<string>("");
    const [companySuggestions, setCompanySuggestions] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState<Company>(undefined);
    const [companyInputValue, setCompanyInputValue] = React.useState<string>("");
    const [itemDisabled, setItemDisabled] = React.useState<boolean>(false);

    const columns = [
        {
            key: "position",
            style: {width: "30%"},
            render: "Position",
        },
        {
            key: "receipt",
            style: {width: "70%"},
            render: "Receipt",
        },
    ];

    function onSelectionInputConfirmButtonPressed() {
        setCustom(true);
        setItemDisabled(false);
        setSelections([]);
        const newSelections: Selection[] = [];
        for (let i = 0; i < selectionCount; i++) {
            newSelections.push(
                {
                    columnIndex: i,
                    receipt: null,
                },
            );
        }
        setSelections(newSelections);
        resetEtuiComponentsInputContent();
    }

    function resetEtuiComponentsInputContent() {
        const list = document.getElementsByClassName("css-vyfatz-SingleValue");
        for (let i = 0; i < list.length; i++) {
            list.item(i).innerHTML = "";
        }
    }

    function onReceiptSelectionInputChange(value, actionMeta, item) {
        setSelections(
            selections.map((e) => {
                if (e.columnIndex === item.columnIndex) {
                    e.receiptGuid = value.item.guid;
                    e.receipt = value.item;
                }
                return e;
            }),
        );
    }

    function onImportFromCompanyAndComponentModelPressed() {
        setCustom(false);
    }

    function submit() {
        componentModelController.linkComponentSelectionConfiguration(
            props.company.guid, props.componentModel.guid, selections,
        ).then(
            (response) => {
                if (response.status === 200) {
                    props.setCompaniesProcessed([...props.companiesProcessed, props.company]);
                    props.setSelectedCompany(undefined);
                    resetEtuiComponentsInputContent();
                    props.onHide();
                } else {
                    props.onHide();
                    Notification.open({
                        message: "Error",
                        description:
                            "Unable to submit configuration!",
                        duration: 100,
                        type: "error",
                        theme: defaultTheme,
                        className: "mt-5",
                    });
                }
            },
        );
    }

    function onComponentModelStateChange(params, downshiftStateAndHelpers) {
        if (!downshiftStateAndHelpers.isOpen) {
            setComponentModelInputValue("");
        } else if (params.hasOwnProperty("inputValue")) {
            setComponentModelInputValue(params.inputValue);
        }
    }

    function onCompanyStateChange(params, downshiftStateAndHelpers) {
        if (!downshiftStateAndHelpers.isOpen) {
            setCompanyInputValue("");
        } else if (params.hasOwnProperty("inputValue")) {
            setCompanyInputValue(params.inputValue);
        }
    }

    function onSearchPressed() {
        setItemDisabled(true);
        componentModelController.findSelectionsByComponentModelAndCompany(
            selectedComponentModel.guid, selectedCompany.guid,
        ).then(
            (response) => {
                setSelections(response.list === undefined ? [] : response.list);
                setCustom(true);
            },
        );
    }

    React.useEffect(() => {
        setCustom(false);
        setCustom(true);
    }, [selections]);
    React.useEffect(() => {
        if (props.company !== undefined) {
            receiptController.findReceiptsByCompany(props.company.guid).then(
                (response) => {
                    setReceipts(response.list);
                },
            );
        }
        setSelections([]);
        setSelectionCount(undefined);
    }, [props.company]);
    React.useEffect(() => {
        if (componentModelInputValue.length >= 3) {
            componentModelController.getComponentModelsByName(componentModelInputValue).then(
                (response) => {
                    setComponentModelSuggestions(
                        response.list.map((item) => {
                            return {
                                item,
                                label: item.name + " (" + item.type + ")",
                                value: item.guid,
                            };
                        }),
                    );
                },
            );
        } else {
            setComponentModelSuggestions([]);
        }
    }, [componentModelInputValue]);
    React.useEffect(() => {
        if (companyInputValue.length >= 3) {
            companyController.getCompaniesByPatternAndRelationType(companyInputValue, "ALL").then(
                (response) => {
                    setCompanySuggestions(
                        response.list
                            .map((item) => {
                                return {
                                    item,
                                    label: item.name,
                                    value: item.guid,
                                };
                            }),
                    );
                },
            );
        }
    }, [companyInputValue]);

    const onNumberInputChange = (value: any) => setSelectionCount(value);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
        >
            <Modal.Header closeButton={true}>
                Default selection configuration
            </Modal.Header>
            <Modal.Body>
                <Row className="m-0 w-100">
                    <Col className="d-flex flex-column col-6">
                        <Row className="mx-0">
                            <NumberInput
                                value={selectionCount}
                                label="Insert number of selections"
                                onChange={onNumberInputChange}
                            />
                            {" "}
                            <Button variant="strong" onClick={onSelectionInputConfirmButtonPressed}>
                                <Check/>
                            </Button>
                        </Row>
                    </Col>
                    <Col className="col-6">
                        <Row className="mr-0  d-flex flex-column">
                            <div className="text-right">
                                <Button variant="strong" onClick={onImportFromCompanyAndComponentModelPressed}>
                                    Import from company and model
                                </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <div style={{height: "20px"}}/>
                {
                    isCustom ? (
                        selections.length !== 0 && (
                            <Table>
                                <thead>
                                <tr>
                                    {
                                        columns.map((col) => (
                                            <th key={col.key} style={col.style}>{col.render}</th>
                                        ))
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    selections.map((e) => (
                                        <tr key={e.columnIndex}>
                                            <td>
                                                {e.columnIndex}
                                            </td>
                                            <td>
                                                <SelectInput
                                                    disabled={itemDisabled}
                                                    name="receipt"
                                                    value={e.receipt === null || e.receipt === undefined ?
                                                        undefined :
                                                        {
                                                            label: e.receipt.productName + " (dilution: "
                                                                + e.receipt.dilution + ")",
                                                            value: e.receipt.guid,
                                                        }}
                                                    label="Select receipt"
                                                    searchable={true}
                                                    onChange={(value, actionMeta) =>
                                                        onReceiptSelectionInputChange(value, actionMeta, e)}
                                                    options={receipts.map(
                                                        (item) => {
                                                            return {
                                                                item,
                                                                label: item.productName
                                                                    + " (dilution: " + item.dilution + ")",
                                                                value: item.guid,
                                                            };
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        )
                    ) : (
                        <Col className="w-100 d-flex flex-column">
                            <AutoSuggest
                                label={"Search component model"}
                                inputValue={componentModelInputValue}
                                suggestions={componentModelSuggestions}
                                itemToString={(item) => {
                                    return item ? item.label : "";
                                }}
                                onSelectedValueChange={(e) => {
                                    if (e !== null) {
                                        setComponentModelInputValue(e.label);
                                        setSelectedComponentModel(e.item);
                                    }
                                }}
                                onStateChange={onComponentModelStateChange}
                            />
                            <div style={{height: "20px"}}/>
                            <AutoSuggest
                                label={"Search company"}
                                inputValue={companyInputValue}
                                suggestions={companySuggestions}
                                itemToString={(item) => {
                                    return item ? item.label : "";
                                }}
                                onSelectedValueChange={(e) => {
                                    if (e !== null) {
                                        setCompanyInputValue(e.label);
                                        setSelectedCompany(e.item);
                                    }
                                }}
                                onStateChange={onCompanyStateChange}
                            />
                            <div style={{height: "20px"}}/>
                            <Button
                                variant="strong"
                                className="mx-auto"
                                onClick={onSearchPressed}
                                disabled={selectedCompany === undefined || selectedComponentModel === undefined}
                            >
                                Search
                            </Button>
                        </Col>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="strong" disabled={selections.length === 0} onClick={submit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
