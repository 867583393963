import {Media} from "../../models/media/mediaModel";
import {JSONList} from "../../models/utils/jsonList";
import {assertOk} from "../../utils/requests";

// todo should generalize to make it upload multiple images and add entity type
export async function uploadImage(imageFiles: File[]): Promise<JSONList<Media>> {
    const data = new FormData();

    imageFiles.forEach((imageFile) => {
        data.append("images", imageFile, imageFile.name);
    });
    const response = await fetch(
        "/media/images",
        {
            method: "POST",
            body: data,
        },
    );
    assertOk(response);
    return response.json();
}
