import {Accordion, AccordionSection, Button, H5, H6, icons, SelectInput} from "@danfoss/etui-core";
// tslint:disable-next-line:no-submodule-imports
import {EmptyState} from "@danfoss/etui-core/EmptyState";
import * as React from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import ConfigurabilityController from "../../../controllers/api/ConfigurabilityController";
import Services from "../../../controllers/utils/Services";
import {SettingTemplate, SettingTemplateVisibility} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import {ConfirmModal} from "../../common/ConfirmModal";
import CreateVisibilityModal from "../modals/CreateVisibilityModal";
import RenameModal from "../modals/RenameModal";

interface DeviceModelSectionProps {
    selectedDeviceModel: Suggestion<DeviceModel>;
    setSelectedDeviceModel: (deviceModel: Suggestion<DeviceModel>) => void;
    deviceModels: Array<Suggestion<DeviceModel>>;
    onCreateOrUpdateNewSettingTemplate: (settingTemplate?: SettingTemplate) => void;
    setLoading: (status: boolean) => void;
    setCurrentStep: (num: number) => void;
    setHasDefault: (bool: boolean) => void;
}

// tslint:disable:max-line-length
const DeviceModelSection: React.FC<DeviceModelSectionProps> = (props) => {
    const services = new Services();
    const [currentST, setCurrentST] = React.useState<SettingTemplate>(undefined);
    const [currentSTVisibility, setCurrentSTVisibility] = React.useState<SettingTemplateVisibility>(undefined);
    const [settingTemplateList, setSettingTemplateList] = React.useState<SettingTemplate[]>([]);

    const [confirmDeleteSTVShow, setConfirmSTVDeleteShow] = React.useState<boolean>(false);
    const [confirmCreateSTVShow, setConfirmCreateSTVShow] = React.useState<boolean>(false);

    const [confirmDeleteShow, setConfirmDeleteShow] = React.useState<boolean>(false);
    const [confirmRenameShow, setConfirmRenameShow] = React.useState<boolean>(false);
    const [currentSTGuid, setCurrentSTGuid] = React.useState<string>(undefined);
    const configurabilityController = new ConfigurabilityController();

    const columns = [
        {key: "company", className: "col-4", render: "Company"},
        {key: "componentType", className: "col-3", render: "Component Type"},
        {key: "componentModel", className: "col-3", render: "Component Model"},
        // {key: "configuration", className: "col-1", render: "Configure"},
        {key: "cancel", className: "col-2", render: "Delete"},
    ];

    function onRemoveSettingTemplate(param: SettingTemplate) {
        return () => {
            setCurrentST(param);
            setConfirmDeleteShow(true);
        };
    }

    function onRemoveSettingTemplateVisibility(param: SettingTemplateVisibility) {
        return () => {
            setCurrentSTVisibility(param);
            setConfirmSTVDeleteShow(true);
        };
    }

    function onCreateUpdateSettingTemplateVisibility(guid: string, param?: SettingTemplateVisibility) {
        return () => {
            setCurrentSTGuid(guid);
            setCurrentSTVisibility(param);
            setConfirmCreateSTVShow(true);
        };
    }

    function onRenameSettingTemplate(param: SettingTemplate) {
        return () => {
            setCurrentST(param);
            setConfirmRenameShow(true);
        };
    }

    React.useEffect(() => {
        if (props.selectedDeviceModel != null) {
            props.setLoading(true);
            searchSettingTemplateByDeviceModelGuid(props.selectedDeviceModel);
        }
    }, []);

    function onDeleteSettingTemplate() {
        return () => {
            props.setLoading(true);
            configurabilityController.deleteSettingTemplate(currentST.guid).then(
                (response) => {
                    if (response.status >= 400 && response.status <= 500) {
                        services.errorNotification(" ", response.body.message);
                    }
                    if (response.status >= 200 && response.status <= 299) {
                        services.successNotification(" ", "Setting Template deleted successfully");
                    }
                    setConfirmDeleteShow(false);
                    searchSettingTemplateByDeviceModelGuid(props.selectedDeviceModel);
                });
        };
    }

    function onDeleteSettingVisibility() {
        return () => {
            configurabilityController.deleteSettingTemplateVisibility(currentSTVisibility.guid).then(
                (response) => {
                    props.setLoading(true);
                    if (response.status >= 400 && response.status <= 500) {
                        services.errorNotification(" ", response.body.message);
                    }
                    if (response.status >= 200 && response.status <= 299) {
                        services.successNotification(" ", "Setting Template visibility deleted successfully");
                    }
                    setConfirmSTVDeleteShow(false);
                    searchSettingTemplateByDeviceModelGuid(props.selectedDeviceModel);
                });
        };
    }

    function onSearchDeviceModel(suggestion: Suggestion<DeviceModel>) {
        if (suggestion != null) {
            props.setSelectedDeviceModel(suggestion);
            props.setLoading(true);
            searchSettingTemplateByDeviceModelGuid(suggestion);
        }
    }

    function searchSettingTemplateByDeviceModelGuid(suggestion: Suggestion<DeviceModel>) {
        configurabilityController.findSettingTemplateByDeviceModelGuid(suggestion.value, true).then(
            (response) => {
                props.setHasDefault(response.set.some((e) => e.default));
                setSettingTemplateList(response.set);
                props.setLoading(false);
            },
        );
    }

    function visibilityRow(visibility: SettingTemplateVisibility, value: SettingTemplate) {
        const c: boolean = !!visibility.companyName;
        const ct: boolean = !!visibility.componentTypeName;
        const cm: boolean = !!visibility.componentModelName;
        const global = !c && !ct && !cm;
        return (
            <tr key={visibility.guid}>
                <td style={{verticalAlign: "middle"}}>
                    {global ? "Global" : (c ? visibility.companyName : "")}
                </td>
                <td style={{verticalAlign: "middle"}}>
                    {global ? "Global" : (ct ? visibility.componentTypeName : "")}
                </td>
                <td style={{verticalAlign: "middle"}}>
                    {global ? "Global" : (cm ? visibility.componentModelName : "")}
                </td>
                {/*<td>*/}
                {/*    <Button*/}
                {/*        className={"ml-auto"}*/}
                {/*        iconProps={services.dotsIconButton()}*/}
                {/*        onClick={onCreateUpdateSettingTemplateVisibility(value.guid, visibility)}*/}
                {/*    />*/}
                {/*</td>*/}
                <td>
                    <Button
                        iconProps={services.removeIconButton()}
                        onClick={onRemoveSettingTemplateVisibility(visibility)}
                    />
                </td>
            </tr>
        );
    }

    const settingTemplateListResult = React.useMemo(() => {
        return (
            <Accordion>
                {settingTemplateList != null && settingTemplateList.length > 0 ? settingTemplateList.map(
                    (value) => (
                        <AccordionSection
                            name={value.name + (value.default ? " (default)" : "")}
                            key={value.guid}
                        >
                            <Row className={"mb-5"} style={{justifyContent: "space-between"}}>
                                <div>
                                    <Button
                                        className={"mr-4"}
                                        iconProps={services.dotsIconButton()}
                                        variant={"secondary"}
                                        onClick={onRenameSettingTemplate(value)}
                                    >Rename Setting Template
                                    </Button>
                                    <Button
                                        className={"mr-4"}
                                        iconProps={services.dotsIconButton()}
                                        variant={"secondary"}
                                        /* tslint:disable-next-line:jsx-no-lambda */
                                        onClick={() => props.onCreateOrUpdateNewSettingTemplate(value)}
                                    >Edit Setting Template
                                    </Button>
                                    <Button
                                        className={"mr-4"}
                                        variant={"secondary"}
                                        iconProps={services.addIconButton()}
                                        onClick={onCreateUpdateSettingTemplateVisibility(value.guid)}
                                    >Create new Visibility
                                    </Button>
                                </div>
                                <Button
                                    variant={"tertiary"}
                                    style={{color: "red"}}
                                    iconProps={services.removeIconButton()}
                                    onClick={onRemoveSettingTemplate(value)}
                                >Delete Setting Template
                                </Button>
                            </Row>
                            {value.visibilities != null && value.visibilities.length > 0 && (
                                <>
                                    <Row style={{justifyContent: "center"}}>
                                        <H6>Visibilities for {value.name}:</H6>
                                    </Row>
                                    <Table striped={true} bordered={true} hover={true}>
                                        <thead>
                                        <tr>
                                            {columns.map((col) => (
                                                <th key={col.key} className={col.className}>{col.render}</th>))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {value.visibilities.map((visibility) => visibilityRow(visibility, value))}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </AccordionSection>
                    )) : (
                    <EmptyState>
                        <EmptyState.Icon size={96} glyph={icons.DISCONNECTED_CLOUD}/>
                        No Setting Template found, create one or select other device model
                    </EmptyState>
                )}
            </Accordion>
        );
    }, [settingTemplateList, props.selectedDeviceModel]);

    return (
        <>
            <Form id={"deviceModelSection"}>
                <Row className={"justify-content-center"}>
                    <Col style={{textAlign: "center"}}>
                        <H5>Select the device model on which you're going to create the Setting Template</H5>
                    </Col>
                </Row>
                <Row className={"my-2"}>
                    <Col style={{maxWidth: "30%"}}>
                        <Form.Label>Device Model *</Form.Label>
                        <SelectInput
                            onChange={onSearchDeviceModel}
                            name="Device Model"
                            options={props.deviceModels}
                            searchable={true}
                            value={props.selectedDeviceModel}
                        />
                    </Col>
                </Row>
                {props.selectedDeviceModel != null && (
                    <>
                        {settingTemplateList != null && settingTemplateList.length > 0 ? (
                            <Row className={"justify-content-center"}>
                                <Col style={{textAlign: "center"}}>
                                    <h5>
                                        Setting Templates for: {" " + props.selectedDeviceModel.label}
                                    </h5>
                                </Col>
                            </Row>
                        ) : (<></>)}
                        <Row>
                            <Col className={"mx-4"}>
                                {settingTemplateListResult}
                            </Col>
                        </Row>
                        <Row style={{justifyContent: "end"}} className={"mt-4"}>
                            <Col style={{textAlign: "right"}} className={"col-4 mt-auto"}>
                                <Button
                                    className={"mx-0"}
                                    variant={"strong"}
                                    /* tslint:disable-next-line:jsx-no-lambda */
                                    onClick={() => props.onCreateOrUpdateNewSettingTemplate()}
                                >
                                    Create new Setting template
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
                <ConfirmModal // delete setting template
                    onConfirm={onDeleteSettingTemplate()}
                    show={confirmDeleteShow}
                    onHide={services.onHideModal(setConfirmDeleteShow)}
                />
                <ConfirmModal // delete setting template visibility
                    onConfirm={onDeleteSettingVisibility()}
                    show={confirmDeleteSTVShow}
                    onHide={services.onHideModal(setConfirmSTVDeleteShow)}
                />
                <RenameModal
                    onSearchDeviceModel={searchSettingTemplateByDeviceModelGuid}
                    setCurrentStep={props.setCurrentStep}
                    currentSettingTemplate={currentST}
                    show={confirmRenameShow}
                    onHide={services.onHideModal(setConfirmRenameShow)}
                    setLoading={props.setLoading}
                    selectedDeviceModel={props.selectedDeviceModel}
                />
                <CreateVisibilityModal
                    currentSTGuid={currentSTGuid}
                    onSearchDeviceModel={searchSettingTemplateByDeviceModelGuid}
                    setCurrentStep={props.setCurrentStep}
                    currentSTV={currentSTVisibility}
                    show={confirmCreateSTVShow}
                    onHide={services.onHideModal(setConfirmCreateSTVShow)}
                    setLoading={props.setLoading}
                    selectedDeviceModel={props.selectedDeviceModel}
                />
            </Form>
        </>
    );
};

export default DeviceModelSection;
