import {Icon, icons} from "@danfoss/etui-core";
import * as React from "react";
import {
    consumeTargetValue,
    stopPropagation,
    toOutsideElements,
} from "../../../../../../../../utils/eventsHandling";
import {constant} from "../../../../../../../../utils/functions";
import {similar} from "../../../../../../../../utils/strings";
import {After} from "../../../../../../../common/After";
import {Td} from "../../../../../../../common/DataTable";
import {Option} from "../../../../../../../common/form/Option";
import {Select, SelectRef} from "../../../../../../../common/form/Select";
import {isVirtualComponentBrand, useAbstractComponentBrands} from "../../../AbstractComponentBrandsContext";
import {useAlreadyUsedComponentBrandsGuids} from "../../AlreadyUsedComponentBrandsContext";
import {ComponentModelCompaniesTableDataCellProps} from "../types";
import {Button} from "../../../../../../../common/Button";

import "./styles.scss";

export function ComponentBrandDataCell(
    {
        value,
        state,
        disabled,
        onValueChange,
        onResetValue,
    }: ComponentModelCompaniesTableDataCellProps,
) {

    const inputRef = React.useRef<SelectRef>();

    const alreadyUsedComponentBrandsGuids = useAlreadyUsedComponentBrandsGuids();
    const {componentBrands, addBrand} = useAbstractComponentBrands();

    const [brandSearchText, setBrandSearchText] = React.useState<string>("");

    const [open, setOpen] = React.useState(false);

    const componentBrandsList = componentBrands.toArray((_, b) => b);

    const newBrandDescription = brandSearchText.trim().replace(/(?<=\s)(\s+)/g, "");

    const isNewBrand = componentBrandsList.findIndex((b) => similar(
        b.description,
        newBrandDescription,
        { method: "equals", case: "insensitive", check: "single-whitespace" },
    )) < 0;

    const brandOptions = open
        ? componentBrandsList
            .filter((brand) => similar(brand.description, brandSearchText))
            .map((brand) => {
                const {key, ...optionProps} = isVirtualComponentBrand(brand) ? {
                    key: brand.timestamp,
                    value: brand.timestamp,
                } : {
                    key: brand.guid,
                    value: brand.guid,
                    disabled:  alreadyUsedComponentBrandsGuids.has(brand.guid) && brand.guid !== value,
                };

                return (
                    <Option key={key} {...optionProps} className={"brand-option"}>
                        <span className={"brand-option-description"}>{brand.description}</span>
                    </Option>
                );
            })
        : (componentBrands.get(value, (brand) => {
                // Todo move out of component is pure function
                const {key, ...optionProps} = isVirtualComponentBrand(brand) ? {
                    key: brand.timestamp,
                    value: brand.timestamp,
                } : {
                    key: brand.guid,
                    value: brand.guid,
                };
                return [(
                    <Option key={key} {...optionProps} className={"brand-option"}>
                        <span className={"brand-option-description"}>{brand.description}</span>
                    </Option>
                )];
            }) ?? []);

    function addAndUseNewComponentBrand() {
        inputRef.current.toggle();
        const virtualComponentBrand = addBrand(newBrandDescription);
        setBrandSearchText("");
        onValueChange(virtualComponentBrand.timestamp);
    }

    function consumeValue(str: string) {
        const valueAsNumber = Number(str);
        onValueChange(componentBrands.has(valueAsNumber) ? valueAsNumber : str);
    }

    return (
        <Td
            state={state}
            className={"component-brand-cell-company-component-model-cell company-component-model-field-data-cell"}
            onBlur={toOutsideElements(constant(setOpen, false), constant(setBrandSearchText, ""))}
            onFocus={constant(setOpen, true)}
        >
            <Select value={value ?? ""} onChange={consumeTargetValue(consumeValue)} disabled={disabled} ref={inputRef}>
                <input
                    type={"text"}
                    value={brandSearchText}
                    onChange={stopPropagation(consumeTargetValue(setBrandSearchText))}
                    placeholder={"Search brand"}
                    autoFocus={true}
                    onFocus={constant(setBrandSearchText, "")}
                />
                <After>
                    {state === "edited" ? (
                        <span
                            className={"undo-changed tertiary-btn"}
                            tabIndex={0}
                            onClick={stopPropagation(onResetValue)}
                        >
                            <Icon glyph={icons.UNDO} size={16}/>
                        </span>
                    ) : null}
                </After>
                <menu className={"brands-menu"}>
                    <Option
                        value={""}
                        className={"brand-option"}
                        disabled={alreadyUsedComponentBrandsGuids.has(null) && Boolean(value)}
                    >
                        <span className={"brand-option-description"}>[No Brand]</span>
                    </Option>
                    {brandOptions}
                    {isNewBrand && brandSearchText.length > 0 ? (
                        <footer className={"brands-menu-footer"}>
                            <Button
                                variant={"primary-positive"}
                                className={"new-brand-btn"}
                                onClick={stopPropagation(addAndUseNewComponentBrand)}
                            >
                                New brand "<span>{newBrandDescription}</span>"
                            </Button>
                        </footer>
                    ) : null}
                </menu>
            </Select>
        </Td>
    );
}
