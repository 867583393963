import {ProcessingSettingList} from "./processingSetting";

export interface RawDataDiagnosticType {
    guid: string;
    diagnosticTypeGuid: string;
    params: ProcessingSettingList;
    name: string;
}

export interface RawDataDiagnosticTypeList {
    set: RawDataDiagnosticType[];
}

export function isRawDataDiagnosticType(object: any) {
    return 'diagnosticTypeGuid' in object;
}