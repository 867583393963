import {Button} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";
import DeviceModelController from "../../../controllers/api/DeviceController";
import Services from "../../../controllers/utils/Services";
import {
    SettingTemplate,
    SettingTemplateCreation,
    TemplateConfigurationSlave,
} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import ConfigurabilityService from "../ConfigurabilityService";
import SettingTemplateSlaveSection from "../sections/SettingTemplateSlaveSection";

interface STSlaveParamProps {
    show: boolean;
    onHide: () => void;
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    setLoading: (loading: boolean) => void;
}

// tslint:disable:max-line-length
const CreateSTSlaveModal: React.FC<STSlaveParamProps> = (props) => {
    const deviceModelController: DeviceModelController = new DeviceModelController();
    const services = new Services();
    const configurabilityService = new ConfigurabilityService();
    const [currentSettingTemplate, setCurrentSettingTemplate] = React.useState<SettingTemplate>(undefined);
    const [settingTemplateCreation, setSettingTemplateCreation] = React.useState<SettingTemplateCreation>(undefined);

    React.useEffect(() => {
        let tempSettingTemplate: SettingTemplate;
        tempSettingTemplate = {
            default: false, type: "SLAVE",
            name: "",
            templateContent: {
                type: "slave",
                deviceModelGuid: props.selectedDeviceModel != null ? props.selectedDeviceModel.value : null,
                dataTemplate: {
                    alarms: [],
                    diagnostics: [],
                },
            },
        };
        const tempSettingTemplateCreation: SettingTemplateCreation = {
            default: false,
            type: "SLAVE",
            name: "",
            templateContentSlave: tempSettingTemplate.templateContent as TemplateConfigurationSlave,
            templateContentTelemetry: null,
            binaryContent: null,
            companyGuid: null,
            componentModelGuid: null,
            componentTypeGuid: null,
        };
        setSettingTemplateCreation(tempSettingTemplateCreation);
        setCurrentSettingTemplate(tempSettingTemplate);
    }, [props.selectedDeviceModel]);

    function onSubmit() {
        props.setLoading(true);
        const temp = {...currentSettingTemplate};
        const slave = temp.templateContent as TemplateConfigurationSlave;
        if (slave.dataTemplate != null) {
            configurabilityService.filterUncheckAndSetCustomType(slave.dataTemplate);
        }
        temp.templateContent = slave;
        setCurrentSettingTemplate(temp);

        const newSettingTemplate = {...settingTemplateCreation};
        newSettingTemplate.templateContentSlave =
            currentSettingTemplate.templateContent as TemplateConfigurationSlave;
        deviceModelController.createSettingTemplate(props.selectedDeviceModel.value, JSON.parse(JSON.stringify(newSettingTemplate)))
            .then((response: { status: number, body: any }) => {
                if (response.status >= 400 && response.status <= 500) {
                    services.errorNotification(" ", response.body.message);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification("Setting Template slave created", response.body.name + " created successfully ");
                }
                props.setLoading(false);
                props.onHide();

            });
    }

    return (
        <>
            <Modal{...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create a setting template Slave
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SettingTemplateSlaveSection
                        isModal={true}
                        setCurrentStep={props.setCurrentStep}
                        selectedDeviceModel={props.selectedDeviceModel}
                        onSubmit={onSubmit}
                        setSettingTemplateCreation={setSettingTemplateCreation}
                        settingTemplateCreation={settingTemplateCreation}
                        currentSettingTemplate={currentSettingTemplate}
                        setCurrentSettingTemplate={setCurrentSettingTemplate}
                        hasDefault={true}
                        setHasDefault={null}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="strong"
                        disabled={currentSettingTemplate ? !currentSettingTemplate.name : true}
                        onClick={onSubmit}
                    >
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateSTSlaveModal;
