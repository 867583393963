import {identity} from "../../../../../../../utils/functions";
import {omit} from "../../../../../../../utils/objects";
import {stringOrEmpty} from "../../../../../../../utils/strings";
import {useStatefulComponentModelChangeObserver} from "../../../../contexts/ComonentModelChangesContext";
import {ComponentModelChangeEvent} from "../../../../contexts/ComonentModelChangesContext/types";
import {changeWithKey} from "../../../../contexts/ComonentModelChangesContext/utils";
import {AbstractCompanyComponentModel} from "../../../types";
import {isVirtualCompanyComponentModel} from "../../../utils";
import {ComponentModelCompaniesTableDataCellProps, RequestedParams} from "./types";

type ParamName = keyof RequestedParams;
type ParamValue<K extends ParamName> = (param: RequestedParams[K]) => string | number;
type MapParamValue<K extends ParamName> = (value: string | number) => string | number | Partial<RequestedParams[K]>;

function action(
    event: ComponentModelChangeEvent,
    prevState: RequestedParams,
    {company, componentBrand}: AbstractCompanyComponentModel,
): RequestedParams {
    if (event.type === "result") {
        return {};
    } else if (event.type !== "updating") {
        const changedCall = event.target.find(changeWithKey(company, componentBrand));
        if (changedCall?.type !== "update-existing-company-component-model" &&
            changedCall?.type !== "update-new-company-component-model") {
            return prevState;
        }
        return event.type === "remove" ? {} : changedCall.params[1];
    }
    return prevState;
}

export function useCompanyComponentModelRequestedParams(initialParams: AbstractCompanyComponentModel) {

    const [requestedChanges, changesManager] = useStatefulComponentModelChangeObserver(
        action,
        {},
        [initialParams],
    );

    function update(newParams: RequestedParams) {
        changesManager.updateCompanyComponentModelParams(initialParams, newParams);
    }

    const isBeingRemoved = isVirtualCompanyComponentModel(initialParams) && initialParams.__virtual === "remove";

    return {
        isBeingRemoved,
        registerCell<K extends ParamName, V extends string>(
            paramName: K,
            paramValue: ParamValue<K> = stringOrEmpty,
            mapValue: MapParamValue<K> = identity,
        ): ComponentModelCompaniesTableDataCellProps {
            const isEdited = paramName in (requestedChanges ?? {});
            const isUsedByAnyComponent = initialParams.componentsCount > 0;
            const currentParamValue = isEdited
                ? paramValue(requestedChanges?.[paramName])
                : paramValue(initialParams?.[paramName]);
            return {
                state: isEdited && !isVirtualCompanyComponentModel(initialParams) ? "edited" : "default",
                value: currentParamValue,
                disabled: isUsedByAnyComponent || isBeingRemoved,
                onValueChange(newValue) {
                    const isDifferentFromOriginalValue = newValue !== (paramValue(initialParams?.[paramName]) ?? "");
                    const isDifferentFromRequestedValue =
                        newValue !== paramValue(requestedChanges?.[paramName]) ||
                        !isEdited ;
                    if (isDifferentFromOriginalValue && isDifferentFromRequestedValue) {
                        update({...requestedChanges, [paramName]: mapValue(newValue)});
                    } else if (!isDifferentFromOriginalValue) {
                        update(omit<RequestedParams, ParamName[]>(requestedChanges, paramName));
                    }
                },
                onResetValue() {
                    update(omit<RequestedParams, ParamName[]>(requestedChanges, paramName));
                },
            };
        },
        remove() {
            if (!isBeingRemoved) {
                changesManager.removeFromCompany(initialParams);
            }
        },
        keep() {
            if (isBeingRemoved) {
                changesManager.addToCompany(initialParams.company, initialParams.componentBrand);
            }
        },
    };
}
