import {Button, SelectInput, TextInput, Toggle} from "@danfoss/etui-core";

import * as React from "react";
// @ts-ignore
import {Col, Form, Modal, ModalProps, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {FunctionCodeModbus} from "../../../models/configurability/configurabilityEnumsModel";
import {AcquisitionSetting} from "../../../models/configurability/rawData/acquisitionSettingModel";
import {ProcessingSetting} from "../../../models/configurability/rawData/processingSetting";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {Suggestion} from "../../../models/Suggestion";

interface RawDataParamProps {
    setParam: (param) => void;
    param: AcquisitionSetting | ProcessingSetting;
    modalProps: ModalProps;
    currentRD: RawData;
    setCurrentRD: (rawData: RawData) => void;
    rawDataName: string;
    samplings: Array<Suggestion<any>>;
    embeddedTypes: Array<Suggestion<string>>;
    functionCodeModbusSuggestion: Array<Suggestion<FunctionCodeModbus>>;
    conversionFunctions: Array<Suggestion<any>>;
    setAcquisitions: (acquisitions: AcquisitionSetting[]) => void;
}

// tslint:disable:max-line-length
const RawDataAcquisitionModal: React.FC<RawDataParamProps> = (props) => {
    const services: Services = new Services();
    const [sampling, setSampling] = React.useState<string>(undefined);
    const [embeddedType, setEmbeddedType] = React.useState<Suggestion<string>>(undefined);
    const [registerCode, setRegisterCode] = React.useState<number>(undefined);
    const [functionCodeModbus, setFunctionCodeModbus] = React.useState<Suggestion<FunctionCodeModbus>>(undefined);
    const [extendedCode, setExtendedCode] = React.useState<number>(undefined);
    const [conversionFunction, setConversionFunction] = React.useState<string>(undefined);
    const [hasRegister, setHasRegister] = React.useState<boolean>(false);

    const acquisitionSetting: AcquisitionSetting = props.param as AcquisitionSetting;
    React.useEffect(() => {
        setSampling(props.modalProps.show && acquisitionSetting ? acquisitionSetting.sampling : undefined);
        setEmbeddedType(props.modalProps.show && acquisitionSetting ? props.embeddedTypes.find((e) => e.label === acquisitionSetting.code) : undefined);
        setRegisterCode(props.modalProps.show && acquisitionSetting && acquisitionSetting.register ? acquisitionSetting.register.code : undefined);
        setFunctionCodeModbus(acquisitionSetting ? getFunctionCodeOf(acquisitionSetting) : null);
        setExtendedCode(props.modalProps.show && acquisitionSetting && acquisitionSetting.register ? acquisitionSetting.register.extendedCode : undefined);
        setConversionFunction(props.modalProps.show && acquisitionSetting ? acquisitionSetting.conversionFunction : undefined);
        setHasRegister(!!(acquisitionSetting && acquisitionSetting.register));
    }, [props.modalProps.show, props.param]);

    const getFunctionCodeOf: (p: AcquisitionSetting) => Suggestion<FunctionCodeModbus> = (as: AcquisitionSetting) => {
        return !as.register ? null : props.functionCodeModbusSuggestion.find((e) => e.value === as.register.functionCodeModbus);
    };

    function onConfirmButton() {
        return () => {
            const as: AcquisitionSetting = {
                code: embeddedType ? embeddedType.label : null,
                conversionFunction,
                sampling,
            };
            if (hasRegister) {
                as.register = {
                    code: registerCode,
                    extendedCode,
                    functionCodeModbus: functionCodeModbus.value,
                };
            }
            const tempRawData: RawData = {...props.currentRD};
            let tempAcq: AcquisitionSetting[] = [...tempRawData.params.acquisitions];
            if (props.param) {
                tempAcq[services.findItem(tempRawData.params.acquisitions, props.param)] = as;
            } else {
                tempAcq = [...props.currentRD.params.acquisitions, as];
            }
            tempRawData.params.acquisitions = tempAcq;
            props.setAcquisitions(tempAcq);
            props.setCurrentRD(tempRawData);
            props.modalProps.onHide();
        };
    }

    function mandatoryFieldsCheck() {
        return (services.isEmpty(sampling) || services.isEmpty(conversionFunction) ||
            (hasRegister && (services.numberUndefinedOrEmpty(registerCode) ||
                services.isEmpty(functionCodeModbus ? functionCodeModbus.value : null)))
        );
    }

    function checkboxHandler() {
        return (value) => {
            if (value.target.checked) {
                setEmbeddedType(null);
                const tempParam: AcquisitionSetting = props.param as AcquisitionSetting;
                if (tempParam != null) {
                    delete tempParam.code;
                    tempParam.register = {
                        code: undefined,
                        functionCodeModbus: "",
                        extendedCode: undefined,
                    };
                    props.setParam(tempParam);
                }

            } else {
                const tempParam: AcquisitionSetting = props.param as AcquisitionSetting;
                if (tempParam != null) {
                    tempParam.code = null;
                    props.setParam(tempParam);
                }
                setRegisterCode(null);
                setExtendedCode(null);
                setFunctionCodeModbus(null);
            }
            setHasRegister(value.target.checked);
        };
    }

    return (
        <>
            <Modal
                {...props.modalProps}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add acquisition {props.rawDataName.trim().length > 0 ? "for " + props.rawDataName : ""}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col>
                        <Row className={"mt-2 d-flex flex-column"}>
                            <Form.Label>Sampling *</Form.Label>
                            <>
                                <SelectInput
                                    onChange={services.onSelectedLabel(setSampling)}
                                    name="Sampling"
                                    label="Insert sampling"
                                    options={props.samplings}
                                    searchable={true}
                                    value={props.samplings.find(
                                        (object) => object.label === sampling)}
                                />
                            </>
                        </Row>
                        <Row className={"mt-2 d-flex flex-column"}>
                            <Form.Label>Conversion Function *</Form.Label>
                            <>
                                <SelectInput
                                    onChange={services.onSelectedLabel(setConversionFunction)}
                                    name="ConversionFunction"
                                    label="Insert a conversion Function"
                                    options={props.conversionFunctions}
                                    searchable={true}
                                    value={props.conversionFunctions.find
                                    ((object) => object.label === conversionFunction)}
                                />
                            </>
                        </Row>

                        <Row className={"mt-2 d-flex flex-column"}>
                            <Form.Label>From Register</Form.Label>
                            <Toggle
                                name={"Integrate"}
                                isChecked={hasRegister}
                                onChange={checkboxHandler()}
                                renderLabels={services.handleCheckboxLabel()}
                            />
                        </Row>
                        {hasRegister ? (
                            <>
                                <Row className={"mt-2 d-flex flex-column"}>
                                    <Form.Label>Register Code *</Form.Label>
                                    <TextInput
                                        name={"formRegisterCode"}
                                        label={"Insert Register Code"}
                                        type={"text"}
                                        value={registerCode}
                                        onChange={services.handleNumberOnChange(setRegisterCode)}
                                    />
                                </Row>
                                <Row className={"mt-2 d-flex flex-column"}>
                                    <Form.Label>Function Code Modbus *</Form.Label>
                                    <>
                                        <SelectInput
                                            onChange={services.onSelectedItem(setFunctionCodeModbus)}
                                            name="Function Code Modbus"
                                            label="Insert Function Code Modbus"
                                            options={props.functionCodeModbusSuggestion}
                                            searchable={true}
                                            value={functionCodeModbus}
                                        />
                                    </>
                                </Row>
                                <Row className={"mt-2 d-flex flex-column"}>
                                    <Form.Label>Extended Code</Form.Label>
                                    <TextInput
                                        name={"formExtendedCode"}
                                        label={"Insert Extended Code"}
                                        type={"text"}
                                        value={extendedCode}
                                        onChange={services.handleNumberOnChange(setExtendedCode)}
                                    />
                                </Row>
                            </>
                        ) : (
                            <Row className={"mt-2 d-flex flex-column"}>
                                <Form.Label>Embedded Type (empty for Input Acquisition)</Form.Label>
                                <SelectInput
                                    clearable={true}
                                    name={"formCode"}
                                    label={"Insert embedded type"}
                                    options={props.embeddedTypes}
                                    value={embeddedType}
                                    onChange={services.onSelectedClearable(setEmbeddedType)}
                                />
                            </Row>
                        )}
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="strong"
                        disabled={mandatoryFieldsCheck()}
                        onClick={onConfirmButton()}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RawDataAcquisitionModal;
