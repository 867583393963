import {Button, icons, Spinner, SpinnerSize} from "@danfoss/etui-core";
import * as React from "react";
import {useDeviceStorageManager} from "../../DeviceStorageManagerContext";
import {DeviceStorageManagerState} from "../../types";
import {SectionTitle} from "../SectionTitle";
import {DateTimePicker} from "./DateTimePicker";
import {StorageLocationInput} from "./StorageLocationInput";

import "./store-devices.scss";

export function StoreDevices() {

    const { state, companyGuid, putDevicesIntoStorage } = useDeviceStorageManager();

    const [locationGuid, setLocationGuid] = React.useState<string>();
    const [timestamp, setTimestamp] = React.useState<number>();

    React.useEffect(() => {
        if (state === DeviceStorageManagerState.INITIAL) {
            setLocationGuid(undefined);
            setTimestamp(undefined);
        }
    }, [state]);

    if (state !== DeviceStorageManagerState.DEVICE_SEARCH_COMPLETE
        && state !== DeviceStorageManagerState.STORING_DEVICES
        && state !== DeviceStorageManagerState.DONE) {
        return null;
    }

    function updateDate(e: React.ChangeEvent<{ value: number }>) {
        if (isNaN(e.target.value as any)) {
            return;
        }
        setTimestamp(e.target.value);
    }

    function storeDevices() {
        if (isNaN(timestamp)) {
            putDevicesIntoStorage(locationGuid);
            return;
        }
        putDevicesIntoStorage(locationGuid, new Date(timestamp));
    }

    return (
        <>
            <section className={"sub-section-destination"}>
                <SectionTitle glyph={icons.INFO_CIRCLE}>
                    Destination
                </SectionTitle>
                <StorageLocationInput
                    companyGuid={companyGuid}
                    locationGuid={locationGuid}
                    onLocationGuidChange={setLocationGuid}
                />
            </section>
            <section className={"sub-section-date-and-time"}>
                <SectionTitle
                    glyph={icons.HELP_CIRCLE}
                    explanationTitle={"Date and time of storage"}
                    explanation={
                        "The date and time the devices have entered the storage location. " +
                        "If not set, the date of entry will be the time of request to the server."
                    }
                >
                    <span>
                        Date & Time <span className={"date-and-time-not-required"}>(Not required)</span>
                    </span>
                </SectionTitle>
                <DateTimePicker onChange={updateDate}/>
            </section>
            <Button
                variant={"strong"}
                styles={{root: {width: "100%"}}}
                disabled={!locationGuid || state === DeviceStorageManagerState.STORING_DEVICES}
                onClick={storeDevices}
            >
                { state === DeviceStorageManagerState.STORING_DEVICES
                    ? <Spinner size={SpinnerSize.small} styles={{circle: {color: "#ffffff"}}}/>
                    : "Change devices' storage location"
                }
            </Button>
        </>
    );
}
