export interface DeviceModelInterfaceType {
    guid: string;
    name: string;
    incompatibles: number[];
    supportBus: boolean;
    propertiesStructure: PropertiesStructure;
    interfaceTypeName: string;
    interfaceTypeGuid: string;
    code: number;
}

export enum PropertiesStructure {
    INPUT = "INPUT", OUTPUT = "OUTPUT", ANALOG = "ANALOG", SERIAL = "SERIAL", EMBEDDED = "EMBEDDED", BLUETOOTH = "BLUETOOTH", CAN = "CAN",
}

export interface DeviceModelInterfaceTypeToCheck {
    item: DeviceModelInterfaceType;
    checked: boolean;
    disabled: boolean;
}

export interface DeviceModelInterfaceTypeSet {
    set: DeviceModelInterfaceType[];
}
