import * as React from "react";
import {Observe} from "./types";

type StatefulObserverAction<S = unknown, O = unknown, Args extends unknown[] = []>
    = (value: O, prevState: S, ...params: Args) => S;

type StatefulObserver<S> = [S];

type InitState<S = unknown> =  S | (() => S);

export function useStatefulObserver<S, O>(observe: Observe<O>, initState: InitState<S>): StatefulObserver<S> ;

export function useStatefulObserver<S, O, Args extends unknown[]>(
    observe: Observe<O>,
    action: StatefulObserverAction<S, O, Args>,
    initState: InitState<S>,
    args?: Args,
): StatefulObserver<S> ;

export function useStatefulObserver(
    observe: Observe,
    actionOnInitState?: StatefulObserverAction | InitState,
    initState?: InitState,
    params: unknown[] = [],
) {
    const [state, setState] = React.useState(
        typeof initState === "undefined" && typeof actionOnInitState !== "undefined"
            ? actionOnInitState
            : initState,
    );

    React.useEffect(() => {
        const disconnect = observe((value) => {
            setState((prevState) => {
                if (typeof actionOnInitState === "function") {
                    return actionOnInitState(value, prevState, ...params);
                }
                return value;
            });
        });
        return () => {
            disconnect();
        };
    }, [observe]);

    return [state];
}
