import * as React from "react";
import {Modal} from "react-bootstrap";
import {Device} from "../../../../../models/device/device";
import {getCurrentInstallationOfDevice} from "../../../utils";
import {ItemDetailView} from "../../../../common/ItemDetailView";
import {DeviceCoreInfoView} from "../DeviceCoreInfoView";

interface InstalledDeviceInfoModalProps {
    show: boolean;
    device: Device;
    onClose(): void;
}

export function InstalledDeviceInfoModal({show, device, onClose}: InstalledDeviceInfoModalProps) {

    const installation = getCurrentInstallationOfDevice(device);
    const installationDateISOStr = installation.installationDate?.substring(0, 29);
    const [installationDateTimezone] = installation.installationDate?.match(/(?<=\[)(.*?)(?=])/g) ?? [];

    const installationDate = new Date(installationDateISOStr);
    const equipment = installation?.equipment;

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton={true}>
                <h4>Device</h4>
            </Modal.Header>
            <Modal.Body className={"device-info"}>
                <DeviceCoreInfoView device={device.core}/>
                <section className={"device-installation-info"}>
                    <h5>Current Installation</h5>
                    <div className={"device-info-grid"}>
                        <span>Equipment</span>
                        <ItemDetailView
                            heading={
                                equipment?.customerCode?.code
                                ?? equipment?.manufacturerCode?.code
                                ?? "-"
                            }
                            subheading={equipment?.model?.name ?? "-"}
                        />
                        <span>Location</span>
                        <ItemDetailView
                            heading={device.location?.description ?? device.location?.code ?? "-"}
                            subheading={device.location?.address?.fullAddress}
                        />
                        <span>Installation Date</span>
                        <ItemDetailView
                            heading={installationDate.toLocaleDateString()}
                            subheading={`${installationDate.toLocaleTimeString()} ${installationDateTimezone ?? ""}`}
                        />
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}
