import {Button} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";

interface ConfirmModalProps {
    onConfirm: () => void;
    show: boolean;
    onHide: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
    return (
        <Modal
            {...props}
            size="sm"
            className="my-auto"
            style={{marginTop: "30%"}}
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to continue?
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={props.onHide}>
                    No
                </Button>
                <Button variant="strong" onClick={props.onConfirm}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
