import * as React from "react";
import {doNothing} from "../utils/functions";

export function useEventListener<K extends keyof HTMLElementEventMap, E extends React.SyntheticEvent<unknown>>(
    eventTarget: EventTarget,
    type: K,
    listener: (e: E) => void,
    options?: boolean | AddEventListenerOptions,
): void;

export function useEventListener(eventTarget: EventTarget, type: string, listener: (e: Event) => void, options): void {
    React.useEffect(() => {
        if (eventTarget) {
            eventTarget.addEventListener(type, listener, options);
            return () => {
                eventTarget.removeEventListener(type, listener, options);
            };
        }
        return doNothing;
    }, [eventTarget, listener]);
}
