import * as React from "react";
import {Observe} from "./types";

export function useStatelessObserver<T, Args extends readonly unknown[]>(
    observe: Observe<T>,
    callback: (value: T, ...args: Args) => void,
    args: Args,
) {
    React.useEffect(() => {
        const disconnect = observe((value) => {
            callback(value, ...args);
        });
        return () => {
            disconnect();
        };
    }, [args]);
}
