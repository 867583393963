import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {Card} from "react-bootstrap";
import ConfigurabilityController from "../../controllers/api/ConfigurabilityController";
import DeviceModelController from "../../controllers/api/DeviceController";
import Services from "../../controllers/utils/Services";
import {setLegacyBreadcrumb} from "../../index";
import {PatchList} from "../../models/company/patchModel";
import {
    DeviceModelInterfaceType,
    DeviceModelInterfaceTypeToCheck,
    PropertiesStructure,
} from "../../models/configurability/interfaceType/deviceModelInterfaceType";
import {
    SettingTemplate,
    SettingTemplateCreation,
    TemplateConfigurationSlave,
    TemplateConfigurationTelemetry,
    TemplateInterfaceConfig,
} from "../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../models/device/deviceModelModel";
import {Suggestion} from "../../models/Suggestion";
import * as LegacyPage from "../../page";
import LoadingModal from "../common/LoadingModal";
import ConfigurabilityService from "./ConfigurabilityService";
import DeviceModelSection from "./sections/DeviceModelSection";
import DeviceModelSlaveSection from "./sections/DeviceModelSlaveSection";
import InterfaceAvailableSection from "./sections/InterfaceAvailableSection";
import SettingTemplateSlaveSection from "./sections/SettingTemplateSlaveSection";
import VisibilitySection from "./sections/VisibilitySection";

export enum Sections { // Do not change the order
    deviceModelSection,
    interfaceAvailableSection,
    deviceModelSlaveSection,
    settingTemplateSlaveSection,
    visibilitySection,
}

export enum SectionsModal { // Do not change the order
    rawDataAlarmType,
    rawDataDiagnosticType,
}

export const CreateSettingTemplate: React.FC = () => {
    // controllers
    const configurabilityController: ConfigurabilityController = new ConfigurabilityController();
    const deviceModelController: DeviceModelController = new DeviceModelController();
    const services = new Services();
    const configurabilityService = new ConfigurabilityService();
    /* settingTemplate Hooks */
    const [currentSettingTemplate, setCurrentSettingTemplate] = React.useState<SettingTemplate>(undefined);
    const [settingTemplateCreation, setSettingTemplateCreation] = React.useState<SettingTemplateCreation>(undefined);

    const [oldSettingTemplate, setOldSettingTemplate] = React.useState<SettingTemplate>(undefined);

    // DeviceModel Hooks
    const [selectedDeviceModel, setSelectedDeviceModel] = React.useState<Suggestion<DeviceModel>>(undefined);
    const [deviceModels, setDeviceModels] = React.useState<Array<Suggestion<DeviceModel>>>([]);
    const [deviceModelSlaves, setDeviceModelSlaves] = React.useState<Array<Suggestion<DeviceModel>>>([]);

    const [availableDMInterface, setAvailableDMInterface] = React.useState<DeviceModelInterfaceTypeToCheck[]>([]);
    const [selectedDMInterfaces, setSelectedDMInterfaces] = React.useState<DeviceModelInterfaceType[]>([]);
    const [currentDMInterface, setCurrentDMInterface] = React.useState<DeviceModelInterfaceType>(undefined);
    const [hasDefault, setHasDefault] = React.useState<boolean>(false);

    /* Steps hooks */
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [currentInterfaceStep, setCurrentInterfaceStep] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        deviceModelController.findDeviceModels(false).then(
            (response) => {
                const deviceModelsSuggestions: Array<Suggestion<DeviceModel>> = response.list
                    .map((item) => ({
                        item: {
                            guid: item.guid,
                            name: item.name,
                            typeName: item.typeName,
                            isSlave: false,
                            defaultProperties: item.defaultProperties != null ? item.defaultProperties : null,
                        },
                        label: item.name,
                        value: item.guid,
                    }));
                deviceModelController.findDeviceModels(true).then(
                    (responseSlave) => {
                        const deviceModelSlavesSuggestions: Array<Suggestion<DeviceModel>> = responseSlave.list
                            .map((item) => ({
                                item: {
                                    guid: item.guid,
                                    name: item.name,
                                    typeName: item.typeName,
                                    isSlave: true,
                                    defaultProperties: item.defaultProperties != null ? item.defaultProperties : null,
                                },
                                label: item.name,
                                value: item.guid,
                            }));
                        setDeviceModelSlaves(deviceModelSlavesSuggestions);
                        setDeviceModels(deviceModelsSuggestions.concat(deviceModelSlavesSuggestions));
                        setLoading(false);
                    },
                );
            },
        );
    }, []);

    function onCreateOrUpdateNewSettingTemplate(settingTemplate: SettingTemplate) {
        if (selectedDeviceModel != null) {
            let tempSettingTemplate: SettingTemplate;
            const tempSettingTemplateCreation: SettingTemplateCreation = {
                name: "",
                templateContentSlave: null,
                templateContentTelemetry: null,
                binaryContent: null,
                companyGuid: null,
                componentModelGuid: null,
                componentTypeGuid: null,
                default: false,
                type: "",
            };
            if (selectedDeviceModel.item.isSlave) { // slaves
                if (settingTemplate != null) {
                    setOldSettingTemplate(JSON.parse(JSON.stringify(settingTemplate)));
                    tempSettingTemplate = settingTemplate;
                } else {
                    tempSettingTemplate = {
                        default: false, type: "SLAVE",
                        name: "",
                        templateContent: {
                            type: "slave",
                            deviceModelGuid: selectedDeviceModel.value,
                            dataTemplate: {
                                alarms: [],
                                diagnostics: [],
                            },
                        },
                    };
                }
                tempSettingTemplateCreation.name = tempSettingTemplate.name;
                tempSettingTemplateCreation.type = tempSettingTemplate.type;
                tempSettingTemplateCreation.default = tempSettingTemplate.default;
                tempSettingTemplateCreation.templateContentSlave = tempSettingTemplate
                    .templateContent as TemplateConfigurationSlave;

                setSettingTemplateCreation(tempSettingTemplateCreation);
                setCurrentSettingTemplate(tempSettingTemplate);
                setCurrentStep(Sections.settingTemplateSlaveSection);
            } else if (!selectedDeviceModel.item.isSlave) { // not slaves
                if (settingTemplate != null) {
                    setOldSettingTemplate(JSON.parse(JSON.stringify(settingTemplate)));
                    tempSettingTemplate = settingTemplate;
                } else {
                    tempSettingTemplate = {
                        default: false,
                        type: "",
                        name: "",
                        templateContent: {type: "telemetry", interfaces: []},
                    };
                }
                tempSettingTemplateCreation.name = tempSettingTemplate.name;
                tempSettingTemplateCreation.type = tempSettingTemplate.type;
                tempSettingTemplateCreation.default = tempSettingTemplate.default;
                tempSettingTemplateCreation.templateContentTelemetry = tempSettingTemplate
                    .templateContent as TemplateConfigurationTelemetry;

                setSettingTemplateCreation(tempSettingTemplateCreation);
                setCurrentSettingTemplate(tempSettingTemplate);

                setLoading(true);

                configurabilityController.findInterfaceTypesByDeviceModelGuid(selectedDeviceModel.value)
                    .then((response) => {
                        const temp: DeviceModelInterfaceTypeToCheck[] = response.set
                            .map((e) => ({
                                item: e,
                                checked: e.propertiesStructure === PropertiesStructure.EMBEDDED,
                                disabled: e.propertiesStructure === PropertiesStructure.EMBEDDED,
                            }));

                        // set check = true if already selected
                        if (tempSettingTemplate != null && tempSettingTemplate.guid != null) {
                            const interfaces = (tempSettingTemplate
                                .templateContent as TemplateConfigurationTelemetry).interfaces;
                            temp.map((e) => {
                                e.checked = interfaces
                                    .map((iArr) => iArr.deviceModelInterfaceTypeGuid)
                                    .some((guid) => guid === e.item.guid);
                                return e;
                            });
                        }

                        // set incompatibilities

                        temp.map((e) => {
                            if (e.item.propertiesStructure === PropertiesStructure.EMBEDDED) {
                                return e;
                            } else {
                                e.disabled = e.item.incompatibles != null && e.item.incompatibles.length > 0
                                    && e.item.incompatibles.some((j) =>
                                        temp.some((p) => p.item.code === j && p.checked));
                                return e;
                            }
                        });

                        temp.sort((a, b) => a.item.guid.localeCompare(b.item.guid));

                        setAvailableDMInterface(temp);
                        setLoading(false);
                        setCurrentStep(Sections.interfaceAvailableSection);
                    });
            }
        }
    }

    const deviceModelSection = (
        <DeviceModelSection
            setHasDefault={setHasDefault}
            setCurrentStep={setCurrentStep}
            selectedDeviceModel={selectedDeviceModel}
            setSelectedDeviceModel={setSelectedDeviceModel}
            deviceModels={deviceModels}
            onCreateOrUpdateNewSettingTemplate={onCreateOrUpdateNewSettingTemplate}
            setLoading={setLoading}
        />
    );

    const settingTemplateSlaveSection = (
        <SettingTemplateSlaveSection
            hasDefault={hasDefault}
            setHasDefault={setHasDefault}
            isModal={false}
            setCurrentStep={setCurrentStep}
            selectedDeviceModel={selectedDeviceModel}
            onSubmit={onCreateSettingTemplateSlave}
            setSettingTemplateCreation={setSettingTemplateCreation}
            settingTemplateCreation={settingTemplateCreation}
            currentSettingTemplate={currentSettingTemplate}
            setCurrentSettingTemplate={setCurrentSettingTemplate}
        />
    );

    const settingTemplateVisibilitySection = (
        <VisibilitySection
            currentSTGuid={currentSettingTemplate ? currentSettingTemplate.guid : null}
            setCurrentStep={setCurrentStep}
            setLoading={setLoading}
            selectedDeviceModel={selectedDeviceModel}
        />
    );

    const templateAvailableSection = (
        <InterfaceAvailableSection
            hasDefault={hasDefault}
            setHasDefault={setHasDefault}
            settingTemplateCreation={settingTemplateCreation}
            setSettingTemplateCreation={setSettingTemplateCreation}
            setCurrentSettingTemplate={setCurrentSettingTemplate}
            setCurrentStep={setCurrentStep}
            selectedDeviceModel={selectedDeviceModel}
            onSubmit={onInterfaceAvailableSectionSubmit}
            availableDMInterface={availableDMInterface}
            setAvailableDMInterface={setAvailableDMInterface}
            currentSettingTemplate={currentSettingTemplate}
        />
    );

    const deviceModelSlaveSection = (
        <DeviceModelSlaveSection
            loading={loading}
            deviceModelSlaves={deviceModelSlaves}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onSubmit={onDeviceModelSlaveSectionSubmit}
            onPreviousInterface={onDeviceModelSlaveSectionPreviousInterface}
            currentSettingTemplate={currentSettingTemplate}
            setCurrentSettingTemplate={setCurrentSettingTemplate}
            selectedDMInterfaces={selectedDMInterfaces}
            currentInterfaceStep={currentInterfaceStep}
            currentDMInterface={currentDMInterface}
            selectedDeviceModel={selectedDeviceModel}
            setLoading={setLoading}
        />
    );

    function setStepForEmbedded(section: Sections) {
        if (section != null) {
            setCurrentStep(section);
        } else {
            setCurrentDMInterface(selectedDMInterfaces[currentInterfaceStep + 1]);
            setCurrentInterfaceStep(currentInterfaceStep + 1);
        }
    }

    function onCreateSettingTemplateSlave() {
        setLoading(true);
        const temp = {...currentSettingTemplate};
        const slave = temp.templateContent as TemplateConfigurationSlave;

        if (slave.dataTemplate != null) {
            configurabilityService.filterUncheckAndSetCustomType(slave.dataTemplate);
        }
        temp.templateContent = slave;
        setCurrentSettingTemplate(temp);
        onCreateSettingTemplate(true);
        setLoading(false);
    }

    function onDeviceModelSlaveSectionSubmit() {
        setLoading(true);
        if (currentInterfaceStep + 1 < selectedDMInterfaces.length) {
            if (selectedDMInterfaces[currentInterfaceStep + 1].propertiesStructure === PropertiesStructure.EMBEDDED) {
                setStepForEmbedded(null);
            } else {
                setCurrentDMInterface(selectedDMInterfaces[currentInterfaceStep + 1]);
                setCurrentInterfaceStep(currentInterfaceStep + 1);
            }
        } else {
            onCreateSettingTemplate(false);
        }
        setLoading(false);
    }

    function onDeviceModelSlaveSectionPreviousInterface() {
        setLoading(true);
        if (currentInterfaceStep <= selectedDMInterfaces.length) {
            setCurrentDMInterface(selectedDMInterfaces[currentInterfaceStep - 1]);
            setCurrentInterfaceStep(currentInterfaceStep - 1);
        }
        setLoading(false);
    }

    function settingTemplatePatchPayload() {
        const payload: PatchList = {
            list: [services.patchOption(
                "replace",
                "templateContent",
                currentSettingTemplate.templateContent)],
        };
        services.addReplaceOpIfValueChanged(payload, oldSettingTemplate.name, currentSettingTemplate.name, "name");
        services.addReplaceOpIfValueChanged(payload, oldSettingTemplate.type, currentSettingTemplate.type, "type");
        services.addReplaceOpIfValueChanged(payload, oldSettingTemplate.default, currentSettingTemplate.default, "default");
        return JSON.parse(JSON.stringify(payload));
    }

    function setResponse(response: { status: number; body: any }, isPatch: boolean) {
        if (response.status >= 400 && response.status <= 500) {
            services.errorNotification(" ", response.body.message);
        }
        if (response.status >= 200 && response.status <= 299) {
            services.successNotification(
                " ",
                response.body.name + (isPatch ? " updated" : " created") + " successfully ",
            );
            setCurrentSettingTemplate(response.body);
            isPatch ? setCurrentStep(Sections.deviceModelSection) : setCurrentStep(Sections.visibilitySection);
        }
    }

    function onCreateSettingTemplate(isSlave: boolean) {
        if (currentSettingTemplate.guid != null) {
            configurabilityController.patchSettingTemplate(settingTemplatePatchPayload(), currentSettingTemplate.guid)
                .then((response: { status: number, body: any }) => {
                    setResponse(response, true);
                });
        } else {
            deviceModelController.createSettingTemplate(selectedDeviceModel.value,
                isSlave ? settingTemplateSlavePayload() : settingTemplatePayload())
                .then((response: { status: number, body: any }) => {
                    setResponse(response, false);
                });
        }
    }

    function settingTemplatePayload() {
        const newSettingTemplate = {...settingTemplateCreation};
        newSettingTemplate.templateContentTelemetry =
            currentSettingTemplate.templateContent as TemplateConfigurationTelemetry;
        return JSON.parse(JSON.stringify(settingTemplateCreation));
    }

    function settingTemplateSlavePayload() {
        const newSettingTemplate = {...settingTemplateCreation};
        newSettingTemplate.templateContentSlave =
            currentSettingTemplate.templateContent as TemplateConfigurationSlave;
        return JSON.parse(JSON.stringify(newSettingTemplate));
    }

    function onInterfaceAvailableSectionSubmit(section: Sections) {
        setLoading(true);
        const deviceModelInterfaceTypeToCheck: DeviceModelInterfaceType[] = availableDMInterface
            .filter((e) => e.checked).map((e) => e.item);
        setSelectedDMInterfaces(deviceModelInterfaceTypeToCheck);
        setCurrentInterfaceStep(0);
        setCurrentDMInterface(deviceModelInterfaceTypeToCheck[0]);
        const tempSettingTemplate = currentSettingTemplate;
        const templateContent = tempSettingTemplate.templateContent as TemplateConfigurationTelemetry;

        // map checked interfaces
        let tempInterfaces: TemplateInterfaceConfig[];
        if (deviceModelInterfaceTypeToCheck.length > 0) {
            tempInterfaces = deviceModelInterfaceTypeToCheck
                .map((e) => (e.propertiesStructure === PropertiesStructure.EMBEDDED ? {
                    slaves: null,
                    deviceModelInterfaceTypeGuid: e.guid,
                    embeddedDataTemplate: {alarms: [], diagnostics: []},
                    interfaceSetting: undefined,
                } : {
                    slaves: [],
                    deviceModelInterfaceTypeGuid: e.guid,
                    embeddedDataTemplate: null,
                    interfaceSetting: undefined,
                }));
        }

        // swap if checked was present
        tempInterfaces = tempInterfaces.map((e) => {
            const alreadyPresentData = templateContent.interfaces
                .find((i) => e.deviceModelInterfaceTypeGuid === i.deviceModelInterfaceTypeGuid);
            if (alreadyPresentData != null) {
                e = alreadyPresentData;
            }
            return e;
        });
        templateContent.interfaces = tempInterfaces;

        // sort by guid
        templateContent.interfaces
            .sort((a, b) => a.deviceModelInterfaceTypeGuid.localeCompare(b.deviceModelInterfaceTypeGuid));

        // add check to already present slave data
        if (templateContent.interfaces.length > 0) {
            templateContent.interfaces.map((i) => {
                if (i.slaves != null) {
                    i.slaves.forEach((s) => s.configured = true);
                }
                return i;
            });
        }

        tempSettingTemplate.templateContent = templateContent;
        setCurrentSettingTemplate(tempSettingTemplate);
        if (deviceModelInterfaceTypeToCheck[0].propertiesStructure === PropertiesStructure.EMBEDDED) {
            setStepForEmbedded(section);
            setLoading(false);
        } else {
            setCurrentStep(section);
            setLoading(false);
        }
    }

    const steps: Array<{ title: string, route: JSX.Element, value: number }> = [
        {
            title: "Device Model Section",
            route: deviceModelSection,
            value: Sections.deviceModelSection.valueOf(),
        }, {
            title: "Select Available Interfaces",
            route: templateAvailableSection,
            value: Sections.interfaceAvailableSection.valueOf(),
        }, {
            title: "Configure Device Slave",
            route: deviceModelSlaveSection,
            value: Sections.deviceModelSlaveSection.valueOf(),
        }, {
            title: "Setting template slave",
            route: settingTemplateSlaveSection,
            value: Sections.settingTemplateSlaveSection.valueOf(),
        }, {
            title: "Setting template visibility",
            route: settingTemplateVisibilitySection,
            value: Sections.visibilitySection.valueOf(),
        },
    ];

    setLegacyBreadcrumb(
        new LegacyPage.Breadcrumb([
            new LegacyPage.BreadcrumbItem("./", "Dashboard", false),
            new LegacyPage.BreadcrumbItem(null, "Web operations", false),
            new LegacyPage.BreadcrumbItem(null, "Create Template", true,
            ),
        ]),
    );

    React.useEffect(() => {
        services.loadingModal();
    }, [loading]);

    return (
        <div className="w-100 mx-auto" id={"createSettingTemplate"}>
            <Card style={{minHeight: "80.2vh"}}>
                <Card.Body>
                    <ThemeProvider theme={defaultTheme}>
                        {steps[currentStep].route}
                        <LoadingModal loading={loading}/>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </div>
    );
};
