import {AlarmTypeSet} from "../../models/alarm/alarmType";
import {ComponentTypes} from "../../models/component/componentType";
import {DiagnosticTypeList} from "../../models/diagnostic/diagnosticType";

class ComponentTypeController {

    public async getComponentTypes(): Promise<ComponentTypes> {
        const response = await fetch("/componentTypes/");
        return response.json();
    }

    // function used to get a list of all diagnostic types filtered by component type guid
    public async getDiagnosticTypesByComponentType(componentTypeGuid): Promise<DiagnosticTypeList> {
        const response = await fetch("/componentTypes/"
            + componentTypeGuid + "/diagnosticTypes");
        return response.json();
    }

    // function used to get a list of all alarm types filtered by component type guid
    public async getAlarmTypesByComponentType(componentTypeGuid): Promise<AlarmTypeSet> {
        const response = await fetch("/componentTypes/"
            + componentTypeGuid + "/alarmTypes");
        return response.json();
    }

    public async getComponentModelsByTypeGuid(typeGuid: string): Promise<any> {
        const response = await fetch("/componentTypes/" + typeGuid + "/models");
        return response.json();
    }
}

export default ComponentTypeController;

const controllerTypeController = new ComponentTypeController();

export function getComponentTypeController() {
    return controllerTypeController;
}
