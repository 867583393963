import {AlarmTypeSet} from "../../models/alarm/alarmType";
import {PatchList} from "../../models/company/patchModel";
import {ComponentModel, ComponentModelList} from "../../models/component/componentModel";
import {ComponentModelDiagnosticTypeList} from "../../models/diagnostic/componentModelDiagnosticType";
import {Manufacturer, ManufacturerList} from "../../models/manufacturer/manufacturer";
import {MediaList} from "../../models/media/mediaModel";
import {PropertyConfigurationList, PropertyList} from "../../models/property/property";
import {SelectionList} from "../../models/selection/selection";

export interface OperationsResponse {
    status: number;
    content: any;
}

interface GetComponentModelsParams {
    name?: string;
    categoryGuid?: string;
    typeGuid?: string;
    limit?: number;
    offset?: number;
    sortProperty?: string;
    sortDirection?: string;
    variant?: "FULL"
}

class ComponentModelController {
    public async findComponentBrands(description?: string, exactMatch: boolean = false): Promise<OperationsResponse> {
        let url: string = "/componentModels/brands";
        if (description) {
            const URLparams = new URLSearchParams();
            URLparams.append("description", description);
            URLparams.append("exactMatch", String(exactMatch));
            url = url.concat("?").concat(URLparams.toString());
        }
        const response = await fetch(url);
        return response.json()
            .then(
                (r) => {
                    return {
                        status: response.status,
                        content: r,
                    };
                },
            )
            .catch((reason) => reason);
    }

    public async findSelectionsByComponentModelAndCompany(
        componentModelGuid: string, companyGuid: string,
    ): Promise<SelectionList> {
        const response = await fetch("/componentModels/" + componentModelGuid
            + "/defaultProductSelectionConfiguration?companyGuid=" + companyGuid);
        return response.json();
    }

    // function used to get a list of all different manufacturers
    public async getManufacturers(filter): Promise<ManufacturerList> {
        const response = await fetch("/componentModels/manufacturers?name=" + filter);
        return response.json();
    }

    public async findManufacturer(textKey: string): Promise<Manufacturer> {
        const response = await fetch("/componentModels/manufacturers/" + textKey);
        return response.json();
    }

    // function used to retrieve all component models filtered by partial name
    public async getComponentModelsByName(name): Promise<ComponentModelList> {
        const response = await fetch("/componentModels?partial=true&name=" + name);
        return response.json();
    }

    public async getComponentModels(params?: GetComponentModelsParams): Promise<ComponentModelList> {
        const urlParams = new URLSearchParams();
        Object
            .entries(params ?? {})
            .forEach(([key, value]) => {
                if (value !== undefined && value !== "") {
                    urlParams.append(key, value);
                }
            });
        const response = await fetch(`/componentModels?${urlParams.toString()}`);
        return response.json();
    }

    // function used to retrieve all diagnostic types of a component model
    public async getComponentModelDiagnosticTypes(componentModelGuid): Promise<ComponentModelDiagnosticTypeList> {
        const response = await fetch("/componentModels/"
            + componentModelGuid + "/diagnosticTypes");
        return response.json();
    }

    public async getComponentModelByNameKey(nameKey: string): Promise<boolean> {
        const response = await fetch("/componentModels?nameKey=" + nameKey);
        return response.json().then(
            (r) => {
                return response.status === 200 && r.list.length > 0;
            },
        );
    }

    // function used to retrieve all alarm types of a component model
    public async getComponentModelAlarmTypes(componentModelGuid): Promise<AlarmTypeSet> {
        const response = await fetch("/componentModels/"
            + componentModelGuid + "/alarmTypes");
        return response.json();
    }

    // function used to retrieve all component model properties
    public async getComponentModelProperties(): Promise<PropertyList> {
        const response = await fetch("/componentModels/properties");
        return response.json();
    }

    public async getComponentModelPropertyConfigurations(guid: string): Promise<PropertyConfigurationList> {
        const response = await fetch(`/componentModels/${guid}/properties`);
        return response.json();
    }

    public async generateVeboxCode(): Promise<any> {
        const response = await fetch("/componentModels/veboxcode");
        return response.json();
    }

    // function used to submit the first form step
    public async postMainInfo(
        typeGuid: string,
        name: string,
        image: string,
        media: File,
        generic: boolean,
        genVeboxCode: boolean,
        nameKey: string,
        manufacturer: string,
        manufacturerNameKey: string,
    ): Promise<ComponentModel | any> {
        const body = {
            generic,
            image,
            manufacturer,
            manufacturerNameKey,
            name,
            nameKey,
            typeGuid,
            genVeboxCode,
        };
        const formData = new FormData();
        formData.append("data", JSON.stringify(body));
        formData.append("image", media);
        const response = await fetch("/componentModels", {
            method: "POST",
            body: formData,
        });
        return response.json().then(
            (r) => {
                return {
                    status: response.status,
                    body: r,
                };
            },
        );
    }

    public async updateComponentModel(
        patchObj: PatchList,
        media: File,
        componentModelGuid: string,
    ): Promise<{ body: ComponentModel, status: number }> {
        const formData = new FormData();
        formData.append("data", JSON.stringify(patchObj));
        if (media) {
            formData.append("image", media);
        }
        const response = await fetch("/componentModels/" + componentModelGuid, {
            method: "PATCH",
            body: formData,
        });
        return response.json().then(
            (r) => {
                return {
                    status: response.status,
                    body: r,
                };
            },
        );
    }

    public async deleteComponentModel(componentModelGuid: string) {
        return fetch(
            `/componentModels/${componentModelGuid}`,
            {
                method: "DELETE",
            },
        );
    }

    public async findComponentModelByName(name: string): Promise<boolean> {
        const response = await fetch("/componentModels?partial=false&name=" + name);
        return response.json().then(
            (r) => {
                return response.status === 200 && r.list.length > 0;
            },
        );
    }

    public async findAllMedia(): Promise<MediaList> {
        const response = await fetch("/componentModels/media");
        return response.json();
    }

    // function used to submit the second form step
    public async linkDiagnosticTypes(
        currentComponentModel,
        componentModelDiagnosticTypes,
    ): Promise<any> {
        const body = {list: componentModelDiagnosticTypes};
        const response = await fetch("/componentModels/"
            + currentComponentModel.guid + "/diagnosticTypes", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return response.json().then(
            (r) => {
                return {
                    status: response.status,
                    body: r,
                };
            },
        );
    }

    // function used to submit the third form step
    public async linkAlarmTypes(
        currentComponentModel,
        componentModelAlarmTypes,
    ): Promise<any> {
        const body = {list: componentModelAlarmTypes};
        const response = await fetch("/componentModels/" + currentComponentModel.guid + "/alarmTypes", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return response.json().then(
            (r) => {
                return {
                    status: response.status,
                    body: r,
                };
            },
        );
    }

    // function used to submit the fourth form step
    public async linkCompanies(
        currentComponentModel: ComponentModel,
        imageList: File[],
        companiesComponentModel,
    ): Promise<OperationsResponse> {
        const formData = new FormData();
        formData.append("data", JSON.stringify({list: companiesComponentModel}));
        imageList.forEach((e) => formData.append("images", e, e.name));
        const response = await fetch("/componentModels/"
            + currentComponentModel.guid + "/companies", {
            method: "PUT",
            body: formData,
        });
        return response.json().then(
            (b) => {
                return {
                    status: response.status,
                    content: b,
                };
            },
        );
    }

    public async getCompanies(
        currentComponentModel: ComponentModel,
    ): Promise<{ list: any[] }> {
        const response = await fetch("/componentModels/"
            + currentComponentModel.guid + "/companies", {
            method: "GET",
        });
        return response.json();
    }

    // function used to submit last form step
    public async linkProperties(componentModelGuid, properties): Promise<OperationsResponse> {
        const body = {list: properties};
        const response = await fetch("/componentModels/"
            + componentModelGuid.guid + "/properties", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return response.json().then(
            (r) => {
                return {
                    status: response.status,
                    content: r,
                };
            },
        );
    }

    public async linkComponentSelectionConfiguration(companyGuid, componentModelGuid,
                                                     selections): Promise<OperationsResponse> {
        const body = {companyGuid, selections};
        const response = await fetch("/componentModels/" + componentModelGuid
            + "/defaultProductSelectionConfiguration", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return response.json().then(
            (r) => {
                return {
                    status: response.status,
                    content: r,
                };
            },
        );
    }

    public async createComponentModelBrand(description: { description: string }): Promise<OperationsResponse> {
        const response = await fetch("/componentModels/brands", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(description),
        });
        return response.json()
            .then(
                (r) => {
                    return {
                        status: response.status,
                        content: r,
                    };
                },
            )
            .catch((reason) => reason);
    }

    public async countComponentByComponentModel(componentModel: Pick<ComponentModel, "guid">) {
        return fetch(`/componentModels/${componentModel.guid}/countComponents`)
            .then((response) => response.json());
    }
}

const componentModelController = new ComponentModelController();

export function getComponentModelController() {
    return componentModelController;
}

export default ComponentModelController;
