import * as React from "react";
import {Dialog} from "../../../common/Dialog";
import {useLocation, useNavigate} from "react-router-dom";
import {ComponentType} from "../../../../models/component/componentType";
import {Company} from "../../../../models/company/companyModel";
import {Button} from "../../../common/Button";
import {Icon} from "../../../common/Icon";
import {constant} from "../../../../utils/functions";

import "./style.scss";

type CompanyComponentTypesLocationState = {
    company: Company & { componentTypes: ComponentType[]},
    origin: string;
};

export function CompanyComponentTypesSideDialogListView() {

    const location = useLocation();

    const {company, origin }: CompanyComponentTypesLocationState = location.state;

    const navigate = useNavigate();

    const goBack = constant(navigate, origin, { state: {reFetch: false}});

    return (
        <Dialog tabIndex={-1} className={"company-component-types-dialog"} type={"right-side"} onClose={goBack}>
            <header>
                <hgroup>
                    <small>{company.name}</small>
                    <h4>Component types</h4>
                </hgroup>
                <Button className={"side-dialog-close"} variant={"secondary"} onClick={goBack}>
                    <Icon name={"close"}/>
                </Button>
            </header>
            <section>
                <ul className={"component-types-list"}>
                    {company.componentTypes.map((ct) => {
                        return (
                            <li key={ct.guid} className={"component-type-item"}>
                                <h6>{ct.name}</h6>
                                <small>{ct.category?.name}</small>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </Dialog>
    );
}