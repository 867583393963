import * as React from "react";

import "./styles.scss";

export type TrState = "default" | "added" | "removed";

type TrProps = React.HTMLProps<HTMLTableRowElement> & {
    state?: TrState,
};

function TrDef({ children, state, ...props}: TrProps, ref: React.ForwardedRef<HTMLTableRowElement>) {

    const trRef = React.useRef();
    React.useImperativeHandle(ref, () => trRef.current, []);

    const stateClassName = state === "added" ? "table-row-data-added"
        : state === "removed" ? "table-row-data-removed"
        : "";

    return (
        <tr {...props} className={`table-row ${stateClassName} ${props.className ?? ""}`} ref={trRef}>
            {children}
        </tr>
    );
}

export const Tr = React.forwardRef(TrDef);
