import {AutoSuggest} from "@danfoss/etui-core";
import * as React from "react";
import {LocationCore} from "../../../../../models/location/location";
import {LocationInfoView} from "./LocationInfoView";
import {isDefaultWarehouse, itemToString, locationToAutoSuggestionItem, notDefaultWarehouse} from "./utils";
import {getCompanyLocations} from "../../../../Companies/api";

interface StorageLocationInputProps {
    companyGuid: string;
    locationGuid: string;
    onLocationGuidChange(newLocationGuid: string): void;
}

export function StorageLocationInput({companyGuid, onLocationGuidChange, locationGuid}: StorageLocationInputProps) {

    const [locations, setLocations] = React.useState<LocationCore[]>([]);
    const [defaultWarehouse, setDefaultWarehouse] = React.useState<LocationCore>();

    const [locationInputString, setLocationInputString] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [initialLocationNumber, setInitialLocationNumber] = React.useState<number>();

    const [selectedLocationGuid, setSelectedLocationGuid] = React.useState<string>("");

    React.useEffect(() => {
        setIsLoading(true);
        getCompanyLocations(
            companyGuid,
            {
                alwaysIncludeDefaultWarehouse: true,
                partialLocationName: locationInputString,
                limit: 10,
            },
        )
            .then(({list}) => {
                setIsLoading(false);
                const foundLocationsWithoutDefaultWarehouse = list.filter(notDefaultWarehouse);
                setLocations(foundLocationsWithoutDefaultWarehouse);
                const retrievedDefaultWarehouse = list.find(isDefaultWarehouse);
                if (retrievedDefaultWarehouse?.guid !== defaultWarehouse?.guid) {
                    setDefaultWarehouse(retrievedDefaultWarehouse);
                }
                if (list.length === 1 && initialLocationNumber === undefined) {
                    onLocationGuidChange(retrievedDefaultWarehouse.guid);
                    setInitialLocationNumber(list.length);
                } else if (initialLocationNumber === undefined) {
                    setInitialLocationNumber(list.length);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [locationInputString]);

    function updateLocationInputValue(params) {
        if ("inputValue" in params) {
            setLocationInputString(params.inputValue);
            setIsLoading(true);
        }
    }

    function selectLocation(option) {
        setSelectedLocationGuid(option.value);
        onLocationGuidChange(option.value);
    }

    function changeLocationFromRadio(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.checked) {
            onLocationGuidChange(e.target.value);
        }
    }

    if (initialLocationNumber === undefined) {
        return <span>Loading company locations...</span>;
    }

    if (initialLocationNumber === 1) {
        return <LocationInfoView location={defaultWarehouse ?? locations[0]}/>;
    }

    if (initialLocationNumber === 2) {
        return (
            <div className={"location-selection-radio-group"}>
                <label className={"rectangular-radio-button"}>
                    <input
                        type={"radio"}
                        name={"locationGuid"}
                        value={defaultWarehouse.guid}
                        onChange={changeLocationFromRadio}
                    />
                    <LocationInfoView location={defaultWarehouse}/>
                </label>
                <label className={"rectangular-radio-button"}>
                    <input
                        type={"radio"}
                        name={"locationGuid"}
                        value={locations[0].guid}
                        onChange={changeLocationFromRadio}
                    />
                    <LocationInfoView location={locations[0]}/>
                </label>
            </div>
        );
    }

    if (defaultWarehouse) {
        return (
            <div className={"location-selection-radio-group"}>
                <label className={"rectangular-radio-button"}>
                    <input
                        type={"radio"}
                        name={"locationGuid"}
                        value={defaultWarehouse.guid}
                        onChange={changeLocationFromRadio}
                    />
                    <LocationInfoView location={defaultWarehouse}/>
                </label>
                <label className={"rectangular-radio-button"}>
                    <input
                        type={"radio"}
                        name={"locationGuid"}
                        value={selectedLocationGuid}
                        onChange={changeLocationFromRadio}
                    />
                    <AutoSuggest
                        label={"Storage location"}
                        inputValue={locationInputString}
                        isLoading={isLoading}
                        emptyPlaceholder={"No locations found"}
                        itemToString={itemToString}
                        onStateChange={updateLocationInputValue}
                        suggestions={locations.map(locationToAutoSuggestionItem)}
                        onSelectedValueChange={selectLocation}
                        isInputDisabled={selectedLocationGuid !== locationGuid}
                        styles={{root: {flexGrow: "1"}}}
                        // openOnFocus={true} fixme: update etui to have feature
                    />
                </label>
            </div>
        );
    }

    return (
        <AutoSuggest
            label={"Storage location"}
            inputValue={locationInputString}
            isLoading={isLoading}
            itemToString={itemToString}
            emptyPlaceholder={"No locations found"}
            onStateChange={updateLocationInputValue}
            suggestions={locations.map(locationToAutoSuggestionItem)}
            onSelectedValueChange={selectLocation}
            styles={{root: {flexGrow: "1"}}}
            // openOnFocus={true} fixme: update etui to have feature
        />
    );
}
