import * as React from "react";
import {Alert} from "react-bootstrap";

interface GeneralAlertProps {
    message: string;
}

const GeneralAlert: React.FC<GeneralAlertProps> = (props) => {
    return (
        props.message != null && (
            <Alert variant={"info"} className="mx-auto">
                {props.message}
            </Alert>
        )
    );
};

export default GeneralAlert;
