import {icons, Notification} from "@danfoss/etui-core";
import {defaultTheme} from "@danfoss/etui-themes";
// tslint:disable-next-line:no-implicit-dependencies
import * as material from "material-colors";
import * as React from "react";
// tslint:disable-next-line:no-implicit-dependencies
import reactCSS from "reactcss";
import {CompanyAlarmType} from "../../models/alarm/alarmType";
import {PatchList} from "../../models/company/patchModel";
import {TableComponentModel} from "../../models/component/componentModel";
import {ProcessingParameter} from "../../models/configurability/rawData/processingParameter";
import {RawDataAlarmType} from "../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../models/configurability/rawData/rawDataModel";
import {GeoPoint} from "../../models/location/geopositionModel";
import {ProcessingParamType, Sections} from "../../presentation/createRawData/CreateRawData";

class Services {
    public maxDistanceBetweenPointsKm: number = 1;

    public LOCATION_TYPE_GUID = "539d6700-c7bf-11e7-ad82-525400c5f801";

    public firstPoint: GeoPoint = {
        lat: 45.477727.toString(),
        lng: 12.236499.toString(),
    };

    public presetColors = ["#FF6900", material.red["500"],
        material.pink["500"], material.purple["500"],
        material.deepPurple["500"], material.indigo["500"],
        material.blue["500"], material.lightBlue["500"],
        material.cyan["500"], material.teal["500"],
        material.green["500"], material.lightGreen["500"],
        material.lime["500"], material.yellow["500"],
        material.amber["500"], material.orange["500"],
        material.deepOrange["500"], material.brown["500"],
        material.blueGrey["500"]];

    public styles = reactCSS({
        default: {
            swatch: {
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
            },
            popover: {
                position: "absolute",
                zIndex: "2",
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            },
        },
    });

    public removeItemOnce(arr, value) {
        const index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    public castRawData(section: Sections, currentRawData) {
        let rawData;
        switch (section) {
            case Sections.rawData:
                rawData = currentRawData as RawData;
                break;
            case Sections.rawDataAlarmType:
                rawData = currentRawData as RawDataAlarmType;
                break;
            case Sections.rawDataDiagnosticType:
                rawData = currentRawData as RawDataDiagnosticType;
                break;
        }
        return rawData;
    }

    public findItem(arr, value) {
        const index = arr.indexOf(value);
        if (index > -1) {
            return index;
        }
    }

    public previousStep = (setCurrentStep, currentStep) => {
        return () => {
            setCurrentStep(currentStep - 1);
        };
    }

    public setStep = (setCurrentStep, currentStep) => {
        setCurrentStep(currentStep);
    }

    public nextStep = (setCurrentStep, currentStep) => {
        setCurrentStep(currentStep + 1);
    }

    public hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16).toString(),
            g: parseInt(result[2], 16).toString(),
            b: parseInt(result[3], 16).toString(),
            a: "1",
        } : null;
    }

    public getDefaultRandomColor = () => {
        const h = 360 * Math.random();
        const s = 25 + 70 * Math.random();
        let l = 85 + 10 * Math.random();
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = (n) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, "0");   // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    public patchOption = (op: string, parameter: string, value?: string | boolean | null | any) => {
        return {
            op,
            path: "/" + parameter,
            value,
        };
    }

    public replaceOrRemove = (list: PatchList, previousValue: string | number, currentValue: string, path: string) => {
        if (previousValue !== "" && currentValue !== "") {
            list.list.push(this.patchOption("replace", path, currentValue));
        } else if (previousValue !== "" && previousValue != null && currentValue === "") {
            list.list.push(this.patchOption("remove", path));
        }
    }

    public addReplaceOpIfValueChanged = (list: PatchList,
                                         previousValue: string | boolean,
                                         currentValue: string | boolean,
                                         path: string) => {
        if (previousValue !== currentValue) {
            list.list.push(this.patchOption("replace", path, currentValue));
        }
    }

    public errorNotification = (errorType: string, message: string) => {
        Notification.open({
            message: "Error " + errorType,
            description: message,
            duration: 5,
            type: "error",
            theme: defaultTheme,
            className: "mt-5",
        });
    }

    public successNotification = (successType: string, message: string) => {
        Notification.open({
            message: successType,
            description: message,
            duration: 5,
            type: "success",
            theme: defaultTheme,
            className: "mt-5",
        });
    }

    public warningNotification = (warningType: string, message: string) => {
        Notification.open({
            message: warningType,
            description: message,
            duration: 5,
            type: "warning",
            theme: defaultTheme,
            className: "mt-5",
        });
    }

    public mediaIcon = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-image"
                viewBox="0 0 16 16"
            >
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
            </svg>
        );
    }

    public uploadIconButton = () => {
        return {glyph: icons.UPLOAD};
    }

    public addCircleIconButton = () => {
        return {glyph: icons.ADD_CIRCLE};
    }

    public addIconButton = () => {
        return {glyph: icons.ADD};
    }

    public dotsIconButton = () => {
        return {glyph: icons.DOTS_HORIZONTAL};
    }

    public addTranslateButton = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-translate"
                viewBox="0 0 16 16"
            >
                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
            </svg>
        );
    }

    public addRightArrow = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
            >
                <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
            </svg>
        );
    }

    public removeIconButton = () => {
        return {glyph: icons.TRASH};
    }

    public onSelectedGuid = (setInputValueFunction, setSelectedGuid) => {
        return (suggestion) => {
            if (suggestion != null) {
                console.log(suggestion.value);
                setInputValueFunction(suggestion.label);
                setSelectedGuid(suggestion.value);
            }
        };
    }

    public onSelectedGuidAndItem = (setInputValueFunction, setSelectedGuid, setSelectedItem) => {
        return (suggestion) => {
            if (suggestion != null) {
                console.log(suggestion.value);
                setInputValueFunction(suggestion.label);
                setSelectedGuid(suggestion.value);
                setSelectedItem(suggestion.item);
            }
        };
    }

    public onSelectedItemAndLabel = (setInputValueFunction, setSelectedItem) => {
        return (suggestion) => {
            if (suggestion != null) {
                setInputValueFunction(suggestion.label);
                setSelectedItem(suggestion.item);
            }
        };
    }

    public onSelectedItem = (setSelectedItem) => {
        return (suggestion) => {
            if (suggestion != null) {
                setSelectedItem(suggestion);
            }
        };
    }

    public onSelectedClearable = (setSelectedItem) => { // todo use on selected item like this
        return (suggestion) => {
            setSelectedItem(suggestion);
        };
    }

    public onSelectedName = (setInputValueFunction, setSelectedName) => {
        return (suggestion) => {
            if (suggestion != null) {
                setInputValueFunction(suggestion.label);
                setSelectedName(suggestion.label);
            }
        };
    }

    public onSelectedLabel = (setSelectedLabel) => {
        return (suggestion) => {
            if (suggestion != null) {
                setSelectedLabel(suggestion.label);
            }
        };
    }

    public handleTextOnChange = (functionToHandle) => {
        return (value) => {
            functionToHandle(value.target.value);
        };
    }

    public loadingModal = () => {
        $(".modal").attr("style",
            "flex-direction: row; align-items: center; justify-content: center; display: flex !important;");
        $(".modal-backdrop").attr("style", "opacity:0.8 !important;");
    }

    public handlePhoneNumberOnChange = (setNumber, setPrefix) => {
        return (value) => {
            const element = (document.querySelector(".PhoneInputInput") as HTMLInputElement).value;
            if (value != null && element.trim().length > 0) {
                const prefix = element.split(" ")[0];
                setPrefix(prefix);
                setNumber(value);
            } else {
                setPrefix("");
                setNumber("");
            }
        };
    }

    public handleNumberOnChange = (functionToHandle) => {
        return (value) => {
            functionToHandle(value.target.value.replace(/[^0-9\.]+/g, ""));
        };
    }

    public handleCheckboxLabel = () => {
        return (checked: boolean) => (checked ? "ON" : "OFF");
    }

    public handleCheckboxOnChange = (functionToHandle) => {
        return (value) => {
            functionToHandle(value.target.checked);
        };
    }

    public onHideModal = (setShowFunction) => {
        return () => {
            setShowFunction(false);
        };
    }

    public onHideModalWithParam = (setShowFunction, setParam) => {
        return () => {
            setShowFunction(false);
            setParam(false);
        };
    }

    public setShowModalWrapper = (setShowModal, status: boolean) => {
        return () => {
            setShowModal(status);
        };
    }

    public setTranslateModalWrapper = (setShowModal,
                                       setAlarmTypeToTranslate,
                                       alarmTypeToTranslate: CompanyAlarmType,
                                       setSelectedTranslation,
                                       selectedTranslationValue: string,
    ) => {
        return () => {
            setSelectedTranslation(selectedTranslationValue);
            setAlarmTypeToTranslate(alarmTypeToTranslate);
            setShowModal(true);
        };
    }

    public onMediaIconClick = (onShowModalButtonPressed, deleteItemMedia, value: TableComponentModel) => {
        return () => {
            value.newMedia === undefined ? (
                value.existingMedia === undefined ?
                    onShowModalButtonPressed(value) :
                    deleteItemMedia(value)
            ) : deleteItemMedia(value);
        };
    }

    public deleteUnselected = (table, setTable) => {
        return () => {
            setTable(
                table.filter((e) => e.checked),
            );
        };
    }

    public isDisabledProcessing(section: Sections, newValues: ProcessingParameter[], setFunction) {

        if (newValues != null && newValues.length === 0) {
            setFunction(true);
        } else {
            let bool = (newValues != null ?
                (newValues.filter((obj) =>
                        (obj.type ? (
                            Object.values(obj.type).includes(undefined) ||
                            Object.values(obj.type).includes(null) ||
                            Object.values(obj.type).includes("")) : true) ||
                        (obj.value ? (
                            Object.values(obj.value).includes(undefined) ||
                            Object.values(obj.value).includes(null) ||
                            Object.values(obj.value).includes("")) : true))
                ).length > 0 : true);
            if (section === Sections.rawDataAlarmType && !bool) {
                bool = newValues != null ? (newValues.find((e) => e.type === ProcessingParamType.REF) == null) : true;
            }
            setFunction(bool);
        }
    }

    public colorFromSeverity = (severity: string) => {
        switch (severity) {
            case "Urgent":
                return "#F20F00";
            case "High":
                return "#FF9800";
            case "Warning":
                return "#FFD310";
            default:
                return "green";
        }
    }

    public onDeleteButton = (setTable) => {
        return () => {
            setTable([]);
        };
    }

    public itemToString = (item) => {
        return item ? item.label : "";
    }

    public numberUndefinedOrEmpty = (nr: number) => {
        return (nr == null || nr.toString().trim().length === 0);
    }

    public isEmpty = (str: string) => {
        return (!str || str.length === 0);
    }

    public isEmptyArr = (arr: any[]) => {
        return (arr == null || arr.length === 0);
    }

}

export default Services;
