import {TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {ProcessingParameter} from "../../../models/configurability/rawData/processingParameter";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {Suggestion} from "../../../models/Suggestion";
import {Sections} from "../CreateRawData";

interface ParamValueProps {
    processingParameters: ProcessingParameter[];
    setProcessingParameters: (state: ProcessingParameter[]) => void;
    index: number;
    setDisabledParam: (status: boolean) => void;
    currentRD: RawData | RawDataAlarmType | RawDataDiagnosticType;
    section: Sections;
}

// tslint:disable:max-line-length
const ParamValue: React.FC<ParamValueProps> = (props) => {

    const [paramValue, setParamValue] = React.useState<string>("");
    const services: Services = new Services();

    React.useEffect(() => {
        if (props.processingParameters != null) {
            const temp: any = props.processingParameters[props.index];
            if (temp != null) {
                const value = temp.value !== "" ? temp.value : undefined;
                setValue(value);
            }
        }
    }, []);

    function setValue(value) {
        if (value && props.processingParameters) {
            setParamValue(value);
            const newValues = props.processingParameters;
            newValues[props.index].value = value;
            props.setProcessingParameters(newValues);
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        } else {
            setParamValue(undefined);
            props.setDisabledParam(true);
        }
    }

    function numberOnChange(suggestion) {
        if (suggestion != null) {
            const value: number = +suggestion.target.value.replace(/[^0-9\.]+/g, "");
            setValue(value);
        }
    }

    return (
        <Row className={"mt-2 d-flex flex-column"}>
            <Form.Label>Processing Param Value {props.index}*</Form.Label>
            <TextInput
                name={"formValue"}
                label={"Insert Processing Parameter  value"}
                type={"text"}
                disabled={props.section === Sections.rawDataDiagnosticType}
                value={paramValue}
                onChange={numberOnChange}
            />
        </Row>
    );
};
export default ParamValue;
