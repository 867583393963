import * as React from "react";

import "./item-detail-view.scss";

type ItemDetailViewProps = React.HTMLAttributes<HTMLDivElement> & {
    heading: string | JSX.Element;
    subheading: string | JSX.Element;
};

export function ItemDetailView(
    {
        heading,
        subheading,
        ...rest
    }: ItemDetailViewProps,
    ) {
    return (
        <div {...rest} className={`item-detail-view ${rest.className ?? ""}`}>
            <h6 className={"item-detail-view-title"}>{heading}</h6>
            <small className={"item-detail-view-sub-title"}>{subheading}</small>
        </div>
    );
}
