import * as React from "react";
import * as ReactDom from "react-dom";

import "./styles.scss";

type DialogProps = React.HTMLProps<HTMLDialogElement> & {
    onClose?(e: Event): void;
    type?: "right-side" | "center"
};

export function Dialog({children, onClose, type, open = true, ...props}: DialogProps) {

    const ref = React.useRef<HTMLDialogElement>();

    React.useEffect(() => {
        if (open) {
            ref.current.showModal();
        }

        if (typeof onClose === "function") {
            ref.current.addEventListener("close", onClose);
        }

    }, [open]);

    const dialogVariant = type === "center" ? "centered-dialog"
        : type === "right-side" ? "right-side-dialog"
        : "";

    return open ? ReactDom.createPortal(
        (
            <dialog {...props} className={`dialog ${dialogVariant} ${props.className ?? ""}`} ref={ref}>
                {children}
            </dialog>
        ),
        document.body,
    ) : null;
}
