import {PropertiesGroup} from "../../property/property";
import {ProcessingSettingList} from "./processingSetting";

export interface RawDataAlarmType {
    guid: string;
    alarmTypeGuid: string;
    childAlarmTypeGuid?: string;
    params: ProcessingSettingList;
    propertyGroupGuid?: any;
    propertiesGroup?: PropertiesGroup;
    propertyGroupName?: string;
    name?: string;
}

export interface RawDataAlarmTypeList {
    set: RawDataAlarmType[];
}

export function isRawDataAlarmType(object: any) {
    return "alarmTypeGuid" in object;
}
