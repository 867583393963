import {Button, SelectInput} from "@danfoss/etui-core";
import * as React from "react";
// @ts-ignore
import {Col, Form, Modal, ModalProps, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {AcquisitionSetting} from "../../../models/configurability/rawData/acquisitionSettingModel";
import {ProcessingParameter} from "../../../models/configurability/rawData/processingParameter";
import {ProcessingSetting} from "../../../models/configurability/rawData/processingSetting";
import {isRawDataAlarmType, RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {
    isRawDataDiagnosticType,
    RawDataDiagnosticType,
} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {ProcessingFunction} from "../../../models/processingFunction/procesingFunctionModel";
import {Suggestion} from "../../../models/Suggestion";
import ProcessingParamComponent from "../components/ProcessingParamComponent";
import {ProcessingParamType, Sections} from "../CreateRawData";

interface RawDataParamProps {
    setParam: (param: AcquisitionSetting | ProcessingSetting) => void;
    param: AcquisitionSetting | ProcessingSetting;
    modalProps: ModalProps;
    currentRD: RawData | RawDataAlarmType | RawDataDiagnosticType;
    setCurrentRD: (rawData: any) => void;
    currentSelections: RawData | RawDataAlarmType | RawDataDiagnosticType;
    acquisitions?: AcquisitionSetting[];
    rawDataName: string;
    categories: Array<Suggestion<string>>;
    groups: Array<Suggestion<string>>;
    processingFunctions: Array<Suggestion<ProcessingFunction>>;
    processingParametersTypes: Array<Suggestion<any>>;
    firstRender: boolean;
    setFirstRender: (status: boolean) => void;
    section: Sections;
    rawDataList: RawData[];
    setProcessing: (processing: ProcessingSetting[]) => void;
    propertyNum?: number;
}

// tslint:disable:max-line-length
const RawDataProcessingModal: React.FC<RawDataParamProps> = (props) => {
    const services: Services = new Services();

    const [disabledParam, setDisabledParam] = React.useState<boolean>(false);
    const [selectedProcessingFunction, setSelectedProcessingFunction] = React.useState<Suggestion<ProcessingFunction>>(undefined);
    const [category, setCategory] = React.useState<string>(undefined);
    const [group, setGroup] = React.useState<string>(undefined);
    const [processingParams, setProcessingParams] = React.useState<ProcessingParameter[]>([]);
    const [mandatory, setMandatory] = React.useState<boolean>(true);

    const processingSetting: ProcessingSetting = props.param ? JSON.parse(JSON.stringify(props.param)) : undefined as ProcessingSetting;
    React.useEffect(() => {
        setSelectedProcessingFunction(props.modalProps.show && processingSetting ? getProcessingFunction() : undefined);
        setCategory(props.modalProps.show && processingSetting ? processingSetting.category
            : isRawDataDiagnosticType(props.currentSelections)
                ? "DIAGNOSTIC"
                : isRawDataAlarmType(props.currentSelections) ? "ALARM" : undefined);
        setGroup(props.modalProps.show && processingSetting
            ? processingSetting.group
            : isRawDataAlarmType(props.currentSelections) ? "NONE" : undefined);
        setProcessingParams(props.modalProps.show && processingSetting ? processingSetting.parameters : getEmptyParams());
    }, [props.modalProps.show, props.param]);

    function getProcessingFunction() {
        return props.processingFunctions.find((e) => e.value === processingSetting.functionCode.toString());
    }

    function getEmptyParams(): ProcessingParameter[] {
        let ps: Suggestion<ProcessingFunction>;
        if (processingSetting && processingSetting.functionCode) {
            ps = getProcessingFunction();
        }
        const num = ps && ps.item ? ps.item.numParams : 1;
        return Array.from({length: num}).map(() => ({type: "", value: ""}));
    }

    function setProcessingParametersType() {
        if (props.section === Sections.rawDataAlarmType) {
            return props.processingParametersTypes.filter((e) =>
                e.value === ProcessingParamType.REF ||
                (props.propertyNum && e.value === ProcessingParamType.REFERENCE_PROPERTY) ||
                e.value === ProcessingParamType.PARAMETER);
        } else if (props.section === Sections.rawDataDiagnosticType) {
            return props.processingParametersTypes.filter((e) =>
                e.value === ProcessingParamType.REF ||
                e.value === ProcessingParamType.REFERENCE_PROPERTY ||
                e.value === ProcessingParamType.PARAMETER);
        } else { // rawData
            return props.processingParametersTypes
                .filter((e) =>
                    e.value !== ProcessingParamType.REF && e.value !== ProcessingParamType.REFERENCE_PROPERTY);
        }
    }

    function onConfirmButton() {
        return () => {
            const ps: ProcessingSetting = {
                category,
                group,
                functionCode: selectedProcessingFunction.item.code,
                parameters: processingParams,
                main: false,
            };
            const rawData = services.castRawData(props.section, props.currentRD);
            const tempRawData = {...rawData};
            let tempProcessing: ProcessingSetting[] = [...tempRawData.params.processing];
            if (props.param != null) {
                tempProcessing[services.findItem(tempRawData.params.processing, props.param)] = ps;
            } else {
                tempProcessing = [...tempRawData.params.processing, ps];
            }
            tempProcessing.map((e) => e.main = false);
            tempProcessing[tempProcessing.length - 1].main = true;

            tempRawData.params.processing = tempProcessing;
            props.setProcessing(tempProcessing);
            props.setCurrentRD(tempRawData);
            props.modalProps.onHide();
        };
    }

    React.useEffect(() => {
        let propertyCheck: boolean;
        if (props.propertyNum) {
            const processingParameters = processingParams.filter((p) => p.type === ProcessingParamType.REFERENCE_PROPERTY);
            const defaultValue = processingParameters.some((e) => services.isEmpty(e.defaultValue));
            propertyCheck = processingParameters.length !== props.propertyNum || defaultValue;
        } else {
            propertyCheck = false;
        }
        const b = props.modalProps.show
            && (propertyCheck || category === null || group === null || !selectedProcessingFunction || disabledParam
                || processingParams.filter((p) => !services.isEmpty(p.value)).length !== selectedProcessingFunction.item.numParams
            );
        setMandatory(b);
    }, [category, group, selectedProcessingFunction, disabledParam, processingParams]);

    function onSelectedProcessingFunction() {
        return (suggestion: Suggestion<ProcessingFunction>) => {
            const num = suggestion && suggestion.item ? suggestion.item.numParams : 1;
            const arr: ProcessingParameter[] = Array.from({length: num}).map(() => ({
                type: "",
                value: "",
            }));
            setProcessingParams(arr);
            setSelectedProcessingFunction(suggestion);
        };
    }

    return (
        <>
            <Modal
                {...props.modalProps}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add processing {props.rawDataName.trim().length > 0 ? "for " + props.rawDataName : ""}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col>
                        <Row className={"mt-2 d-flex flex-column"}>
                            <Form.Label>Category *</Form.Label>
                            <>
                                <SelectInput
                                    onChange={services.onSelectedLabel(setCategory)}
                                    name="Category"
                                    label="Insert Category"
                                    options={props.categories}
                                    searchable={true}
                                    value={props.categories.find((object) => object.label === category)}
                                    disabled={props.section !== Sections.rawData}
                                />
                            </>
                        </Row>
                        <Row className={"mt-2 d-flex flex-column"}>
                            <Form.Label>Group *</Form.Label>
                            <>
                                <SelectInput
                                    onChange={services.onSelectedLabel(setGroup)}
                                    name="Group"
                                    label="Insert Group"
                                    options={props.groups}
                                    searchable={true}
                                    value={props.groups.find((object) => object.label === group)}
                                    disabled={props.section !== Sections.rawData}
                                />
                            </>
                        </Row>
                        <Row className={"mt-2 d-flex flex-column"}>
                            <Form.Label>
                                Function *
                                {(selectedProcessingFunction && selectedProcessingFunction.item && selectedProcessingFunction.item.name === "Bitmask") ? " (Bit param must be power of 2)" : ""}
                            </Form.Label>
                            <>
                                <SelectInput
                                    onChange={onSelectedProcessingFunction()}
                                    name="Function"
                                    label="Insert Function"
                                    options={props.processingFunctions}
                                    searchable={true}
                                    clearable={true}
                                    disabled={props.section === Sections.rawDataDiagnosticType}
                                    value={selectedProcessingFunction}
                                />
                            </>
                        </Row>
                        {props.section === Sections.rawDataAlarmType ? (
                            <Row className={"mt-2 d-flex flex-column"}>
                                {"Insert at least 1 REF" + (props.propertyNum ? " and exactly " + props.propertyNum + " Reference property" : "")}
                            </Row>
                        ) : <></>}
                        {selectedProcessingFunction && selectedProcessingFunction.item ? [...Array(selectedProcessingFunction.item.numParams)].map((e, i) => (
                            <>
                                <hr/>
                                <ProcessingParamComponent
                                    currentProcessing={props.param as ProcessingSetting}
                                    index={i}
                                    key={selectedProcessingFunction.item.code + `-${i}`}
                                    processingParameters={processingParams}
                                    setProcessingParameters={setProcessingParams}
                                    processingParametersTypes={setProcessingParametersType()}
                                    setDisabledParam={setDisabledParam}
                                    currentRD={props.currentSelections}
                                    section={props.section}
                                    rawDataList={props.rawDataList}
                                />
                            </>
                        )) : (<></>)}
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {props.section !== Sections.rawDataDiagnosticType && (
                        <Button variant="strong" disabled={mandatory} onClick={onConfirmButton()}>
                            Confirm
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default RawDataProcessingModal;
