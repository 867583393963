import * as React from "react";
import {getCurrentDevicesStorage, storeDevices} from "../../../controllers/api/devices";
import {Device} from "../../../models/device/device";
import {DeviceStorage} from "../../../models/device/storage";
import {JSONResourceNotFound} from "../../../models/utils/jsonFailure";
import {JSONResult} from "../../../models/utils/jsonResult";
import {
    DeviceSearchResultListAction,
    DeviceSearchResultsStats,
    DeviceStorageManagerState,
    SearchResultError,
} from "../types";
import {getCodeFromSearchResult, voidFunction} from "../utils";
import {StateModal} from "./StateModal";
import {
    bubbleInvalid,
    createDeviceSearchResultsStats,
    deviceSearchResultByCompanyGuid,
    getDeviceSearchResultDeviceGuid,
    HTTP_CLIENT_ERROR_GROUP_START,
    HTTP_SERVER_ERROR_GROUP_START,
    isSearchResultDeviceFound,
    isSearchResultNotInstalled,
    isSearchResultValid,
    searchResultToDataOwnerInfoToTuple,
} from "./utils";

type DevicesSearchResults = Array<JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>>;

interface DeviceStorageManagerContextType {
    state: DeviceStorageManagerState;
    devicesSearchResults: DevicesSearchResults;
    stats: DeviceSearchResultsStats;
    dataOwnersMap?: Map<string, string>; // key: dataOwnerGuid, value: dataOwnerName
    companyGuid?: string;

    searchDevices(deviceCodes: string[]): void;

    updateSearchResult(action: DeviceSearchResultListAction, visibleCodes: Set<string>): string[];

    updateCompanyGuid(companyGuid: string): void;

    putDevicesIntoStorage(locationGuid: string, enterDate?: Date): void;
}

const DeviceStorageManagerContext = React.createContext<DeviceStorageManagerContextType>({
    state: DeviceStorageManagerState.INITIAL,
    devicesSearchResults: [],
    stats: {
        count: 0,
        alreadyInstalled: 0,
        notFound: 0,
    },
    searchDevices: voidFunction,
    updateSearchResult: voidFunction,
    updateCompanyGuid: voidFunction,
    putDevicesIntoStorage: voidFunction,
});

export function useDeviceStorageManager() {
    return React.useContext(DeviceStorageManagerContext);
}

export function DeviceStorageManagerContextProvider({children}: React.PropsWithChildren<unknown>) {

    const [state, setState] = React.useState(DeviceStorageManagerState.INITIAL);
    const [stats, setStats] = React.useState<DeviceSearchResultsStats>({
        count: 0,
        alreadyInstalled: 0,
        notFound: 0,
    });

    const [devicesSearchResults, setDevicesSearchResults] = React.useState<DevicesSearchResults>([]);
    const [dataOwnersMap, setDataOwnersMap] = React.useState<Map<string, string>>();
    const [companyGuid, setCompanyGuid] = React.useState<string>();

    function handleRequestErrors(e: unknown) {
        if (e instanceof Response) {
            if (HTTP_CLIENT_ERROR_GROUP_START >= e.status && e.status < HTTP_SERVER_ERROR_GROUP_START) {
                setState(DeviceStorageManagerState.MISC_REQUEST_ERROR_CLIENT);
                return;
            }
            if (e.status >= HTTP_SERVER_ERROR_GROUP_START) {
                setState(DeviceStorageManagerState.MISC_REQUEST_ERROR_SERVER);
                return;
            }
        }
        setState(DeviceStorageManagerState.MISC_REQUEST_ERROR);
    }

    function searchDevices(deviceCodes: string[]) {
        setState(DeviceStorageManagerState.SEARCHING_DEVICES);
        getCurrentDevicesStorage<SearchResultError<JSONResourceNotFound<Device | string>>>({deviceCodes})
            .then(({list}) => {
                setDevicesSearchResults(list.sort(bubbleInvalid));

                const dataOwners = new Map<string, string>(
                    list
                        .map(searchResultToDataOwnerInfoToTuple)
                        .filter(Boolean),
                );

                setDataOwnersMap(dataOwners);

                if (dataOwners.size > 1) {
                    setState(DeviceStorageManagerState.ERROR_MULTIPLE_COMPANIES);
                    return;
                }

                if (dataOwners.size === 1) {
                    setCompanyGuid(dataOwners.keys().next().value);
                }

                const newStats = createDeviceSearchResultsStats(list);

                setStats(newStats);

                if (newStats.notFound === 0 && newStats.alreadyInstalled === 0) {
                    setState(DeviceStorageManagerState.DEVICE_SEARCH_COMPLETE);
                    return;
                }

                setState(DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES);
            })
            .catch(handleRequestErrors);
    }

    function updateSearchResult(action: DeviceSearchResultListAction, visibleCodes: Set<string>): string[] {
        function isCodeVisible(code: string) {
            return visibleCodes.has(code);
        }

        switch (action) {
            case DeviceSearchResultListAction.REMOVE_NOT_VALID:
                const validDevices = devicesSearchResults
                    .filter(isSearchResultValid)
                    .map(getCodeFromSearchResult)
                    .filter(isCodeVisible);
                searchDevices(validDevices);
                return validDevices;
            case DeviceSearchResultListAction.REMOVE_INSTALLED:
                const notInstalledDevices = devicesSearchResults
                    .filter(isSearchResultNotInstalled)
                    .map(getCodeFromSearchResult)
                    .filter(isCodeVisible);
                searchDevices(notInstalledDevices);
                return notInstalledDevices;
            case DeviceSearchResultListAction.REMOVE_NOT_FOUND:
                const foundDevices = devicesSearchResults
                    .filter(isSearchResultDeviceFound)
                    .map(getCodeFromSearchResult)
                    .filter(isCodeVisible);
                searchDevices(foundDevices);
                return foundDevices;
            default:
                console.error(`DeviceSearchResultListAction ${action as any} is not recognized`);
                return [];
        }
    }

    function updateCompanyGuid(selectedCompanyGuid: string) {
        setCompanyGuid(selectedCompanyGuid);
        if (state === DeviceStorageManagerState.ERROR_MULTIPLE_COMPANIES) {
            if (dataOwnersMap.has(selectedCompanyGuid)) {
                const companyName = dataOwnersMap.get(selectedCompanyGuid);
                setDataOwnersMap(new Map([
                    [selectedCompanyGuid, companyName],
                ]));
            }

            const filteredDeviceSearchResults = devicesSearchResults
                .filter(deviceSearchResultByCompanyGuid(selectedCompanyGuid));

            setDevicesSearchResults(filteredDeviceSearchResults);

            const newStats = createDeviceSearchResultsStats(filteredDeviceSearchResults);

            setStats(newStats);

            if (newStats.notFound > 0 || newStats.alreadyInstalled > 0) {
                setState(DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES);
                return;
            }
        }

        setState(DeviceStorageManagerState.DEVICE_SEARCH_COMPLETE);
    }

    function putDevicesIntoStorage(locationGuid: string, enterDate: Date) {
        setState(DeviceStorageManagerState.STORING_DEVICES);
        storeDevices({
            locationGuid,
            enterDate,
            devicesGuid: devicesSearchResults.map(getDeviceSearchResultDeviceGuid),
        }).then(({list}) => {
            setState(DeviceStorageManagerState.DONE);
        }).catch(handleRequestErrors);
    }

    React.useEffect(() => {
        if (state === DeviceStorageManagerState.INITIAL) {
            setDataOwnersMap(undefined);
            setDevicesSearchResults([]);
            setCompanyGuid(undefined);
        }
    }, [state]);

    return (
        <>
            <DeviceStorageManagerContext.Provider
                value={{
                    state,
                    devicesSearchResults,
                    dataOwnersMap,
                    companyGuid,
                    stats,
                    searchDevices,
                    updateSearchResult,
                    updateCompanyGuid,
                    putDevicesIntoStorage,
                }}
            >
                {children}
            </DeviceStorageManagerContext.Provider>
            <StateModal
                title={"Done"}
                message={"The storage location of the devices have been updated."}
                state={state}
                openOnState={DeviceStorageManagerState.DONE}
                returnStateOnClose={DeviceStorageManagerState.INITIAL}
                onClose={setState}
            />
            <StateModal
                title={"Server error"}
                message={"There server currently has some issues."}
                state={state}
                openOnState={DeviceStorageManagerState.MISC_REQUEST_ERROR_SERVER}
                returnStateOnClose={DeviceStorageManagerState.INITIAL}
                onClose={setState}
            />
            <StateModal
                title={"Client error"}
                message={"Data sent to the server isn't correct."}
                state={state}
                openOnState={DeviceStorageManagerState.MISC_REQUEST_ERROR_CLIENT}
                returnStateOnClose={DeviceStorageManagerState.INITIAL}
                onClose={setState}
            />
        </>
    );
}
