import * as React from "react";
import {DataState, DataStoreContext} from "./context";
import {useRefInit} from "../hooks/useRefInit";
import {useStream} from "../hooks/observables";


export function DataStoreProvider({children}: React.PropsWithChildren<unknown>) {

    const stream = useStream<[string, DataState<unknown>]>();
    const sharedDataStore = useRefInit(() => new Map<string, DataState<unknown>>());

    return (
        <DataStoreContext.Provider value={{sharedDataStore: sharedDataStore.current, stream}}>
            {children}
        </DataStoreContext.Provider>
    )
}