import {Badge} from "@danfoss/etui-core";
import * as React from "react";
import {LocationCore} from "../../../../../models/location/location";
import {ItemDetailView} from "../../../../common/ItemDetailView";

interface LocationInfoViewProps {
    location: LocationCore;
}

export function LocationInfoView({location}: LocationInfoViewProps) {
    if (location.description) {
        return (
            <ItemDetailView
                heading={(
                    <div className={"default-warehouse-info-view-title"}>
                        {location.description}
                        {location.isDefaultWarehouse
                            ? (
                                <Badge
                                    text={"Default warehouse"}
                                    styles={{root: {bg: "rebeccapurple"}}}
                                    isRound={false}
                                />
                            )
                            : null}
                    </div>
                )}
                subheading={`Code ${location.code} | ${location.address.fullAddress}`}
            />
        );
    }

    return (
        <ItemDetailView
            heading={(
                <div className={"default-warehouse-info-view-title"}>
                    {location.description}
                    {location.isDefaultWarehouse
                        ? (
                            <Badge
                                text={"Default warehouse"}
                                styles={{root: {bg: "rebeccapurple"}}}
                                isRound={false}
                            />
                        )
                        : null}
                </div>
            )}
            subheading={location.address.fullAddress}
        />
    );
}
