import {TemplateData} from "../../models/configurability/template/settingTemplate";

// tslint:disable:max-line-length

class ConfigurabilityService {
    public filterUncheckAndSetCustomType(tempTemplateData: TemplateData) {
        const templateAlarms = tempTemplateData.alarms
            .filter((alarms) => alarms.checked)
            .map((e) => {

                e.customAlarmTypeGuid = e.customAlarmTypeGuid ? e.customAlarmTypeGuid : e.defaultAlarmTypeGuid;
                return e;
            });
        const templateDiagnostics = tempTemplateData.diagnostics
            .filter((diagnostics) => diagnostics.checked)
            .map((e) => {
                e.customDiagnosticTypeGuid = (e.customDiagnosticTypeGuid && e.customDiagnosticTypeGuid !== e.defaultDiagnosticTypeGuid) ? e.customDiagnosticTypeGuid : e.defaultDiagnosticTypeGuid;
                return e;
            });
        tempTemplateData.alarms = templateAlarms;
        tempTemplateData.diagnostics = templateDiagnostics;
    }

}

export default ConfigurabilityService;
