import {assertOk, toRequestParamsString} from "../../../utils/requests";
import {Company} from "../../../models/company/companyModel";

type EnableMarketingConfigurationProps = {
    startDate?: string,
};


type EnableMarketingInformation = {
    companyGuid: string,
    startDate: string,
};


export async function enableMarketingConfiguration(
    company: Company,
    props: EnableMarketingConfigurationProps = {}
): Promise<EnableMarketingInformation> {
    const params = toRequestParamsString(props);
    const response = await fetch(`companies/${company.guid}/marketingConfiguration?${params}`, { method: "PUT" });
    assertOk(response);
    return response.json();
}
