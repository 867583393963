import {types} from "sass";
import String = types.String;

export function includes(a: string, b: string) {
    return a.includes(b);
}

interface SimilarityCheckMode {
    method?: "includes" | "equals";
    case?: "sensitive" | "insensitive";
    check?: "all" | "alphanumerics" | "single-whitespace";
}

const DefaultSimilarityCheckMode: SimilarityCheckMode = {
    method: "includes",
    case: "insensitive",
    check: "alphanumerics",
} as const;

type Case = "lowercase" | "uppercase" | "none";

function toCase(a: string, type: Case = "lowercase") {
    if (type === "lowercase") {
        return a.toLowerCase();
    } else if (type === "uppercase") {
        return a.toUpperCase();
    }
    return a;
}

export function similar(a: string, b: string, mode: SimilarityCheckMode = DefaultSimilarityCheckMode) {
    const type = mode.case === "sensitive" ? "none" : "uppercase";
    const pattern = mode.check === "alphanumerics" ? /[^a-zA-Z0-9]/g
        : mode.check === "single-whitespace" ? /(?<=\s)(\s+)/g
        : "";

    switch (mode.method) {
        case "includes": {
            return toCase(a.replace(pattern, ""), type).includes(toCase(b.replace(pattern, ""), type));
        }
        case "equals": {
            return toCase(a.replace(pattern, ""), type) === (toCase(b.replace(pattern, ""), type));
        }
    }
    return a === b;
}

export function stringOrEmpty(x: unknown): string {
    if (typeof x === "string") {
        return x;
    } else if (x == null) {
        return "";
    }
    return `${x}`;
}

export function localeCompare<T>(mapper: (x: T) => string) {
    return (a: T, b: T) => {
        return mapper(a).localeCompare(mapper(b));
    };
}