import {AlarmType, CompanyAlarmType} from "../../models/alarm/alarmType";
import {Company, CompanyList} from "../../models/company/companyModel";

class CompanyController {
    public minCompanyCharacters: number = 2;

    public async generateVeboxCode(): Promise<any> {
        const response = await fetch("/companies/veboxCode");
        return response.json();
    }

    public async getAllCompaniesTypes(): Promise<any> {
        const response = await fetch("/companies/types");
        return response.json();
    }

    public async findComponentTypeCompanies(componentTypeGuid: string): Promise<CompanyList> {
        const response = await fetch("/companies?componentTypeGuid=" + componentTypeGuid);
        return response.json();
    }

    public async getParentCompanies(partialName: string): Promise<any> {
        if (partialName.length < this.minCompanyCharacters) {
            return {list: []};
        }

        return this.getCompaniesByPatternAndRelationType(partialName, "PARENT_COMPANIES");
    }

    public async getComponentModelsByCompanyGuid(companyGuid: string): Promise<any> {
        const response = await fetch("/companies/" + companyGuid + "/componentModels");
        return response.json();
    }

    public async getCompanyByGuid(companyGuid: string): Promise<Company> {
        const response = await fetch("/companies/" + companyGuid);
        return response.json();
    }

    public async getAlarmTypesByCompanyGuid(companyGuid: string): Promise<CompanyAlarmType[]> {
        const response = await fetch("/companies/" + companyGuid + "/alarmTypes");
        return response.json().then(
            (x) => x.list,
        );
    }

    public async getAlarmTypesByPartialName(name: string): Promise<AlarmType[]> {
        const response = await fetch("/alarmTypes?name=" + name);
        return response.json().then(
            (x) => x.list,
        );
    }

    public async getCompaniesByPatternAndRelationType(partialName, companyRelationType): Promise<any> {
        const response = await fetch("/companies/?companyRelationType=" +
            companyRelationType +
            "&partialName=" + partialName);
        return response.json();
    }

    public async linkComponents(
        currentCompany: Company,
        imageList: File[],
        companiesComponentModel,
    ): Promise<any> {
        const formData = new FormData();
        formData.append("data", JSON.stringify({list: companiesComponentModel}));
        imageList.forEach((e) => formData.append("images", e, e.name));
        const response = await fetch("/companies/"
            + currentCompany.guid + "/componentModels", {
            method: "PUT",
            body: formData,
        });
        return response.json().then(
            (b) => {
                return {
                    status: response.status,
                    content: b,
                };
            },
        );
    }

    public async linkComponentTypes(
        currentCompany: Company,
        companiesComponentType,
    ): Promise<any> {
        const formData = JSON.stringify({list: companiesComponentType});
        const response = await fetch("/companies/"
            + currentCompany.guid + "/componentTypes", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: formData,
        });
        return response.json().then(
            (b) => {
                return {
                    status: response.status,
                    content: b,
                };
            },
        );
    }

    public async createCompany(payload): Promise<any> {
        const response = await fetch("/companies", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async patchCompany(payload, guid): Promise<any> {
        const response = await fetch("/companies/" + guid, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async patchDefaultWarehouse(payload, guid): Promise<any> {
        const response = await fetch("/companies/" + guid + "/defaultWarehouse", {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async defaultWarehouse(payload, companyGuid): Promise<any> {
        const response = await fetch("/companies/" + companyGuid + "/defaultWarehouse/", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        return response.json().then(
            (result) => {
                return {status: response.status, body: result};
            },
        );
    }

    public async getCompanyFactoriesAndWarehouses(companyGuid: string): Promise<any> {
        const url = `/companies/${companyGuid}/locations/factoriesAndWarehouses`;
        const init = {
            method: "GET",
        };
        const response = await fetch(url, init);
        return response.json();
    }

    public async getSubCompaniesFactoriesAndWarehouses(
        parentCompanyGuid: string,
        partialName: string = "",
    ): Promise<any> {
        const url = `/companies/${parentCompanyGuid}/subCompanies/locations?partialName=${partialName}`;
        const init = {
            method: "GET",
        };
        const response = await fetch(url, init);
        return response.json();
    }

    public async putAlarmTypes(companyGuid, alarmTypeList: any[]) {
        const url = `/companies/${companyGuid}/alarmTypes`;
        const init = {
            method: "PUT",
            body: JSON.stringify({list: alarmTypeList}),
        };
        const response = await fetch(url, init);
        return response.json().then(
            (r) => {
                return {
                    content: r,
                    status: response.status,
                };
            },
        );
    }
}

const companyController: CompanyController = new CompanyController();

export function getCompanyController() {
    return companyController;
}

export default CompanyController;
