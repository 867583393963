import {icons} from "@danfoss/etui-core";
import * as React from "react";
import {useDeviceCodeSelectionContext} from "../../DeviceCodeSelectionContext";
import {useDeviceStorageManager} from "../../DeviceStorageManagerContext";
import {DeviceSearchResultListAction, DeviceStorageManagerState} from "../../types";
import {SectionTitle} from "../SectionTitle";
import {DeviceSearchResultsStatsInvalidView} from "./DeviceSearchResultsStatsInvalidView";
import {InvalidDevicesActionsButton} from "./InvalidDevicesActionsButton";
import {deviceSearchResultToListItem} from "./utils";

import "./devices-storage-list.scss";

export function DevicesStorageList() {
    const { devicesSearchResults, state, stats, updateSearchResult} = useDeviceStorageManager();
    const { inputCodes, select, updateCodes } = useDeviceCodeSelectionContext();

    const visibleCodes = new Set(inputCodes?.split(",").map((c) => c.toUpperCase()));
    function changeSelectedCode(e: React.MouseEvent<HTMLLIElement>) {
        select(e.currentTarget.dataset.value);

    }
    function createListActionPerformer(action: DeviceSearchResultListAction) {
        return () => {
            const devicesCodes = updateSearchResult(action, visibleCodes);
            updateCodes(devicesCodes);
        };
    }

    if (state === DeviceStorageManagerState.INITIAL
        || state === DeviceStorageManagerState.SEARCHING_DEVICES) {
        return null;
    }

    const glyph = state === DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES
        ? icons.WARNING
        : icons.INFO_CIRCLE;

    const iconColor = state === DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES
        ? "#dc3545"
        : "#3c3f42";

    const explanationModalTitle = state === DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES
        ? "Cannot store listed devices"
        : "Search result list";

    const explanation = state === DeviceStorageManagerState.ERROR_NOT_ALL_VALID_DEVICES
        ? "Some devices in the list cannot be stored due to them being installed in an equipment, " +
            "or some of the codes provided are not linked to any device. " +
            "Please remove the codes of the invalid equipment to proceed."
        : undefined;

    return (
        <>
            <SectionTitle
                glyph={glyph}
                iconColor={iconColor}
                explanationTitle={explanationModalTitle}
                explanation={explanation}
            >
                Devices to store
            </SectionTitle>
            <div className={"device-search-results"}>
                <DeviceSearchResultsStatsInvalidView stats={stats}/>
                <ul className={"device-search-results-list"}>
                    {devicesSearchResults.map(deviceSearchResultToListItem(changeSelectedCode, visibleCodes))}
                </ul>
                <footer className={"device-search-results-footer"}>
                    <span>Count: {stats.count}</span>
                    <InvalidDevicesActionsButton
                        stats={stats}
                        actionPerformerFactory={createListActionPerformer}
                    />
                </footer>
            </div>
        </>
    );
}
