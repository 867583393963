import React = require("react");
import {StatusMessageType, ValidationStatusMessage, ValidationStatusMessageProps} from "./ValidationStatusMessage";

interface ValidationStatusMessagesProps {
    statuses: ValidationStatusMessageProps[]
}

export const ValidationStatusMessages: React.FC<ValidationStatusMessagesProps> = ({statuses}) => {


    return <>
        {statuses
            .sort((fStatus, sStatus) => {
                switch (fStatus.status) {
                    case StatusMessageType.ERROR:
                        return sStatus.status === StatusMessageType.ERROR ? 1 : -1
                    case StatusMessageType.WARNING:
                        return sStatus.status === StatusMessageType.ERROR ? -1 : 1
                }
            }).map((itemStatus, index) => <ValidationStatusMessage
                key={`${index}_status_message`}
                message={itemStatus.message}
                status={itemStatus.status}
            />)}
    </>;
}