import * as React from "react";
import {voidFunction} from "./utils";

interface DeviceCodeSelectionContextValues {
    highlightedCode: string;
    codes: string[];
    inputCodes: string;
    select(newCode: string): void;
    updateCodes(newCodes: string[]): void;
    updateInputCodes(newInputCodes: string): void;
}

const DeviceCodeSelectionContext = React.createContext<DeviceCodeSelectionContextValues>({
    highlightedCode: undefined,
    codes: [],
    inputCodes: undefined,
    select: voidFunction,
    updateCodes: voidFunction,
    updateInputCodes: voidFunction,
});

export function useDeviceCodeSelectionContext() {
    return React.useContext(DeviceCodeSelectionContext);
}

export function DeviceCodeSelectionProvider({children}: React.PropsWithChildren<unknown>) {

    const [highlightedCode, setHighlightedCode] = React.useState<string>();
    const [selectedCodes, setCodes] = React.useState<string[]>([]);
    const [inputCodes, setInputCodes] = React.useState<string>();

    const codes = React.useMemo(() => selectedCodes, [selectedCodes.length]);

    return (
        <DeviceCodeSelectionContext.Provider
            value={{
                highlightedCode,
                codes,
                inputCodes,
                select: setHighlightedCode,
                updateCodes: setCodes,
                updateInputCodes: setInputCodes,
            }}
        >
            {children}
        </DeviceCodeSelectionContext.Provider>
    );
}
