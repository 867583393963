import * as React from "react";
import {NoOptionsProps} from "./types";

export function NoOptions({children, asTag, ...props}: NoOptionsProps) {
    return React.createElement(
        asTag ?? "div",
        props,
        children,
    );
}
