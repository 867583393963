import * as React from "react";

import {SelectInput} from "@danfoss/etui-core";
import {TemplateData} from "../../../models/configurability/template/settingTemplate";
import {Suggestion} from "../../../models/Suggestion";
import {SectionsModal} from "../CreateSettingTemplate";

interface SelectInputCustomTypeProps {
    currentTemplateData: TemplateData;
    section: SectionsModal;
    index: number;
    suggestions: Array<Suggestion<any>>;
    setTemplate: (dataTemplate: TemplateData) => void;
    currentInterfaceStep: number;
}

const SelectInputCustomType: React.FC<SelectInputCustomTypeProps> = (props) => {
    const [customType, setCustomType] = React.useState<Suggestion<any>>(undefined);

    React.useEffect(() => {
        const temp = {...props.currentTemplateData};
        if (props.suggestions.length > 0) {
            if (props.section === SectionsModal.rawDataAlarmType) {
                const guid = temp.alarms[props.index].customAlarmTypeGuid;
                if (guid != null && guid.trim() !== "") {
                    setCustomType(props.suggestions.find((e) => e.value === guid));
                } else {
                    setCustomType(null);
                }
            } else {
                const guid = temp.diagnostics[props.index].customDiagnosticTypeGuid;
                if (guid != null && guid.trim() !== "") {
                    setCustomType(props.suggestions.find((e) => e.value === guid));
                } else {
                    setCustomType(null);
                }
            }
        }
    }, [props.suggestions, props.index, props.currentInterfaceStep]);

    function onChange(value) {
        setCustomType(value);
        const temp = {...props.currentTemplateData};
        if (props.section === SectionsModal.rawDataAlarmType) {
            temp.alarms[props.index].customAlarmTypeGuid = value != null ? value.item.guid : null;
        } else {
            temp.diagnostics[props.index].customDiagnosticTypeGuid = value != null ? value.item.guid : null;
        }
        props.setTemplate({...temp});
    }

    return (
        <>
            <SelectInput
                clearable={true}
                onChange={onChange}
                name="Custom"
                options={props.suggestions}
                searchable={true}
                value={customType}
                disabled={props.suggestions == null || props.suggestions.length === 0}
            />
        </>
    );
};

export default SelectInputCustomType;
