import * as React from "react";

type Entries<K, V> = Array<[K, V]> | Map<K, V>;

type FromEntries<K, V, A extends unknown[]> = Entries<K, V> | ((prevEntries: Map<K, V>, ...args: A) => Entries<K, V>);

export interface StatefulMap<K, V> {
    mapRef(): Map<K, V>;
    toArray(): Array<[K, V]>;
    toArray<T>(mapper: (key: K, value: V) => T): T[];
    set(key: K, value: V): void;
    set<Args extends unknown[]>(key: K, value: (prevValue: V, ...args: Args) => V, args?: Args): void;
    add(entries: Entries<K, V>): void;
    from(entries: Entries<K, V>): void;
    from<Args extends unknown[]>(entries: (prevEntries: Map<K, V>, ...args: Args) => Entries<K, V>, args?: Args): void;
    get(key: K): V;
    get<M extends (value: V, key: K) => unknown>(key: K, mapper: M): ReturnType<M>;
    has(key: K): boolean;
    delete(key: K): void;
    delete(...keys: K[]): void;
    delete(predicate: (value: V, key) => boolean, key: K): void;
    delete(predicate: (value: V, key) => boolean, ...keys: K[]): void;
    clear(): void;
    isEmpty(): boolean;
}

type EntriesInit<K, V> = Entries<K, V> | (() => Entries<K, V>);

export function useMap<K, V>(entriesInit?: EntriesInit<K, V>): StatefulMap<K, V> {
    const [state, setState] = React.useState(() => {
        const entries = Array.from((typeof entriesInit === "function" ? entriesInit() : entriesInit) ?? []);
        return {
            entries,
            map: new Map<K, V>(entries),
        };
    });
    return {
        mapRef() {
            return state.map;
        },
        toArray<T>(mapper?: (key: K, value: V) => T) {
            if (typeof mapper === "function") {
                return  state.entries.map(([key, value]) => mapper(key, value));
            }
            return state.entries;
        },
        set(key, value, args = []) {
            setState((prevState) => {
                const newMap = new Map(prevState.entries);
                newMap.set(key, typeof value === "function" ? value(prevState.map.get(key), ...args) : value);
                return {
                    entries: Array.from(newMap.entries()),
                    map: newMap,
                };
            });
        },
        add(entries) {
            setState((prevState) => {
                const newMap = new Map(prevState.entries);
                Array.from(entries).forEach(([key, value]) => newMap.set(key, value));
                return {
                    entries: Array.from(newMap),
                    map: newMap,
                };
            });
        },
        from(entriesOrFactory: FromEntries<K, V, unknown[]>, args = []) {
            setState((prevState) => {
                const newEntries = Array.from(typeof entriesOrFactory === "function"
                    ? entriesOrFactory(prevState.map, ...args)
                    : entriesOrFactory,
                );
                return {
                    entries: newEntries,
                    map: new Map(newEntries),
                };
            });
        },
        delete(keyOrPredicate, ...keys) {
            setState((prevState) => {
                const newMap = new Map(prevState.entries);
                if (typeof keyOrPredicate === "function") {
                    keys.filter((key) => keyOrPredicate(newMap.get(key), key))
                        .forEach(newMap.delete);
                } else {
                    newMap.delete(keyOrPredicate);
                    keys.forEach(newMap.delete);
                }
                return {
                    entries: Array.from(newMap.entries()),
                    map: newMap,
                };
            });
        },
        get(key, mapper?) {
            const value = state.map.get(key);
            if (typeof mapper === "function" && state.map.has(key)) {
                return mapper(value, key);
            }
            return value;
        },
        has(key) {
            return state.map.has(key);
        },
        clear() {
            setState(() => ({
                entries: [],
                map: new Map(),
            }));
        },
        isEmpty(): boolean {
            return state.map.size === 0;
        },
    };
}
