import * as React from "react";

export type AfterProps = React.HTMLProps<HTMLElement> & {
    asTag?: keyof JSX.IntrinsicElements;
};

export function After({children, asTag, ...props}: AfterProps) {
    return React.createElement(
        asTag ?? "span",
        props,
        children,
    );
}
