import * as React from "react";
import {usePromise} from "../hooks/usePromise";
import {DataState, DataStoreContext} from "./context";


type UseDataProps<T, U> = {
    loader: (() => T )| (() => Promise<T>),
    keys: string[],
    map?(data: Awaited<T>): U,
};


type UseDataType<T, E = unknown> = {
    state: "loading" | "successful" | "error",
    data: Awaited<T>,
    error: E,
    update(newData: T): void,
    refresh(): void,
};

export function useData<T, U = T>(props: UseDataProps<T, U>): UseDataType<U> {

    const store = React.useContext(DataStoreContext);

    const [useDataState, setUseDataState] = React.useState<DataState<U>>({
        state: "loading",
        data: undefined,
        error: undefined,
    });

    const {state, data} = usePromise(async () => {
        if (store != null) {
            if (store.sharedDataStore.has(props.keys[0])) {
                return store.sharedDataStore.get(props.keys[0]) as DataState<U>;
            }

            store.sharedDataStore.set(props.keys[0], {
                state: "loading",
                data: undefined,
                error: undefined,
            });
        }

        const loadedData = await props.loader();

        const newDataState: DataState<U> = {
            state: "successful",
            data: await (props.map ? props.map(loadedData) : loadedData as U),
            error: undefined,
        }

        if (store != null) {
            store.sharedDataStore.set(props.keys[0], newDataState);
            store.stream.push([props.keys[0], newDataState]);
        }
        return newDataState;
    }, []);


    React.useEffect(() => {
        return store != null ? store.stream.observe(([key, newDataState]) => {
            if (!props.keys.includes(key)) {
                return;
            }
            setUseDataState(newDataState as DataState<U>);
        }) : undefined;
    }, []);


    React.useEffect(() => {
        if (state === "fulfilled") {
            setUseDataState(data);
        }
    }, [state]);

    return {
        ...useDataState,
        update(newData: U) {},
        refresh() {}
    };
}
