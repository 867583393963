import * as React from "react";
import {Company} from "../../models/company/companyModel";
import {ItemDetailView} from "./ItemDetailView";

interface CompanyItemViewProps {
    company: Company;
}

export function CompanyItemView({company}: CompanyItemViewProps) {

    if (company.parentCompany) {
        return (
            <ItemDetailView
                heading={company.name}
                subheading={`${company.type.name} • ${company.parentCompany.name}`}
            />
        );
    }
    return (
        <ItemDetailView
            heading={company.name}
            subheading={company.type.name}
        />
    );
}
