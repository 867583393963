import {ReceiptList} from "../../models/selection/selection";

class ReceiptController {
    public async findReceiptsByProductPartialName(name: string): Promise<ReceiptList> {
        const response = await fetch("/receipts?name=" + name);
        return response.json();
    }

    public async findReceiptsByCompany(companyGuid: string): Promise<ReceiptList> {
        const response = await fetch("/receipts?companyGuid=" + companyGuid);
        return response.json();
    }
}

export default ReceiptController;
