import {Button, Checkbox, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Services from "../../controllers/utils/Services";
import {AlarmTypeSeveritySuggestions, CompanyAlarmType} from "../../models/alarm/alarmType";
import {Company} from "../../models/company/companyModel";
import {Translation, Translations} from "../../models/translations/translationsModels";
import TranslationController from "../../controllers/api/TranslationController";

interface TranslationModalProps {
    currentCompany: Company;
    onHide: () => void;
    show: boolean;
    alarmTypeToTranslate: CompanyAlarmType;
    type: string;
}

interface TranslationModel {
    text: string;
    key: string;
    render: string;
    defaultTranslation: string;
    companyTranslation: string;
    countryCode: string;
}

const TranslationModal: React.FC<TranslationModalProps> = (props) => {
    const translationController: TranslationController = new TranslationController();
    const services = new Services();

    const [translationForms, setTranslationForm] = React.useState<TranslationModel[]>(getInitialTranslationList());

    function getInitialTranslationList() {
        return [
            {
                text: "",
                key: "english",
                render: "English",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "us.png",
            }, {
                text: "",
                key: "italian",
                render: "Italian",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "it.png",
            }, {
                text: "",
                key: "greek",
                render: "Greek",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "gr.png",
            }, {
                text: "",
                key: "spanish",
                render: "Spanish",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "es.png",
            }, {
                text: "",
                key: "russian",
                render: "Russian",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "ru.png",
            }, {
                text: "",
                key: "slovak",
                render: "Slovak",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "sk.png",
            }, {
                text: "",
                key: "hungarian",
                render: "Hungarian",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "hu.png",
            }, {
                text: "",
                key: "dutch",
                render: "Dutch",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "nl.png",
            }, {
                text: "",
                key: "turkish",
                render: "Turkish",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "tr.png",
            }, {
                text: "",
                key: "portuguese",
                render: "Portuguese",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "pt.png",
            }, {
                text: "",
                key: "bosnian",
                render: "Bosnian",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "ba.png",
            }, {
                text: "",
                key: "polish",
                render: "Polish",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "pl.png",
            }, {
                text: "",
                key: "romanian",
                render: "Romanian",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "ro.png",
            }, {
                text: "",
                key: "french",
                render: "French",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "fr.png",
            }, {
                text: "",
                key: "czech",
                render: "Czech",
                defaultTranslation: "",
                companyTranslation: "",
                countryCode: "cz.png",
            },
        ];
    }

    function onRemovePressed() {
        props.onHide();
    }

    function onSubmit() {
        const translation: Translation = {
            textKey: props.alarmTypeToTranslate.alarmType.nameKey +
                (props.type === "description" ? ".description" : ""),
            englishSource: translationForms.find((e) => e.key === "english").text,
            companyGuid: props.currentCompany.guid,
            translations: createTranslationsInstance(),
        };
        translationController.putTranslation(props.currentCompany.guid, translation).then(
            (response) => {
                if (response.status === 201) {
                    services.successNotification(" ", "Translation successfully created");
                } else {
                    services.errorNotification(" ", "An error occurred, translation has not been created!");
                }
                props.onHide();
            },
        );
    }

    function createTranslationsInstance() {
        const t: Translations = {};
        translationForms.filter((e) => e.text != null && e.text !== "").forEach((e) => {
            t[e.key] = e.text;
        });
        return t;
    }

    function onResetPressed() {
        return () => {
            setTranslationForm(
                translationForms.map((e) => {
                    e.text = "";
                    return e;
                }),
            );
        };
    }

    function onInputTextChange(column: string) {
        return (t) => {
            const value = t.target.value;
            const newTranslationForms = translationForms.map((e) => {
                e.text = e.key === column ? value : e.text;
                return e;
            });
            setTranslationForm(newTranslationForms);
        };
    }

    React.useEffect(() => {
        if (props.alarmTypeToTranslate != null) {
            const newTranslationForms = [...translationForms];
            switch (props.type) {
                case "name":
                    if (props.alarmTypeToTranslate.customNameTranslation != null) {
                        newTranslationForms.map((e) => {
                            e.companyTranslation = props.alarmTypeToTranslate.customNameTranslation
                                .translations[e.key];
                            return e;
                        });
                    } else {
                        newTranslationForms.map((e) => {
                            e.companyTranslation = "";
                            return e;
                        });
                    }
                    if (props.alarmTypeToTranslate.alarmType.nameTranslation != null) {
                        newTranslationForms.map((e) => {
                            e.defaultTranslation = props.alarmTypeToTranslate.alarmType.nameTranslation
                                .translations[e.key];
                            return e;
                        });
                    } else {
                        newTranslationForms.map((e) => {
                            e.defaultTranslation = "";
                            return e;
                        });
                    }
                    break;
                case "description":
                    if (props.alarmTypeToTranslate.customDescriptionTranslation != null) {
                        newTranslationForms.map((e) => {
                            e.companyTranslation = props.alarmTypeToTranslate.customDescriptionTranslation
                                .translations[e.key];
                            return e;
                        });
                    } else {
                        newTranslationForms.map((e) => {
                            e.companyTranslation = "";
                            return e;
                        });
                    }
                    if (props.alarmTypeToTranslate.alarmType.descriptionTranslation != null) {
                        newTranslationForms.map((e) => {
                            e.defaultTranslation = props.alarmTypeToTranslate.alarmType.descriptionTranslation
                                .translations[e.key];
                            return e;
                        });
                    } else {
                        newTranslationForms.map((e) => {
                            e.defaultTranslation = "";
                            return e;
                        });
                    }
                    break;
                default:
                    break;
            }
            setTranslationForm(newTranslationForms);
            setTranslationForm(
                translationForms.map((e) => {
                    e.text = "";
                    return e;
                }),
            );
        }
    }, [props.alarmTypeToTranslate, props.type]);

    function importText(col: TranslationModel, text: string) {
        return () => {
            const newTranslationForms = translationForms.map((e) => {
                    if (e.key === col.key) {
                        e.text = text;
                    }
                    return e;
                },
            );
            setTranslationForm(newTranslationForms);
        };
    }

    function importDefaultText() {
        return () => {
            const newTranslationForms = translationForms.map((e) => {
                e.text = props.type === "name" ?
                    (props.alarmTypeToTranslate.alarmType.nameTranslation != null ?
                        props.alarmTypeToTranslate.alarmType.nameTranslation.translations[e.key] : "") :
                    (props.alarmTypeToTranslate.alarmType.descriptionTranslation != null ?
                        props.alarmTypeToTranslate.alarmType.descriptionTranslation.translations[e.key] : null);
                return e;
            });
            setTranslationForm(newTranslationForms);
        };
    }

    function importCustomText() {
        return () => {
            const newTranslationForms = translationForms.map((e) => {
                e.text = props.type === "name" ?
                    (props.alarmTypeToTranslate.customNameTranslation != null ?
                        props.alarmTypeToTranslate.customNameTranslation.translations[e.key] : "") :
                    (props.alarmTypeToTranslate.customDescriptionTranslation != null ?
                        props.alarmTypeToTranslate.customDescriptionTranslation.translations[e.key] : "");
                return e;
            });
            setTranslationForm(newTranslationForms);
        };
    }

    return (
        <Modal {...props} contentClassName={"modal-dialog"} id={"translationModal"}>
            <Modal.Header>
                <Row style={{justifyContent: "flex-start", display: "flex", width: "100%"}}>
                    {/*//TODO*/}
                    {/*    <Button onClick={}> */}
                    {/*        Use Default*/}
                    {/*    </Button>*/}
                    {
                        translationForms.some((e) => e.defaultTranslation != null && e.defaultTranslation !== "") && (
                            <Button
                                style={{marginLeft: "30px", marginRight: "30px"}}
                                onClick={importDefaultText()}
                            > Apply Default to all
                            </Button>
                        )
                    }
                    {
                        translationForms.some((e) => e.companyTranslation != null && e.companyTranslation !== "") && (
                            <Button
                                style={{marginLeft: "30px", marginRight: "30px"}}
                                variant="secondary"
                                onClick={importCustomText()}
                            > Apply from {props.alarmTypeToTranslate.companyName}
                            </Button>
                        )
                    }
                    <Button
                        style={{marginLeft: "30px", marginRight: "30px"}}
                        variant="primary"
                        onClick={onResetPressed()}
                    > Reset
                    </Button>
                </Row>
            </Modal.Header>
            <Modal.Body>
                {translationForms.map((col) => (
                    <Col key={col.key} style={{paddingBottom: "20px", borderBottomStyle: "inset", borderBottomColor: "#ebebeb"}}>
                        <Row style={{display: "flex"}} className={"pb-1 px-2"}>
                            <div style={{marginRight: "20px"}}>
                                <img src={"https://vbx.prosa.com/vebox/images/flags/" + col.countryCode}/>
                            </div>
                            <div>
                                <b>{col.render}</b>
                            </div>

                        </Row>
                        <Row className={"px-2"}>
                            <Col>
                                {col.defaultTranslation != null && col.defaultTranslation !== "" ?
                                    (
                                        <Row>
                                            <Col className={"col-11 my-auto"}>
                                                <p style={{marginBottom: "0px"}}>
                                                    <b>
                                                        Default:{" "}
                                                    </b>
                                                    {col.defaultTranslation}
                                                </p>
                                            </Col>
                                            <Col className={"col-1 my-auto"}>
                                                <Button
                                                    onClick={importText(col, col.defaultTranslation)}
                                                >  {services.addRightArrow()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (<></>)
                                }
                                {col.companyTranslation != null && col.companyTranslation !== "" ?
                                    (
                                        <Row>
                                            <Col className={"col-11 my-auto"}>
                                                <p style={{marginBottom: "0px"}}>
                                                    <b>
                                                        Company ({props.alarmTypeToTranslate.companyName}):{" "}
                                                    </b>
                                                    {col.companyTranslation}
                                                </p>
                                            </Col>
                                            <Col className={"col-1 my-auto"}>
                                                <Button
                                                    onClick={importText(col, col.companyTranslation)}
                                                >  {services.addRightArrow()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (<></>)
                                }
                            </Col>
                            <Col>
                                <Form.Control
                                    className={"sc-crzoAE sc-giAqHp textInput bhgTuB"}
                                    as="textarea"
                                    rows={2}
                                    value={col.text}
                                    onChange={onInputTextChange(col.key)}
                                />
                                {/*<TextInput*/}
                                {/*    styles={{*/}
                                {/*        input: {textOverflow: "auto", height: "100%", width: "100%"},*/}
                                {/*        root: {height: "100%", width: "100%"},*/}
                                {/*    }}*/}
                                {/*    value={col.text}*/}
                                {/*    onChange={onInputTextChange(col.key)}*/}
                                {/*/>*/}
                            </Col>
                        </Row>
                    </Col>

                ))
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    
                    onClick={onRemovePressed}
                >
                    Close
                </Button>
                {" "}
                <Button
                    disabled={!translationForms.some((t) => t.text !== "")}
                    variant="strong"
                    onClick={onSubmit}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TranslationModal;
