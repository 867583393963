import * as React from "react";
import {DeviceSearchResultsStats} from "../../types";
import {ItemDetailView} from "../../../common/ItemDetailView";

interface DeviceSearchResultsStatsInvalidViewProps {
    stats: DeviceSearchResultsStats;
}

export function DeviceSearchResultsStatsInvalidView({stats}: DeviceSearchResultsStatsInvalidViewProps) {
    if (stats.alreadyInstalled === 0 && stats.notFound === 0) {
        return null;
    }
    return (
        <ItemDetailView
            className={"device-search-results-stats-invalid-devices"}
            heading={"Cannot Proceed: not valid devices found"}
            subheading={(
                <span className={"device-search-results-stats-invalid-devices-numbers"}>
                    {stats.notFound > 0
                        ? <span>Not found: {stats.notFound}</span>
                        : null}
                    {stats.alreadyInstalled > 0
                        ? <span> Already installed: {stats.alreadyInstalled}</span>
                        : null}
                </span>
            )}
        />
    );
}
