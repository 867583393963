import * as React from "react";

import "./styles.scss";

export function FileUploadButton({children, accept, ...props}: React.HTMLProps<HTMLLabelElement>) {
    return (
        <label
            {...props}
            className={`btn file-upload-btn ${props.className ?? ""}`}
            role={"button"}
            tabIndex={0}
        >
            <input
                className={"file-upload-input"}
                type={"file"}
                accept={accept}
            />
            {children}
        </label>
    );
}
