import * as React from "react";

export function toOutsideElements<E extends Node>(
    f: React.FocusEventHandler<E>,
    ...also: Array<React.FocusEventHandler<E>>
): React.FocusEventHandler<E> {
    return (e) => {
        if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) {
            return;
        }
        f(e);
        also.forEach((otherF) => {
            otherF(e);
        });
    };
}

export function toOutsideElementsIgnoring<E extends Node>(
    ignoredElements: Array<React.RefObject<Node>>,
    f: React.FocusEventHandler<E>,
    ...also: Array<React.FocusEventHandler<E>>
): React.FocusEventHandler<E> {
    return (e) => {
        if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) {
            return;
        } else if (e.relatedTarget instanceof Node) {
            for (const node of ignoredElements) {
                if (Boolean(node.current)
                    && (node.current.isEqualNode(e.relatedTarget) || node.current.contains(e.relatedTarget))) {
                    return;
                }
            }
        }
        f(e);
        also.forEach((otherF) => {
            otherF(e);
        });
    };
}

export function stopPropagation<E extends React.SyntheticEvent>(
    f: React.EventHandler<E>,
    ...also: Array<React.EventHandler<E>>
): React.EventHandler<E> {
    return (e) => {
        e.stopPropagation();
        f(e);
        also.forEach((otherF) => {
            otherF?.(e);
        });
    };
}

export function preventDefault<E extends React.SyntheticEvent>(e: React.SyntheticEvent): void;

export function preventDefault<E extends React.SyntheticEvent>(
    f: React.EventHandler<E>,
    ...also: Array<React.EventHandler<E>>
): React.EventHandler<E> ;
export function preventDefault<E extends React.SyntheticEvent>(f?, ...also) {
    if (f?.nativeEvent instanceof Event) {
        f.preventDefault();
        return undefined;
    }

    return (e) => {
        e.preventDefault();
        f(e);
        also.forEach((otherF) => {
            otherF(e);
        });
    };
}

type RKEH<E = Element> = React.KeyboardEventHandler<E>;

interface KeyMap {
    [p: string]: RKEH[];
}

type accum = [string, KeyMap];

// Todo When update typescript over 4.4.2
//  type NonConsecutiveSequence<T extends readonly unknown[], V, Spacer> =
//     T extends readonly [...Spacer[], Spacer] ? T
//     : T extends readonly [V, Spacer] ? T
//     : T extends readonly [...infer T2, V, Spacer] ? (T2 extends NonConsecutiveSequence<T2, V, Spacer> ? T : never)
//     : T extends readonly [...infer T3, Spacer] ? (T3 extends NonConsecutiveSequence<T3, V, Spacer> ? T : never)
//     : never;
//  type F = (...x: any) => unknown;
//  function onKey<E extends Element, Params extends unknown[]>(
//     key: string,
//     f: React.KeyboardEventHandler<E>,
//     ...otherKeys: NonConsecutiveSequence<Params, string, F>
//  ): React.KeyboardEventHandler<E>;

export function onKey<E extends Element>(
    key: string,
    f: RKEH<E>,
    ...additionalHandlersAndKeys: Array<string | RKEH<E>>
): RKEH<E> {

    // todo refactor code to make more readable
    const [, keyHandlerMap] = additionalHandlersAndKeys.reduce<accum>(([lastKey, keyMap], item: (string | RKEH)) => {
        if (typeof item === "string") {
            return [item, {...keyMap, [item]: []}];
        }
        return [lastKey, {...keyMap, [lastKey]: [...keyMap[lastKey], item]}];
    }, [key, {[key]: []}]);

    return (e) => {
        if (e.key === key) {
            f(e);
        }
        keyHandlerMap[e.key]?.forEach((handlers) => handlers(e));
    };
}

type ValueConsumer = (value: unknown) => void;

export function consumeCurrenTargetValue< E extends Event>(consumer: ValueConsumer) {
    return <T extends HTMLDataElement>(e: React.BaseSyntheticEvent<E, T, EventTarget>) => {
        consumer(e.currentTarget.value);
    };
}

export function consumeTargetValue< E extends Event>(consumer: ValueConsumer, ...otherConsumers: ValueConsumer[]) {
    return <T extends HTMLDataElement, C extends Element>(e: React.BaseSyntheticEvent<E, C, T>) => {
        consumer(e.target.value);
        otherConsumers.forEach((otherConsumer) => {
            otherConsumer(e.target.value);
        });
    };
}

export function consumeTarget<T extends HTMLElement, E extends Event>(
    property: keyof T,
    consumer: ValueConsumer,
    ...otherConsumers: ValueConsumer[]
) {
    return <C extends Element>(e: React.BaseSyntheticEvent<E, C, T>) => {
        consumer(e.target[property]);
        otherConsumers.forEach((otherConsumer) => {
            otherConsumer(e.target[property]);
        });
    };
}

type EventHandler<T extends React.SyntheticEvent = React.SyntheticEvent> = React.EventHandler<T>;
export function makeCurrentTargetTheTarget<E extends React.SyntheticEvent>(e: E): void;
export function makeCurrentTargetTheTarget<T extends EventHandler>(then: T, ...also: T[]): T;
export function makeCurrentTargetTheTarget(thenOrEvent, ...also): void | React.EventHandler<React.SyntheticEvent> {
    if (thenOrEvent?.nativeEvent instanceof Event) {
        thenOrEvent.target = thenOrEvent.currentTarget;
        return;
    }
    return (e) => {
        e.target = e.currentTarget;
        thenOrEvent?.(e);
        also.forEach((otherEventHandler) => {
            otherEventHandler?.(e);
        });
    };
}

export function selectCurrentTargetText<E extends React.SyntheticEvent<HTMLInputElement>>(e: E) {
    e.currentTarget.select();
}

export function onCurrentTarget<E extends React.SyntheticEvent>(
    then: EventHandler<E>,
    ...also: Array<EventHandler<E>>
): EventHandler<E> {
    return (e) => {
        if (e.target instanceof Node && !e.currentTarget.isEqualNode(e.target)) {
            return;
        }
        then?.(e);
        also.forEach((otherEventHandler) => {
            otherEventHandler?.(e);
        });
    };
}
