import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {Card} from "react-bootstrap";
import AlarmController from "../../controllers/api/AlarmController";
import ConfigurabilityController from "../../controllers/api/ConfigurabilityController";
import DeviceModelController from "../../controllers/api/DeviceController";
import DiagnosticController from "../../controllers/api/DiagnosticController";
import Services from "../../controllers/utils/Services";
import {setLegacyBreadcrumb} from "../../index";
import {AlarmType} from "../../models/alarm/alarmType";
import {PatchList} from "../../models/company/patchModel";
import {FunctionCodeModbus} from "../../models/configurability/configurabilityEnumsModel";
import {ComputationSetting} from "../../models/configurability/rawData/computationSettings";
import {RawDataAlarmType} from "../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../models/configurability/rawData/rawDataModel";
import {DeviceModel} from "../../models/device/deviceModelModel";
import {DiagnosticType} from "../../models/diagnostic/diagnosticType";
import {ProcessingFunction} from "../../models/processingFunction/procesingFunctionModel";
import {PropertiesGroup} from "../../models/property/property";
import {Suggestion} from "../../models/Suggestion";
import * as LegacyPage from "../../page";
import LoadingModal from "../common/LoadingModal";
import DeviceModelSection from "./sections/DeviceModelSection";
import RawDataAlarmTypeSection from "./sections/RawDataAlarmTypeSection";
import RawDataDiagnosticTypeSection from "./sections/RawDataDiagnosticTypeSection";
import RawDataSection from "./sections/RawDataSection";

export enum Sections { // Do not change the order
    deviceModel,
    rawData,
    rawDataAlarmType,
    rawDataDiagnosticType,
}

export enum ProcessingParamType {
    PARAMETER = "PARAMETER",
    REFERENCE_DATA = "REFERENCE_DATA",
    REFERENCE_PROCESSED_DATA = "REFERENCE_PROCESSED_DATA",
    REFERENCE_PROPERTY = "REFERENCE_PROPERTY",
    REF = "REF",
}

// tslint:disable:max-line-length
export const CreateRawData: React.FC = () => {
    // controllers
    const configurabilityController: ConfigurabilityController = new ConfigurabilityController();
    const deviceModelController: DeviceModelController = new DeviceModelController();
    const alarmController: AlarmController = new AlarmController();
    const diagnosticController: DiagnosticController = new DiagnosticController();
    const services = new Services();

    const [samplings, setSamplings] = React.useState<Array<Suggestion<any>>>([]);
    const [dataGroup, setDataGroup] = React.useState<Array<Suggestion<string>>>([]);
    const [processingParametersType, setProcessingParametersType] = React.useState<Array<Suggestion<any>>>([]);
    const [functionCodeModbusSuggestion, setFunctionCodeModbusSuggestion] = React.useState<Array<Suggestion<FunctionCodeModbus>>>([]);
    const [processingCategory, setProcessingCategory] = React.useState<Array<Suggestion<any>>>([]);
    const [conversionFunction, setConversionFunction] = React.useState<Array<Suggestion<any>>>([]);
    const [processingFunctions, setProcessingFunctions] = React.useState<Array<Suggestion<ProcessingFunction>>>([]);
    const [embeddedTypes, setEmbeddedTypes] = React.useState<Array<Suggestion<string>>>([]);

    const [confirmDeleteShow, setConfirmDeleteShow] = React.useState<boolean>(false);

    /* RawData Hooks */
    const [currentRD, setCurrentRD] = React.useState<RawData>(undefined);

    const [selectedInputRawData, setSelectedInputRawData] = React.useState<RawData>(undefined);
    const [selectedInputRawDataName, setSelectedInputRawDataName] = React.useState<string>("");

    const [currentRawDataDiagnosticType, setCurrentRawDataDiagnosticType] = React.useState<RawDataDiagnosticType>(undefined);
    const [currentRawDataAlarmType, setCurrentRawDataAlarmType] = React.useState<RawDataAlarmType>(undefined);

    const [rawDataName, setRawDataName] = React.useState<string>("");
    const [group, setGroup] = React.useState<string>("");
    const [pos, setPos] = React.useState<number>();

    const [loading, setLoading] = React.useState<boolean>(false);
    // DeviceModel Hooks
    const [deviceModel, setDeviceModel] = React.useState<string>("");
    const [selectedDeviceModel, setSelectedDeviceModel] = React.useState<DeviceModel>(undefined);
    const [deviceModels, setDeviceModels] = React.useState<Array<Suggestion<DeviceModel>>>([]);

    const [alarmTypes, setAlarmTypes] = React.useState<Array<Suggestion<AlarmType>>>([]);
    const [propertiesGroups, setPropertiesGroups] = React.useState<Array<Suggestion<PropertiesGroup>>>([]);
    const [diagnosticTypes, setDiagnosticTypes] = React.useState<Array<Suggestion<DiagnosticType>>>([]);
    const [computationSetting, setComputationSetting] = React.useState<ComputationSetting>(undefined);

    const [rawDataList, setRawDataList] = React.useState<RawData[]>([]);
    const [rawDataSuggestionList, setRawDataSuggestionList] = React.useState<Array<Suggestion<RawData>>>([]);
    const [rawDataDiagnosticTypeList, setRawDataDiagnosticTypeList] = React.useState<RawDataDiagnosticType[]>([]);
    const [rawDataAlarmTypeList, setRawDataAlarmTypeList] = React.useState<RawDataAlarmType[]>([]);

    /* Steps hooks */
    const [currentStep, setCurrentStep] = React.useState<number>(0);

    React.useEffect(() => {

        setLoading(true);
        deviceModelController.findDeviceModels().then((response) => {
                setDeviceModels(response.list.map((item) => ({item, label: item.name, value: item.guid})));
                setLoading(false);
            },
        );
        alarmController.findGenericAlarmTypes().then((response) => {
                setAlarmTypes(response.set.map((item) => ({item, label: item.name, value: item.guid})));
            },
        );
        configurabilityController.findAllPropertiesGroups().then((response) => {
                setPropertiesGroups(response.list.map((item) => ({item, label: item.name, value: item.guid})));
            },
        );
        diagnosticController.findGenericDiagnosticTypes().then((response) => {
                setDiagnosticTypes(response.set.map((item) => ({item, label: item.name, value: item.guid})));
            },
        );
        configurabilityController.findAllProcessingFunction().then((response) => {
                setProcessingFunctions(response.list.map((item) => ({
                    item,
                    label: item.name,
                    value: item.code.toString(),
                })));
            },
        );
        configurabilityController.findComputationSettingsEnums().then(
            (response) => {
                setSamplings(response.sampling.map((item) => ({item, label: item, value: item})));
                setDataGroup(response.dataGroupType.map((item) => ({item, label: item, value: item})));
                setFunctionCodeModbusSuggestion(response.functionCodeModbus
                    .map((item) => ({item, label: item.value, value: item.code})));
                setProcessingCategory(response.processingCategory.map((item) => ({item, label: item, value: item})));
                setConversionFunction(response.conversionFunction.map((item) => ({item, label: item, value: item})));
                setProcessingParametersType(response.processingParametersType
                    .map((item) => ({item, label: item, value: item})));
                setEmbeddedTypes(response.embeddedTypes.map((item) => ({item, label: item, value: item})));
            },
        );

    }, []);

    const deviceModelSection = (
        <DeviceModelSection
            loading={loading}
            rawDataList={rawDataList}
            deviceModel={deviceModel}
            selectedDeviceModel={selectedDeviceModel}
            deviceModels={deviceModels}
            onUpdateButton={onUpdateButton}
            onCreateButton={onCreateButton}
            onDeleteRawData={onDeleteRawData}
            setConfirmDeleteShow={setConfirmDeleteShow}
            confirmDeleteShow={confirmDeleteShow}
            onSearchDeviceModel={onSearchDeviceModel}
            rawDataAlarmTypeList={rawDataAlarmTypeList}
            rawDataDiagnosticTypeList={rawDataDiagnosticTypeList}
            diagnosticTypes={diagnosticTypes}
        />
    );

    function fetchData(guid: string, section?: Sections) {
        configurabilityController.findRawDataByDeviceModelGuid(guid).then(
            (rawDataResponse) => {
                setRawDataList(rawDataResponse.set);
                const rawDataSuggestions: Array<Suggestion<RawData>> = rawDataResponse.set.map((item) => ({
                    item,
                    label: item.name,
                    value: item.guid,
                }));
                setRawDataSuggestionList(rawDataSuggestions);
                configurabilityController.findRawDataDiagnosticTypeByDeviceModelGuid(guid).then(
                    (rawDataDiagnosticTypeResponse) => {
                        setRawDataDiagnosticTypeList(rawDataDiagnosticTypeResponse.set);
                        configurabilityController.findRawDataAlarmTypeByDeviceModelGuid(guid).then(
                            (rawDataAlarmTypeResponse) => {
                                setRawDataAlarmTypeList(rawDataAlarmTypeResponse.set
                                    .map((item) => {
                                        const tmpPropertyGroupSuggestion = propertiesGroups
                                            .find((pg) => pg.value === item.propertyGroupGuid);
                                        const tmpPG = {...item};
                                        tmpPG.propertiesGroup = tmpPropertyGroupSuggestion !== undefined
                                            ? tmpPropertyGroupSuggestion.item : undefined;
                                        return tmpPG;
                                    }));
                                setLoading(false);
                                if (section != null) {
                                    services.setStep(setCurrentStep, section);
                                }
                            },
                        );
                    },
                );
            },
        );
    }

    function onSearchDeviceModel(suggestion) {
        if (suggestion !== null) {
            setSelectedDeviceModel(suggestion.item);
            setDeviceModel(suggestion.label);
            const guid = suggestion.item.guid;
            setLoading(true);
            fetchData(guid);
        }
    }

    function onUpdateButton(section: Sections, value: any) {
        return () => {
            switch (section) {
                case Sections.rawData: {
                    if (value != null) {
                        setCurrentRD(value);
                        setRawDataName(value.name);
                        setPos(value.positionIndex);
                        setComputationSetting(value.params);
                        setCurrentStep(section);
                    }
                    break;
                }
                case Sections.rawDataAlarmType: {
                    if (value != null) {
                        setCurrentRawDataAlarmType(value);
                        setCurrentStep(section);
                    }
                    break;
                }
                case Sections.rawDataDiagnosticType: {
                    if (value != null) {
                        setCurrentRawDataDiagnosticType(value);
                        const tempRawData: RawData = rawDataList.find((e) => e.guid === value.params.processing[0].parameters[0].value);
                        setSelectedInputRawData(tempRawData);
                        setSelectedInputRawDataName(tempRawData.name);
                        setGroup(value.params.processing[0].group);
                        setCurrentStep(section);
                    }
                    break;
                }
            }
        };
    }

    function onCreateButton(section: Sections) {
        return () => {
            const tempCompSetting = {acquisitions: [], processing: []};
            switch (section) {
                case Sections.rawData: {
                    setCurrentRD({
                        guid: "",
                        deviceModelGuid: selectedDeviceModel.guid,
                        name: "",
                        params: tempCompSetting,
                        positionIndex: undefined,
                    });
                    setRawDataName("");
                    setPos(undefined);
                    setComputationSetting(tempCompSetting);
                    setCurrentStep(section);
                    break;
                }
                case Sections.rawDataAlarmType: {
                    setCurrentRawDataAlarmType({
                        guid: "",
                        alarmTypeGuid: "",
                        childAlarmTypeGuid: "",
                        params: {processing: []},
                        propertyGroupGuid: undefined,
                        propertiesGroup: undefined,
                    });
                    setCurrentStep(section);
                    break;
                }
                case Sections.rawDataDiagnosticType: {
                    setCurrentRawDataDiagnosticType({
                        guid: "",
                        diagnosticTypeGuid: "",
                        params: {processing: []},
                        name: "",
                    });
                    setCurrentStep(section);
                    setSelectedInputRawDataName(undefined);
                    setSelectedInputRawData(undefined);
                    setGroup(undefined);
                    break;
                }
            }
        };
    }

    const rawDataAlarmType = (
        <RawDataAlarmTypeSection
            loading={loading}
            selectedDeviceModel={selectedDeviceModel}
            setCurrentStep={setCurrentStep}
            categories={processingCategory}
            groups={dataGroup}
            processingFunctions={processingFunctions}
            processingParametersTypes={processingParametersType}
            onSubmit={rawDataAlarmTypeSubmit}
            currentRawDataAlarmType={currentRawDataAlarmType}
            setCurrentRawDataAlarmType={setCurrentRawDataAlarmType}
            alarmTypes={alarmTypes}
            propertiesGroups={propertiesGroups}
            rawDataList={rawDataList}
        />
    );

    const rawDataDiagnosticType = (
        <RawDataDiagnosticTypeSection
            loading={loading}
            selectedDeviceModel={selectedDeviceModel}
            setCurrentStep={setCurrentStep}
            categories={processingCategory}
            groups={dataGroup}
            processingFunctions={processingFunctions}
            processingParametersTypes={processingParametersType}
            onSubmit={rawDataDiagnosticTypeSubmit}
            currentRawDataDiagnosticType={currentRawDataDiagnosticType}
            setCurrentRawDataDiagnosticType={setCurrentRawDataDiagnosticType}
            section={Sections.rawDataDiagnosticType}
            rawDataSuggestionList={rawDataSuggestionList}
            diagnosticTypes={diagnosticTypes}
            rawDataList={rawDataList}
            selectedInputRawData={selectedInputRawData}
            selectedInputRawDataName={selectedInputRawDataName}
            setSelectedInputRawData={setSelectedInputRawData}
            setSelectedInputRawDataName={setSelectedInputRawDataName}
            group={group}
            setGroup={setGroup}
        />
    );

    const rawData = (
        <RawDataSection
            embeddedTypes={embeddedTypes}
            loading={loading}
            currentRD={currentRD}
            rawDataName={rawDataName}
            pos={pos}
            selectedDeviceModel={selectedDeviceModel}
            setCurrentRD={setCurrentRD}
            setRawDataName={setRawDataName}
            setPos={setPos}
            computationSetting={computationSetting}
            samplings={samplings}
            conversionFunctions={conversionFunction}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            functionCodeModbusSuggestion={functionCodeModbusSuggestion}
            categories={processingCategory}
            groups={dataGroup}
            processingFunctions={processingFunctions}
            processingParametersTypes={processingParametersType}
            onSubmit={rawDataSubmit}
            rawDataList={rawDataList}
        />
    );

    function rawDataAlarmTypeSubmit(payload) {
        if (currentRawDataAlarmType != null && currentRawDataAlarmType.guid !== "") {
            onUpdateSection(Sections.rawDataAlarmType, payload);
        } else {
            onCreateSection(Sections.rawDataAlarmType, payload);
        }
    }

    function rawDataDiagnosticTypeSubmit(payload) {
        if (currentRawDataDiagnosticType != null && currentRawDataDiagnosticType.guid !== "") {
            onUpdateSection(Sections.rawDataDiagnosticType, payload);
        } else {
            onCreateSection(Sections.rawDataDiagnosticType, payload);
        }
    }

    function rawDataSubmit() {
        if (currentRD != null && currentRD.guid !== "") {
            onUpdateSection(Sections.rawData);
        } else {
            onCreateSection(Sections.rawData);
        }
    }

    function rawDataPayload() {
        const newRawData: RawData = {
            guid: "",
            deviceModelGuid: selectedDeviceModel.guid,
            name: rawDataName,
            params: computationSetting,
            positionIndex: pos,
        };
        return JSON.parse(JSON.stringify(newRawData));
    }

    function rawDataPatchPayload() {
        const payload: PatchList = {
            list: [
                services.patchOption("replace", "params", computationSetting), // fixme viene cambiato sempre
            ],
        };
        services.addReplaceOpIfValueChanged(payload, currentRD.name, rawDataName, "name");
        services.replaceOrRemove(payload, currentRD.positionIndex,
            pos != null ? pos.toString() : "", "positionIndex");
        return JSON.parse(JSON.stringify(payload));
    }

    function setNewCurrentRawData(response: { status: number; body: any }) {
        const responseRawData: RawData = response.body as RawData;
        setCurrentRD(responseRawData);
        setRawDataSuggestionList([...rawDataSuggestionList, {
            item: responseRawData,
            value: responseRawData.guid,
            label: responseRawData.name,
        }]);
    }

    function setNewCurrentRawDataAlarmType(response: { status: number; body: any }) {
        const responseRawDataAlarmType: RawDataAlarmType = response.body as RawDataAlarmType;
        setCurrentRawDataAlarmType(responseRawDataAlarmType);
    }

    function setNewCurrentRawDataDiagnosticType(response: { status: number; body: any }) {
        const responseRawDataDiagnosticType: RawDataDiagnosticType = response.body as RawDataDiagnosticType;
        setCurrentRawDataDiagnosticType(responseRawDataDiagnosticType);
    }

    function extracted(response: { status: number; body: any }, section: Sections) {
        if (response.status >= 400 && response.status <= 500) {
            services.errorNotification(" ", response.body.message);
        }
        if (response.status >= 200 && response.status <= 299) {
            services.successNotification(
                Sections[section][0].toUpperCase() + Sections[section].substring(1) + " created ",
                response.body.name + " created successfully ",
            );
            switch (section) {
                case Sections.rawData:
                    setNewCurrentRawData(response);
                    break;
                case Sections.rawDataAlarmType:
                    setNewCurrentRawDataAlarmType(response);
                    break;
                case Sections.rawDataDiagnosticType:
                    setNewCurrentRawDataDiagnosticType(response);
                    break;
            }
            fetchData(selectedDeviceModel.guid, Sections.deviceModel);

        }
    }

    function onCreateSection(section: Sections, payloadat?) {
        let payload;
        setLoading(true);
        switch (section) {
            case Sections.rawData:
                payload = rawDataPayload();
                break;
            case Sections.rawDataAlarmType:
                payload = payloadat;
                break;
            case Sections.rawDataDiagnosticType:
                payload = payloadat;
                break;
        }
        configurabilityController.createRawData(Sections[section], payload).then(
            (response: { status: number, body: any }) => {
                extracted(response, section);
            });
    }

    function onUpdateSection(section: Sections, payloadat?) {
        let payload;
        let guid;
        setLoading(true);
        switch (section) {
            case Sections.rawData:
                payload = rawDataPatchPayload();
                guid = currentRD.guid;
                break;
            case Sections.rawDataAlarmType:
                payload = payloadat;
                guid = currentRawDataAlarmType.guid;
                break;
            case Sections.rawDataDiagnosticType:
                payload = payloadat;
                guid = currentRawDataDiagnosticType.guid;
                break;
        }
        configurabilityController.patchRawData(Sections[section], payload, guid).then(
            (response: { status: number, body: any }) => {
                if (response.status >= 400 && response.status <= 500) {
                    services.errorNotification(" ", response.body.message);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification(
                        Sections[section][0].toUpperCase() + Sections[section].substring(1) + " Updated",
                        response.body.name + " updated successfully",
                    );
                    const responseRawData: RawData = response.body as RawData;
                    setCurrentRD(responseRawData);
                    fetchData(selectedDeviceModel.guid, Sections.deviceModel);
                }
            });
    }

    function onDeleteRawData(section: string, rawDataToDelete: RawData) {
        return () => {
            setConfirmDeleteShow(false);
            configurabilityController.deleteRawData(section, rawDataToDelete.guid).then(
                (response) => {
                    setLoading(true);
                    if (response.status >= 400 && response.status <= 500) {
                        services.errorNotification(" ", response.body.message);
                    }
                    if (response.status >= 200 && response.status <= 299) {
                        services.successNotification(" ", rawDataToDelete.name + " deleted successfully");
                        fetchData(selectedDeviceModel.guid, Sections.deviceModel);
                    }
                });

        };
    }

    const steps: Array<{ title: string, route: JSX.Element, value: number }> = [
        {
            title: "Select Device Model",
            route: deviceModelSection,
            value: Sections.deviceModel.valueOf(),
        }, {
            title: "Raw Data information",
            route: rawData,
            value: Sections.rawData.valueOf(),
        }, {
            title: "Raw Data Alarm Type information",
            route: rawDataAlarmType,
            value: Sections.rawDataAlarmType.valueOf(),
        },
        {
            title: "Raw Data Diagnostic Type information",
            route: rawDataDiagnosticType,
            value: Sections.rawDataDiagnosticType.valueOf(),
        },
    ];

    setLegacyBreadcrumb(
        new LegacyPage.Breadcrumb([
            new LegacyPage.BreadcrumbItem("./", "Dashboard", false),
            new LegacyPage.BreadcrumbItem(null, "Web operations", false),
            new LegacyPage.BreadcrumbItem(null, "Create RawData", true,
            ),
        ]),
    );

    React.useEffect(() => {
        if (loading) {
            services.loadingModal();
        }
    }, [loading]);

    return (
        <div className="w-100 mx-auto" id={"createRawData"}>
            <Card style={{height: "80.2vh"}}>
                <Card.Body>
                    <ThemeProvider theme={defaultTheme}>
                        <div style={{height: "20px"}}/>
                        {steps[currentStep].route}
                        <LoadingModal loading={loading}/>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </div>
    );
};
