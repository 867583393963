import {
    AutoSuggest,
    Button,
    Checkbox,
    icons,
    SelectInput, Spinner,
    TextInput,
} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import CompanyController from "../../../controllers/api/CompanyController";
import ComponentCategoryController from "../../../controllers/api/ComponentCategoryController";
import ComponentTypeController from "../../../controllers/api/ComponentTypeController";
import TranslationController from "../../../controllers/api/TranslationController";
import Services from "../../../controllers/utils/Services";
import {Company, CompanySuggestion} from "../../../models/company/companyModel";
import {ComponentBrand, ComponentBrandSuggestion} from "../../../models/component/componentBrand";
import {ComponentCategory, ComponentCategorySuggestion} from "../../../models/component/componentCategory";
import {ComponentModel, TableComponentModel} from "../../../models/component/componentModel";
import {
    ComponentCategoryType,
    ComponentType,
} from "../../../models/component/componentType";
import {Suggestion} from "../../../models/Suggestion";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";
import {MediaModal} from "../../common/MediaModal";

interface CompanyComponentModelsSectionProps {
    currentCompany: Company;
    tableComponentModels: TableComponentModel[];
    componentBrands: ComponentBrand[];
    setTableComponentModels: (componentModels: TableComponentModel[]) => void;
    componentBrandSuggestions: ComponentBrandSuggestion[];
    componentCategories: ComponentCategorySuggestion[];
    setLoading: (t: boolean) => void;
    selectedComponentCategory: ComponentCategory;
    setSelectedComponentCategory: (c: ComponentCategory) => void;
    componentCategoriesName: string;
    setComponentCategoriesName: (c: string) => void;

    componentTypes: Array<Suggestion<ComponentType>>;
    selectedComponentType: ComponentType;
    setSelectedComponentType: (c: ComponentType) => void;
    componentTypesName: string;
    setComponentTypesName: (c: string) => void;
    selectedComponentTypes: ComponentCategoryType[];
    setSelectedComponentTypes: (c: ComponentCategoryType[]) => void;

    inputComponentModels: Array<Suggestion<ComponentModel>>;
    selectedInputComponentModel: ComponentModel;
    setSelectedInputComponentModel: (c: ComponentModel) => void;
    selectedInputComponentModelsName: string;
    setSelectedInputComponentModelsName: (c: string) => void;

    loading: boolean;
    changeStep: (step: number) => void;
    step: number;
    submit: (imageList: File[]) => void;
}

const CompanyComponentModelsSection: React.FC<CompanyComponentModelsSectionProps> = (props) => {
    const services = new Services();
    const companyController: CompanyController = new CompanyController();
    const componentTypeController: ComponentTypeController = new ComponentTypeController();
    const componentCategoryController: ComponentCategoryController = new ComponentCategoryController();
    const translationController: TranslationController = new TranslationController();

    const INFO_TRANSLATION_TEXT_KEY = "operations.company_creation.component_models.generic_note";

    // Company Hooks
    const [selectedCompany, setSelectedCompany] = React.useState<Company>(undefined);
    const [companyName, setCompanyName] = React.useState<string>("");
    const [companies, setCompanies] = React.useState<CompanySuggestion[]>([]);

    // Table Hooks
    const [allChecked, setAllChecked] = React.useState<boolean>(true);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(true);

    const [actionsAvailable, setActionsAvailable] = React.useState<boolean>(true);

    const [selectedComponentModel, setSelectedComponentModel] = React.useState<TableComponentModel>(undefined);
    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    // Image Hooks
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [localMedia, setLocalMedia] = React.useState<File[]>([]);

    function submitWrapper() {
        return () => {
            props.submit(localMedia);
        };
    }

    const columns = [
        {
            key: "checkbox",
            style: {width: "10%"},
            render: (
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <span> Select all</span>
                    <br/>
                    <Checkbox
                        key={"selectAll"}
                        checked={allChecked}
                        indeterminate={indeterminate}
                        onChange={handleCheckbox}
                    />
                </div>

            ),
        },
        {
            key: "name",
            style: {width: "10%"},
            render: "Name",
        },
        {
            key: "client_name",
            style: {width: "10%"},
            render: "Client name",
        },
        {
            key: "client_name_key",
            style: {width: "20%"},
            render: "Client name key",
        },
        {
            key: "client_code",
            style: {width: "10%"},
            render: "Client code",
        },
        {
            key: "brand",
            style: {width: "23%"},
            render: "Brand",
        },
        {
            key: "nickname",
            style: {width: "12%"},
            render: "Nickname",
        },
        {
            key: "media",
            style: {width: "12%", maxWidth: "12%"},
            render: (
                <div>
                    <span style={{paddingRight: 2}}>Media </span>
                    {services.mediaIcon()}
                </div>
            ),
        },
    ];

    function handleCheckbox() {
        setAllChecked(!allChecked);
        props.setTableComponentModels(props.tableComponentModels.map((t) => {
            t.checked = !allChecked;
            return t;
        }));
    }

    function prepareComponentModels(item: TableComponentModel) {
        return (t) => {
            const value = t.target.checked;
            const newComponentModels = props.tableComponentModels.map((c) => {
                if (c.item.guid === item.item.guid &&
                    props.tableComponentModels.indexOf(c) === props.tableComponentModels.indexOf(item)) {
                    c.checked = value;
                }
                return c;
            });
            props.setTableComponentModels(
                [...newComponentModels.filter((e) => e.checked)
                    .sort((a, b) => (a.item.name >= b.item.name) ? -1 : 1),
                    ...newComponentModels.filter((e) => !e.checked)
                        .sort((a, b) => (a.item.name >= b.item.name) ? -1 : 1)],
            );
        };
    }

    function duplicateLine(item: TableComponentModel) {
        return () => {
            const l: TableComponentModel[] = [...props.tableComponentModels,
                {
                    item: item.item,
                    checked: true,
                    clientCode: "",
                    clientName: item.clientName,
                    clientNameKey: item.clientNameKey,
                    nickname: "",
                    brand: {item: {guid: "", description: ""}, label: "", value: ""},
                },
            ];
            props.setTableComponentModels(
                [...l.filter((e) => e.checked)
                    .sort((a, b) => (a.item.name >= b.item.name) ? -1 : 1),
                    ...l.filter((e) => !e.checked)
                        .sort((a, b) => (a.item.name >= b.item.name) ? -1 : 1)],
            );
        };
    }

    function onInputTextChange(item: TableComponentModel, column: string) {
        return (t) => {
            const value = t.target.value;
            const newComponentModels = props.tableComponentModels.map((c) => {
                if (item.item.guid === c.item.guid &&
                    props.tableComponentModels.indexOf(item) === props.tableComponentModels.indexOf(c)) {
                    switch (column) {
                        case "client_name":
                            c.clientName = value;
                            c.clientNameKey = item.item.manufacturerName + "." +
                                value.toLowerCase().split(" ").join("_");
                            break;
                        case "client_code":
                            c.clientCode = value.replace(/[^0-9\.]+/g, "");
                            break;
                        case "client_name_key":
                            c.clientNameKey = value;
                            break;
                        case "nickname":
                            c.nickname = value;
                            break;
                    }
                }
                return c;
            });
            props.setTableComponentModels(newComponentModels);
        };
    }

    function onShowModalButtonPressed(value: TableComponentModel) {
        setSelectedComponentModel(value);
        setShowModal(true);
    }

    function onSelectInputChange(item) {
        return (value) => {
            props.setTableComponentModels(
                props.tableComponentModels.map((c) => {
                    if (item.item.guid === c.item.guid &&
                        props.tableComponentModels.indexOf(item) === props.tableComponentModels.indexOf(c)) {
                        c.brand = value;
                    }
                    return c;
                }),
            );
        };
    }

    React.useEffect(() => {
        const map = {};
        let changed = false;
        props.tableComponentModels.filter((e) => e.checked).forEach(
            (item) => {
                if (map[item.item.guid + (item.brand !== undefined ? item.brand.item.guid : "")]) {
                    changed = true;
                    return;
                }
                map[item.item.guid + (item.brand !== undefined ? item.brand.item.guid : "")] = true;
            },
        );
        setActionsAvailable(!changed);

        if (props.tableComponentModels.every((v) => v.checked)) {
            setAllChecked(true);
            setIndeterminate(false);
        } else if (props.tableComponentModels.some((v) => v.checked)) {
            setAllChecked(false);
            setIndeterminate(true);
        } else {
            setAllChecked(false);
            setIndeterminate(false);
        }
    }, [props.tableComponentModels]);

    const handleCompanyStateChange = (params, downshiftStateAndHelpers) => {
        if (selectedCompany != null) {
            setSelectedCompany(undefined);
        }
        if (!downshiftStateAndHelpers.isOpen) {
            setCompanyName("");
        } else if (params.hasOwnProperty("inputValue")) {
            setCompanyName(params.inputValue);
        }
    };

    function deleteItemMedia(item: TableComponentModel) {
        props.setTableComponentModels(
            props.tableComponentModels.map((e) => {
                    if (e.item.guid + e.brand.value === item.item.guid + item.brand.value) {
                        e.existingMedia = undefined;
                        e.newMedia = undefined;
                    }
                    return e;
                },
            ),
        );
        setSelectedComponentModel(undefined);
    }

    React.useEffect(() => {
        if (companyName.length > 0) {
            props.setSelectedComponentType(undefined);
            props.setComponentTypesName("");
            props.setSelectedComponentCategory(undefined);
            props.setComponentCategoriesName("");
        }
        if (companyName.length >= 3) {
            companyController.getCompaniesByPatternAndRelationType(companyName, "ALL").then(
                (response) => {
                    const companySuggestion: CompanySuggestion[] = response.list
                        .filter((e) => e.guid !== props.currentCompany.guid)
                        .map((item) => ({
                            item,
                            label: item.name,
                            value: item.guid,
                        }));
                    setCompanies(companySuggestion);
                },
            );
        } else {
            setCompanies([]);
        }
    }, [companyName]);

    function onSearchButton() {
        props.setLoading(true);
        if (selectedCompany !== undefined && companyName !== "") {
            companyController.getComponentModelsByCompanyGuid(selectedCompany.guid).then(
                getOnfulfilled(),
            );
        }
        if (props.selectedComponentCategory !== undefined &&
            props.selectedComponentType === undefined &&
            props.selectedInputComponentModel === undefined
        ) {
            componentCategoryController.getComponentModelsByCategoryGuid(props.selectedComponentCategory.guid).then(
                getOnfulfilled(),
            );
        }
        if (props.selectedComponentCategory !== undefined &&
            props.selectedComponentType !== undefined &&
            props.selectedInputComponentModel === undefined
        ) {
            componentTypeController.getComponentModelsByTypeGuid(props.selectedComponentType.guid).then(
                getOnfulfilled(),
            );
        }
        if (props.selectedComponentCategory !== undefined &&
            props.selectedComponentType !== undefined &&
            props.selectedInputComponentModel !== undefined
        ) {
            if (!(props.tableComponentModels
                .filter((e) => e.brand.value === "")
                .map((e) => e.item.guid)
                .includes(props.selectedInputComponentModel.guid))) {
                const tableLength: number = props.tableComponentModels.length;
                const tableComponentModels: TableComponentModel[] = [...props.tableComponentModels,
                    ({
                        item: props.selectedInputComponentModel,
                        checked: true,
                        clientCode: "",
                        clientName: props.selectedInputComponentModel.name,
                        clientNameKey: props.selectedInputComponentModel.name,
                        nickname: "",
                        brand: {item: {guid: "", description: ""}, label: "", value: ""},
                    }),
                ];
                props.setTableComponentModels(tableComponentModels);
                if (tableComponentModels.length === tableLength) {
                    services.warningNotification(" ", "Element already present in the list");
                } else {
                    services.successNotification(" ", "Component model added");
                }
            }
            props.setLoading(false);
        }

        function getOnfulfilled() {
            return (response) => {
                if (response.list.length > 0) {
                    const sorted = response.list.sort((a, b) => a.name >= b.name ? -1 : 1);
                    const tableLength: number = props.tableComponentModels.length;
                    const tableComponentModels: TableComponentModel[] = [...props.tableComponentModels,
                        ...sorted.filter((item) =>
                            !props.tableComponentModels
                                .filter((e) => e.brand.value === "")
                                .map((e) => e.item.guid)
                                .includes(item.guid))
                            .map((item) => ({
                                item,
                                checked: true,
                                clientCode: "",
                                clientName: item.name,
                                clientNameKey: item.nameKey,
                                brand: {item: {guid: "", description: ""}, label: "", value: ""},
                            })),
                    ];
                    props.setTableComponentModels(tableComponentModels.sort(
                        (a, b) => a.item.name >= b.item.name ? -1 : 1));
                    if (tableComponentModels.length === tableLength) {
                        services.warningNotification(" ", "Elements already present in the list");
                    } else {
                        services.successNotification(" ", "Component model from company added");
                    }
                } else {
                    services.warningNotification("No Component Model found", "Try with different input");
                }
                props.setLoading(false);
            };
        }
    }

    let customMarginTop: string;
    let table: JSX.Element;
    let deleteSection: JSX.Element;
    if (props.tableComponentModels.length > 0) {
        customMarginTop = "20px";
        deleteSection = (
            <Col>
                <Row>
                    <div>
                        <Button
                            onClick={services.deleteUnselected(
                                props.tableComponentModels, props.setTableComponentModels)}
                            styles={{root: {mr: 10}}}
                        >
                            Delete Unselected
                        </Button>
                    </div>
                    <div className={"mx-2"}>
                        <Button
                            className={"mx-0"}
                            variant="secondary"
                            onClick={services.onDeleteButton(props.setTableComponentModels)}
                            styles={{
                                root: {mr: 10},
                            }}
                            style={{padding: "12px 16px"}}
                            iconProps={{glyph: icons.TRASH}}
                        >
                            Delete All
                        </Button>
                    </div>
                </Row>
            </Col>
        );
        table = (
            <Table striped={true} bordered={true} hover={true}>
                <thead>
                <tr>
                    {
                        columns.map((col) => (
                            <th key={col.key} style={col.style}>{col.render}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    props.tableComponentModels.map((value) => (
                        <tr key={value.item.guid + props.tableComponentModels.indexOf(value)}>
                            <td style={{verticalAlign: "middle"}}>
                                <Row>
                                    <Col className="col-4 p-0" />
                                    <Col className="col-4 d-flex flex-column p-0">
                                        <div className="mx-auto">
                                            <Checkbox
                                                className="mx-auto my-auto"
                                                style={{padding: "0", textAlign: "center"}}
                                                checked={value.checked}
                                                onChange={prepareComponentModels(value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-4 d-flex flex-column p-0">
                                        <Button
                                            className="mx-auto my-auto"
                                            onClick={duplicateLine(value)}
                                            style={{padding: "0", textAlign: "center"}}
                                            iconProps={{glyph: icons.ADD}}
                                            disabled={value.brand.value === ""}
                                        />
                                    </Col>
                                </Row>

                            </td>
                            <td style={{verticalAlign: "middle"}}><b>{value.item.name}</b>
                                <p>
                                    {value.item.manufacturerName.split("models.")[1]}
                                </p>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <TextInput
                                    value={value.clientName}
                                    onChange={onInputTextChange(value, "client_name")}
                                />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <TextInput
                                    value={value.clientNameKey}
                                    onChange={onInputTextChange(value, "client_name_key")}
                                />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <TextInput
                                    value={value.clientCode}
                                    onChange={onInputTextChange(value, "client_code")}
                                />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <SelectInput
                                    searchable={true}
                                    name="Component brand"
                                    value={value.brand}
                                    options={props.componentBrandSuggestions}
                                    onChange={onSelectInputChange(value)}
                                />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <TextInput
                                    value={value.nickname}
                                    onChange={onInputTextChange(value, "nickname")}
                                />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <Row>
                                    <div style={{margin: "0 auto", textAlign: "center"}}>
                                        <Button
                                            className={"mx-auto"}
                                            iconProps={value.newMedia === undefined ? (
                                                value.existingMedia === undefined ?
                                                    services.uploadIconButton() :
                                                    services.removeIconButton()
                                            ) : services.removeIconButton()
                                            }
                                            onClick={services.onMediaIconClick(
                                                onShowModalButtonPressed,
                                                deleteItemMedia,
                                                value,
                                            )}
                                        />
                                        <br/>
                                        <span style={{maxWidth: "75%", display: "inline-block"}}>
                                            {
                                                value.newMedia === undefined ?
                                                    (value.existingMedia === undefined ?
                                                        "" : value.existingMedia.name)
                                                    : value.newMedia.name
                                            }
                                        </span>
                                    </div>
                                </Row>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        );
    } else {
        deleteSection = <></>;
        customMarginTop = "150px";
        table = <></>;
    }
    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <div className={"mx-4"}>
            <Form>
                <Row className="w-100 m-0">
                    <GeneralAlert
                        message={infoMessage}
                    />
                </Row>
                <Row>
                    <Col style={{maxWidth: "23%"}}>
                        <p>Component Category</p>
                        <>
                            <SelectInput
                                onChange={services.onSelectedItemAndLabel(
                                    props.setComponentCategoriesName,
                                    props.setSelectedComponentCategory,
                                )}
                                name="Component Category"
                                label="Insert Component Category"
                                options={props.componentCategories.slice(1)}
                                searchable={true}
                                value={props.componentCategories.find(
                                    (object) => object.label === props.componentCategoriesName)}
                                // defaultValue={componentCategories[0]}
                                disabled={companyName.length > 0}
                            />
                        </>
                    </Col>
                    <Col style={{maxWidth: "23%"}}>
                        <p>Component Type</p>
                        <>
                            <SelectInput
                                onChange={services.onSelectedItemAndLabel(
                                    props.setComponentTypesName,
                                    props.setSelectedComponentType,
                                )}
                                name="Component Type"
                                label="Insert Component Type"
                                options={props.componentTypes.slice(1)}
                                searchable={true}
                                value={props.componentTypes.find((object) => object.label === props.componentTypesName)}
                                // defaultValue={componentTypes[0]}
                                disabled={services.isEmpty(props.componentCategoriesName) || companyName.length > 0}
                            />
                        </>
                    </Col>
                    <Col style={{maxWidth: "23%"}}>
                        <p>Component Model</p>
                        <>
                            <SelectInput
                                onChange={services.onSelectedItemAndLabel(
                                    props.setSelectedInputComponentModelsName,
                                    props.setSelectedInputComponentModel,
                                )}
                                name="Component Model"
                                label="Insert Component Model"
                                options={props.inputComponentModels.slice(1)}
                                searchable={true}
                                value={props.inputComponentModels.find(
                                    (object) => object.label === props.selectedInputComponentModelsName)}
                                // defaultValue={componentTypes[0]}
                                disabled={services.isEmpty(props.componentTypesName) || companyName.length > 0}
                            />
                        </>
                    </Col>
                    <Col style={{maxWidth: "26%"}}>
                        <p>Import all from Company</p>
                        <AutoSuggest
                            label="Insert  Company"
                            emptyPlaceholder="No  Company Found"
                            suggestions={companies}
                            inputValue={companyName}
                            // selectedItem={Company}
                            // onSelectedValueChange={services.onSelectedItem(
                            //     setCompanyName,
                            //     setSelectedCompany)}
                            onSelectedValueChange={services.onSelectedItemAndLabel(
                                setCompanyName,
                                setSelectedCompany)}
                            itemToString={services.itemToString}
                            onStateChange={handleCompanyStateChange}
                        />
                    </Col>
                    <Col className={"align-self-end mx-0"} style={{maxWidth: "5%"}}>
                        <Button
                            className={"mx-0"}
                            style={{padding: "13px 20px"}}
                            disabled={selectedCompany == null &&
                            selectedComponentModel == null &&
                            props.selectedComponentType == null &&
                            props.selectedComponentCategory == null
                            }
                            variant="primary"
                            onClick={onSearchButton}
                        >
                            +
                        </Button>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    {table}
                </Row>
                <Row style={{marginTop: customMarginTop}}>
                    {deleteSection}
                    <Col>
                        <Row style={{justifyContent: "flex-end"}}>
                            <div className={"mx-2"}>
                                <Button
                                    onClick={() => props.changeStep(props.step - 1)}
                                    styles={{root: {mr: 10}}}
                                >
                                    Previous
                                </Button>
                            </div>
                            <div className={"mx-2"}>
                                <Button
                                    onClick={submitWrapper()}
                                    variant="strong"
                                    disabled={!actionsAvailable}
                                >
                                    Next
                                </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <MediaModal
                show={showModal}
                onHide={services.setShowModalWrapper(setShowModal, false)}
                table={selectedComponentModel}
                localMedia={localMedia}
                setLocalMedia={setLocalMedia}
                arrayTables={props.tableComponentModels}
                setArrayTables={props.setTableComponentModels}
            />
            {/* Loading modal */}
            <LoadingModal loading={props.loading}/>
        </div>
    );
};

export default CompanyComponentModelsSection;
