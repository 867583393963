interface ElementStartingPoint {
    top?: number;
    left?: number;
}

interface ElementAdjustment {
    xAdjust: number;
    yAdjust: number;
    xFit: boolean;
    yFit: boolean;
}

export function adjustToBeInViewport(elem: Element, startingPoint?: ElementStartingPoint): ElementAdjustment {
    const elementRect = elem.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const left = startingPoint?.left ?? elementRect.left;
    const right = left + elementRect.width;
    const top = startingPoint?.top ?? elementRect.top;
    const bottom = top + elementRect.height;
    return {
        xAdjust: axisAdjust(0, viewportWidth, left, right),
        yAdjust:  axisAdjust(0, viewportHeight, top, bottom),
        xFit: viewportWidth >= elementRect.width,
        yFit: viewportHeight >= elementRect.height,
    };
}

export function adjustToBeInOtherElement(elem: Element, otherElement: Element): ElementAdjustment {
    const elementRect = elem.getBoundingClientRect();
    const otherRect = otherElement.getBoundingClientRect();
    return {
        xAdjust: axisAdjust(otherRect.left, otherRect.right, elementRect.left, elementRect.right),
        yAdjust: axisAdjust(otherRect.top, otherRect.bottom, elementRect.top, elementRect.bottom),
        xFit: otherRect.width >= elementRect.width,
        yFit: otherRect.height >= elementRect.height,
    };
}

export function adjustToBeInParent(elem: Element): ElementAdjustment {
    return adjustToBeInOtherElement(elem, elem.parentElement);
}

function axisAdjust(minBoundary: number, maxBoundary: number, min: number, max: number): number {
    return (Math.max(minBoundary, min) - min) || (Math.min(maxBoundary, max) - max);
}

const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, "value").set;
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;

export function setInputValue(
    elem: HTMLInputElement | HTMLSelectElement,
    value: unknown,
    eventType: keyof HTMLElementEventMap = "change",
    eventInitDict: EventInit = {bubbles: true},
) {
    if (elem instanceof HTMLInputElement) {
        nativeInputValueSetter.call(elem, value);
        elem.dispatchEvent(new Event(eventType, eventInitDict));
    } else if (elem instanceof HTMLSelectElement) {
        nativeSelectValueSetter.call(elem, value);
        elem.dispatchEvent(new Event(eventType, eventInitDict));
    }
}

export function checkInput(
    elem: HTMLInputElement,
    checked: boolean = true
) {
    if (elem instanceof HTMLInputElement && checked != elem.checked) {
        elem.click();
    }
}
