import {Button, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import ConfigurabilityController from "../../../controllers/api/ConfigurabilityController";
import Services from "../../../controllers/utils/Services";
import {PatchList} from "../../../models/company/patchModel";
import {SettingTemplate} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import {Sections} from "../CreateSettingTemplate";

interface RenameModalProps {
    show: boolean;
    onHide: () => void;
    currentSettingTemplate: SettingTemplate;
    setLoading: (status: boolean) => void;
    setCurrentStep: (num: number) => void;
    selectedDeviceModel: Suggestion<DeviceModel>;
    onSearchDeviceModel: (suggestion: Suggestion<DeviceModel>) => void;
}

const RenameModal: React.FC<RenameModalProps> = (props) => {
    const services = new Services();
    const configurabilityController: ConfigurabilityController = new ConfigurabilityController();

    function renamePatchPayload() {
        const payload: PatchList = {list: [services.patchOption("replace", "name", settingTemplateName)]};
        return JSON.parse(JSON.stringify(payload));
    }

    function onConfirmButton() {
        return () => {
            props.setLoading(true);
            configurabilityController
                .patchSettingTemplate(renamePatchPayload(), props.currentSettingTemplate.guid).then(
                (response: { status: number, body: any }) => {
                    if (response.status >= 400 && response.status <= 500) {
                        services.errorNotification(" ", response.body.message);
                        props.setLoading(false);
                    }
                    if (response.status >= 200 && response.status <= 299) {
                        services.successNotification(
                            "Updated",
                            response.body.name + " updated successfully",
                        );
                    }
                    props.onHide();
                    props.setCurrentStep(Sections.deviceModelSection);
                    props.onSearchDeviceModel(props.selectedDeviceModel);
                });
        };
    }

    function mandatoryFieldsCheck() {
        return false;
    }

    const [settingTemplateName, setSettingTemplateName] = React.useState<string>("");

    function handleTextOnChange() {
        return (value) => {
            setSettingTemplateName(value.target.value);
        };
    }

    React.useEffect(() => {
        if (props.currentSettingTemplate != null &&
            props.currentSettingTemplate.name != null &&
            props.currentSettingTemplate.name.trim().length > 0) {
            setSettingTemplateName(props.currentSettingTemplate.name);
        }
    }, [props.currentSettingTemplate]);

    return (
        <>
            <Modal{...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Rename Setting Template
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={"mx-2 pb-3"}>
                        <Col>
                            <Form.Label>Template Name *</Form.Label>
                            <TextInput
                                name={"formName"}
                                label={"Insert Template Name*"}
                                type={"text"}
                                onChange={handleTextOnChange()}
                                value={settingTemplateName}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="strong" disabled={mandatoryFieldsCheck()} onClick={onConfirmButton()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RenameModal;
