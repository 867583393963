import * as React from "react";
import {Td, Tr} from "../DataTable";

import "./styles.scss";

type SkeletonProps = React.HTMLProps<HTMLElement> & {
    type: "text" | "table-row";
    size?: number;
    animationDelay?: number;
};

export function Skeleton({ type, size, animationDelay, ...props}: SkeletonProps) {
    switch (type) {
        case "text": {
            return (
                <span className={"text-skeleton skeleton-bg"} {...props} />
            );
        }
        case "table-row": {
            return (
                <Tr
                    className={"table-row-skeleton"}
                    style={{
                        "--skeleton-bg-animation-delay" : `${animationDelay}s`,
                        "--skeleton-bg-animation-duration": "4s",
                    } as any}
                >
                    {Array
                        .from({length: size}, (_, i) => (
                            <Td key={i}>
                                <Skeleton type={"text"}/>
                            </Td>
                        ))
                    }
                </Tr>
            );
        }
    }
    return null;
}
