import * as React from "react";

import {TextInput} from "@danfoss/etui-core";
import {Col, Form, Row} from "react-bootstrap";
import {SettingTemplate, SettingTemplateCreation} from "../../../models/configurability/template/settingTemplate";

interface NameInputProps {
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;

    settingTemplateCreation: SettingTemplateCreation;
    setSettingTemplateCreation: (settingTemplateCreation: SettingTemplateCreation) => void;

}

const NameInput: React.FC<NameInputProps> = (props) => {
    const [settingTemplateName, setSettingTemplateName] = React.useState<string>("");

    React.useEffect(() => {
        if (props.currentSettingTemplate != null &&
            props.currentSettingTemplate.name != null &&
            props.currentSettingTemplate.name.trim().length > 0) {
            setSettingTemplateName(props.currentSettingTemplate.name);
        }
    }, []);

    function handleTextOnChange() {
        return (value) => {
            props.currentSettingTemplate.name = value.target.value;
            props.setCurrentSettingTemplate({...props.currentSettingTemplate});

            props.settingTemplateCreation.name = value.target.value;
            props.setSettingTemplateCreation({...props.settingTemplateCreation});

            setSettingTemplateName(value.target.value);
        };
    }

    return (
        <Row className={"mx-2 pb-3"}>
            <Col style={{maxWidth: "25%"}}>
                <Form.Label>Template Name *</Form.Label>
                <TextInput
                    name={"formName"}
                    label={"Insert Template Name*"}
                    type={"text"}
                    onChange={handleTextOnChange()}
                    value={settingTemplateName}
                />
            </Col>
        </Row>
    );
};

export default NameInput;
