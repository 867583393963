import {Button, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row, Table} from "react-bootstrap";
import {Company} from "../../../models/company/companyModel";
import {
    ComponentCategoryType,
    TableComponentType,
} from "../../../models/component/componentType";
import ColorPickerModal from "../../common/ColorPickerModal";
import LoadingModal from "../../common/LoadingModal";
import GeneralAlert from "../../common/GeneralAlert";
import TranslationController from "../../../controllers/api/TranslationController";

interface FourthFormProps {
    currentCompany: Company;
    tableComponentType: TableComponentType[];
    setTableComponentType: (componentModelType: TableComponentType[]) => void;
    loading: boolean;
    changeStep: (step: number) => void;
    step: number;
    selectedComponentTypes: ComponentCategoryType[];
    setSelectedComponentTypes: (c: ComponentCategoryType[]) => void;
    submit: () => void;
}

const CompanyComponentTypeSection: React.FC<FourthFormProps> = (props) => {
    const translationController: TranslationController = new TranslationController();
    const INFO_TRANSLATION_TEXT_KEY = "operations.company_creation.component_types.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);

    function onInputTextChange(item: TableComponentType, value: string, column: string) {
        const newComponentType = props.tableComponentType.map((c) => {
            if (item.item.guid === c.item.guid &&
                props.tableComponentType.indexOf(item) === props.tableComponentType.indexOf(c)) {
                switch (column) {
                    case "client_name":
                        c.clientName = value;
                        // c.clientNameKey = item.item.manufacturerName +
                        // "." + value.toLowerCase().split(" ").join("_");
                        break;
                    case "client_name_key":
                        c.clientNameKey = value;
                        break;
                }
            }
            return c;
        });
        props.setTableComponentType(newComponentType);
    }

    const columns = [
        {
            key: "name",
            className: "col-2",
            render: "Name",
        },
        {
            key: "client_name",
            className: "col-2",
            render: "Client name",
        },
        {
            key: "client_name_key",
            className: "col-3",
            render: "Client name key",
        },
        {
            key: "color",
            className: "col-2",
            render: "Color",
        },
    ];
    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <div className={"mx-4"}>
            <Form>
                <Row className="w-100 m-0">
                    <GeneralAlert
                        message={infoMessage}
                    />
                </Row>
                <Row style={{marginTop: "20px"}}>
                    <Table striped={true} bordered={true} hover={true}>
                        <thead>
                        <tr>
                            {
                                columns.map((col) => (
                                    <th key={col.key} className={col.className}>{col.render}</th>
                                ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.tableComponentType.map((value) => (
                                <tr key={value.item.guid + props.tableComponentType.indexOf(value)}>
                                    <td><b>{value.item.name}</b>
                                        <p>
                                            {value.item.category}
                                        </p>
                                    </td>
                                    <td>
                                        <TextInput
                                            value={value.clientName}
                                            onChange={(t) =>
                                                onInputTextChange(value, t.target.value, "client_name")}
                                        />
                                    </td>
                                    <td>
                                        <TextInput
                                            value={value.clientNameKey}
                                            onChange={(t) =>
                                                onInputTextChange(value, t.target.value, "client_name_key")}
                                        />
                                    </td>
                                    <td>
                                        <ColorPickerModal
                                            arrayTables={props.tableComponentType}
                                            value={value}
                                            setArrayTables={props.setTableComponentType}
                                        />

                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                    {/*</div>*/}
                </Row>
                <Row className={"my-4 justify-content-end"}>
                    <div className={"mx-2"}>
                        <Button
                            onClick={() => props.changeStep(props.step - 1)}
                            styles={{root: {mr: 10}}}
                        >
                            Previous
                        </Button>
                    </div>
                    <div className={"mx-2"}>
                        <Button
                            onClick={() => props.submit()}
                            variant="strong"
                        >
                            Next
                        </Button>
                    </div>
                </Row>
            </Form>
            <LoadingModal loading={props.loading} />
        </div>
    );
};

export default CompanyComponentTypeSection;
