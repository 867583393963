import * as React from "react";

export type UsePromiseResultState = "fulfilled" | "rejected" | "pending" | "idle";

type UsePromiseResult<R, P, E> = {
    state: UsePromiseResultState;
    data: R;
    error: E;
    refresh(parameters?: P): void;
};

type PromiseReducerState = {
    state: UsePromiseResultState;
    result: any,
};

type PromiseReducerAction = {
    type: "fulfill";
    result: unknown;
} | {
    type: "reject";
    error: unknown;
} | { type: "initial"};

function promiseReducer(state: PromiseReducerState, action: PromiseReducerAction): PromiseReducerState {
    switch (action.type) {
        case "initial": {
            return {
                state: "pending",
                result: undefined,
            };
        }
        case "fulfill": {
            return {
                state: "fulfilled",
                result: action.result,
            };
        }
        case "reject": {
            return {
                state: "rejected",
                result: action.error,
            }
        }
    }
}

type UsePromiseOptions<R> = {
    callOnMount?: boolean;
    initResult?: R;
};

export function usePromise<F extends (...p: any[]) => Promise<any>, E>(
    fn: F,
    params: Parameters<F>,
    options?: UsePromiseOptions<Awaited<ReturnType<F>>>
): UsePromiseResult<Awaited<ReturnType<F>>, Parameters<F>, E> {
    
    const [state, dispatch] = React.useReducer(promiseReducer, {
        state:  (options?.callOnMount ?? true) ? "pending" : "idle",
        result: undefined,
    });

    function refresh(newParams = params) {
        if (state.state !== "pending") {
            dispatch({type: "initial"});
        }

        fn(...newParams)
            .then((result) => dispatch({type: "fulfill", result}))
            .catch((error) => dispatch({type: "reject", error}));
    }

    React.useEffect(() => {
        if (options?.callOnMount ?? true) {
            refresh();
        }
    }, []);
    
    return {
        state: state.state,
        data: state.state === "fulfilled" ? state.result : options?.initResult,
        error: state.state === "rejected" ? state.result : undefined,
        refresh,
    };
}