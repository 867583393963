type ArrayOrFunction = unknown[] | ((item: unknown) => unknown);
type ItemCallback = (item: unknown) => unknown;

export function toMap<T, K = unknown, V = T>(key: (v: T) => K, value: (v: T) => V): (list: T[]) => Map<K, V>;
export function toMap<T, K, V>(list: T[], key: (item: T) => K, value: (item: T) => V): Map<K, V>;
export function toMap(listOrKey: ArrayOrFunction, keyOrValue: ItemCallback, valueOrUndefined?: ItemCallback) {
    if (typeof listOrKey === "function") {
        return (list: unknown[]) => new Map(list.map((item) => [listOrKey(item), keyOrValue(item)]));
    }
    return new Map(listOrKey.map((item) => [keyOrValue(item), valueOrUndefined(item)]));
}

export function groupBy<T, K>(callback: (item: T) => K): (items: T[]) => Map<K, T[]>;
export function groupBy<T, K>(items: T[], callback: (item: T) => K): Map<K, T[]>;
export function groupBy<T, K>(itemsOrCallback: ((item: T) => K) | T[], callback?: (item: T) => K) {
    if (typeof itemsOrCallback === "function") {
        return (items: T[]) => {
            return items.reduce((groups, item) => {
                const groupKey = itemsOrCallback(item);
                if (groups.has(groupKey)) {
                    return groups.set(groupKey, groups.get(groupKey).concat(item));
                }
                return groups.set(groupKey, [item]);
            }, new Map<K, T[]>());
        };
    }
    return itemsOrCallback.reduce((groups, item) => {
        const groupKey = callback(item);
        if (groups.has(groupKey)) {
            return groups.set(groupKey, groups.get(groupKey).concat(item));
        }
        return groups.set(groupKey, [item]);
    }, new Map<K, T[]>());
}

export function map<A, B, K, V>(callback: (key: A, value: B) => [K, V]): (entries: Map<A, B>) => Map<K, V>;
export function map<A, B, K, V>(entries: Map<A, B>, callback: (key: A, value: B) => [K, V]): Map<K, V>;
export function map<A, B, K, V>(
    entriesOrCallback: Map<A, B> | ((key: A, value: B) => [K, V]),
    callback?: (key: A, value: B) => [K, V],
) {
    if (typeof entriesOrCallback === "function") {
        return (entries: Map<A, B>) => {
            return new Map(Array.from(entries).map(([key, value]) => entriesOrCallback(key, value)));
        };
    }
    return new Map(Array.from(entriesOrCallback).map(([key, value]) => callback(key, value)));
}
export function putAll<K, V>(destination: Map<K, V>, source: Map<K, V>) {
    source.forEach((value, key) => {
        destination.set(key, value);
    });
}