import {Button, icons, Spinner} from "@danfoss/etui-core";
// tslint:disable-next-line:no-submodule-imports
import {EmptyState} from "@danfoss/etui-core/EmptyState";
import * as React from "react";
import {Card, Col, Form, Modal, Row} from "react-bootstrap";
import ComponentModelController from "../../controllers/api/ComponentModelController";
import {TableCompany} from "../../models/company/companyModel";
import {TableComponentModel} from "../../models/component/componentModel";
import {Media} from "../../models/media/mediaModel";

interface MediaModalProps {
    show: boolean;
    onHide: () => void;
    localMedia: File[];
    setLocalMedia: (t: File[]) => void;

    table: TableCompany | TableComponentModel;
    arrayTables: Array<TableCompany | TableComponentModel>;
    setArrayTables: (t: Array<TableCompany | TableComponentModel>) => void;
}

export const MediaModal: React.FC<MediaModalProps> = (props) => {
    // controllers
    const componentModelController: ComponentModelController = new ComponentModelController();

    const [mediaList, setMediaList] = React.useState<Media[]>(undefined);
    const [media, setMedia] = React.useState<string>("");
    const [mediaFile, setMediaFile] = React.useState<File>(undefined);
    const [mediaSelected, setMediaSelected] = React.useState<Media>(undefined);
    const [isNew, setIsNew] = React.useState<boolean>(false);

    function submit() {
        props.setArrayTables(
            props.arrayTables.map((e) => {
                if (e.item.guid === props.table.item.guid && e.brand === props.table.brand) {
                    if (isNew) {
                        e.newMedia = mediaFile;
                        e.existingMedia = undefined;
                    } else {
                        e.existingMedia = mediaSelected;
                        e.newMedia = undefined;
                    }
                }
                return e;
            }),
        );
        props.onHide();
    }

    function onRemovePressed() {
        // props.setArrayTables(
        //     props.arrayTables.map((e) => {
        //         if (e.item.guid === props.company.item.guid) {
        //             e.newMedia = undefined;
        //             e.existingMedia = undefined;
        //         }
        //         return e;
        //     }),
        // );
        // setMediaSelected(undefined);
        // setMediaFile(undefined);
        // setMedia("");
        props.onHide();
    }

    function setAsNewMedia(file: File) {
        if (!props.localMedia.some((e) => file.name === e.name)) {
            props.setLocalMedia([...props.localMedia, file]);
        }
        setMediaFile(props.localMedia.find((e) => e.name === file.name));
        setMediaSelected(undefined);
        setMedia(file.name);
        setIsNew(true);
    }

    function onSelectedMediaPressed(file: Media) {
        setMediaSelected(file);
        setMediaFile(undefined);
        setMedia(file.name);
        setIsNew(false);
    }

    function onLocalMediaPressed(file: File) {
        setMediaFile(file);
        setMediaSelected(undefined);
        setMedia(file.name);
        setIsNew(true);
    }

    React.useEffect(() => {
        componentModelController.findAllMedia().then(
            (response) => {
                setMediaList(response.list);
            },
        );
    }, []);
    React.useEffect(() => {
        if (props.table !== undefined) {
            setMedia((props.table.existingMedia === undefined ?
                (props.table.newMedia === undefined ? "" : props.table.newMedia.name)
                : props.table.existingMedia.name));
            setMediaFile(props.table.newMedia !== undefined ? props.table.newMedia : undefined);
            setMediaSelected(props.table.existingMedia !== undefined ? props.table.existingMedia : undefined);
        } else {
            setMedia("");
        }
    }, [props.table]);
    return (
        <Modal {...props} size="lg">
            <Modal.Header closeButton={true}>
                {props.table !== undefined ? "Media for " + props.table.item.name : ""}
            </Modal.Header>
            <Modal.Body>
                <Col className="d-flex flex-column align-items-center">
                    <Row>
                        <p>Select existing media</p>
                    </Row>
                    <Row className="overflow-auto" style={{paddingLeft: "5%"}}>
                        {
                            mediaList === undefined ? (
                                <Spinner/>
                            ) : (
                                mediaList.length === 0 ? (
                                    <EmptyState>
                                        <EmptyState.Icon size={144} glyph={icons.DISCONNECTED_CLOUD}/>
                                        <EmptyState.Title color="inherit">
                                            No media found!
                                        </EmptyState.Title>
                                    </EmptyState>
                                ) : (
                                    mediaList.map((e) => (
                                        <Row className="mx-2 my-2">
                                            <a
                                                style={{cursor: "pointer"}}
                                                onClick={() => onSelectedMediaPressed(e)}
                                            >
                                                <Card
                                                    className="px-1 py-1 d-flex flex-column"
                                                    style={
                                                        mediaSelected !== undefined ? (
                                                            mediaSelected.guid === e.guid ? (
                                                                {
                                                                    width: "120px",
                                                                    height: "180px",
                                                                    borderStyle: "solid",
                                                                    borderColor: "coral",
                                                                }
                                                            ) : (
                                                                {width: "120px", height: "180px"}
                                                            )
                                                        ) : {width: "120px", height: "180px"}
                                                    }
                                                >
                                                    <Card.Img
                                                        variant="top"
                                                        className="my-auto mx-auto"
                                                        style={{width: "70px", height: "70px"}}
                                                        src={e && ("/media/" + e.guid)}
                                                        alt="Image..."
                                                    />
                                                    <Card.Body style={{fontSize: "14px"}}>{e.name}</Card.Body>
                                                </Card>
                                            </a>
                                        </Row>
                                    ))
                                )
                            )
                        }
                    </Row>
                    {
                        props.localMedia.length > 0 && (
                            <>
                                <Row style={{height: "20px"}}/>
                                <Row>
                                    <p>Local media</p>
                                </Row>
                                <Row style={{height: "20px"}}/>
                                <Row className="overflow-auto" style={{paddingLeft: "5%"}}>
                                    {
                                        props.localMedia.map((e) => (
                                            <Row className="mx-2 my-2">
                                                <a style={{cursor: "pointer"}} onClick={() => onLocalMediaPressed(e)}>
                                                    <Card
                                                        className="px-1 py-1 d-flex flex-column"
                                                        style={mediaFile !== undefined ? (
                                                            // tslint:disable-next-line:max-line-length
                                                            props.localMedia.indexOf(mediaFile) === props.localMedia.indexOf(e) ? (
                                                                {
                                                                    width: "120px",
                                                                    height: "180px",
                                                                    borderStyle: "solid",
                                                                    borderColor: "coral",
                                                                }
                                                            ) : (
                                                                {width: "120px", height: "180px"}
                                                            )
                                                        ) : (
                                                            {width: "120px", height: "180px"}
                                                        )}
                                                    >
                                                        <Card.Img
                                                            variant="top"
                                                            className="my-auto mx-auto"
                                                            style={{width: "70px", height: "70px"}}
                                                            src={URL.createObjectURL(e)}
                                                            alt="Image..."
                                                        />
                                                        <Card.Body style={{fontSize: "14px"}}>{e.name}</Card.Body>
                                                    </Card>
                                                </a>
                                            </Row>
                                        ))
                                    }
                                </Row>
                            </>
                        )
                    }
                    <Row style={{height: "20px"}}/>
                    <Row>
                        <p>Import new media</p>
                    </Row>
                    <Row>
                        <Col className="col-6 px-0 d-flex flex-row">
                            <Form.File
                                className="mx-auto my-auto"
                                onChange={(v) => setAsNewMedia(v.target.files[0])}
                            />
                        </Col>
                        <Col className="col-6 px-0 d-flex flex-row">
                            <p className="mx-auto my-auto">
                                {media !== "" ? "Media selected: " + media : "No selected media"}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onRemovePressed}
                >
                    Close
                </Button>
                {" "}
                <Button
                    variant="strong"
                    disabled={mediaSelected === undefined && mediaFile === undefined}
                    onClick={() => submit()}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
