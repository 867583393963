import {
    CallKeySearch, CallRunner, CallsRunner,
    CallUpdater,
    FnCall,
    FnCallResult,
    FunctionCallResults,
    FunctionQueue, FunctionQueueInterceptors,
} from "./types";

type UpdateCountAndCalls<FC extends FnCall> = [number, FC[]];
type UpdateAndCountFunction<FC extends FnCall> = (prev: UpdateCountAndCalls<FC>, call: FC) => UpdateCountAndCalls<FC>;

function updateQueue<FC extends FnCall>(key: CallKeySearch<FC>, updater: CallUpdater<FC>): UpdateAndCountFunction<FC> {
    return ([count, queue], call) => {
        if ((typeof key === "function" && !key(call)) || ((typeof key !== "function") && call.key !== key)) {
            return [count, [...queue, call]];
        }
        return [count + 1, [...queue, updater(call)]];
    };
}

function removeKey<FC extends FnCall>(key: CallKeySearch<FC>) {
    return (call): boolean => {
        if (typeof key === "function") {
            return !key(call);
        }
        return call.key !== key;
    };
}

function findKey<FC extends FnCall>(key: CallKeySearch<FC>) {
    return (call): boolean => {
        if (typeof key === "function") {
            return key(call);
        }
        return call.key === key;
    };
}

function removeCall<FC extends FnCall>(key: CallKeySearch<FC>, calls) {
    const updatedQueue = calls.filter(removeKey(key));
    const oNumberOfItemRemoved = calls.length - updatedQueue.length;
    return [ updatedQueue, oNumberOfItemRemoved ];
}

async function runCall<FC extends FnCall, E>(call: FC): Promise<FnCallResult<FC, E>> {
    try {
        return {
            call,
            result: await call.fun(...call.params),
            status: "successful",
        };
    } catch (e) {
        return  {
            call,
            error: e,
            status: "error",
        };
    }
}

async function runCalls<FC extends FnCall>(calls: FC[], runner: CallRunner<FC>) {
    const result: FunctionCallResults<FC> = [];
    while (calls.length > 0) {
        result.push(await runner(calls.shift()));
    }
    return result;
}

function runCallsFactory<FC extends FnCall>(
    interceptors: FunctionQueueInterceptors<FC>,
    self: FunctionQueue<FC>,
    resultsRef: FunctionCallResults<FC, any>,
): CallsRunner<FC> {
    return (callQueue): Promise<any> => {
        return runCalls(callQueue, runCallFactory(interceptors, self, resultsRef));
    };
}

function runCallFactory<FC extends FnCall>(
    interceptors: FunctionQueueInterceptors<FC>,
    self: FunctionQueue<FC>,
    resultsRef: FunctionCallResults<FC, any>,
): CallRunner<FC> {
    return async <E>(call) => {
        const result = await (typeof interceptors?.dequeueAndCall === "function"
            ? interceptors.dequeueAndCall<E>(call, runCall, self)
            : runCall<FC, E>(call));
        resultsRef.push(result);
        return result;
    };
}

export function newFunctionQueue<FC extends FnCall>(interceptors?: FunctionQueueInterceptors<FC>): FunctionQueue<FC> {
    let calls: FC[] = [];
    let results: FunctionCallResults<FC, any> = [];

    const self: FunctionQueue<FC> = {
        calls(): FC[] {
            return calls;
        },
        results(): FunctionCallResults<FC> {
            return results;
        },
        clearResults(): number {
            const resultToBeRemoved = results.length;
            results = [];
            return resultToBeRemoved;
        },
        enqueue(call) {
            if (typeof interceptors?.enqueue === "function") {
                interceptors.enqueue(call, calls, (overriddenCall) => {
                    calls.push(overriddenCall);
                }, self);
            } else {
                calls.push(call);
            }
        },
        find(key) {
            return calls.filter(findKey(key));
        },
        remove(key): number {
            if (typeof interceptors?.remove === "function") {
                return interceptors.remove(key, calls, (overriddenKey) => {
                    const [updatedCalls , difference] = removeCall(overriddenKey, calls);
                    calls = updatedCalls;
                    return difference;
                }, self);
            }
            const [updatedQueue , numberOfItemRemoved] = removeCall(key, calls);
            calls = updatedQueue;
            return numberOfItemRemoved;
        },
        update(key, updater): number {
            if (typeof interceptors?.update === "function") {
                return interceptors.update(key, updater, (overriddenKey, overriddenUpdate) => {
                    const [updateCount, updatedQueue] = calls.reduce<UpdateCountAndCalls<FC>>(
                        updateQueue(overriddenKey, overriddenUpdate), [0, []],
                    );
                    calls = updatedQueue;
                    return updateCount;
                }, self);
            }

            const [count, newQueue] = calls.reduce<UpdateCountAndCalls<FC>>(updateQueue(key, updater), [0, []]);
            calls = newQueue;
            return count;
        },
        dequeueAndCall<E>(): Promise<FnCallResult<FC, E>> {
            const firstCall = calls.shift();
            const runner = runCallFactory(interceptors, self, results);
            return runner<E>(firstCall);
        },
        async callAll<E>(): Promise<FunctionCallResults<FC, E>> {
            if (typeof interceptors?.callAll === "function") {
                results = await interceptors.callAll<E>(
                    calls,
                    runCallsFactory(interceptors, self, results),
                    runCallFactory(interceptors, self, results),
                    self,
                );
                return results;
            }
            results = await runCalls<FC>(calls, runCallFactory(interceptors, self, results));
            return results;
        },
    };
    return self;
}
