import {SelectInput} from "@danfoss/etui-core";
import * as React from "react";
import {Form, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {ProcessingParameter} from "../../../models/configurability/rawData/processingParameter";
import {ProcessingSetting} from "../../../models/configurability/rawData/processingSetting";
import {RawDataAlarmType} from "../../../models/configurability/rawData/rawDataAlarmTypeModel";
import {RawDataDiagnosticType} from "../../../models/configurability/rawData/rawDataDiagnosticTypeModel";
import {RawData} from "../../../models/configurability/rawData/rawDataModel";
import {Suggestion} from "../../../models/Suggestion";
import {ProcessingParamType, Sections} from "../CreateRawData";
import ParamValue from "./ParamValue";
import PropertyValue from "./PropertyValue";
import ReferenceDataValue from "./ReferenceDataValue";
import ReferenceProcessedDataValue from "./ReferenceProcessedDataValue";
import REFValue from "./REFValue";

interface RawDataParamProps {
    currentProcessing: ProcessingSetting;
    processingParametersTypes: Array<Suggestion<any>>;
    processingParameters: ProcessingParameter[];
    setProcessingParameters: (state: ProcessingParameter[]) => void;
    index: number;
    setDisabledParam: (status: boolean) => void;
    currentRD: RawData | RawDataAlarmType | RawDataDiagnosticType;
    section: Sections;
    rawDataList: RawData[];
}

// tslint:disable:max-line-length
const ProcessingParamComponent: React.FC<RawDataParamProps> = (props) => {

    const [processingParametersType, setProcessingParametersType] = React.useState<Suggestion<ProcessingParamType>>(undefined);
    const services: Services = new Services();

    React.useEffect(() => {
        if (props.processingParameters != null) {
            const temp: any = props.processingParameters[props.index];
            if (temp != null) {
                const type = temp.type !== "" ? temp.type : "";
                onSelectType(props.processingParametersTypes.find((object) => object.label === type));
            }
        }
    }, []);

    function onSelectType(suggestion: Suggestion<ProcessingParamType>) {
        if (suggestion && props.processingParameters) {
            setProcessingParametersType(suggestion);
            const newValues = props.processingParameters;
            newValues[props.index].type = suggestion.value;
            props.setProcessingParameters(newValues);
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        } else {
            setProcessingParametersType(undefined);
            props.setDisabledParam(true);
        }
    }

    function onTypeChange(suggestion: Suggestion<ProcessingParamType>) {
        if (suggestion && props.processingParameters) {
            setProcessingParametersType(suggestion);
            if (suggestion !== processingParametersType) {
                const newValues = props.processingParameters;
                newValues[props.index].type = suggestion.value;
                newValues[props.index].value = undefined;
                if (newValues[props.index].defaultValue) {
                    newValues[props.index].defaultValue = undefined;
                }
                if (!ProcessingParamType.REFERENCE_PROPERTY) {
                    delete newValues[props.index].defaultValue;
                }
                props.setProcessingParameters(newValues);
            }
            services.isDisabledProcessing(props.section, props.processingParameters, props.setDisabledParam);
        } else {
            setProcessingParametersType(undefined);
            props.setDisabledParam(true);
        }
    }

    function selectValue() {
        if (processingParametersType) {
            switch (processingParametersType.item) {
                case ProcessingParamType.REFERENCE_PROCESSED_DATA:
                    return (
                        <ReferenceProcessedDataValue
                            currentProcessing={props.currentProcessing}
                            processingParameters={props.processingParameters}
                            setProcessingParameters={props.setProcessingParameters}
                            index={props.index}
                            setDisabledParam={props.setDisabledParam}
                            currentRD={props.currentRD}
                            section={props.section}
                        />
                    );
                case ProcessingParamType.REFERENCE_DATA:
                    return (
                        <ReferenceDataValue
                            processingParameters={props.processingParameters}
                            setProcessingParameters={props.setProcessingParameters}
                            index={props.index}
                            setDisabledParam={props.setDisabledParam}
                            currentRD={props.currentRD}
                            section={props.section}
                        />
                    );
                case ProcessingParamType.REF:
                    return (
                        <REFValue
                            processingParameters={props.processingParameters}
                            setProcessingParameters={props.setProcessingParameters}
                            index={props.index}
                            setDisabledParam={props.setDisabledParam}
                            currentRD={props.currentRD}
                            section={props.section}
                            rawDataList={props.rawDataList}
                        />
                    );
                case ProcessingParamType.REFERENCE_PROPERTY:
                    return (
                        <PropertyValue
                            processingParameters={props.processingParameters}
                            setProcessingParameters={props.setProcessingParameters}
                            index={props.index}
                            setDisabledParam={props.setDisabledParam}
                            currentRD={props.currentRD}
                            section={props.section}
                        />
                    );
                default:
                    return (
                        <ParamValue
                            processingParameters={props.processingParameters}
                            setProcessingParameters={props.setProcessingParameters}
                            index={props.index}
                            setDisabledParam={props.setDisabledParam}
                            currentRD={props.currentRD}
                            section={props.section}
                        />
                    );
            }
        }
        return <></>;
    }

    return (
        <>
            <Row className={"mt-3 d-flex flex-column"}>
                <Form.Label>Processing Param Type {props.index} *</Form.Label>
                <SelectInput
                    onChange={onTypeChange}
                    name="Type"
                    label="Insert Processing Parameter Type"
                    options={props.processingParametersTypes}
                    searchable={true}
                    clearable={true}
                    disabled={props.section === Sections.rawDataDiagnosticType}
                    value={processingParametersType}
                />
            </Row>
            {selectValue()}
        </>
    );
};

export default ProcessingParamComponent;
