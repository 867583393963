import {Button, List, TextInput, useDebounce} from "@danfoss/etui-core";
import * as React from "react";
import {Modal} from "react-bootstrap";
import {OperationsResponse} from "../../../controllers/api/ComponentModelController";
import {ComponentBrand} from "../../../models/component/componentBrand";

interface CreateNewBrandModalProp {
    show: boolean;
    onHide: () => void;
    addBrand: (brandDescription: string) => void;
    errorManager: {
        state: string,
        setState: (value: (((prevState: string) => string) | string)) => void;
    };
    findSimilarBrands: (brandDescription: string) => Promise<OperationsResponse>;
}

const CreateNewBrandModal: React.FC<CreateNewBrandModalProp> = (props) => {

    const [brandDescription, setBrandDescription] = React.useState<string>();
    const [similarBrands, setSimilarBrands] = React.useState<ComponentBrand[]>([]);
    const [brandDescriptionToSearch, setBrandDescriptionToSearch] = useDebounce<string>(brandDescription, 500);
    const [waiting, setWaiting] = React.useState<boolean>(true);

    const valueChange = (e) => {
        setWaiting(true);
        if (similarBrands.length > 0) {
            setSimilarBrands([]);
        }
        setBrandDescription(e.target.value);
        setBrandDescriptionToSearch(e.target.value);
        if (props.errorManager?.state?.length > 0) {
            props.errorManager?.setState("");
        }
    };
    const addBrand = () => {
        props.addBrand(brandDescription);
        setBrandDescription("");
        setBrandDescriptionToSearch("");
    };

    React.useEffect(() => {
        if (brandDescriptionToSearch) {
            props.findSimilarBrands(brandDescriptionToSearch)
                .then((response) => {
                    setWaiting(false);
                    if (response.status === 200) {
                        setSimilarBrands(response.content.list);
                        if (response.content.list.length) {
                            props.errorManager?.setState("There is already a brand similar/equal to the one entered");
                        } else {
                            props.errorManager?.setState("");
                        }
                    } else {
                        props.errorManager?.setState(response.content.message);
                    }
                });
        }
    }, [brandDescriptionToSearch]);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create a new component model brand
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{color: "red"}}>{props?.errorManager?.state}</p>
                    <TextInput
                        label={"Brand description"}
                        type="text"
                        value={brandDescription}
                        onChange={valueChange}
                        styles={props?.errorManager?.state?.length > 0
                            ? {
                                label: {color: "red"},
                                input: {borderColor: "red", hoverBorderColor: "red"},
                            }
                            : {}}
                    />
                    {similarBrands.length > 0
                        ? (
                            <>
                                <br/>
                                <h4>Similar or equal brands</h4>
                                <div style={{maxHeight: "200px", overflow: "auto"}}>
                                    <List
                                        dataSource={similarBrands}
                                        isAnimationEnabled={true}
                                        mode={"compact"}
                                        renderItem={(item: ComponentBrand) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={`${item.description}`}
                                                    description={item.guid}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </>
                        )
                        : <></>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="strong"
                        onClick={addBrand}
                        disabled={!brandDescriptionToSearch
                            || brandDescriptionToSearch?.length === 0
                            || props?.errorManager?.state?.length > 0
                            || waiting}
                    >
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateNewBrandModal;
