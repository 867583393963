import * as React from "react";

import {Button, EmptyState, H5, icons, SelectInput} from "@danfoss/etui-core";
import {Check, Error} from "@danfoss/etui-icons";
import {Col, Form, Row, Table} from "react-bootstrap";
import ConfigurabilityController from "../../../controllers/api/ConfigurabilityController";
import Services from "../../../controllers/utils/Services";
import {InterfaceSettingEnumsJson} from "../../../models/configurability/configurabilityEnumsModel";
import {
    DeviceModelInterfaceType,
    PropertiesStructure,
} from "../../../models/configurability/interfaceType/deviceModelInterfaceType";
import {
    SerialSetting,
    SettingTemplate,
    TemplateConfigurationSlave,
    TemplateConfigurationTelemetry,
} from "../../../models/configurability/template/settingTemplate";
import {DeviceModel} from "../../../models/device/deviceModelModel";
import {Suggestion} from "../../../models/Suggestion";
import {ConfirmModal} from "../../common/ConfirmModal";
import InputSettingInput from "../common/interfaceSetting/InputSettingInput";
import OutputSettingInput from "../common/interfaceSetting/OutputSettingInput";
import SerialSettingInput from "../common/interfaceSetting/SerialSettingInput";
import RawDataTableCustom from "../common/RawDataTable";
import SelectInputTitle from "../common/SelectInputTitle";
import ConfigurabilityService from "../ConfigurabilityService";
import {SectionsModal} from "../CreateSettingTemplate";
import CreateSTSlaveModal from "../modals/createSTSlaveModal";
import RawDataModal from "../modals/RawDataModal";

interface FirstFormProps {
    selectedDeviceModel: Suggestion<DeviceModel>;
    currentStep: number;
    setCurrentStep: (num: number) => void;
    onSubmit: () => void;
    selectedDMInterfaces: DeviceModelInterfaceType[];
    currentSettingTemplate: SettingTemplate;
    setCurrentSettingTemplate: (setTemplate: SettingTemplate) => void;
    currentInterfaceStep: number;
    onPreviousInterface: () => void;
    deviceModelSlaves: Array<Suggestion<DeviceModel>>;
    currentDMInterface: DeviceModelInterfaceType;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

// tslint:disable:max-line-length
const InfoSection: React.FC<FirstFormProps> = (props) => {
    const services = new Services();
    const configurabilityService = new ConfigurabilityService();
    const configurabilityController = new ConfigurabilityController();
    const [interfaceSettingEnums, setInterfaceSettingEnums] = React.useState<InterfaceSettingEnumsJson>(undefined);

    const [settingTemplate, setSettingTemplate] = React.useState<Suggestion<SettingTemplate>>(undefined);
    const [deviceModelSlave, setDeviceModelSlave] = React.useState<Suggestion<DeviceModel>>(undefined);
    const [settingTemplates, setSettingTemplates] = React.useState<Array<Suggestion<SettingTemplate>>>([]);
    const [paramToDelete, setParamToDelete] = React.useState<TemplateConfigurationSlave>(undefined);
    const [confirmDeleteShow, setConfirmDeleteShow] = React.useState<boolean>(false);
    const [rawDataModalShow, setRawDataModalShow] = React.useState(false);

    const [deviceModelSlaves, setDeviceModelSlaves] = React.useState<Array<Suggestion<DeviceModel>>>([]);

    const [createSTSModalShow, setCreateSTSModalShow] = React.useState(false);

    function mandatoryFieldsCheck() {
        let slaveLength;
        let notConfigured = false;
        let isEmbedded;
        let alarms = 0;
        let diagnostics = 0;
        const currentInterface = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep];
        if (props.currentDMInterface.propertiesStructure !== "EMBEDDED") {
            isEmbedded = false;
            slaveLength = currentInterface.slaves.length;
            notConfigured = currentInterface.slaves.some((e) => !e.configured);
        } else {
            isEmbedded = true;
            alarms = currentInterface.embeddedDataTemplate.alarms.filter((e) => e.checked).length;
            diagnostics = currentInterface.embeddedDataTemplate.diagnostics.filter((e) => e.checked).length;
        }
        return slaveLength === 0 || notConfigured || mandatoryProperties() || (isEmbedded && (alarms + diagnostics === 0));
    }

    function onSubmit() {
        if (props.currentDMInterface.propertiesStructure === PropertiesStructure.EMBEDDED) {
            const tempTemplate = {...props.currentSettingTemplate};
            const templateConfigurationTelemetry = {
                ...tempTemplate.templateContent as TemplateConfigurationTelemetry,
            };

            const templateData = {...templateConfigurationTelemetry.interfaces[props.currentInterfaceStep].embeddedDataTemplate};
            configurabilityService.filterUncheckAndSetCustomType(templateData);

            templateConfigurationTelemetry.interfaces[props.currentInterfaceStep]
                .embeddedDataTemplate = {...templateData};
            props.setCurrentSettingTemplate(tempTemplate);
        }
        props.onSubmit();
    }

    const [currentSlaveIndex, setCurrentSlaveIndex] = React.useState<number>(0);

    function mandatoryAddCheck() {
        const slaveLength = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry)
            .interfaces[props.currentInterfaceStep].slaves.length;
        return deviceModelSlave == null || (isSerial() ? (slaveLength > 4) : (slaveLength > 0));
    }

    function mandatoryProperties() {
        if (props.currentDMInterface != null && props.currentDMInterface.propertiesStructure != null) {
            switch (props.currentDMInterface.propertiesStructure) {
                case PropertiesStructure.INPUT:
                    return false;
                case PropertiesStructure.OUTPUT:
                    return false;
                case PropertiesStructure.ANALOG:
                    return false;
                case PropertiesStructure.SERIAL:
                    const serial = ((props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry)
                        .interfaces[props.currentInterfaceStep].interfaceSetting as SerialSetting);
                    return serial == null || serial.stopBits == null || serial.baudRate == null ||
                        serial.wordLength == null || serial.parity == null;
                case PropertiesStructure.EMBEDDED:
                    return false;
                case PropertiesStructure.BLUETOOTH:
                    return false;
                case PropertiesStructure.CAN:
                    return false;
            }
        }
        return true;
    }

    function onAddDeviceModel() {
        const tempSettingTemplate = {...props.currentSettingTemplate};
        const configurationTelemetry = tempSettingTemplate.templateContent as TemplateConfigurationTelemetry;
        if (settingTemplate != null) {
            configurationTelemetry.interfaces[props.currentInterfaceStep].slaves
                .push({
                    type: "slave",
                    configured: true,
                    deviceModelName: deviceModelSlave.label,
                    deviceModelGuid: deviceModelSlave.value,
                    dataTemplate: (settingTemplate.item.templateContent as TemplateConfigurationSlave).dataTemplate,
                    selectedTemplateName: settingTemplate.label,
                });
        } else {
            configurationTelemetry.interfaces[props.currentInterfaceStep].slaves
                .push({
                    type: "slave",
                    configured: false,
                    deviceModelName: deviceModelSlave.label,
                    deviceModelGuid: deviceModelSlave.value,
                    dataTemplate: undefined, // keep it
                    selectedTemplateName: "None",
                });
        }
        tempSettingTemplate.templateContent = configurationTelemetry;
        props.setCurrentSettingTemplate(tempSettingTemplate);
        if (mandatoryAddCheck()) {
            services.warningNotification(" ", "Maximum number of slave added");
        }
        setSettingTemplate(null);
    }

    function onRemoveConfigModalShow(param: TemplateConfigurationSlave) {
        return () => {
            setParamToDelete(param);
            setConfirmDeleteShow(true);
        };
    }

    const interfaceProperties = React.useMemo(() => {
        if (props.currentDMInterface != null && props.currentDMInterface.propertiesStructure != null) {
            switch (props.currentDMInterface.propertiesStructure) {
                case PropertiesStructure.INPUT:
                    return (
                        <InputSettingInput
                            currentInterfaceStep={props.currentInterfaceStep}
                            currentSettingTemplate={props.currentSettingTemplate}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                            suggestions={interfaceSettingEnums}
                        />
                    );
                case PropertiesStructure.OUTPUT:
                    return (
                        <OutputSettingInput
                            currentInterfaceStep={props.currentInterfaceStep}
                            currentSettingTemplate={props.currentSettingTemplate}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                            suggestions={interfaceSettingEnums}
                        />
                    );
                case PropertiesStructure.ANALOG:
                    return <></>;
                case PropertiesStructure.SERIAL:
                    return (
                        <SerialSettingInput
                            deviceModelSlave={deviceModelSlave}
                            currentInterfaceStep={props.currentInterfaceStep}
                            currentSettingTemplate={props.currentSettingTemplate}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                            suggestions={interfaceSettingEnums}
                        />
                    );
                case PropertiesStructure.EMBEDDED:
                    return <></>;
                case PropertiesStructure.BLUETOOTH:
                    return <></>;
                case PropertiesStructure.CAN:
                    return <></>;
            }
        }
        return <></>;
    }, [interfaceSettingEnums, props.currentDMInterface, deviceModelSlave]);

    function onDeleteCustom() {
        const tempSettingTemplate = props.currentSettingTemplate;
        const configurationTelemetry = tempSettingTemplate.templateContent as TemplateConfigurationTelemetry;
        configurationTelemetry.interfaces[props.currentInterfaceStep].slaves =
            configurationTelemetry.interfaces[props.currentInterfaceStep].slaves
                .filter((e) => paramToDelete !== e);
        setSettingTemplate(null);
        setConfirmDeleteShow(false);
    }

    const columns = [
        {key: "index", className: "col-2", render: ""},
        {key: "model_name", className: "col-3", render: "Device Model Name"},
        {key: "template", className: "col-3", render: "Template Name"},
        {key: "configuration", className: "col-3", render: "Configure"},
        {key: "cancel", className: "col-1", render: "Delete"},
    ];

    React.useEffect(() => {
        findTemplateByDeviceModel();
    }, [deviceModelSlave]);

    function findTemplateByDeviceModel() {
        if (deviceModelSlave != null) {
            configurabilityController.findSettingTemplateByDeviceModelGuid(deviceModelSlave.value).then(
                (response) => {
                    let settingTemplatesSuggestion: Array<Suggestion<SettingTemplate>>;
                    if (response.set.length > 0) {
                        settingTemplatesSuggestion = response.set
                            .map((item) => ({item, label: item.name, value: item.guid}));
                    } else {
                        settingTemplatesSuggestion = [];
                        setSettingTemplate(null);
                    }
                    setSettingTemplates(settingTemplatesSuggestion);
                },
            );
        }
    }

    function onConfigureDeviceModel(deviceModelGuid: string, index: number) {
        return () => {
            setCurrentSlaveIndex(index);
            setRawDataModalShow(true);
        };
    }

    function onHideModal() {
        findTemplateByDeviceModel();
        setCreateSTSModalShow(false);
    }

    function onCreateSTS() {
        return () => {
            setCreateSTSModalShow(true);
        };
    }

    React.useEffect(() => {
        configurabilityController.findInterfaceSettingEnums().then((response) => {
            setInterfaceSettingEnums(response);
        });
        if (props.currentDMInterface.propertiesStructure !== PropertiesStructure.EMBEDDED) {
            props.setLoading(true);
            configurabilityController.getCompatibleDeviceModelsWithInterface(props.currentDMInterface.interfaceTypeGuid).then((response) => {
                const deviceModelAvalible = response.list.map((item) => ({item, label: item.name, value: item.guid}));
                setDeviceModelSlaves(deviceModelAvalible);
                if ((props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves != null &&
                    (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves[0] != null) {
                    const guid = (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves[0].deviceModelGuid;
                    if (guid != null) {
                        setDeviceModelSlave(deviceModelAvalible.find((e) => e.value === guid));
                    }
                }
                props.setLoading(false);
            });
        }
    }, []);

    function isSerial() {
        return props.currentDMInterface.supportBus;
    }

    function hasDefaultProp(suggestion: Suggestion<DeviceModel>) {
        return suggestion.item.defaultProperties != null;
    }

    function onSelectDeviceModelSlave() {
        return (suggestion: Suggestion<DeviceModel>) => {
            if (suggestion == null) {
                setDeviceModelSlave(suggestion);
            } else {
                if (isSerial() && hasDefaultProp(suggestion) &&
                    (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves != null &&
                    (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves.length > 0 &&
                    props.currentDMInterface != null &&
                    props.currentDMInterface.propertiesStructure != null &&
                    props.currentDMInterface.propertiesStructure === PropertiesStructure.SERIAL) {
                    const alreadySelectedDM = props.deviceModelSlaves
                        .find((e) => e.value === (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves[0].deviceModelGuid);
                    if (alreadySelectedDM != null && alreadySelectedDM.item.defaultProperties != null) {
                        (suggestion.item.defaultProperties as SerialSetting).protocol === (alreadySelectedDM.item.defaultProperties as SerialSetting).protocol ?
                            setDeviceModelSlave(suggestion) :
                            services.warningNotification("Incompatible Protocol", "Try with different device Model");
                    }
                } else {
                    setDeviceModelSlave(suggestion);
                }
            }
        };
    }

    return (
        <>
            <Form>
                <Row className={"justify-content-end mb-5"}>
                    <Col className={"col-7"}>
                        <H5>
                            {props.currentSettingTemplate.guid == null ? "Define " : "Update "}
                            which device models will be connected to the interface
                            <b>{props.currentDMInterface != null ? " " + props.currentDMInterface.name + " " : " "}</b>
                            for the <b>{" " + props.selectedDeviceModel.label}</b>.

                        </H5>
                    </Col>
                    <Col className={"col-2"} style={{textAlign: "end"}}>
                        Step: {props.currentInterfaceStep + 1}/{props.selectedDMInterfaces.length}
                    </Col>
                </Row>
                {props.currentDMInterface.propertiesStructure !== "EMBEDDED" ? (
                    <>
                        <Row className={"mx-2 pb-3"}>
                            <Col>
                                <Form.Label>Select Device Model Slave *</Form.Label>
                                <SelectInput
                                    onChange={onSelectDeviceModelSlave()}
                                    name="Device Model"
                                    options={deviceModelSlaves}
                                    searchable={true}
                                    value={deviceModelSlave}
                                />
                            </Col>
                            <Col>
                                <SelectInputTitle
                                    label={"Select Template"}
                                    tooltipText={"If a template is selected, its' going to use it's data, otherwise data needs to be configured"}
                                />
                                <SelectInput
                                    onChange={setSettingTemplate as any}
                                    name="Device Model"
                                    options={settingTemplates}
                                    searchable={true}
                                    clearable={true}
                                    value={settingTemplate}
                                    disabled={deviceModelSlave == null ||
                                    (settingTemplates != null && settingTemplates.length < 1)}
                                />
                            </Col>
                            <Col style={{alignSelf: "flex-end"}}>
                                <Button disabled={mandatoryAddCheck()} variant="strong" onClick={onAddDeviceModel}>
                                    Add
                                </Button>
                            </Col>
                            <Col style={{alignSelf: "flex-end", textAlign: "end"}}>
                                {deviceModelSlave != null && (
                                    <Button variant="primary" onClick={onCreateSTS()}>
                                        Create Setting Template Slave for {deviceModelSlave.label}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row className={"mx-2 pb-3"}>
                            {(props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves != null &&
                            (props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves.length > 0 ?
                                (
                                    <Table striped={true} bordered={true} hover={true}>
                                        <thead>
                                        <tr>
                                            {columns.map((col) => (
                                                <th key={col.key} className={col.className}>{col.render}</th>))}
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {(props.currentSettingTemplate.templateContent as TemplateConfigurationTelemetry).interfaces[props.currentInterfaceStep].slaves.map(
                                            (value, index) => (
                                                <tr key={index}>
                                                    <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                                        {index}
                                                    </td>
                                                    <td style={{verticalAlign: "middle"}}>
                                                        {value.deviceModelName}
                                                    </td>
                                                    <td style={{verticalAlign: "middle"}}>
                                                        {value.selectedTemplateName}
                                                    </td>
                                                    <td style={{verticalAlign: "middle"}}>
                                                        <Row
                                                            style={{
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Button
                                                                className={"mx-2"}
                                                                style={{transform: "rotate(-90deg)"}}
                                                                small={true}
                                                                variant={value.configured ? "secondary" : "primary"}
                                                                iconProps={{glyph: icons.CHEVRON_DOWN}}
                                                                onClick={onConfigureDeviceModel(value.deviceModelGuid, index)}
                                                            />
                                                            <div className={"mx-2"}>
                                                                {value.configured ?
                                                                    (<Check style={{fill: "green"}}/>) :
                                                                    (<Error style={{fill: "red"}}/>)}
                                                            </div>
                                                        </Row>
                                                    </td>
                                                    <td style={{verticalAlign: "middle"}}>
                                                        <Button
                                                            iconProps={services.removeIconButton()}
                                                            onClick={onRemoveConfigModalShow(value)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <Col style={{textAlign: "center"}}>
                                        <EmptyState>
                                            No slave selected <br/> Select a device model and press add
                                        </EmptyState>
                                    </Col>
                                )}
                        </Row>
                        <Row className={"justify-content-center mt-5"}>
                            <Col style={{textAlign: "center"}}>
                                <H5> {props.currentSettingTemplate.guid == null ? "Define " : "Update "}
                                    the interface properties for this Device Model Slave.</H5>
                            </Col>
                        </Row>
                        <Col className={"mb-5 mt-2"}>
                            {interfaceProperties}
                        </Col>
                    </>
                ) : (
                    <>
                        <RawDataTableCustom
                            section={SectionsModal.rawDataAlarmType}
                            currentSettingTemplate={props.currentSettingTemplate}
                            currentInterfaceStep={props.currentInterfaceStep}
                            selectedDeviceModel={props.selectedDeviceModel}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                        />
                        <RawDataTableCustom
                            section={SectionsModal.rawDataDiagnosticType}
                            currentSettingTemplate={props.currentSettingTemplate}
                            currentInterfaceStep={props.currentInterfaceStep}
                            selectedDeviceModel={props.selectedDeviceModel}
                            setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                        />
                    </>
                )}
                {/* Bottom nav buttons */}
                <Row className="w-100 d-flex flex-column">
                    <div className="text-right pt-4">
                        <Button onClick={services.previousStep(props.setCurrentStep, props.currentStep)}>
                            Previous
                        </Button>
                        {(props.selectedDMInterfaces.length > 1 && props.currentInterfaceStep > 0) ? (
                            <>
                                {" "}
                                <Button variant="primary" onClick={props.onPreviousInterface}>
                                    Previous Interface
                                </Button>
                            </>
                        ) : <></>}
                        {" "}
                        <Button disabled={mandatoryFieldsCheck()} variant="strong" onClick={onSubmit}>
                            {props.currentInterfaceStep + 1 !== props.selectedDMInterfaces.length ? "Next" :
                                (props.currentSettingTemplate && props.currentSettingTemplate.guid ? "Update Setting Template" : "Create Setting Template")}
                        </Button>
                    </div>
                </Row>
            </Form>
            <RawDataModal
                loading={props.loading}
                show={rawDataModalShow}
                onHide={services.onHideModal(setRawDataModalShow)}
                currentSettingTemplate={props.currentSettingTemplate}
                setCurrentSettingTemplate={props.setCurrentSettingTemplate}
                currentInterfaceStep={props.currentInterfaceStep}
                currentSlaveIndex={currentSlaveIndex}
            />
            <ConfirmModal
                onConfirm={onDeleteCustom}
                show={confirmDeleteShow}
                onHide={services.onHideModal(setConfirmDeleteShow)}
            />
            <CreateSTSlaveModal
                setCurrentStep={props.setCurrentStep}
                selectedDeviceModel={deviceModelSlave}
                onHide={onHideModal}
                show={createSTSModalShow}
                setLoading={props.setLoading}
            />
        </>
    );
};

export default InfoSection;
