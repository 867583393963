import {LocationCore} from "../../../../../models/location/location";

export function notDefaultWarehouse(location: LocationCore) {
    return !location.isDefaultWarehouse;
}

export function isDefaultWarehouse(location: LocationCore) {
    return location.isDefaultWarehouse;
}

export function locationToAutoSuggestionItem(location: LocationCore) {
    return {
        label: location.description ?? location.code,
        value: location.guid,
        location,
    };
}

export function itemToString<T extends { label: string }>(item: T) {
    return item && item.label;
}
