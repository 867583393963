import * as React from "react";
import {Modal} from "react-bootstrap";
import {DeviceStorageManagerState} from "../types";

interface StateModalProps {
    title: string;
    message: string;
    openOnState: DeviceStorageManagerState;
    state: DeviceStorageManagerState;
    returnStateOnClose: DeviceStorageManagerState;

    onClose(state: DeviceStorageManagerState): void;
}

export function StateModal({title, message, openOnState, state, returnStateOnClose, onClose}: StateModalProps) {
    function close() {
        onClose(returnStateOnClose);
    }

    return (
        <Modal show={openOnState === state} onHide={close} keyboard={true}>
            <Modal.Header closeButton={true}>
                <h4>{title}</h4>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
        </Modal>
    );
}
