import * as React from "react";

import {Button, SelectInput, TextInput} from "@danfoss/etui-core";
import {Check, Error} from "@danfoss/etui-icons";
import {Col, Form, Row} from "react-bootstrap";
import {Circle, LayerGroup, LayersControl, MapContainer, Marker, Polygon, TileLayer, useMap} from "react-leaflet";
import PhoneInput from "react-phone-number-input";
import GeolocationController from "../../../controllers/api/GeolocationController";
import Services from "../../../controllers/utils/Services";
import {Company} from "../../../models/company/companyModel";
import {Country, CountrySuggestion} from "../../../models/country/countryModel";
import {GeoPoint} from "../../../models/location/geopositionModel";
import LoadingModal from "../../common/LoadingModal";
import GeneralAlert from "../../common/GeneralAlert";
import TranslationController from "../../../controllers/api/TranslationController";

interface SecondFormProps {
    currentStep: number;
    setCurrentStep: (step: number) => void;
    currentCompany: Company;
    setCurrentCompany: (C: Company) => void;
    cityInputValue: string;
    provinceInputValue: string;
    locationAddressInputValue: string;
    codeInputValue: string;
    locationDescriptionInputValue: string;
    locationPhoneNumberInputValue: string;
    locationPrefixPhoneNumberInputValue: string;
    locationStreetNumberInputValue: string;
    verifiedLocation: boolean;
    selectedCountry: Country;
    position: GeoPoint;
    customPosition: GeoPoint;
    markerPosition: GeoPoint;
    setCityInputValue: (cityInputValue: string) => void;
    setProvinceInputValue: (provinceInputValue: string) => void;
    setLocationAddressInputValue: (locationAddressInputValue: string) => void;
    setCodeInputValue: (codeInputValue: string) => void;
    setLocationDescriptionInputValue: (locationDescriptionInputValue: string) => void;
    setLocationPhoneNumberValue: (locationPhoneNumberValue: string) => void;
    setLocationPrefixPhoneNumberInputValue: (locationPrefixPhoneNumberInputValue: string) => void;
    setLocationStreetNumberValue: (locationStreetNumberValue: string) => void;
    setVerifiedLocation: (verifiedLocation: boolean) => void;
    setSelectedCountry: (country: Country) => void;
    setPosition: (position: GeoPoint) => void;
    setCustomPosition: (position: GeoPoint) => void;
    setMarkerPosition: (position: GeoPoint) => void;
    country: string;
    loading: boolean;
    setLoading: (status: boolean) => void;
    countries: CountrySuggestion[];
    setCountry: (country: string) => void;
    submit: () => void;
    validPhoneNumber: boolean;
    setValidPhoneNumber: (status: boolean) => void;
}

const DefaultWarehouseSection: React.FC<SecondFormProps> = (props) => {
    // controllers
    const geolocationController: GeolocationController = new GeolocationController();
    const translationController: TranslationController = new TranslationController();
    const services: Services = new Services();
    const INFO_TRANSLATION_TEXT_KEY = "operations.company_creation.default_warehouse.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);

    function DraggableMarker() {
        const map = useMap();
        map.flyTo({lat: parseFloat(props.markerPosition.lat), lng: parseFloat(props.markerPosition.lng)}, 14);
        const markerRef = React.useRef(null);
        const eventHandlers = React.useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current;
                    if (marker != null) {
                        const distanceInKmFromCustomPoint: number = getDistanceFromLatLonInKm(
                            marker.getLatLng().lat,
                            marker.getLatLng().lng,
                            parseFloat(props.position.lat),
                            parseFloat(props.position.lng),
                        );
                        if (distanceInKmFromCustomPoint < services.maxDistanceBetweenPointsKm) {
                            props.setCustomPosition(marker.getLatLng());
                            props.setMarkerPosition(marker.getLatLng());
                        } else {
                            services.warningNotification(" ", "Distance between selected address and custom position it's bigger than the maximum distance (" +
                                services.maxDistanceBetweenPointsKm + " km)");
                            props.setCustomPosition({lat: "", lng: ""});
                            props.setMarkerPosition(props.position);
                        }
                        if (
                            props.position.lng === services.firstPoint.lng &&
                            props.position.lat === services.firstPoint.lat
                        ) {
                            services.warningNotification(" ", "Verify the address before setting custom position");
                            props.setCustomPosition({lat: "", lng: ""});
                            props.setMarkerPosition(services.firstPoint);
                            props.setPosition(services.firstPoint);
                        }
                    }
                },
            }),
            [],
        );
        return (
            // @ts-ignore eventHandlers non è stato ancora implementato completaente in react
            <Marker
                draggable={true}
                position={{lat: parseFloat(props.markerPosition.lat), lng: parseFloat(props.markerPosition.lng)}}
                ref={markerRef}
                eventHandlers={eventHandlers}
            />
        );
    }

    function mandatoryVerifyAddressCheck() {
        return (services.isEmpty(props.locationAddressInputValue) || services.isEmpty(props.country));
    }

    function mandatoryFieldsCheck() {
        return (services.isEmpty(props.locationAddressInputValue) || services.isEmpty(props.cityInputValue)
            || services.isEmpty(props.country) || services.isEmpty(props.provinceInputValue)
            || services.isEmpty(props.locationDescriptionInputValue) || services.isEmpty(props.codeInputValue)
            || !props.verifiedLocation ||
            (props.locationPhoneNumberInputValue.trim().length > 0 && !props.validPhoneNumber)
        );
    }

    function getGeocodeAddress() {
        props.setLoading(true);
        const JSONResponse = geolocationController.getGeocodeAddress(
            props.locationAddressInputValue,
            props.cityInputValue,
            props.selectedCountry.name,
            props.provinceInputValue,
            props.locationStreetNumberInputValue,
        ).then(
            (response: { status: number, body: any }) => {
                if (response.status >= 400 && response.status <= 500) {
                    services.warningNotification("Geolocation failed", "Change input parameters");
                    props.setVerifiedLocation(false);
                }
                if (response.status >= 200 && response.status <= 299) {
                    services.successNotification(" ", "Location found");
                    const newPosition = {
                        lat: response.body.geoPoint.latitude,
                        lng: response.body.geoPoint.longitude,
                    };
                    props.setProvinceInputValue(response.body.address.province);
                    props.setCityInputValue(response.body.address.city);
                    props.setLocationAddressInputValue(response.body.address.street);
                    props.setCustomPosition({lat: "", lng: ""});
                    props.setMarkerPosition(newPosition);
                    props.setPosition(newPosition);
                    props.setVerifiedLocation(true);
                }
                props.setLoading(false);
            });
    }

    function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
        const R: number = 6371; // Radius of the earth in km
        const dLat: number = deg2rad(lat2 - lat1);  // deg2rad below
        const dLon: number = deg2rad(lon2 - lon1);
        const a: number =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d: number = R * c; // Distance in km
        return d;
    }

    function deg2rad(deg: number) {
        return deg * (Math.PI / 180);
    }

    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <Form id={"defaultWarehouseSection"}>
                <Row className="w-100 m-0">
                    <GeneralAlert
                        message={infoMessage}
                    />
                </Row>
                <h5 className={""}>
                    Default warehouse Information
                </h5>
                <Row>
                    <Col>
                        <p>Code *</p>
                        <TextInput
                            name={"formCode"}
                            label={"Insert Code"}
                            type={"text"}
                            value={props.codeInputValue}
                            onChange={services.handleTextOnChange(props.setCodeInputValue)}
                        />
                        {/*<p>Prefix Phone Number</p>*/}
                        {/*<TextInput*/}
                        {/*    name={"formPrefixPhoneNumber"}*/}
                        {/*    label={"Insert Prefix PhoneNumber"}*/}
                        {/*    type={"text"}*/}
                        {/*    value={props.locationPrefixPhoneNumberInputValue}*/}
                        {/*    onChange={services.handleTextOnChange(props.setLocationPrefixPhoneNumberInputValue)}*/}
                        {/*/>*/}
                    </Col>
                    <Col>
                        <p>Description *</p>
                        <TextInput
                            name={"formDescription"}
                            label={"Insert Description"}
                            type={"text"}
                            value={props.locationDescriptionInputValue}
                            onChange={services.handleTextOnChange(props.setLocationDescriptionInputValue)}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <p>Phone Number</p>
                            {
                                props.locationPhoneNumberInputValue.trim() !== "" && (
                                    props.validPhoneNumber ? (
                                        <Row className="mx-1">
                                            <Check fontSize={18} color="green"/>
                                            <p style={{marginBottom: ".5rem", color: "green", fontSize: "12px"}}>
                                                Valid!
                                            </p>
                                        </Row>
                                    ) : (
                                        <Row className="mx-1">
                                            <Error fontSize={18} color="red"/>
                                            <p style={{marginBottom: ".5rem", color: "red", fontSize: "12px"}}>
                                                Not valid!
                                            </p>
                                        </Row>
                                    )
                                )
                            }
                        </Row>
                        <PhoneInput
                            international={true}
                            value={props.locationPhoneNumberInputValue}
                            onChange={services.handlePhoneNumberOnChange
                            (props.setLocationPhoneNumberValue,
                                props.setLocationPrefixPhoneNumberInputValue)}
                        />
                    </Col>
                </Row>
                <Row className={"my-5"}>
                    <Col>
                        <h5 className={""}>
                            Set Location
                        </h5>
                        <Row>
                            <Col>
                                <p>Country *</p>
                                <>
                                    <SelectInput
                                        onChange={services.onSelectedItemAndLabel(props.setCountry, props.setSelectedCountry)}
                                        name="Country"
                                        label="Insert Country"
                                        options={props.countries.slice(1)}
                                        searchable={true}
                                        value={props.countries.find((object) => object.label === props.country)}
                                        defaultValue={props.countries[0]}
                                    />
                                </>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Province</p>
                                <TextInput
                                    name={"formProvince"}
                                    label={"Insert Province"}
                                    type={"text"}
                                    value={props.provinceInputValue}
                                    onChange={services.handleTextOnChange(props.setProvinceInputValue)}
                                />
                            </Col>
                        </Row>

                        <p>City</p>
                        <TextInput
                            name={"formCity"}
                            label={"Insert City"}
                            type={"text"}
                            value={props.cityInputValue}
                            onChange={services.handleTextOnChange(props.setCityInputValue)}
                        />
                        <p>Address *</p>
                        <TextInput
                            name={"formAddress"}
                            label={"Insert Address "}
                            type={"text"}
                            value={props.locationAddressInputValue}
                            onChange={services.handleTextOnChange(props.setLocationAddressInputValue)}
                        />
                        <Row>
                            <Col>
                                <p>Street Number</p>
                                <TextInput
                                    name={"formStreetNumber"}
                                    label={"Insert Street Number"}
                                    type={"text"}
                                    value={props.locationStreetNumberInputValue}
                                    onChange={services.handleTextOnChange(props.setLocationStreetNumberValue)}
                                />
                            </Col>
                            <Col>
                                <div className={"text-right"} style={{marginTop: "40px"}}>
                                    <Button
                                        onClick={getGeocodeAddress}
                                        variant="strong"
                                        styles={{root: {mr: 10}}}
                                        disabled={mandatoryVerifyAddressCheck()}
                                    >
                                        Verify Address *
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <MapContainer
                            center={{
                                lat: parseFloat(services.firstPoint.lat),
                                lng: parseFloat(services.firstPoint.lng),
                            }}
                            zoom={13}
                            scrollWheelZoom={true}
                        >
                            <LayersControl position="topright">
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LayersControl.Overlay checked={false} name="Show maximum custom lat">
                                    <LayerGroup>
                                        <Circle
                                            center={{
                                                lat: parseFloat(props.position.lat),
                                                lng: parseFloat(props.position.lng),
                                            }}
                                            pathOptions={{fillColor: "red"}}
                                            radius={services.maxDistanceBetweenPointsKm * 1000}
                                            stroke={false}
                                        />
                                    </LayerGroup>
                                </LayersControl.Overlay>
                            </LayersControl>
                            <DraggableMarker/>
                        </MapContainer>
                        <Row>
                            <Col>
                                <p>Custom lat</p>
                                <TextInput
                                    name={"formCustomLat"}
                                    type={"text"}
                                    value={props.customPosition.lat}
                                    onChange={services.handleTextOnChange(props.setCustomPosition)}
                                    disabled={true}
                                />
                            </Col>
                            <Col>
                                <p>Custom Lng</p>
                                <TextInput
                                    name={"formCustomLng"}
                                    type={"text"}
                                    value={props.customPosition.lng}
                                    onChange={services.handleTextOnChange(props.setCustomPosition)}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mx-2 pb-3">

                    <Col className="my-auto">
                        <b><p style={{fontSize: 14}}>* mandatory fields</p></b>
                    </Col>
                    <Col className="my-auto">
                        <div className={"mx-2"} style={{textAlign: "right"}}>
                            <Button
                                onClick={services.previousStep(props.setCurrentStep, props.currentStep)}
                                variant="tertiary"
                                styles={{root: {mr: 10}}}
                            >
                                Previous
                            </Button>
                            <Button
                                onClick={props.submit}
                                variant="strong"
                                disabled={mandatoryFieldsCheck()}
                            >
                                {props.currentCompany.defaultWarehouse == null ? " Create & next" : " Update & next"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            <LoadingModal loading={props.loading}/>
        </>
    );
};

export default DefaultWarehouseSection;
