import * as React from "react";
import {
    consumeCurrenTargetValue,
    consumeTargetValue, onKey,
    selectCurrentTargetText, stopPropagation,
    toOutsideElements,
} from "../../../../../../../../utils/eventsHandling";
import {constant} from "../../../../../../../../utils/functions";
import {Td} from "../../../../../../../common/DataTable";
import {ComponentModelCompaniesTableDataCellProps} from "../types";

import "./styles.scss";
import {Button} from "../../../../../../../common/Button";
import {Icon} from "../../../../../../../common/Icon";

export function GenericInputCell(
    {
        value,
        state,
        disabled,
        onValueChange,
        onResetValue,
    }: ComponentModelCompaniesTableDataCellProps,
) {

    const [cellValue, setCellValue] = React.useState<string | number>("");

    React.useEffect(() => {
        setCellValue(value ?? "");
    }, [value]);

    return (
        <Td
            state={state}
            className={"generic-cell-company-component-model-cell company-component-model-field-data-cell"}
            onBlur={toOutsideElements(constant(onValueChange, cellValue))}
        >
            <label className={"generic-input input-field"}>
                <input
                    value={cellValue}
                    className={"input-field"}
                    onChange={consumeTargetValue(setCellValue)}
                    onFocus={selectCurrentTargetText}
                    onKeyDown={onKey("Enter", consumeCurrenTargetValue(onValueChange))}
                    disabled={disabled}
                />
                {state === "edited" ? (
                    <Button variant={"tertiary"} onClick={stopPropagation(onResetValue)}>
                        <Icon name={"undo"}/>
                    </Button>
                    // <span tabIndex={0} className={"undo-changed tertiary-btn"} >
                    //     <Icon glyph={icons.UNDO} size={16}/>
                    // </span>
                ) : null}
            </label>
        </Td>
    );
}
