import * as React from "react";
import {Device} from "../../../../models/device/device";
import {DeviceStorage} from "../../../../models/device/storage";
import {FailureType, IdentifierType, JSONResourceNotFound} from "../../../../models/utils/jsonFailure";
import {JSONResult} from "../../../../models/utils/jsonResult";
import {SearchResultError} from "../../types";
import {isDeviceValidForStorage} from "../../utils";
import {NotFoundSearchResult} from "./NotFoundSearchResult";
import {NotMovableSearchResult} from "./NotMovableSearchResult";
import {SuccessfulSearchResult} from "./SuccessfulSearchResult";

export function deviceSearchResultToListItem(
    onMouseEnter: React.MouseEventHandler<HTMLLIElement>,
    visibleCodes: Set<string>,
) {
    return (
        result: JSONResult<DeviceStorage, SearchResultError<JSONResourceNotFound<Device | string>>>,
        index,
    ) => {
        if (result.successful === true) {
            const deviceGuid = result.result.device.guid;
            const deviceCode = result.result.device.code;
            return (
                <SuccessfulSearchResult
                    key={deviceGuid}
                    data-value={deviceCode}
                    device={result.result.device}
                    location={result.result.location}
                    enterDate={result.result.enterDate}
                    onMouseEnter={onMouseEnter}
                    className={!visibleCodes.has(deviceCode) ? "not-in-textarea" : ""}
                />
            );
        }
        if ((result.error.type === FailureType.RESOURCE_NOT_FOUND)
            && (result.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP)
            && isDeviceValidForStorage(result.error.identifierValue)) {
            const deviceGuid = result.error.identifierValue.core.guid;
            const deviceCode = result.error.identifierValue.core.code;
            return (
                <SuccessfulSearchResult
                    key={deviceGuid}
                    data-value={deviceCode}
                    device={result.error.identifierValue.core}
                    onMouseEnter={onMouseEnter}
                    className={!visibleCodes.has(deviceCode) ? "not-in-textarea" : ""}
                />
            );
        }
        if ((result.error.type === FailureType.RESOURCE_NOT_FOUND)
            && (result.error.identifierType === IdentifierType.ENTITY_RELATIONSHIP)) {
            const deviceGuid = result.error.identifierValue.core.guid;
            const deviceCode = result.error.identifierValue.core.code;
            return (
                <NotMovableSearchResult
                    key={deviceGuid}
                    data-value={deviceCode}
                    device={result.error.identifierValue}
                    onMouseEnter={onMouseEnter}
                    className={!visibleCodes.has(deviceCode) ? "not-in-textarea" : ""}
                />
            );
        }
        if ((result.error.type === FailureType.RESOURCE_NOT_FOUND)
            && result.error.identifierType === IdentifierType.CODE) {
            return (
                <NotFoundSearchResult
                    key={index}
                    data-value={result.error.identifierValue}
                    deviceCode={result.error.identifierValue}
                    onMouseEnter={onMouseEnter}
                    className={!visibleCodes.has(result.error.identifierValue) ? "not-in-textarea" : ""}
                />
            );
        }
        return null;
    };
}
