import {Breadcrumb, BreadcrumbItem, CustomAjax, LoadablePage} from "./page";

export class TaskPage extends LoadablePage {
    constructor(protected getUrl: string, protected startTaskUrl: string, protected  breadcrumb: Breadcrumb) {
        super(getUrl, breadcrumb);
    }

    protected onLoadSuccess(): void {
        this.bindStartTaskEvent();
    }

    private bindStartTaskEvent() {
        $("#startTaskButton").one("click", () => {
            CustomAjax.simplePostRequest(this.startTaskUrl, (data) => {
                this.bindStartTaskEvent();
                alert(data);
            });
        });
    }
}

export class InstallAndMoveDevicesPage extends TaskPage {
    constructor() {
        super("installAndMoveDevicesPage", "tasks/installAndMoveDevices", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Task", false),
            new BreadcrumbItem(null, "Install and Move devices", true),
        ]));
    }
}

export class InstallDevicesPage extends TaskPage {
    constructor() {
        super("installDevicesPage", "tasks/installDevices", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Task", false),
            new BreadcrumbItem(null, "Install devices", true),
        ]));
    }
}

export class MoveDevicesPage extends TaskPage {
    constructor() {
        super("moveDevicesPage", "tasks/moveDevices", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Task", false),
            new BreadcrumbItem(null, "Move devices", true),
        ]));
    }
}

export class CreateUsersPage extends TaskPage {
    constructor() {
        super("createUsersPage", "tasks/createUsers", new Breadcrumb([
            new BreadcrumbItem("./", "Dashboard", false),
            new BreadcrumbItem(null, "Task", false),
            new BreadcrumbItem(null, "Create users", true),
        ]));
    }
}
