import * as React from "react";
import {Row} from "react-bootstrap";
import {SketchPicker} from "react-color";
import Services from "../../controllers/utils/Services";
import {TableCompanyAlarmType} from "../../models/alarm/alarmType";
import {TableComponentType} from "../../models/component/componentType";

interface ColorPickerModalProps {
    arrayTables: Array<TableComponentType | TableCompanyAlarmType>;
    setArrayTables: (t: Array<TableComponentType | TableCompanyAlarmType>) => void;
    value: TableComponentType | TableCompanyAlarmType;
}

const ColorPickerModal: React.FC<ColorPickerModalProps> = (props) => {
    const services = new Services();

    function onInputTextChange(table: TableComponentType | TableCompanyAlarmType, value: any, column: string) {
        const newComponentType = props.arrayTables.map((c) => {
            if (table.item.guid === c.item.guid &&
                props.arrayTables.indexOf(table) === props.arrayTables.indexOf(c)) {
                switch (column) {
                    case "color":
                        c.color = value;
                        break;
                    case "selectedColor":
                        c.selectedColor = value;
                        break;
                }
            }
            return c;
        });
        props.setArrayTables(newComponentType);
    }

    function onColorChange() {
        return (t) => {
            onInputTextChange(props.value, t.rgb, "selectedColor");
            onInputTextChange(props.value, t.hex.toUpperCase(), "color");
        };
    }

    function onBooleanChange(table: TableComponentType | TableCompanyAlarmType, value: boolean, column: string) {
        return () => {
            const newComponentType = props.arrayTables.map((c) => {
                if (table.item.guid === c.item.guid &&
                    props.arrayTables.indexOf(table) === props.arrayTables.indexOf(c)) {
                    switch (column) {
                        case "displayColorPicker":
                            c.displayColorPicker = value;
                            break;
                    }
                }
                return c;
            });
            props.setArrayTables(newComponentType);
        };

    }

    return (
        <Row className={"px-3"} style={{justifyContent: "space-between"}}>
            <div>
                <div
                    style={services.styles.swatch}
                    onClick={onBooleanChange(props.value, !props.value.displayColorPicker, "displayColorPicker")}
                >
                    <div
                        style={{
                            width: "150px",
                            height: "40px",
                            borderRadius: "2px",
                            background: `rgba(${props.value.selectedColor.r}, ${props.value.selectedColor.g}, ${props.value.selectedColor.b}, ${props.value.selectedColor.a})`,
                        }}
                    />
                </div>
                {
                    props.value.displayColorPicker ?
                        (
                            <div style={services.styles.popover}>
                                <div
                                    style={services.styles.cover}
                                    onClick={onBooleanChange(props.value, false, "displayColorPicker")}
                                />
                                <SketchPicker
                                    color={props.value.selectedColor}
                                    presetColors={services.presetColors}
                                    onChange={onColorChange()}
                                />
                            </div>
                        ) : null
                }
            </div>
            <p>
                {props.value.color}
            </p>
        </Row>
    );
};

export default ColorPickerModal;
