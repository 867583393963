import {Translation} from "../../models/translations/translationsModels";

class TranslationController {
    public async putTranslation(companyGuid: string, translation: Translation) {
        const response = await fetch("/companies/" + companyGuid + "/translation", {
            method: "PUT",
            body: JSON.stringify(translation),
        });
        return response.json().then(
            (b) => {
                return {
                    status: response.status,
                    content: b,
                };
            },
        );
    }

    public async getTranslationByTextKey(textKey: string) {
        const response = await fetch("/translations?textKey=" + textKey);
        return response.json().then(
            (b) => {
                return {
                    status: response.status,
                    content: b,
                };
            },
        );
    }
}

export default TranslationController;
